/* @flow */
import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import TextareaAutoSize from 'react-textarea-autosize';

import styles from './TextArea.module.scss';

type Props = {
  className?: string,
  autoSize?: boolean,
  autoFocus?: boolean,
  noResize?: boolean,
  disableNewLine?: boolean,
  inputRef?: Function,
  onChange?: Function,
};

const onChangeDisableNewLine = (onChange) => (e) => {
  e.target.value = e.target.value.replace(/\r?\n/gi, '');
  if (onChange) {
    onChange(e);
  }
};

const TextArea = ({
  className,
  noResize,
  autoSize,
  autoFocus,
  inputRef,
  onChange,
  disableNewLine,
  ...rest
}: Props) => {
  const commonProps = {
    className: classNames([
      styles.textarea,
      { [styles['no-resize']]: noResize },
      className,
    ]),
    onChange: disableNewLine ? onChangeDisableNewLine(onChange) : onChange,
  };
  /** Auto focus input when component did mount */
  const inputEl = useRef(null);
  /** Set input ref */
  useEffect(
    () => {
      if (isFunction(inputRef)) {
        inputRef(inputEl.current);
      }
      if (autoFocus && inputEl.current) {
        // Set cursor to the end of the input
        inputEl?.current?.setSelectionRange(
          rest?.value?.length || 0,
          rest?.value?.length || 0,
        );
        inputEl.current.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputEl, autoFocus, inputRef],
  );

  if (autoSize) {
    return <TextareaAutoSize ref={inputEl} {...commonProps} {...rest} />;
  }
  return <textarea ref={inputEl} {...commonProps} {...rest} />;
};

export default TextArea;
