/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DonotSuggestUserMutation($input: DonotSuggestUserInput!) {
    donotSuggestUser(input: $input) {
      donotSuggestUserId
      error {
        message
      }
    }
  }
`;

export default (
  donotSuggestUserId: string,
  { parentID }: { parentID: string },
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        donotSuggestUserId,
      },
    };

    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID,
        connectionKeys: [
          {
            key: 'SearchListView_PeopleYouMayKnow_peopleYouMayKnow',
          },
          {
            key: 'SearchMapView_PeopleYouMayKnow_peopleYouMayKnow',
          },
        ],
        deletedIDFieldName: 'donotSuggestUserId',
        pathToConnection: ['search', 'peopleYouMayKnow'],
      },
    ];

    const optimisticResponse = {
      donotSuggestUser: {
        donotSuggestUserId,
        error: null,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
