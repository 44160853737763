/**
 * @generated SignedSource<<e67cbd71c1c37ece7e879b6abbef196c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputSkillList_viewer$data = {
  readonly inputSkillListProfile?: {
    readonly avatar: {
      readonly id: string;
      readonly smallThumbnail: string | null;
    } | null;
    readonly first_name: string | null;
    readonly id: string;
    readonly last_name: string | null;
    readonly profile_color: string | null;
    readonly skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string | null;
          readonly user: {
            readonly avatar: {
              readonly id: string;
              readonly smallThumbnail: string | null;
            } | null;
            readonly id: string;
            readonly profile_color: string | null;
            readonly username: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly skillCreation: {
    readonly id: string;
    readonly title_suggestions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "InputSkillList_viewer";
};
export type InputSkillList_viewer$key = {
  readonly " $data"?: InputSkillList_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputSkillList_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallThumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 8,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "other_user_id"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipOwnSkills"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "title_term"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "skillCreation",
          "title_suggestions"
        ]
      }
    ]
  },
  "name": "InputSkillList_viewer",
  "selections": [
    {
      "condition": "skipOwnSkills",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "inputSkillListProfile",
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "other_user_id"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 25
                }
              ],
              "concreteType": "SkillConnection",
              "kind": "LinkedField",
              "name": "skills",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SkillEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Skill",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "username",
                              "storageKey": null
                            },
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "skills(first:25)"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "title_term",
          "variableName": "title_term"
        }
      ],
      "concreteType": "SkillCreation",
      "kind": "LinkedField",
      "name": "skillCreation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "title_suggestions",
          "args": null,
          "concreteType": "TagConnection",
          "kind": "LinkedField",
          "name": "__SkillTitleAutoSuggest_skillCreation_title_suggestions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TagEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};
})();

(node as any).hash = "bceebd884c7f79b0e51953e626dee68e";

export default node;
