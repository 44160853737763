import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeleteAccountMutation($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      error {
        message
      }
    }
  }
`;

const DeleteAccountMutation = (): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {},
    };

    commitMutation({
      mutation,
      variables,
      // no optimistic response on purpose
      onCompleted: resolve,
      onError: reject,
    });
  });

export default DeleteAccountMutation;
