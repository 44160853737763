/**
 * @generated SignedSource<<3ab64c00cc455982463ae7e0828a2984>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeletePaymentMethodInput = {
  clientMutationId?: string | null;
  id: string;
};
export type DeletePaymentMethodMutation$variables = {
  input: DeletePaymentMethodInput;
};
export type DeletePaymentMethodMutation$data = {
  readonly deletePaymentMethod: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly paymentMethodId: string | null;
    readonly paymentMethods: {
      readonly defaultPaymentMethodId: string | null;
      readonly id: string;
    } | null;
    readonly user: {
      readonly id: string;
      readonly settings: {
        readonly has_valid_payment_method: boolean | null;
        readonly id: string;
      } | null;
    } | null;
  } | null;
};
export type DeletePaymentMethodMutation = {
  response: DeletePaymentMethodMutation$data;
  variables: DeletePaymentMethodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentMethodId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethods",
  "kind": "LinkedField",
  "name": "paymentMethods",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPaymentMethodId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_valid_payment_method",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePaymentMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePaymentMethodPayload",
        "kind": "LinkedField",
        "name": "deletePaymentMethod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePaymentMethodMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePaymentMethodPayload",
        "kind": "LinkedField",
        "name": "deletePaymentMethod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a04c410a0ad8d9da4435beaeeef2a7a0",
    "id": null,
    "metadata": {},
    "name": "DeletePaymentMethodMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePaymentMethodMutation(\n  $input: DeletePaymentMethodInput!\n) {\n  deletePaymentMethod(input: $input) {\n    paymentMethodId\n    paymentMethods {\n      id\n      defaultPaymentMethodId\n    }\n    user {\n      id\n      settings {\n        id\n        has_valid_payment_method\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a715d5cfc25727175128858d3ad11169";

export default node;
