/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './Tile.module.scss';

type Props = {
  className: ?string,
  dashedBorder: ?boolean,
  uiType: ?'clay' | 'transparent' | 'clay-split',
};

const Tile = ({ className, dashedBorder, uiType, ...rest }: Props) => (
  <div
    className={classNames(
      styles.tile,
      { [styles['dashed-border']]: dashedBorder },
      { [styles[uiType]]: uiType },
      className,
    )}
    {...rest}
  />
);

export default Tile;
