/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation RemoveMediaFromSkillMutation($input: RemoveMediaFromSkillInput!) {
    removeMediaFromSkill(input: $input) {
      deletedMediaId
      error {
        message
      }
    }
  }
`;

export default (media_id: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        media_id,
      },
    };

    /**
     * @todo figure out optimistic updates
     */
    const configs = [
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'deletedMediaId',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: (res) => resolve(res),
      onError: (err) => reject(err),
    });
  });
