/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
// import pick from 'lodash/pick';
// import { PAYMENT_STATUSES } from '@pluralcom/plural-js-utils';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation ConfirmRequestedPaymentMutation(
    $input: ConfirmRequestedPaymentInput!
  ) {
    confirmRequestedPayment(input: $input) {
      payment {
        id
        thread_id
        status

        # Repulling sensitive data
        amount
        amount_after_fee
        service_fee

        deal {
          id
          ...DealsItem_deal
          ...DealControls_deal
        }
      }
      error {
        message
      }
    }
  }
`;

export default (
  {
    payment_id,
    confirm_and_pay,
  }: {
    payment_id: string,
    confirm_and_pay: boolean,
  },
  { payment }: { payment: Object } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        payment_id,
        confirm_and_pay,
      },
      payment,
    };

    // const nowISO = new Date().toISOString();

    commitMutation({
      mutation,
      variables,
      // Disabled since server response is preferred
      // optimisticResponse: payment
      //   ? {
      //       confirmRequestedPayment: {
      //         error: null,
      //         payment: {
      //           id: payment_id,
      //           ...pick(payment, [
      //             'thread_id',
      //             'status',
      //             'amount',
      //             'amount_after_fee',
      //             'service_fee',
      //           ]),
      //         },
      //       },
      //     }
      //   : undefined,

      onCompleted: resolve,
      onError: reject,
    });
  });
