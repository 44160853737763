/* @flow */
import React from 'react';
import get from 'lodash/get';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { withRouter } from '../../../../hocs/withRouter/withRouter';
import FancyDialog from '../../../FancyDialog/FancyDialog';

const UnsubscribePopUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(get(location, 'search', {}));
  const { show_unsubscibe_pop_up = false } = queryParams || {};

  const onPrimaryBtnOnClick = () => {
    navigate('/search', { replace: true });
  };

  return show_unsubscibe_pop_up ? (
    <FancyDialog
      toggle={onPrimaryBtnOnClick}
      isOpen
      title="You’ve been unsubscribed!"
      primaryBtnText="Ok"
      primaryBtnOnClick={onPrimaryBtnOnClick}
    >
      We won’t send any more notifications. Your subscription change will take
      effect within 48 hours.
    </FancyDialog>
  ) : null;
};

export default withRouter(UnsubscribePopUp);
