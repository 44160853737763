/* @flow */
import React, { useState } from 'react';

import ButtonClose from '../ButtonClose/ButtonClose';
import FancyDialog from '../FancyDialog/FancyDialog';

import { logger } from '../../utils';

type Props = {
  onClick?: Function,
  withConfirm?: ?boolean,
  dialogProps?: ?Object,
  Element?: React$Component,
  ButtonFloating?: Boolean,
};

const ButtonActionDialog = ({
  onClick,
  withConfirm,
  dialogProps,
  Element = ButtonClose,
  ButtonFloating,
  ...rest
}: Props) => {
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const _toggleDialog = (val) =>
    setIsOpenDialogConfirm((currVal) => val ?? !currVal);
  const _toggleDialogClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    return _toggleDialog();
  };

  const _shouldRenderDialog = withConfirm || dialogProps;

  return (
    <>
      <Element
        {...rest}
        disabled={rest.disabled || isLoadingAction}
        onClick={
          _shouldRenderDialog
            ? _toggleDialogClick
            : (e) => {
                if (onClick) {
                  e.preventDefault();
                  e.stopPropagation();
                  return onClick(e);
                }
                return undefined;
              }
        }
      />
      {_shouldRenderDialog ? (
        <FancyDialog
          toggle={_toggleDialog}
          isOpen={isOpenDialogConfirm}
          title="Are you sure?"
          primaryBtnText="Nevermind"
          primaryBtnOnClick={_toggleDialogClick}
          secondaryBtnText="Yes"
          secondaryBtnOnClick={async (e) => {
            if (onClick) {
              _toggleDialog();
              setIsLoadingAction(true);
              try {
                await onClick(e);
              } catch (err) {
                logger.error('Error in close button action', err);
              }
              setIsLoadingAction(false);
            }
          }}
          {...dialogProps}
        />
      ) : null}
    </>
  );
};

export default ButtonActionDialog;
