import React from 'react';
import { useMedia } from 'use-media';

import VerifyCode from '../../VerifyCode/VerifyCode';
import { AuthModal } from '../../components';
import { responsive } from '../../../../utils';
import styles from './VerifyCodeModal.module.scss';

interface VerifyCodeModalProps extends  React.ComponentProps<typeof VerifyCode>{
  /** The status of the Modal, either open or close */
  isOpen?: boolean;
  /** Function to toggle the Modal */
  onToggle?: Function;
  /** Function to be called on successful verification */
  onSuccess?: Function;
}

const VerifyCodeModal = ({
  isOpen,
  onToggle,
  onSuccess,
  ...rest
}: VerifyCodeModalProps) => {
  const isDesktop = useMedia({ minWidth: responsive.minDeviceWidthLG });

  const _onSuccess = (...params) => {
    if (onSuccess) {
      onSuccess(...params);
    } else {
      onToggle?.();
    }
  };

  return (
    <AuthModal
      isOpen={isOpen}
      toggle={onToggle}
      withNavHeader
      className={styles['modal-container']}
      navHeaderProps={{
        rightButtonProps: undefined,
        withBackButton: !isDesktop,
        leftButtonProps: {
          iconProps: {
            type: 'fa',
            icon: ['fas','chevron-left'],
          },
          onClick: (e) => {
            e.preventDefault();
            if (onToggle) {
              onToggle();
            }
          },
        },
        text: '',
      }}
      modalBodyClassName={styles['modal-body']}
      >
      <VerifyCode
        className={styles.container}
        onSuccess={_onSuccess}
        {...rest}
      />
    </AuthModal>
  );
};

export default VerifyCodeModal;
