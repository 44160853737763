/* eslint-disable */
/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import filter from 'lodash/filter';
import { commitMutation } from '../..';
import without from 'lodash/without';

type Props = {
  type: 'ADD' | 'REMOVE',
  value: string,
  messageId?: string,
  ownerId?: string,
  /** list of reactions */
  reactions: Array<{
    /** reaction value */
    value: string,
    /** list of users */
    users: Array<{
      /** user id */
      id: string,
    }>,
  }>,
};

const getOptimisticReactionsAdd = (type, reactions, value, ownerId) => {
  const existedIndex = reactions.findIndex(
    (reaction) => value === reaction.value,
  );
  const ownerIndex = reactions.findIndex((reaction) =>
    reaction.users.map(({ id }) => id).includes(ownerId),
  );

  let newReactions = [...reactions];
  if (type === 'Add') {
    // if user has left some reaction on message we need to remove it first
    if (ownerIndex !== -1) {
      // remove old reaction
      if (reactions[ownerIndex].users.length === 1) {
        // removeReactionFromMessage
        newReactions = without(
          reactions,
          (id) => id === reactions[ownerIndex].id,
        );
      } else {
        // removeUserFromReactionMessage
        newReactions = [
          ...newReactions.slice(0, ownerIndex),
          {
            value: newReactions[ownerIndex],
            users: [
              {
                id: without(
                  newReactions[ownerIndex].users.map(({ id }) => id),
                  ownerId,
                ),
              },
            ],
          },
          ...newReactions.slice(ownerIndex + 1, reactions.length),
        ];
      }
    }

    if (existedIndex < 0) {
      newReactions = [...newReactions, { value, users: [{ id: ownerId }] }];
    } else {
      const toAddUser = reactions[existedIndex];
      newReactions = [
        ...newReactions.slice(0, existedIndex),
        {
          value: toAddUser.value,
          users: [...toAddUser.users, { id: ownerId }],
        },
        ...newReactions.slice(existedIndex + 1, reactions.length),
      ];
    }
  }

  if (type === 'REMOVE') {
    const newUsers = filter(
      reactions[existedIndex]?.users,
      ({ id }) => id === ownerId,
    );
    if (newUsers.length) {
      newReactions = [
        ...reactions.slice(0, existedIndex),
        {
          value: reactions[existedIndex].value,
          users: [...newUsers],
        },
        ...reactions.slice(existedIndex + 1, reactions.length),
      ];
    } else {
      newReactions = [
        ...reactions.filter((reaction) => value !== reaction.value),
      ];
    }
  }

  return newReactions;
};

const updateMessageReaction = ({
  type,
  value,
  messageId,
  ownerId,
  reactions,
}: Props): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation UpdateMessageReactionMutation(
        $input: UpdateMessageReactionInput!
      ) {
        updateMessageReaction(input: $input) {
          newMessageEdge {
            node {
              id
              reactions {
                value
                users {
                  id
                }
              }
            }
          }
          error {
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        type,
        value,
        messageId,
      },
    };

    const optimisticResponse = {
      updateMessageReaction: {
        newMessageEdge: {
          node: {
            id: messageId,
            reactions: getOptimisticReactionsAdd(
              type,
              reactions,
              value,
              ownerId,
            ),
          },
        },
        error: null,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default updateMessageReaction;
