/**
 * @generated SignedSource<<cd43e89afcccc5a01ced43e63a33fbbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunityActivityTypeEnum = "collaborator_added" | "location_changed" | "position_added" | "search_looking_for" | "skill_liked" | "skill_published" | "user_published" | "user_recommended" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunityActivitiesListItem_data$data = {
  readonly created_at: any | null;
  readonly emoji: string | null;
  readonly id: string;
  readonly location: {
    readonly id: string;
    readonly latitude: number;
    readonly longitude: number;
  } | null;
  readonly route: {
    readonly skill_id: string | null;
    readonly to: string | null;
    readonly user_id: string | null;
  } | null;
  readonly title_short: string | null;
  readonly type: CommunityActivityTypeEnum | null;
  readonly user_search: {
    readonly id: string;
    readonly tag: {
      readonly id: string;
      readonly title: string | null;
    } | null;
    readonly term: string | null;
  } | null;
  readonly users: ReadonlyArray<{
    readonly avatar: {
      readonly id: string;
      readonly smallThumbnail: string | null;
    } | null;
    readonly first_name: string | null;
    readonly id: string;
    readonly last_name: string | null;
    readonly profile_color: string | null;
  } | null> | null;
  readonly " $fragmentType": "CommunityActivitiesListItem_data";
};
export type CommunityActivitiesListItem_data$key = {
  readonly " $data"?: CommunityActivitiesListItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityActivitiesListItem_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityActivitiesListItem_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommunityActivityRoute",
      "kind": "LinkedField",
      "name": "route",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skill_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title_short",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profile_color",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "smallThumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSearchPublic",
      "kind": "LinkedField",
      "name": "user_search",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tag",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "term",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CommunityActivity",
  "abstractKey": null
};
})();

(node as any).hash = "af621dabe015b0d480fb3345744772d0";

export default node;
