import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { InputText, Avatar, InputTextRefHandle } from '@pluralcom/blueprint';
import { COLOR_WHITE } from '@pluralcom/blueprint/dist/styles/js/index';

import { inputsMaxLengths } from '../../assets/data';

import InputSkillList from './components/InputSkillList/InputSkillList';
import { useOutsideClick } from '../../hooks';

import styles from './InputSkill.module.scss';
import { InputSkill_viewer$data } from './__generated__/InputSkill_viewer.graphql';

interface InputSkillProps {
  /** unique ID of User */
  otherUserId?: string;
  /** viewer object */
  viewer: InputSkill_viewer$data;
  /** selected skill */
  selectedSkill?: {
    /** selected skill ID */
    id: string;
    /** selected skill title */
    title: string;
    /** selected skill user */
    user?: {
      /** user ID */
      id: string;
      /** user profile color */
      profile_color?: string;
      /** user avatar */
      avatar?: {
        /** avatar ID */
        id?: string;
        /** smallThumbnail */
        smallThumbnail?: string;
      };
    };
  };
  /** onSelectAutosuggest */
  onSelectAutosuggest: Function;
  /** placeholder */
  placeholder?: string;
  /** skill_id */
  skill_id?: string;
  /** inputProps */
  inputProps?: Object;
  /** skipOwnSkills */
  skipOwnSkills?: boolean;
  /** className */
  className?: string;
  /** skillTitleToPreselect */
  skillTitleToPreselect?: string;
  /** initialValue */
  initialValue?: string;
  /** setSelectedSkill */
  setSelectedSkill?: Function;
}

/**
 *
 * FIGMA v0.0.1
 * InputSkill component.
 */
const InputSkill = ({
  viewer,
  otherUserId,
  selectedSkill,
  onSelectAutosuggest,
  placeholder = 'Select skill',
  skill_id,
  inputProps,
  skipOwnSkills,
  className,
  skillTitleToPreselect,
  initialValue = '',
  setSelectedSkill,
}: InputSkillProps) => {
  const inputRef = useRef<InputTextRefHandle | null>(null);
  const [isAutosuggestOpen, setIsAutosuggestOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsAutosuggestOpen(false);
    }
  };
  /** Close autosuggest on outside click */
  useOutsideClick(handleClickOutside, 'mousedown');

  /** Focus input when auto suggest is opened */
  useEffect(() => {
    if (isAutosuggestOpen && inputRef.current) {
      inputRef?.current?.inputRef?.current?.focus?.();
    }
  }, [isAutosuggestOpen]);

  /** Preselect skill if needed */
  useEffect(() => {
    if (skillTitleToPreselect) {
      onSelectAutosuggest({ title: skillTitleToPreselect });
    }
  }, [skillTitleToPreselect, onSelectAutosuggest]);

  const _handleInputTextClick = () => {
    if (selectedSkill) {
      setSelectedSkill?.(null);
    }
    if (value) {
      setValue('');
    }
    if (!isAutosuggestOpen) {
      setIsAutosuggestOpen(true);
    }
  };
  return (
    <div ref={wrapperRef} className={classNames([styles.container, className])}>
      <InputText
        value={selectedSkill?.title || value}
        inputContainerClassName={classNames({
          [styles['input-focused-container']]: selectedSkill,
          [styles['input-focused-container-border']]: isAutosuggestOpen,
        })}
        placeholder={placeholder}
        containerOnClick={_handleInputTextClick}
        onChange={(e) => setValue(e.target.value)}
        maxLength={inputsMaxLengths.skillTitle}
        {...inputProps}
        ref={(ref) => {
          // @ts-ignore
          inputRef.current = ref;
        }}
        rightButtonIconProps={{
          icon: ['fas', 'caret-down'],
          type: 'fa',
          iconClassName: styles['dropdown-icon'],
          onClick: _handleInputTextClick,
        }}
        renderLeftChild={
          selectedSkill ? (
            <Avatar
              user={
                selectedSkill?.user as React.ComponentProps<
                  typeof Avatar
                >['user']
              }
              size="xs"
              icon={{
                icon: ['fas', 'plus'],
                type: 'fa',
                className: styles.icon,
              }}
              color={COLOR_WHITE}
            />
          ) : undefined
        }
      />
      {isAutosuggestOpen && (
        <InputSkillList
          viewer={viewer}
          otherUserId={otherUserId}
          value={value}
          onSelectAutosuggest={(skill) => {
            setIsAutosuggestOpen(false);
            onSelectAutosuggest(skill);
          }}
          skill_id={skill_id}
          skipOwnSkills={skipOwnSkills}
        />
      )}
    </div>
  );
};

export { InputSkill as PureInputSkill };
export default createFragmentContainer(InputSkill, {
  viewer: graphql`
    fragment InputSkill_viewer on ViewerType
    @argumentDefinitions(
      other_user_id: { type: "String", defaultValue: "" }
      title_term: { type: "String", defaultValue: "" }
      skipOwnSkills: { type: "Boolean", defaultValue: false }
    ) {
      ...InputSkillList_viewer
        @arguments(
          other_user_id: $other_user_id
          title_term: $title_term
          skipOwnSkills: $skipOwnSkills
        )
    }
  `,
});
