/* @flow */
import React from 'react';

import { Icon } from '@pluralcom/blueprint';
import style from './Loader.module.scss';

const Loader = ({ isLoading }: { isLoading?: boolean }) =>
  isLoading ? (
    <div className={style['loader-container']}>
      <Icon
        icon={['fas', 'spinner']}
        type="fa"
        className={style.loader}
        pulse
        scale="2x"
        size="xl"
      />
    </div>
  ) : null;

export default Loader;
