/* @flow */
const highlightSearchTerm = (str: string, searchTerm: string) => {
  const index = str.toLowerCase().indexOf(searchTerm.toLowerCase());

  return {
    before: index !== -1 ? str.slice(0, index) : '',
    highlight: index !== -1 ? str.slice(index, index + searchTerm.length) : '',
    after:
      index !== -1 ? str.slice(index + searchTerm.length, str.length) : str,
  };
};

export default highlightSearchTerm;
