/* @flow */
import React, { Suspense } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { locationHelpers } from '@pluralcom/plural-js-utils';

import UserAvatar from '../UserAvatar/UserAvatar';
import Text from '../Text/Text';
import ButtonClose from '../ButtonClose/ButtonClose';
import { withAuthModals } from '../../hocs';
import { mixpanelHelpers } from '../../utils';

import { DonotSuggestUserMutation } from '../../graphql/mutations';

import styles from './TilePersonItemLegacy.module.scss';
import type { LocationType } from '../../../flow';

type Props = {
  className?: ?string,
  user: {
    id: string,
    username: string,
    first_name: string,
    last_name: string,
    avatar: {
      thumbnail?: string,
      smallThumbnail?: string,
    },
  },
  mp_location: string,
  // Parent connection string for store updates
  parentID: string,
  isAuthenticated: boolean,
  toggleAuthModal: Function,
  toggleAuthModals: Function,
  userLocation: {
    latitude?: number,
    longitude?: number,
    name?: number,
  },
  xButtonOnClickCb: (string) => any,
  noXBtn?: ?boolean,
  dispatch: Function,
  noSuspenseLegacyJest?: boolean,
};
/**
 * Gets the subtext
 * @todo @globalize
 *
 * returns one of the follwoing labels, in priority:
 * ConnectionLabel
 * Distance
 * User's Location Name
 * null
 */
const getSubText = ({
  userLocation,
  user,
}: {
  userLocation: LocationType,
  user: Object,
}): ?string => {
  if (user.relationship_label) {
    return user.relationship_label;
  }
  if (user.main_skill) {
    return user.main_skill.title;
  }
  if (userLocation && user.location) {
    if (user.location.latitude) {
      return `${locationHelpers.distanceToHumanText(
        locationHelpers.getDistanceBetweenLocationPoints(
          userLocation,
          user.location,
          { units: 'miles' },
        ),
      )} away`;
    }
    return user.location.name;
  }
  if (user.published_at) {
    return `Joined ${moment(user.published_at).format('MMM YYYY')}`;
  }
  return null;
};

const SubText = ({
  userLocation,
  user,
}: {
  userLocation: LocationType,
  user: Object,
}) => {
  const subtext = getSubText({ userLocation, user });
  return (
    subtext && (
      <Text className={classNames([styles.text, styles.subtext])}>
        {subtext}
      </Text>
    )
  );
};

const TilePersonItemLegacy = ({
  className,
  user,
  parentID,
  isAuthenticated,
  toggleAuthModal,
  userLocation,
  xButtonOnClickCb,
  mp_location,
  // filter
  toggleAuthModals,
  dispatch,

  noXBtn,

  // @tests @legacy @jest @fixme
  noSuspenseLegacyJest,

  ...rest
}: Props) => {
  const { avatar } = user;

  return (
    <div className={classNames([styles.container, className])} {...rest}>
      {!noXBtn && (
        <ButtonClose
          className={styles['btn-x']}
          onClick={mixpanelHelpers.trackEventAndPass({
            name: `${
              mp_location ? `${mp_location}_` : ''
            }PeopleYouMayKnow_Remove`,
          })((e) => {
            e.preventDefault();
            if (isAuthenticated) {
              DonotSuggestUserMutation(user.id, { parentID });
              if (xButtonOnClickCb) {
                xButtonOnClickCb(user.id);
              }
            } else {
              toggleAuthModal('Signup', {
                VerifyCode: {
                  onSuccess: () => {
                    DonotSuggestUserMutation(user.id, { parentID });
                    if (xButtonOnClickCb) {
                      xButtonOnClickCb(user.id);
                    }
                  },
                },
              })();
            }
          })}
        />
      )}
      <div className={styles.content}>
        <UserAvatar
          user={user}
          type={avatar && avatar.thumbnail ? 'thumbnail' : 'smallThumbnail'}
          className={styles.avatar}
        />
        <Text className={classNames([styles.text, styles.maintext])}>
          {`${user.first_name} ${user.last_name}`}
        </Text>
        {noSuspenseLegacyJest ? (
          <SubText userLocation={userLocation} user={user} />
        ) : (
          <Suspense fallback={null}>
            <SubText userLocation={userLocation} user={user} />
          </Suspense>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export { TilePersonItemLegacy as PureTilePersonItemLegacy };

export default withAuthModals()(connect(mapStateToProps)(TilePersonItemLegacy));
