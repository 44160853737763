/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

type LocationType = {
  coords: {
    latitude?: number,
    longitude?: number,
  },
  timestamp: number,
};

const KEYS = {
  location: 'location',
};

const setLocation = (location: LocationType): void => {
  localStorageService.setItem(
    KEYS.location,
    JSON.stringify({ updated_at: Date.now(), ...location }),
  );
};

const getLocation = (): LocationType => {
  const location = localStorageService.getItem(KEYS.location);
  return location;
};

export { setLocation, getLocation };
