/**
 * @generated SignedSource<<a199cdb1c9498c846c8675dd7f274f17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSkillVideoLinkInput = {
  clientMutationId?: string | null;
  id: string;
  videoLink: string;
};
export type AddSkillVideoLinkMutation$variables = {
  input: AddSkillVideoLinkInput;
};
export type AddSkillVideoLinkMutation$data = {
  readonly addSkillVideoLink: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newVideoEdge: {
      readonly node: {
        readonly id: string;
        readonly video: {
          readonly id: string;
          readonly thumbnailUrl: string | null;
          readonly url: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type AddSkillVideoLinkMutation = {
  response: AddSkillVideoLinkMutation$data;
  variables: AddSkillVideoLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaEdge",
  "kind": "LinkedField",
  "name": "newVideoEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Media",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Video",
          "kind": "LinkedField",
          "name": "video",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSkillVideoLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSkillVideoLinkPayload",
        "kind": "LinkedField",
        "name": "addSkillVideoLink",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSkillVideoLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddSkillVideoLinkPayload",
        "kind": "LinkedField",
        "name": "addSkillVideoLink",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6119a58502be7771a087735879eda15",
    "id": null,
    "metadata": {},
    "name": "AddSkillVideoLinkMutation",
    "operationKind": "mutation",
    "text": "mutation AddSkillVideoLinkMutation(\n  $input: AddSkillVideoLinkInput!\n) {\n  addSkillVideoLink(input: $input) {\n    newVideoEdge {\n      node {\n        id\n        video {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97f9164e3b5b7c5fa4b7922f611d8988";

export default node;
