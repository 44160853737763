/* @flow */
import { useEffect } from 'react';

import { googleAnalytics } from '../../../../utils';

const useTrackRouteChange = ({ location }) => {
  /** Track route change */
  useEffect(() => {
    googleAnalytics.trackPage(`${location.pathname}${location.search}`);
  }, [location.pathname, location.search]);
};

export default useTrackRouteChange;
