/* @flow */
import React from 'react';
import classNames from 'classnames';

import { errorTexts } from '../../assets/data';

import styles from './ErrorText.module.scss';

const getErrorTextFromSlug = (slug: string) => errorTexts[slug] || slug;

const ErrorText = ({
  error,
  className,
  children,
  dataTestId,
  ...rest
}: {
  error?: string,
  className?: ?string,
  dataTestId?: ?string,
  children?: React$Node,
}) => {
  if (error || children) {
    return (
      <div
        data-testid={dataTestId}
        className={classNames([styles.error, className])}
        {...rest}
      >
        {children || getErrorTextFromSlug(error)}
      </div>
    );
  }
  return null;
};

export default ErrorText;
