/**
 * @generated SignedSource<<5638952c81e306659ce336dbc18d46b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyPinInput = {
  auth_reqid: string;
  clientMutationId?: string | null;
  email?: string | null;
  phone?: string | null;
  pin: string;
  with_auth?: boolean | null;
};
export type VerifyPinSettingsMutation$variables = {
  input: VerifyPinInput;
};
export type VerifyPinSettingsMutation$data = {
  readonly verifyPin: {
    readonly auth_reqid: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly emails: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
        readonly verified: boolean | null;
      } | null> | null;
      readonly id: string;
      readonly phones: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
        readonly verified: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};
export type VerifyPinSettingsMutation = {
  response: VerifyPinSettingsMutation$data;
  variables: VerifyPinSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auth_reqid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "verified",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "emails",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Phone",
      "kind": "LinkedField",
      "name": "phones",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPinSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPinSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1282ddb1331ac7a64139e71b7bcbf038",
    "id": null,
    "metadata": {},
    "name": "VerifyPinSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPinSettingsMutation(\n  $input: VerifyPinInput!\n) {\n  verifyPin(input: $input) {\n    auth_reqid\n    profile {\n      id\n      emails {\n        id\n        value\n        verified\n      }\n      phones {\n        id\n        value\n        verified\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8824aefeaa81ac693764136e76766302";

export default node;
