/* @flow */
import { useEffect, useState } from 'react';

import { ipHelpers, localStorageHelpers } from '../../utils';

type Options = {
  enableHighAccuracy?: boolean,
  maximumAge?: number,
  timeout?: number,
  watch?: boolean,
  // Extra options
  fallbackToIp?: boolean,
  fallbackToIpTimeout?: number,
  initWithLocalStorageTimeDelta?: number,
};

type IpInfo = {
  ip: string,
  type: ?string,
  city: ?string,
  countryName: ?string,
  countryCode: ?string,
  latitude: ?number,
  longitude: ?number,
  regionName: ?string,
  regionCode: ?string,
  timezone: ?string,
  zipcode: ?string,
  continentCode: ?string,
  continentName: ?string,
  languages: ?Array<string>,
  currency: ?string,
  provider: ?string,
};

type Position = {
  coords: {
    latitude: number,
    longitude: number,
    altitude: number | null,
    accuracy: number,
    altitudeAccuracy: number | null,
    heading: number,
    speed: number | null,
    zoomLevel: number | null,
  },
  timestamp: number,
  ipInfo?: IpInfo,
};

type LocationStore = {
  set: (Position) => any,
  get: () => Position,
};

const _defaultStore: LocationStore = {
  set: (val) => localStorageHelpers.setLocation(val),
  get: () => localStorageHelpers.getLocation(),
};

const _parseIpInfoToPosition = (ipInfo: ?IpInfo): Position | null => {
  if (ipInfo) {
    return {
      coords: {
        latitude: ipInfo.latitude,
        longitude: ipInfo.longitude,
      },
      timestamp: Date.now(),
      ipInfo,
    };
  }
  return null;
};

/**
 * useGeolocation
 *
 * Fetches the Geolocation from the browser's navigator and fallsback to the IP location.
 *
 * - Supports watching the live position
 * - Caches both browser geolocation and ip location
 *
 * @param {Options} options - to customize the navigator and behavior
 * @param {LocationStore} store - to cache the geolocation data
 */
const useGeolocation = (
  /** Options */
  options: Options = {},
  /** Ensure the store value is static to avoid infinite loops */
  store: LocationStore = _defaultStore,
): [Position, Error] => {
  const {
    enableHighAccuracy = true,
    maximumAge = 1 * 60 * 60 * 1000, // 1h in ms
    timeout = 10000,
    watch = false,
    // Extra options
    fallbackToIp = true,
    fallbackToIpTimeout,
    fetchLocationByIp = ipHelpers.fetchAndSetIpInfo,
    initWithLocalStorageTimeDelta = 3 * 60 * 60 * 1000, // 3h in ms
  } = options;

  const [position, setPosition] = useState(() => {
    /** Initialize with data if needed */
    if (initWithLocalStorageTimeDelta && store?.get) {
      const currLocalStoragePosition = store.get();
      if (
        Date.now() - currLocalStoragePosition?.timestamp <
        initWithLocalStorageTimeDelta
      ) {
        return {
          ...currLocalStoragePosition,
          initial: true,
        };
      }
    }
    return null;
  });
  const [error, setError] = useState();

  /** Fetch the ip location on mount */
  useEffect(() => {
    let canceled = false;

    /** Timeout for fetching ip based location */
    let _ipFetchTimer = fallbackToIp
      ? setTimeout(async () => {
          const newIpInfo = await fetchLocationByIp();
          if (!canceled && newIpInfo) {
            setPosition(
              (currPos) => currPos || _parseIpInfoToPosition(newIpInfo),
            );
          }
        }, fallbackToIpTimeout ?? 1)
      : null;

    /** Get the browser's position */
    const watcher = navigator.geolocation[
      watch ? 'watchPosition' : 'getCurrentPosition'
    ](
      (newPosition) => {
        if (!canceled) {
          setPosition((currPosition) =>
            currPosition?.timestamp !== newPosition?.timestamp
              ? newPosition
              : currPosition,
          );
        }
        /** Cancel ip timeout */
        if (_ipFetchTimer) {
          clearTimeout(_ipFetchTimer);
          _ipFetchTimer = null;
        }
        if (store?.set) {
          /** Update store */
          store.set({
            /**
             * https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
             * manually destructuring since the GeolocationCoordinates is a JS class not an object
             */
            coords: {
              latitude: newPosition.coords.latitude,
              longitude: newPosition.coords.longitude,
              altitude: newPosition.coords.altitude,
              accuracy: newPosition.coords.accuracy,
              altitudeAccuracy: newPosition.coords.altitudeAccuracy,
              heading: newPosition.coords.heading,
              speed: newPosition.coords.speed,
            },
            timestamp: newPosition.timestamp,
          });
        }
      },
      async (newError) => {
        if (!canceled) {
          setError(newError);
          if (fallbackToIp) {
            const newIpInfo = await fetchLocationByIp();
            setPosition(
              (currPos) => currPos || _parseIpInfoToPosition(newIpInfo),
            );
          }
        }
      },
      {
        enableHighAccuracy,
        maximumAge,
        timeout,
      },
    );

    /** CLeanup */
    return () => {
      /** Cancel curr logic - avoid setting state of an unmounted component */
      canceled = true;
      /** Cancel watch */
      if (watch) {
        navigator.geolocation.clearWatch(watcher);
      }
      /** Cancel ip timeout */
      if (_ipFetchTimer) {
        clearTimeout(_ipFetchTimer);
        _ipFetchTimer = null;
      }
    };
  }, [
    enableHighAccuracy,
    maximumAge,
    timeout,
    watch,
    fallbackToIp,
    fallbackToIpTimeout,
    fetchLocationByIp,
    store,
  ]);

  return [position, error];
};

export default useGeolocation;
