import React, { useState, useEffect, useMemo } from 'react';
import { createPaginationContainer, RelayPaginationProp } from 'react-relay';
import { FlatList, LoadingLayout, Spinner } from '@pluralcom/blueprint';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { COLOR_NEUTRAL_950 } from '@pluralcom/blueprint/dist/styles/js/index';

import type { InputSkillList_viewer$data } from './__generated__/InputSkillList_viewer.graphql';
import InputSkillListItem from '../InputSkillListItem/InputSkillListItem';

import styles from './InputSkillList.module.scss';

interface InputSkillListProps {
  /** value */
  value?: string;
  /** onSelectAutosuggest */
  onSelectAutosuggest?: Function;
  /** isAutosuggestOpen */
  isAutosuggestOpen?: boolean;
  /** relay */
  relay?: RelayPaginationProp;
  /** viewer */
  viewer?: InputSkillList_viewer$data;
  /** skill_id */
  skill_id?: string;
  /** skipOwnSkills */
  skipOwnSkills?: Boolean;
  /** otherUserId */
  otherUserId?: string;
}

const REFETCH_DELAY = 500;
const PER_PAGE = 8;

/**
 *
 * FIGMA v0.0.1
 * InputSkillList component to display a skills list in the input skill list
 */
const InputSkillList = ({
  value = '',
  viewer,
  relay,
  isAutosuggestOpen = false,
  onSelectAutosuggest,
  skill_id,
  otherUserId,
  skipOwnSkills = false,
  ...rest
}: InputSkillListProps) => {
  const { skillCreation, inputSkillListProfile } = viewer || {};
  const { edges: suggestionsEdges = [] } =
    skillCreation?.title_suggestions || {};
  const { edges: skillsEdges = [] } = inputSkillListProfile?.skills || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const debouncedRefetch = debounce((args) => {
    relay?.refetchConnection(PER_PAGE, () => setIsLoading(false), args);
    setTimeout(() => setIsLoading(false), REFETCH_DELAY);
  }, REFETCH_DELAY);

  const loadMore = () => {
    if (relay?.hasMore() && !relay?.isLoading()) {
      setIsLoadingMore(true);
      relay?.loadMore(PER_PAGE, () => setIsLoadingMore(false));
    }
  };
  /** refetch effect */
  useEffect(
    () => {
      /** we can prevent the loader from showing during first load by setting Boolean(value) */
      setIsLoading(true);
      debouncedRefetch({
        title_term: value,
        other_user_id: otherUserId,
        skipOwnSkills: skipOwnSkills && !otherUserId,
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [value, otherUserId, skipOwnSkills],
  );

  const autocompleteSearches = useMemo(() => {
    // @ts-ignore
    const results = [...skillsEdges, ...suggestionsEdges];
    const shouldConcatVal =
      value && !results.find((el) => el.node.title === value);
    return (shouldConcatVal ? [{ id: 'value', title: value }] : []).concat(
      results
        .filter(({ node }) =>
          node.title.toLowerCase().includes(value.toLowerCase()),
        )
        .map(({ node }) => node),
    );
  }, [skillsEdges, suggestionsEdges, value]);

  /** restore  selectedSkill from stripe redirect url effect */
  useEffect(() => {
    if (skill_id) {
      const foundSkill = autocompleteSearches.find(
        (item) => item.id === skill_id,
      );
      if (foundSkill) {
        onSelectAutosuggest?.(foundSkill);
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [skill_id, autocompleteSearches]);

  /** ListFooter - renders LoadingLayout when isLoadingMore or isSyncPhonebook is true */
  const ListFooterComponent = useMemo(
    () => (isLoadingMore ? <Spinner /> : undefined),
    [isLoadingMore],
  );

  /** Renders a list item */
  const _renderListItem = ({ index }) => {
    // @ts-ignore
    const item = autocompleteSearches[index] || {};

    return (
      <div key={item.id}>
        <InputSkillListItem
          data={item}
          {...{
            onClick: () => {
              onSelectAutosuggest?.(
                // @ts-ignore
                item?.user ? item : omit(item, 'id'),
              );
            },
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingLayout
          className={styles.loader}
          spinnerProps={{ spinnerColor: COLOR_NEUTRAL_950 }}
        />
      ) : (
        <FlatList
          data={autocompleteSearches}
          className={styles.list}
          renderItem={_renderListItem}
          loadMore={loadMore}
          // @ts-ignore
          loadMoreCount={10}
          isLoadingMore={isLoadingMore}
          ListFooterComponent={ListFooterComponent}
          {...rest}
        />
      )}
    </div>
  );
};
export { InputSkillList as PureInputSkillList };
export default createPaginationContainer(
  InputSkillList,
  {
    viewer: graphql`
      fragment InputSkillList_viewer on ViewerType
      @argumentDefinitions(
        other_user_id: { type: "String", defaultValue: "" }
        title_term: { type: "String", defaultValue: "" }
        skipOwnSkills: { type: "Boolean", defaultValue: false }
        count: { type: "Int", defaultValue: 8 }
        cursor: { type: "String" }
      ) {
        inputSkillListProfile: profile(id: $other_user_id)
          @skip(if: $skipOwnSkills) {
          id
          first_name
          last_name
          profile_color
          avatar {
            id
            smallThumbnail
          }
          skills(first: 25) {
            edges {
              node {
                id
                title
                user {
                  id
                  username
                  profile_color
                  avatar {
                    id
                    smallThumbnail
                  }
                }
              }
            }
          }
        }
        skillCreation(title_term: $title_term) {
          id
          title_suggestions(first: $count, after: $cursor)
            @connection(
              key: "SkillTitleAutoSuggest_skillCreation_title_suggestions"
            ) {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    `,
  },
  {
    getVariables(props, { count, cursor }) {
      return {
        title_term: props.value,
        count,
        cursor,
        other_user_id: props.otherUserId,
        skipOwnSkills: props.skipOwnSkills && !props.otherUserId,
      };
    },
    query: graphql`
      query InputSkillListPaginationQuery(
        $other_user_id: String!
        $title_term: String!
        $skipOwnSkills: Boolean!
        $count: Int!
        $cursor: String
      ) {
        viewer {
          ...InputSkillList_viewer
            @arguments(
              other_user_id: $other_user_id
              title_term: $title_term
              skipOwnSkills: $skipOwnSkills
              count: $count
              cursor: $cursor
            )
        }
      }
    `,
  },
);
