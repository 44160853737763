/**
 * @generated SignedSource<<3a058ba74a0f42a148b45b1a45fa5eb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BlockUserInput = {
  block: boolean;
  clientMutationId?: string | null;
  user_id: string;
};
export type BlockUserMutation$variables = {
  input: BlockUserInput;
};
export type BlockUserMutation$data = {
  readonly blockUser: {
    readonly blocked_user: {
      readonly id: string;
      readonly viewer_blocked_at: any | null;
    } | null;
    readonly blocked_user_id: string | null;
    readonly error: {
      readonly id: string;
      readonly message: string | null;
    } | null;
  } | null;
};
export type BlockUserMutation = {
  response: BlockUserMutation$data;
  variables: BlockUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BlockUserPayload",
    "kind": "LinkedField",
    "name": "blockUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "blocked_user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewer_blocked_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blocked_user_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d8c805c65cdc5c46731c9d80dbd213b4",
    "id": null,
    "metadata": {},
    "name": "BlockUserMutation",
    "operationKind": "mutation",
    "text": "mutation BlockUserMutation(\n  $input: BlockUserInput!\n) {\n  blockUser(input: $input) {\n    error {\n      id\n      message\n    }\n    blocked_user {\n      id\n      viewer_blocked_at\n    }\n    blocked_user_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "59d3d78d8d81c6ad3486843f15d82a1e";

export default node;
