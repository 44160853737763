/* @flow */
import React from 'react';
import { Beforeunload as BeforeunloadBase } from 'react-beforeunload';

import alertMessages from '../../../../assets/data/alertMessages';

type Props = {
  when: boolean,
  nativeUnloadPrompt?: (Event) => ?(string | boolean),
};

const Beforeunload = ({
  when,
  nativeUnloadPrompt = () => alertMessages.changesMayNotBeSaved,
  ...rest
}: Props) => (
  <BeforeunloadBase
    onBeforeunload={
      Boolean(when) && typeof nativeUnloadPrompt === 'function'
        ? nativeUnloadPrompt
        : () => nativeUnloadPrompt
    }
    {...rest}
  />
);

export default Beforeunload;
