/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

type InputType = {
  shortLivedFbAccessToken?: string,
};

export default ({ shortLivedFbAccessToken }: InputType): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation ExtendRemoteAccessTokenMutation(
        $input: ExtendRemoteAccessTokenInput!
      ) {
        extendRemoteAccessToken(input: $input) {
          error {
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        shortLivedFbAccessToken,
      },
    };

    commitMutation({
      mutation,
      variables,
      onCompleted: res => resolve(res),
      onError: err => reject(err),
    });
  });
