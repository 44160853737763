import { commitMutation as commitMutationRaw } from 'react-relay';
import { getEnvironment } from '../Environment';
import configMiddleware from './configMiddleware';

const commitMutation = (environment, config) =>
  commitMutationRaw(
    environment,
    configMiddleware(config, {
      retryCommitMutation: (newConfig) =>
        commitMutationRaw(environment, newConfig),
    }),
  );

export { commitMutationRaw, commitMutation };

export default (config) => commitMutation(getEnvironment(), config);
