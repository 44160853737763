/**
 * @generated SignedSource<<d92f47970d892f07c88cfd0435eff1e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSkillInput = {
  clientMutationId?: string | null;
  description?: string | null;
  is_ghost?: boolean | null;
  is_hobby?: boolean | null;
  published?: boolean | null;
  skillTags?: string | null;
  suggested_user_id?: string | null;
  title: string;
  videoLinks?: ReadonlyArray<string | null> | null;
};
export type CreateSkillMutation$variables = {
  input: CreateSkillInput;
};
export type CreateSkillMutation$data = {
  readonly createSkill: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newSkillEdge: {
      readonly node: {
        readonly id: string;
        readonly is_draft: boolean | null;
        readonly is_ghost: boolean | null;
        readonly is_hobby: boolean | null;
        readonly published: boolean | null;
        readonly published_at: any | null;
        readonly title: string | null;
        readonly user: {
          readonly id: string;
          readonly skills: {
            readonly totalCount: number | null;
          } | null;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"SkillCardMini_skill">;
      } | null;
    } | null;
  } | null;
};
export type CreateSkillMutation = {
  response: CreateSkillMutation$data;
  variables: CreateSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_draft",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_ghost",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_hobby",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published_at",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallThumbnail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSkillPayload",
        "kind": "LinkedField",
        "name": "createSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillEdge",
            "kind": "LinkedField",
            "name": "newSkillEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "isSkillCardPreview",
                        "value": true
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SkillCardMini_skill"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSkillPayload",
        "kind": "LinkedField",
        "name": "createSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillEdge",
            "kind": "LinkedField",
            "name": "newSkillEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hiring_types",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillLikes",
                    "kind": "LinkedField",
                    "name": "likes",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewerHasLiked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "first_name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "last_name",
                                    "storageKey": null
                                  },
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "users(first:3)"
                      },
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "SkillCardMiniHeader_likes_users",
                        "kind": "LinkedHandle",
                        "name": "users"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v13/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 50
                      }
                    ],
                    "concreteType": "MediaConnection",
                    "kind": "LinkedField",
                    "name": "media",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MediaEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Media",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v14/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "medium_wide",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Video",
                                "kind": "LinkedField",
                                "name": "video",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "media(first:50)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abd4885b3dad6c0b01195a5917bc0cc7",
    "id": null,
    "metadata": {},
    "name": "CreateSkillMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSkillMutation(\n  $input: CreateSkillInput!\n) {\n  createSkill(input: $input) {\n    newSkillEdge {\n      node {\n        id\n        title\n        is_draft\n        is_ghost\n        is_hobby\n        published\n        published_at\n        ...SkillCardMini_skill_3IJs4M\n        user {\n          id\n          skills {\n            totalCount\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n\nfragment SkillCardMiniHeader_skill_43J5FO on Skill {\n  id\n  title\n  is_hobby\n  hiring_types\n  likes {\n    id\n    viewerHasLiked\n    users(first: 3) {\n      totalCount\n      edges {\n        node {\n          id\n          first_name\n          last_name\n          profile_color\n          avatar {\n            id\n            smallThumbnail\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  is_draft\n  is_ghost\n  published\n}\n\nfragment SkillCardMiniMediaSection_skill on Skill {\n  id\n  user {\n    id\n    profile_color\n  }\n  media(first: 50) {\n    edges {\n      node {\n        id\n        image {\n          id\n          smallThumbnail\n          medium_wide\n        }\n        video {\n          id\n          url\n        }\n      }\n    }\n  }\n}\n\nfragment SkillCardMini_skill_3IJs4M on Skill {\n  id\n  ...SkillCardMiniHeader_skill_43J5FO\n  ...SkillCardMiniMediaSection_skill\n}\n"
  }
};
})();

(node as any).hash = "b09e247038a6cf87cec0ed78f9e65356";

export default node;
