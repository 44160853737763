/* @flow */
/**
 * - Using Script as it passes along a lot of information on the users' location, machine, etc. by default.
 * That's not the case with the npm module, which is why it's not used.
 *
 * - Always use window.heap as destructuring doesn't work
 */

import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

import { waitForVar } from '@pluralcom/plural-web-utils';
import removeElementFromDom from '../uiDomHelpers/removeElementFromDom';

/** Heap object - no destructuring to ensure reference exists */

const init = () => {
  waitForVar('heap').then(() => {
    const { REACT_APP_HEAP_TOKEN } = process.env;
    if (window.heap?.load && REACT_APP_HEAP_TOKEN) {
      window.heap.load(REACT_APP_HEAP_TOKEN);
    }
  });
};

/**
 * Sets the heap user.
 * To be called upon authentication and upon opening the website by an authenticated user
 */
const setUser = (user, { roles }: { roles: Array<string> } = {}) => {
  if (!user?.id || !window.heap) {
    return;
  }
  window.heap.identify(user.id);
  window.heap.addUserProperties(
    omitBy(
      {
        id: user.id,
        username: user.username,
        birthdate: user.birthdate,
        gender: user.gender,
        created_at: user.created_at,
        published_at: user.published_at,
        website: user.website,
        roles,
        // mixpanel preset keys (removed $ in heap)
        email: user.email,
        phone: user.phone,
        avatar: user?.avatar?.thumbnail || user?.avatar?.smallThumbnail,
        first_name: user.first_name,
        last_name: user.last_name,
        name: user.name,
      },
      isEmpty,
    ),
  );
};

/**
 * Tracks an analytics event
 */
const trackEvent = (name: string, properties?: ?Object) =>
  window.heap?.track(`${name}`, properties);

/**
 * A curried version of trackEvent.
 * To be used on elements ie: onClick
 */
const trackEventAndPass = (
  analyticsEvent: ?{
    name: string,
    properties?: ?Object,
  },
  {
    /** If true -> tracks the analyticsEvent even if func is falsy */
    forceTrack,
  } = {},
) => (func?: ?Function) => (e?: ?Event) => {
  if (func || forceTrack) {
    if (analyticsEvent) {
      trackEvent(analyticsEvent.name, analyticsEvent.properties);
    }
    return func && func(e);
  }
  return func;
};

/** Fully Resets the heap user's data */
const reset = () => window.heap?.resetIdentity();

const unInit = () => {
  reset();
  removeElementFromDom(document.getElementById('script-heap'));
};

export default {
  init,
  setUser,
  trackEvent,
  trackEventAndPass,
  reset,
  unInit,
};
