/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

const LAST_VIEWED_THREAD_TIMESTAMP = 'lv_thread_ts';
const LAST_VIEWED_NOTIFICATION_TIMESTAMP = 'lv_notification_ts';

export const getThreadLastViewedTimestamp = () =>
  localStorageService.getItem(LAST_VIEWED_THREAD_TIMESTAMP);

export const setThreadLastViewedTimestamp = (
  activitiesLastViewedTimestamp = Date.now(),
) =>
  localStorageService.setItem(
    LAST_VIEWED_THREAD_TIMESTAMP,
    activitiesLastViewedTimestamp,
  );

export const getNotificationLastViewedTimestamp = () =>
  localStorageService.getItem(LAST_VIEWED_NOTIFICATION_TIMESTAMP);

export const setNotificationLastViewedTimestamp = (
  activitiesLastViewedTimestamp = Date.now(),
) =>
  localStorageService.setItem(
    LAST_VIEWED_NOTIFICATION_TIMESTAMP,
    activitiesLastViewedTimestamp,
  );
