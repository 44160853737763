/* @flow */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { analyticsHelpers } from '../../../../utils';

type Props = {
  consentCookie: ?{ analytics?: boolean },
};

/**
 * Holds Analytics scripts that are added manually
 *
 * NOTE: mixpanel had to be added to index.html due to the script being incompatible with Helmet
 */
const Analytics = ({ consentCookie }: Props) => {
  const { analytics: analyticsEnabled } = consentCookie || {};

  /**
   * Initialize or unInitialize analytics based on the consent cookie
   */
  useEffect(() => {
    if (analyticsEnabled) {
      /** Init Analytics */
      analyticsHelpers.init();
    } else {
      analyticsHelpers.unInit();
    }
  }, [analyticsEnabled]);

  if (!analyticsEnabled) {
    return null;
  }

  const { REACT_APP_HEAP_TOKEN } = process.env;

  return (
    <Helmet>
      {REACT_APP_HEAP_TOKEN ? (
        <script async id="script-heap" type="text/javascript">
          {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${REACT_APP_HEAP_TOKEN}");`}
        </script>
      ) : null}
    </Helmet>
  );
};
/** Segment */
// <script>
//   {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
// analytics.load("${REACT_APP_SEGMENT_TOKEN}");
// analytics.page();
// }}();`}
// </script>;

export default Analytics;
