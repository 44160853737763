/* @flow */
const ROOT_PREFIX: string = 'AUTH/';

export type AuthMainFormType = {
  emailOrPhone: string,
  firstName: string,
  lastName: string,
  auth_reqid?: string,
};

export type AuthMainFormFragmentType = {
  emailOrPhone?: string,
  firstName?: string,
  lastName?: string,
  auth_reqid?: string,
};

export type SettingsStateType = {
  isAuthenticated: boolean,
  authMainForm: AuthMainFormType,
};

const initialState: SettingsStateType = {
  isAuthenticated: false,
  authMainForm: {
    emailOrPhone: '',
    firstName: '',
    lastName: '',
    auth_reqid: undefined,
  },
};

// Actions
export type AuthActionType = { type: string, [string]: any };
export const SET_AUTHENTICATED = `${ROOT_PREFIX}SET_AUTHENTICATED`;
export const RESET_AUTHENTICATED = `${ROOT_PREFIX}RESET_AUTHENTICATED`;
export const AUTH_FORM_UPDATE = `${ROOT_PREFIX}AUTH_FORM_UPDATE`;
export const AUTH_FORM_RESET = `${ROOT_PREFIX}AUTH_FORM_RESET`;
export const AUTH_ROOT_UPDATE = `${ROOT_PREFIX}AUTH_ROOT_UPDATE`;

// @TODO USE https://redux-form.com/7.4.2/docs/gettingstarted.md/
export const authMainFormUpdate = (formObj: AuthMainFormFragmentType) => (
  dispatch: Function,
) => {
  dispatch({
    type: AUTH_FORM_UPDATE,
    payload: formObj,
  });
};

export const authMainFormReset = (formObj: AuthMainFormFragmentType = {}) => (
  dispatch: Function,
) => {
  dispatch({
    type: AUTH_FORM_RESET,
    payload: formObj,
  });
};

export const authRootObjUpdate = (obj: Object = {}) => (dispatch: Function) => {
  dispatch({
    type: AUTH_ROOT_UPDATE,
    payload: obj,
  });
};

// Action creators

// Reducer
export default (
  state: SettingsStateType = initialState,
  action: AuthActionType,
) => {
  switch (action.type) {
    case AUTH_FORM_UPDATE:
      return {
        ...state,
        authMainForm: {
          ...state.authMainForm,
          ...action.payload,
        },
      };
    case AUTH_FORM_RESET:
      return {
        ...state,
        authMainForm: {
          ...initialState,
          ...action.payload,
        },
      };
    case AUTH_ROOT_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };
    case RESET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
