/**
 * @generated SignedSource<<7ee389674587889e425ea393ba699eb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveMediaFromSkillInput = {
  clientMutationId?: string | null;
  media_id: string;
};
export type RemoveMediaFromSkillMutation$variables = {
  input: RemoveMediaFromSkillInput;
};
export type RemoveMediaFromSkillMutation$data = {
  readonly removeMediaFromSkill: {
    readonly deletedMediaId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type RemoveMediaFromSkillMutation = {
  response: RemoveMediaFromSkillMutation$data;
  variables: RemoveMediaFromSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedMediaId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveMediaFromSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveMediaFromSkillPayload",
        "kind": "LinkedField",
        "name": "removeMediaFromSkill",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveMediaFromSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveMediaFromSkillPayload",
        "kind": "LinkedField",
        "name": "removeMediaFromSkill",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ef3516bff544f21e9f035d3b8fb71e7",
    "id": null,
    "metadata": {},
    "name": "RemoveMediaFromSkillMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveMediaFromSkillMutation(\n  $input: RemoveMediaFromSkillInput!\n) {\n  removeMediaFromSkill(input: $input) {\n    deletedMediaId\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7c5830f6252c3eb4998a6256cc0a2d9";

export default node;
