/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

/**
 * Double check that we need to pull the skillEdge here? Maybe not.
 */
const mutation = graphql`
  mutation AddSkillVideoLinkMutation($input: AddSkillVideoLinkInput!) {
    addSkillVideoLink(input: $input) {
      newVideoEdge {
        node {
          id
          video {
            id
            url
            thumbnailUrl
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export default (skill_id: string, url: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        videoLink: url,
        id: skill_id,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: res => resolve(res),
      onError: err => reject(err),
    });
  });
