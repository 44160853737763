/**
 * @generated SignedSource<<ecdd1f381e63a910909b1b3359a378a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputSkillListPaginationQuery$variables = {
  count: number;
  cursor?: string | null;
  other_user_id: string;
  skipOwnSkills: boolean;
  title_term: string;
};
export type InputSkillListPaginationQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"InputSkillList_viewer">;
  } | null;
};
export type InputSkillListPaginationQuery = {
  response: InputSkillListPaginationQuery$data;
  variables: InputSkillListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "other_user_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipOwnSkills"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title_term"
},
v5 = {
  "kind": "Variable",
  "name": "title_term",
  "variableName": "title_term"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallThumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InputSkillListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "other_user_id",
                "variableName": "other_user_id"
              },
              {
                "kind": "Variable",
                "name": "skipOwnSkills",
                "variableName": "skipOwnSkills"
              },
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "InputSkillList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "InputSkillListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "skipOwnSkills",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "inputSkillListProfile",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "other_user_id"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 25
                      }
                    ],
                    "concreteType": "SkillConnection",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Skill",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "skills(first:25)"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "SkillCreation",
            "kind": "LinkedField",
            "name": "skillCreation",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "TagConnection",
                "kind": "LinkedField",
                "name": "title_suggestions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "SkillTitleAutoSuggest_skillCreation_title_suggestions",
                "kind": "LinkedHandle",
                "name": "title_suggestions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e10e5f76b72f7e8b6565718858903c0",
    "id": null,
    "metadata": {},
    "name": "InputSkillListPaginationQuery",
    "operationKind": "query",
    "text": "query InputSkillListPaginationQuery(\n  $other_user_id: String!\n  $title_term: String!\n  $skipOwnSkills: Boolean!\n  $count: Int!\n  $cursor: String\n) {\n  viewer {\n    ...InputSkillList_viewer_2zMUbr\n  }\n}\n\nfragment InputSkillList_viewer_2zMUbr on ViewerType {\n  inputSkillListProfile: profile(id: $other_user_id) @skip(if: $skipOwnSkills) {\n    id\n    first_name\n    last_name\n    profile_color\n    avatar {\n      id\n      smallThumbnail\n    }\n    skills(first: 25) {\n      edges {\n        node {\n          id\n          title\n          user {\n            id\n            username\n            profile_color\n            avatar {\n              id\n              smallThumbnail\n            }\n          }\n        }\n      }\n    }\n  }\n  skillCreation(title_term: $title_term) {\n    id\n    title_suggestions(first: $count, after: $cursor) {\n      edges {\n        node {\n          id\n          title\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed1aa01384df31a8665f59e5278f4f80";

export default node;
