/* @flow */
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { locationHelpers } from '@pluralcom/plural-js-utils';

const _joinStrQueries = (list: Array<string>): string =>
  list.filter((x) => !isEmpty(x)).join('&');

/** Detects if all the items in a list are numbers */
const _areNumbers = (list): boolean =>
  list.reduce((bool, value) => Boolean(value && !isNaN(value) && bool), true);

const SEARCH_QUERY_STRING_KEYS = ['@', 'q', 'isCategorySearch'];

/**
 * Encodes the search query inputs to a query string
 */
const encodeSearchUrlQuery = (
  {
    term,
    latitude,
    longitude,
    zoomLevel = 12,
    isCategorySearch,
  }: {
    term?: string,
    latitude?: number,
    longitude?: number,
    zoomLevel?: number,
    isCategorySearch?: ?Boolean,
  },
  { currentQueryString } = {},
): string => {
  let newQueryObj = currentQueryString
    ? omit(queryString.parse(currentQueryString), SEARCH_QUERY_STRING_KEYS)
    : {};
  if (!isEmpty(term)) {
    newQueryObj = { ...newQueryObj, q: term };
  }
  if (isCategorySearch) {
    newQueryObj = { ...newQueryObj, isCategorySearch };
  }
  const stringified = queryString.stringify(newQueryObj);
  if (latitude && longitude && zoomLevel) {
    const locationString = `@=${locationHelpers.correctLatitude(
      latitude,
    )},${locationHelpers.correctLongitude(longitude)},${zoomLevel}z`;
    return _joinStrQueries([locationString, stringified]);
  }
  return stringified;
};

const _decodeLocationString = (
  locationString: ?string,
): {
  latitude?: number,
  longitude?: number,
  zoomLevel?: number,
} => {
  if (locationString) {
    const locationStringSplit = locationString.split(',').map(parseFloat);
    if (locationStringSplit.length === 3) {
      const [latitude, longitude, zoomLevel] = locationStringSplit;
      if (_areNumbers([latitude, longitude, zoomLevel])) {
        return {
          latitude,
          longitude,
          zoomLevel: Math.abs(Number(Number(zoomLevel).toFixed(2))),
        };
      }
    }
  }
  return {};
};

/**
 * Decodes the query string of the search url to an object with the search query params
 */
const decodeSearchUrlQuery = (queryStr: string) => {
  const parsedQueryString = queryString.parse(queryStr);
  const {
    '@': locationString,
    q: term = '',
    isCategorySearch = false,
  } = parsedQueryString;
  return {
    ..._decodeLocationString(locationString),
    term,
    isCategorySearch: Boolean(isCategorySearch),
  };
};

export default { encodeSearchUrlQuery, decodeSearchUrlQuery };
