import React from 'react';
import classNames from 'classnames';
import { Text, TouchableOpacity } from '@pluralcom/blueprint';

import styles from './NavigationText.module.scss';

interface NavigationTextProps {
  /** The function to call when the link is clicked */
  linkOnClick?: Function;
  /** The link to navigate to */
  linkTo?: string;
  /** The state of the component */
  state?: 'login' | 'signup';
}

const NavigationText = ({
  linkOnClick,
  linkTo,
  state,
  ...rest
}: NavigationTextProps) => (
  <div className={styles.container}>
    <Text
      fontSizeType="t6"
      stickToBreakpoint="md"
      className={styles.text}
      element="span"
    >
      {state === 'login'
        ? 'Already have an account?'
        : 'Don’t have an account?'}
    </Text>
    <TouchableOpacity
      // @ts-ignore
      to={linkTo}
      onClick={() => {
        if (linkOnClick) {
          linkOnClick();
        }
      }}
      {...rest}
    >
      <Text
        fontSizeType="t6"
        stickToBreakpoint="md"
        element="span"
        className={classNames([styles.text, styles['text-link']])}
      >
        {state === 'login' ? 'Log in' : 'Sign up'}
      </Text>
    </TouchableOpacity>
  </div>
);

export default NavigationText;
