/**
 * @TODO globalize plural-web-utils
 */
/* @flow */
import removeElementFromDom from './removeElementFromDom';
import clickInBoundingClientRect from './clickInBoundingClientRect';
import getClosestParent from './getClosestParent';
import isActiveElement from './isActiveElement';
import isDescendant from './isDescendant';
import setCaretPosition from './setCaretPosition';
import disableScroll from './disableScroll';
import enableScroll from './enableScroll';

export default {
  removeElementFromDom,
  clickInBoundingClientRect,
  getClosestParent,
  isActiveElement,
  isDescendant,
  setCaretPosition,
  disableScroll,
  enableScroll,
};
