/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation AddLanguageMutation($input: AddLanguageInput!) {
    addLanguage(input: $input) {
      newLanguageEdge {
        node {
          id
          code
          name
          native_name
        }
      }
      error {
        message
      }
    }
  }
`;

export default ({ code }: { code: string }, userId: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        language_code: code,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: userId,
        connectionInfo: [
          {
            key: 'UserMoreInfo_languages',
            rangeBehavior: 'append',
          },
          {
            key: 'EditProfile_languages',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'newLanguageEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
