/**
 * @generated SignedSource<<558db5c957e9897c857eb8872f184e32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type messageReactionsSubscribeSubscription$variables = {};
export type messageReactionsSubscribeSubscription$data = {
  readonly messageReactionsSubscribe: {
    readonly messageId: string | null;
    readonly reactions: ReadonlyArray<{
      readonly users: ReadonlyArray<{
        readonly id: string;
      } | null> | null;
      readonly value: string | null;
    } | null> | null;
  } | null;
};
export type messageReactionsSubscribeSubscription = {
  response: messageReactionsSubscribeSubscription$data;
  variables: messageReactionsSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "messageReactionsSubscribe",
    "kind": "LinkedField",
    "name": "messageReactionsSubscribe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageReaction",
        "kind": "LinkedField",
        "name": "reactions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "messageId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "messageReactionsSubscribeSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "messageReactionsSubscribeSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f26dacdaec3f8d65c0193249b848c429",
    "id": null,
    "metadata": {},
    "name": "messageReactionsSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription messageReactionsSubscribeSubscription {\n  messageReactionsSubscribe {\n    reactions {\n      value\n      users {\n        id\n      }\n    }\n    messageId\n  }\n}\n"
  }
};
})();

(node as any).hash = "320838f824b8e0f879818507fc2bbcb6";

export default node;
