/* @flow */
import React from 'react';
import classNames from 'classnames';

import Spinner from '../Spinner/Spinner';

import styles from './LoadingLayout.module.scss';

type Props = {
  className?: ?string,
  absolutePositionCenter?: ?boolean,
  flexCenter?: ?boolean,
  size?: Number,
};

const LoadingLayout = ({
  className,
  absolutePositionCenter,
  flexCenter,
  size,
  ...rest
}: Props) => (
  <Spinner
    className={classNames([
      styles.container,
      { [styles['position-center']]: absolutePositionCenter },
      { [styles['flex-center']]: flexCenter },
      className,
    ])}
    {...rest}
  />
);

export default LoadingLayout;
