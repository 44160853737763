/**
 * @generated SignedSource<<45815d9f9a899923f9a589386875dc19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommunityActivityTypeEnum = "collaborator_added" | "location_changed" | "position_added" | "search_looking_for" | "skill_liked" | "skill_published" | "user_published" | "user_recommended" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunityActivitiesList_data$data = {
  readonly communityActivities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: any | null;
        readonly id: string;
        readonly type: CommunityActivityTypeEnum | null;
        readonly " $fragmentSpreads": FragmentRefs<"CommunityActivitiesListItem_data">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "CommunityActivitiesList_data";
};
export type CommunityActivitiesList_data$key = {
  readonly " $data"?: CommunityActivitiesList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityActivitiesList_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "communityActivities"
        ]
      }
    ]
  },
  "name": "CommunityActivitiesList_data",
  "selections": [
    {
      "alias": "communityActivities",
      "args": null,
      "concreteType": "CommunityActivityConnection",
      "kind": "LinkedField",
      "name": "__CommunityActivities_communityActivities_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommunityActivityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CommunityActivity",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CommunityActivitiesListItem_data"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node as any).hash = "c594a84f8b7f6c83d2c36e4397e50f19";

export default node;
