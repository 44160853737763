/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './StickyFooter.module.scss';

const StickyFooter = ({
  children,
  className,
  absolute,
  ...rest
}: {
  children: React$Node,
  className?: ?string,
  absolute?: boolean,
}) => (
  <div
    className={classNames([
      styles.container,
      { [styles[absolute]]: absolute },
      className,
    ])}
    {...rest}
  >
    {children}
  </div>
);

export default StickyFooter;
