/**
 * @generated SignedSource<<92ea844237ee9c60ca15d9f3235557ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeclineCollaboratorRequestInput = {
  clientMutationId?: string | null;
  id: string;
};
export type DeclineCollaboratorRequestMutation$variables = {
  input: DeclineCollaboratorRequestInput;
};
export type DeclineCollaboratorRequestMutation$data = {
  readonly declineCollaboratorRequest: {
    readonly collaboratorId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type DeclineCollaboratorRequestMutation = {
  response: DeclineCollaboratorRequestMutation$data;
  variables: DeclineCollaboratorRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collaboratorId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclineCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclineCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "declineCollaboratorRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclineCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclineCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "declineCollaboratorRequest",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db51d64b83e8f7e41167964f856e2bfd",
    "id": null,
    "metadata": {},
    "name": "DeclineCollaboratorRequestMutation",
    "operationKind": "mutation",
    "text": "mutation DeclineCollaboratorRequestMutation(\n  $input: DeclineCollaboratorRequestInput!\n) {\n  declineCollaboratorRequest(input: $input) {\n    collaboratorId\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a91aab3c99ef947d2161e0156b8e7be";

export default node;
