/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity, Icon } from '@pluralcom/blueprint';
import styles from './ButtonClose.module.scss';

type Props = {
  uiType?: 'lite' | undefined,
  size?: 'xs' | 'sm' | 'md' | undefined,
  absolute?: ?boolean,
  position: string,
  onClick?: Function,
  children?: React$Node,
};

const ButtonClose = ({
  uiType,
  size = 'sm',
  position = 'left',
  absolute,
  children,
  ...rest
}: Props) => (
  <TouchableOpacity
    {...rest}
    className={classNames([
      styles.btn,
      { [styles[uiType]]: uiType },
      { [styles[size]]: size },
      { [styles.absolute]: absolute },
      { [styles[`${size}_${position}`]]: position },
      rest.className,
    ])}
  >
    <Icon
      type="fa"
      icon={['fas', 'fa-xmark']}
      className={classNames([styles['btn-xmark'], rest.iconClassName])}
      size={size}
    />
  </TouchableOpacity>
);

export default ButtonClose;
