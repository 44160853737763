import { requestSubscription as requestSubscriptionRaw } from 'react-relay';
import { getEnvironment } from '../Environment';

import configMiddleware from './configMiddleware';

const requestSubscription = (environment, config) =>
  requestSubscriptionRaw(
    environment,
    configMiddleware(config, {
      retry: (newConfig) => requestSubscriptionRaw(environment, newConfig),
    }),
  );

export { requestSubscriptionRaw };

export default (config) => requestSubscription(getEnvironment(), config);
