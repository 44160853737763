/**
 * @generated SignedSource<<8ee6cd4c0770b4e14a47dd4970dbec20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppleAuthClient = "ios" | "web" | "%future added value";
export type SigninAppleInput = {
  client?: AppleAuthClient | null;
  clientMutationId?: string | null;
  code?: string | null;
  first_name?: string | null;
  id_token: string;
  last_name?: string | null;
  nonce?: string | null;
  real_user_status?: number | null;
  with_auth?: boolean | null;
};
export type SigninAppleConnectMutation$variables = {
  input: SigninAppleInput;
};
export type SigninAppleConnectMutation$data = {
  readonly signinApple: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly id: string;
      readonly remote_user_apple: {
        readonly email: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
  } | null;
};
export type SigninAppleConnectMutation = {
  response: SigninAppleConnectMutation$data;
  variables: SigninAppleConnectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteUser",
      "kind": "LinkedField",
      "name": "remote_user_apple",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SigninAppleConnectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SigninApplePayload",
        "kind": "LinkedField",
        "name": "signinApple",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SigninAppleConnectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SigninApplePayload",
        "kind": "LinkedField",
        "name": "signinApple",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b78bfac9300ac42714015b96a8b6698",
    "id": null,
    "metadata": {},
    "name": "SigninAppleConnectMutation",
    "operationKind": "mutation",
    "text": "mutation SigninAppleConnectMutation(\n  $input: SigninAppleInput!\n) {\n  signinApple(input: $input) {\n    profile {\n      id\n      remote_user_apple {\n        id\n        name\n        email\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82b06a88c4809702da801c3e58868caa";

export default node;
