/* @flow */
/* eslint no-useless-escape: 0 */

const BASE_IMG_URL: string = 'https://img.youtube.com/vi/';

const extractId = (url: string): string => {
  let preKeyWord: string = 'v=';
  let videoId: string = '';
  /** Normal YT Video URL */
  const indexOfV: number = url.indexOf(preKeyWord);
  if (indexOfV !== -1) {
    const ampPos: number = videoId.indexOf('&');
    videoId = url.substring(
      indexOfV + preKeyWord.length,
      ampPos === -1 ? url.length : ampPos
    );
  } else {
    /** Embed YT Video URL */
    preKeyWord = 'embed/';
    const indexOfEmbed: number = url.indexOf(preKeyWord);
    const indexOfQ: number = url.lastIndexOf('?');
    if (indexOfEmbed !== -1) {
      videoId = url.substring(
        indexOfEmbed + preKeyWord.length,
        indexOfQ === -1 ? url.length : indexOfQ
      );
    }
  }
  return videoId;
};

const getThumbnail = (url: string, thumbnailIndex: number = 0): string =>
  `${BASE_IMG_URL}${extractId(url)}/${thumbnailIndex}.jpg`;

const getHQImage = (url: string): string =>
  `${BASE_IMG_URL}${extractId(url)}/maxresdefault.jpg`;

const isValidVideoUrl = (url: string): boolean =>
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.test(
    url
  );

export default {
  extractId,
  getThumbnail,
  getHQImage,
  isValidVideoUrl,
};
