/* @flow */
import ReactGA from 'react-ga4';

import environment from '../environment/environment';
import cookiesConsent from '../cookiesConsent/cookiesConsent';
import removeElementFromDom from '../uiDomHelpers/removeElementFromDom';
import localStorageHelpers from '../localStorageHelpers';

const GOOGLE_TRACKING_CODE =
  process.env.REACT_APP_GOOGLE_TRACKING_CODE || 'G-BJEM3571KQ';

let IS_ENABLED =
  cookiesConsent.getConsentCookie()?.analytics && environment.isProduction();

const setUser = (user?: ?{ id: string }) =>
  ReactGA.set({ userId: user?.id || null });

const init = () => {
  if (IS_ENABLED) {
    ReactGA.initialize(GOOGLE_TRACKING_CODE);
    const user = localStorageHelpers.getUser();
    if (user) {
      setUser(user);
    }
  }
};

const trackPage = (page: string) => {
  if (IS_ENABLED) {
    ReactGA.send('pageview');
    ReactGA.set({
      page,
    });
  }
};

const unInit = () => {
  IS_ENABLED = false;
  ['script[src*="google-analytics"]', 'link[src*="google-analytics"]'].forEach(
    (elementRegex) => {
      removeElementFromDom(document.querySelector(elementRegex));
    },
  );
};

const reset = () => {
  ReactGA.set({ userId: null });
};

export default {
  trackPage,
  init,
  unInit,
  setUser,
  reset,
};
