import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation SetUserNotificationSettingsMutation(
    $input: SetUserNotificationSettingsInput!
  ) {
    setUserNotificationSettings(input: $input) {
      error {
        message
      }
      userNotificationSettings {
        account {
          email
          sms
          native_push_notif
        }
        marketing {
          email
          sms
          native_push_notif
        }
        activity {
          email
          sms
          native_push_notif
        }
      }
    }
  }
`;

interface SetUserNotificationSettingsInput {
  /** id */
  id?: string;
  /** account */
  account: {
    /** email */
    email: boolean;
    /** sms */
    sms: boolean;
    /** native push notification */
    native_push_notif: boolean;
  };
  /** marketing */
  marketing: {
    /** email */
    email: boolean;
    /** sms */
    sms: boolean;
    /** native push notification */
    native_push_notif: boolean;
  };
  /** activity */
  activity: {
    /** email */
    email: boolean;
    /** sms */
    sms: boolean;
    /** native push notification */
    native_push_notif: boolean;
  };
}

/**
 * - Set User Notification Settings Mutation
 * - Set the user notification settings
 */
const SetUserNotificationSettingsMutation = (
  { account, marketing, activity },
  settings: SetUserNotificationSettingsInput,
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        account,
        marketing,
        activity,
      },
    };

    const optimisticResponse = {
      setUserNotificationSettings: {
        error: null,
        userNotificationSettings: {
          ...settings,
        },
      },
    };

    return commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default SetUserNotificationSettingsMutation;
