import { setUser, getUser, setAvatar, getAvatar } from './user';
import { setUserRoles, getUserRoles, isUser, isAdmin } from './userRoles';
import { getLocation, setLocation } from './location';
import { setSocketConnId, getSocketConnId } from './socket_conn_id';
import { getIpInfo, setIpInfo } from './ipInfo';
import {
  getSearchListCounter,
  incrementSearchListCounter,
  getOnboardingVisitedCounter,
  incrementOnboardingVisitedCounter,
} from './pageVisitedCounter';
import {
  getCommunityNotificationFindFriendsBanner,
  setCommunityNotificationFindFriendsBanner,
} from './banners';

export default {
  // user
  getUser,
  setUser,
  // userRoles
  setUserRoles,
  getUserRoles,
  isUser,
  isAdmin,
  // avatar
  setAvatar,
  getAvatar,
  // location
  getLocation,
  setLocation,
  // socket_conn_id
  setSocketConnId,
  getSocketConnId,
  // ipInfo
  getIpInfo,
  setIpInfo,
  // SearchListCounter
  getSearchListCounter,
  incrementSearchListCounter,
  getOnboardingVisitedCounter,
  incrementOnboardingVisitedCounter,
  getCommunityNotificationFindFriendsBanner,
  setCommunityNotificationFindFriendsBanner,
};
