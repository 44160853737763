/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation AddEducationMutation($input: AddEducationInput!) {
    addEducation(input: $input) {
      newEducationEdge {
        node {
          id
          name
          url
        }
      }
      error {
        message
      }
    }
  }
`;

export default ({ name }: { name: string }, userId: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        name,
        type: 'University',
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: userId,
        connectionInfo: [
          {
            key: 'UserMoreInfo_education',
            rangeBehavior: 'append',
          },
          {
            key: 'EditProfile_education',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'newEducationEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
