import React from 'react';
import classNames from 'classnames';

import { ButtonFlat } from '@pluralcom/blueprint';

import styles from './SkillCardMiniFloatingButton.module.scss';

export interface Props
  extends Omit<React.ComponentProps<typeof ButtonFlat>, 'size'> {
  /** The size of the button */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

const ICON_SIZE_MAP: {
  [key: string]: React.ComponentProps<typeof ButtonFlat>['size'];
} = {
  sm: 'sm',
  md: 'sm',
  lg: 'md',
  xl: 'md',
  xxl: 'md',
  xxxl: 'md',
};
const SkillCardMiniFloatingButton = ({
  className,
  size = 'xs',
  children,
  ...rest
}: Props) => {
  const iconSize = ICON_SIZE_MAP[size] || 'sm';

  return (
    <ButtonFlat
      uiType="tertiary"
      size={iconSize}
      className={classNames([
        styles.container,
        styles[`container-${size}`],
        className,
      ])}
      borderType
      {...rest}
    >
      {children}
    </ButtonFlat>
  );
};
export default SkillCardMiniFloatingButton;
