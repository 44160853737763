/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DisableUserNotificationMutation(
    $input: DisableUserNotificationInput!
  ) {
    disableUserNotification(input: $input) {
      error {
        message
      }
    }
  }
`;

const DisableUserNotificationMutation = ({
  token,
}: {
  token: String,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        token,
      },
    };

    commitMutation({
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });
export default DisableUserNotificationMutation;
