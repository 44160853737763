/**
 * @generated SignedSource<<3d4ba3783ea448c2453a1b274e3f91ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormReferUser_viewer$data = {
  readonly referrals: {
    readonly id: string;
    readonly totalEarnableReferrals: number | null;
    readonly totalEarned: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"InputSkill_viewer" | "InputUsers_viewer">;
  readonly " $fragmentType": "FormReferUser_viewer";
};
export type FormReferUser_viewer$key = {
  readonly " $data"?: FormReferUser_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormReferUser_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "otherUserId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipOwnSkills"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormReferUser_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Referrals",
      "kind": "LinkedField",
      "name": "referrals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalEarned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalEarnableReferrals",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InputUsers_viewer"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "other_user_id",
          "variableName": "otherUserId"
        },
        {
          "kind": "Variable",
          "name": "skipOwnSkills",
          "variableName": "skipOwnSkills"
        }
      ],
      "kind": "FragmentSpread",
      "name": "InputSkill_viewer"
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node as any).hash = "6904da8e1a11e6c4c1a1cb1c358d6d97";

export default node;
