/* @flow */

/** Sets the caret position in an input element with a type that supports caret positioning */
const setCaretPosition = (el: HTMLElement, caretPos: number): void => {
  if (el) {
    if (el.createTextRange) {
      const range = el.createTextRange();
      range.move('character', caretPos);
      range.select();
      return;
    }
    el.focus();
    // (el.selectionStart === 0 added for Firefox bug)
    if (el.selectionStart || el.selectionStart === 0) {
      el.setSelectionRange(caretPos, caretPos);
    }
  }
};

export default setCaretPosition;
