/* @flow */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import MDSpinner from 'react-md-spinner';

import { COLOR_BLUE } from '../../assets/styles/js/colors';

import styles from './Spinner.module.scss';

type Props = {
  color?: string,
  className?: ?string,
  size?: number,
  borderSize?: number,
};

const Spinner = ({
  className,
  color = COLOR_BLUE,
  size,
  borderSize,
  ...rest
}: Props) => {
  const style = useMemo(
    () => (size ? { width: `${size}px`, height: `${size}px` } : undefined),
    [size],
  );
  return (
    <span
      className={classNames([styles.container, className])}
      style={style}
      {...rest}
    >
      <MDSpinner singleColor={color} size={size} borderSize={borderSize} />
    </span>
  );
};

export default Spinner;
