/* @flow */
import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import styles from './Tooltip.module.scss';
import disableScroll from '../../utils/uiDomHelpers/disableScroll';
import enableScroll from '../../utils/uiDomHelpers/enableScroll';

/**
 * Provide simple tooltip component based on
 *  - https://www.npmjs.com/package/react-popper-tooltip
 * @example
 *    <Tooltip
 *      ref={tooltipRef}
 *      renderTrigger={() => <FontAwesomeIcon ...}
 *     >
 *      <div>Tooltip body here</div>
 *    </Tooltip>
 *
 *  - to hide/show
 *     tooltipRef.current && tooltipRef.current.hideTooltip()
 * */

type TooltipWrapperProps = {
  arrowRef: Function,
  tooltipRef: Function,
  getArrowProps: Function,
  getTooltipProps: Function,
  placement: string,
  children: ReactNode,
  tooltipStyle?: Object,
  arrowClassName?: string,
  tooltipClassName?: string,
  tooltipBodyClassName?: string,
  noArrow?: boolean,
};

const TooltipWrapper = ({
  tooltipRef,
  getArrowProps,
  getTooltipProps,
  placement,
  children,
  tooltipStyle = {},
  arrowClassName,
  tooltipClassName,
  tooltipBodyClassName,
  noArrow,
}: TooltipWrapperProps) => {
  const tooltipProps = getTooltipProps({
    ref: tooltipRef,
    className: classNames(
      'tooltip-container',
      styles['tooltip-container'],
      tooltipClassName,
    ),
  });

  return (
    <div {...tooltipProps} style={{ ...tooltipProps?.style, ...tooltipStyle }}>
      {noArrow ? null : (
        <div
          {...getArrowProps({
            className: classNames(
              'tooltip-arrow',
              styles['tooltip-arrow'],
              arrowClassName,
            ),
            'data-placement': placement,
          })}
        />
      )}
      <div
        className={classNames(
          'tooltip-body',
          styles['tooltip-body'],
          tooltipBodyClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

type TriggerProps = {
  triggerRef: Function,
  triggerClassName?: string,
  children: ReactNode,
};

const Trigger = ({ triggerRef, triggerClassName, children }: TriggerProps) => (
  <div
    className={classNames(
      'trigger',
      styles['trigger-container'],
      triggerClassName,
    )}
    ref={triggerRef}
  >
    {children}
  </div>
);

type Props = {
  children: ReactNode,
  renderTrigger: ReactNode,
  triggerClassName?: string,
  trigger: 'click' | 'hover',
  placement?: string,
  isOpen?: Boolean,
  onVisibleChange?: Function,
  defaultVisible?: boolean,
  offset?: Array<String>,
  renderChildren?: Function,
  withType?: string,
};

const Tooltip = ({
  children,
  renderTrigger,
  triggerClassName,
  placement,
  isOpen,
  onVisibleChange,
  offset,
  defaultVisible = false,
  trigger = 'click',
  renderChildren,
  withType = '',
  ...rest
}: Props) => {
  const [visible, setVisible] = useState(defaultVisible);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible: reactPopperVisible,
  } = usePopperTooltip({
    trigger,
    placement,
    onVisibleChange: trigger === 'click' ? undefined : onVisibleChange,
    visible,
    interactive: true,
    offset,
  });

  /**
   * We rewrite trigger = 'click' behaviour, while react-popper-tooltip
   * is hiding tooltip on onmouseleave event,
   * it hardcoded in https://github.com/mohsinulhaq/react-popper-tooltip/blob/master/src/usePopperTooltip.ts#L186 */
  const _toggleTooltip = useCallback(
    (val) => {
      if (isOpen === undefined) {
        if (withType === 'tabNotification') {
          disableScroll();
        }
        setVisible(val ?? !visible);
        if (onVisibleChange) {
          onVisibleChange(val ?? !visible);
        }
      } else if (onVisibleChange) {
        onVisibleChange(val ?? !isOpen);
      }
    },
    [visible, isOpen, setVisible, onVisibleChange, withType],
  );

  const handleClickOutside = useCallback(
    (event) => {
      enableScroll();
      if (tooltipRef == null) return;
      if (
        !tooltipRef.contains(event.target) &&
        !triggerRef.contains(event.target)
      ) {
        if (isOpen === undefined) {
          setVisible(false);
        }
        if (onVisibleChange) {
          onVisibleChange(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isOpen, setVisible, tooltipRef, triggerRef, onVisibleChange],
  );

  /** handle trigger click */
  useEffect(() => {
    if (triggerRef == null || trigger !== 'click') return;
    triggerRef.addEventListener('click', _toggleTooltip);
    // eslint-disable-next-line consistent-return
    return () => {
      triggerRef.removeEventListener('click', _toggleTooltip);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRef, _toggleTooltip]);

  /** handle outside click */
  useLayoutEffect(() => {
    if (tooltipRef == null || trigger !== 'click') return;
    window.addEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef, handleClickOutside]);

  /** handle isOpen change */
  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const isVisible = trigger === 'click' ? visible : reactPopperVisible;

  return (
    <>
      <Trigger triggerRef={setTriggerRef} triggerClassName={triggerClassName}>
        {renderTrigger()}
      </Trigger>

      {isVisible && (
        <TooltipWrapper
          getTooltipProps={getTooltipProps}
          getArrowProps={getArrowProps}
          tooltipRef={setTooltipRef}
          placement={placement}
          {...rest}
        >
          {children ||
            (renderChildren &&
              renderChildren({ toggleTooltip: _toggleTooltip }))}
        </TooltipWrapper>
      )}
    </>
  );
};

export { TooltipWrapper, Trigger };
export default Tooltip;
