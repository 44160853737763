/* @flow */
/**
 * Returns true to proceed and false to skip
 */
const skipMiddleware = async ({ data, socket }) => {
  const { skip_socket_ids } = data || {};
  return !(skip_socket_ids && skip_socket_ids.includes(socket.id));
};

export default skipMiddleware;
