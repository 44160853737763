import responsive from './responsive.json';

const {
  gridBreakpointXS,
  gridBreakpointSM,
  gridBreakpointMD,
  gridBreakpointLG,
  gridBreakpointXLG,
  gridBreakpointXXLG,
} = responsive;

const getMaxDeviceWithFromNextGridBreakpoint = (nextGridBreakPoint: number) =>
  nextGridBreakPoint - 1;

export default {
  // gridBreakpoints
  gridBreakpointXS,
  gridBreakpointSM,
  gridBreakpointMD,
  gridBreakpointLG,
  gridBreakpointXLG,
  gridBreakpointXXLG,
  // minDeviceWidth
  minDeviceWidthXS: gridBreakpointXS,
  minDeviceWidthSM: gridBreakpointSM,
  minDeviceWidthMD: gridBreakpointMD,
  minDeviceWidthLG: gridBreakpointLG,
  minDeviceWidthXLG: gridBreakpointXLG,
  minDeviceWidthXXLG: gridBreakpointXXLG,
  // maxDeviceWidth
  maxDeviceWidthXS: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointSM),
  maxDeviceWidthSM: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointMD),
  maxDeviceWidthMD: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointLG),
  maxDeviceWidthLG: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointXLG),
  maxDeviceWidthXLG: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointXXLG),

  // funcs
  scrollToTop: (e: {
    /**
     * Scrolls to the top of the page with smooth behavior.
     * @param {Object} e - The event object.
     */
    preventDefault: Function;
  }) => {
    if (e) {
      e.preventDefault();
    }
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  },

  scrollTo: (opts: {
    /** The left position to scroll to */
    left: number;
    /** The top position to scroll to */
    top: number;
    /** The scroll behavior */
    behavior?: 'auto' | 'smooth';
  }) => {
    window.scrollTo({
      behavior: 'smooth',
      ...opts,
    });
  },

  getScroll: (): {
    /** The left position to scroll to */
    left: number;
    /** The top position to scroll to */
    top: number;
  } => {
    if (window.pageYOffset) {
      return { left: window.pageXOffset, top: window.pageYOffset };
    }
    const r = document.documentElement || {};
    const b = document.body || {};
    const left = r.scrollLeft || b.scrollLeft || 0;
    const top = r.scrollTop || b.scrollTop || 0;
    return { left, top };
  },

  genMinWidthPxMQ: (width: string | number) => `(min-width: ${width}px)`,

  isMatchMedia: (mqString: string) => window.matchMedia(mqString).matches,
};
