/**
 * @generated SignedSource<<3837fd338423ef90ccc5fd35f01801d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenderType = "FEMALE" | "MALE" | "NOT_SPECIFIED" | "%future added value";
export type EditProfileInput = {
  bio?: string | null;
  birthdate?: string | null;
  clientMutationId?: string | null;
  first_name?: string | null;
  gender?: GenderType | null;
  last_name?: string | null;
  location?: LocationInput | null;
  profile_color?: string | null;
  socialProfiles?: SocialProfilesInputType | null;
  username?: string | null;
  website?: string | null;
};
export type SocialProfilesInputType = {
  behance?: string | null;
  buymeacoffee?: string | null;
  dribbble?: string | null;
  flickr?: string | null;
  github?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  medium?: string | null;
  patreon?: string | null;
  pinterest?: string | null;
  quora?: string | null;
  snapchat?: string | null;
  stackoverflow?: string | null;
  substack?: string | null;
  tiktok?: string | null;
  tumblr?: string | null;
  twitter?: string | null;
  vimeo?: string | null;
  youtube?: string | null;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  is_anonymized?: boolean | null;
  latitude?: number | null;
  line1?: string | null;
  line2?: string | null;
  longitude?: number | null;
  note?: string | null;
  offset?: string | null;
  postal_code?: string | null;
  single_line?: string | null;
  state?: string | null;
  zoom_level?: number | null;
};
export type EditProfileColorMutation$variables = {
  input: EditProfileInput;
};
export type EditProfileColorMutation$data = {
  readonly editProfile: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly id: string;
      readonly profile_color: string | null;
    } | null;
  } | null;
};
export type EditProfileColorMutation = {
  response: EditProfileColorMutation$data;
  variables: EditProfileColorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profile_color",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileColorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditProfilePayload",
        "kind": "LinkedField",
        "name": "editProfile",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfileColorMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditProfilePayload",
        "kind": "LinkedField",
        "name": "editProfile",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fee75725a80cec23699bdfbd10c80809",
    "id": null,
    "metadata": {},
    "name": "EditProfileColorMutation",
    "operationKind": "mutation",
    "text": "mutation EditProfileColorMutation(\n  $input: EditProfileInput!\n) {\n  editProfile(input: $input) {\n    profile {\n      id\n      profile_color\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fca03f4254565825c196e6ca81b658b6";

export default node;
