import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  Avatar,
  ButtonFlat,
  ButtonLink,
  CardMiniMedia,
  Icon,
  Text,
} from '@pluralcom/blueprint';

import styles from './ListItemComplex.module.scss';

interface ListItemComplexProps {
  /** Title */
  title?: string;
  /** text */
  text?: string | ReactNode;
  /** subtitle */
  subtitle?: string;
  /** custom css */
  className?: string;
  /** button props */
  buttonProps?: React.ComponentProps<typeof ButtonFlat>;
  /** optional function to render a button - can be used to pass an instance of a button instead of the default one */
  renderButton?: (
    buttonProps: React.ComponentProps<typeof ButtonFlat> | undefined,
  ) => React.ReactNode;
  /** icon props */
  leftIconProps?: React.ComponentProps<typeof Icon>;
  /** avatar props */
  avatarProps?: React.ComponentProps<typeof Avatar>;
  /** button link props */
  buttonLinkProps?: Omit<React.ComponentProps<typeof ButtonLink>, 'children'>;
  /** title class name */
  titleClassName?: string;
}

const ListItemComplex = ({
  text,
  title,
  subtitle,
  className,
  buttonProps,
  leftIconProps,
  avatarProps,
  renderButton,
  buttonLinkProps,
  titleClassName,
  ...rest
}: ListItemComplexProps) => (
  <ButtonLink {...buttonLinkProps} {...rest}>
    <div className={classNames([styles.container, className])}>
      {leftIconProps && (
        <CardMiniMedia
          hasBorder
          iconProps={{
            iconClassName: classNames([
              leftIconProps?.iconClassName,
              styles['left-icon'],
            ]),
            ...leftIconProps,
          }}
        />
      )}
      {avatarProps && (
        <Avatar size="md" borderType="no-border" {...avatarProps} />
      )}
      <div className={styles['date-time-container']}>
        <div className={styles['title-info-container']}>
          <div className={styles['title-container']}>
            {title && (
              <Text
                fontSizeType="t6"
                className={classNames([
                  styles['text-container'],
                  styles.text,
                  titleClassName,
                ])}
                element="span"
              >
                {title}
              </Text>
            )}

            {subtitle && (
              <Text
                fontSizeType="t7"
                className={classNames([styles.text, styles['text-container']])}
                element="span"
              >
                {subtitle}
              </Text>
            )}
          </div>
          {text && (
            <Text
              fontSizeType="t7"
              className={classNames([styles['text-container'], styles.subtext])}
              element="span"
            >
              {text}
            </Text>
          )}
        </div>
      </div>

      {buttonProps && (
        <ButtonFlat
          uiType="secondary"
          size="sm"
          className={classNames([styles.button, buttonProps?.className])}
          {...buttonProps}
        />
      )}
      {renderButton
        ? renderButton({
            ...buttonProps,
            className: classNames([styles.button, buttonProps?.className]),
          })
        : null}
    </div>
  </ButtonLink>
);

export default ListItemComplex;
