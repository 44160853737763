/* @flow */
const ROOT_PREFIX: string = 'MODALS/';

/** Typechecking for javascript */
export type InitialStateType = {
  isOnBoardingModalsOpen: boolean,
  isFindFriendsModalOpen: boolean,
  isSearchPublicModalOpen: boolean,
};
const initialState: InitialStateType = {
  isOnBoardingModalsOpen: false,
  isFindFriendsModalOpen: false,
  isSearchPublicModalOpen: false,
};

// Actions
export type AuthActionType = { type: string, [string]: any };
export const TOGGLE_ONBOARDING_MODALS = `${ROOT_PREFIX}TOGGLE_ONBOARDING_MODALS`;
export const TOGGLE_FIND_FRIENDS = `${ROOT_PREFIX}TOGGLE_FIND_FRIENDS`;
export const TOGGLE_SEARCH_PUBLIC_MODAL = `${ROOT_PREFIX}TOGGLE_SEARCH_PUBLIC_MODAL`;

// Action creators
// export const toggleOnBoardingModals = (value: any) => (dispatch: Function) => {
//   dispatch({
//     type: TOGGLE_ONBOARDING_MODALS,
//     payload: value,
//   });
// };

export const toggleOnBoardingModals = () => (dispatch: Function) => {
  dispatch({
    type: TOGGLE_ONBOARDING_MODALS,
  });
};

export const toggleFindFriendsModal = (value: any) => (dispatch: Function) => {
  dispatch({
    type: TOGGLE_FIND_FRIENDS,
    payload: value,
  });
};

export const toggleSearchPublicModal = (value: any) => (dispatch: Function) => {
  dispatch({
    type: TOGGLE_SEARCH_PUBLIC_MODAL,
    payload: value,
  });
};

// Reducer
export default (
  state: InitialStateType = initialState,
  action: AuthActionType,
) => {
  switch (action.type) {
    case TOGGLE_ONBOARDING_MODALS:
      return {
        ...state,
        // isOnBoardingModalsOpen: action.payload,
        isOnBoardingModalsOpen: !state.isOnBoardingModalsOpen,
      };
    case TOGGLE_FIND_FRIENDS:
      return {
        ...state,
        isFindFriendsModalOpen: action.payload,
      };
    case TOGGLE_SEARCH_PUBLIC_MODAL:
      return {
        ...state,
        isSearchPublicModalOpen: action.payload,
      };
    default:
      return state;
  }
};
