/**
 * @generated SignedSource<<905af96082ceb74d652e25c9468a4b45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AIAssistantEnum = "search_hire" | "%future added value";
export type SendMessageToAIAssistantInput = {
  clientMutationId?: string | null;
  location?: LocationInput | null;
  message: string;
  thread_id?: string | null;
  type?: AIAssistantEnum | null;
};
export type LocationInput = {
  city?: string | null;
  country?: string | null;
  country_code?: string | null;
  is_anonymized?: boolean | null;
  latitude?: number | null;
  line1?: string | null;
  line2?: string | null;
  longitude?: number | null;
  note?: string | null;
  offset?: string | null;
  postal_code?: string | null;
  single_line?: string | null;
  state?: string | null;
  zoom_level?: number | null;
};
export type SendMessageToAIAssistantMutation$variables = {
  input: SendMessageToAIAssistantInput;
};
export type SendMessageToAIAssistantMutation$data = {
  readonly sendMessageToAIAssistant: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly external_results: ReadonlyArray<{
      readonly avatar_url: string | null;
      readonly first_name: string | null;
      readonly formatted_address: string | null;
      readonly id: string;
      readonly image_url: string | null;
      readonly last_name: string | null;
      readonly location: {
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly title: string | null;
      readonly url: string | null;
    } | null> | null;
    readonly location: {
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly message: string | null;
    readonly skill_title: string | null;
    readonly skills: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"SkillCardMini_skill">;
        } | null;
      } | null> | null;
    } | null;
    readonly thread_id: string | null;
  } | null;
};
export type SendMessageToAIAssistantMutation = {
  response: SendMessageToAIAssistantMutation$data;
  variables: SendMessageToAIAssistantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thread_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skill_title",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AIAssistantExternalResults",
  "kind": "LinkedField",
  "name": "external_results",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar_url",
      "storageKey": null
    },
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formatted_address",
      "storageKey": null
    },
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallThumbnail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendMessageToAIAssistantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendMessageToAIAssistantPayload",
        "kind": "LinkedField",
        "name": "sendMessageToAIAssistant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "isSkillCardLean",
                            "value": true
                          }
                        ],
                        "kind": "FragmentSpread",
                        "name": "SkillCardMini_skill"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "skills(first:5)"
          },
          (v13/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendMessageToAIAssistantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendMessageToAIAssistantPayload",
        "kind": "LinkedField",
        "name": "sendMessageToAIAssistant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "SkillConnection",
            "kind": "LinkedField",
            "name": "skills",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "is_hobby",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hiring_types",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SkillLikes",
                        "kind": "LinkedField",
                        "name": "likes",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerHasLiked",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profile_color",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserRecommendations",
                            "kind": "LinkedField",
                            "name": "recommendations",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserConnection",
                                "kind": "LinkedField",
                                "name": "received",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "totalCount",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 50
                          }
                        ],
                        "concreteType": "MediaConnection",
                        "kind": "LinkedField",
                        "name": "media",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MediaEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Media",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v14/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "medium_wide",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Video",
                                    "kind": "LinkedField",
                                    "name": "video",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "media(first:50)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "skills(first:5)"
          },
          (v13/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c32ddecbea7ec7f70c3d08e163b42d86",
    "id": null,
    "metadata": {},
    "name": "SendMessageToAIAssistantMutation",
    "operationKind": "mutation",
    "text": "mutation SendMessageToAIAssistantMutation(\n  $input: SendMessageToAIAssistantInput!\n) {\n  sendMessageToAIAssistant(input: $input) {\n    thread_id\n    message\n    skill_title\n    skills(first: 5) {\n      edges {\n        node {\n          id\n          ...SkillCardMini_skill_15bC7U\n        }\n      }\n    }\n    external_results {\n      id\n      url\n      title\n      image_url\n      avatar_url\n      first_name\n      last_name\n      formatted_address\n      location {\n        id\n        name\n      }\n    }\n    location {\n      id\n      name\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n\nfragment SkillCardMiniHeader_skill_1vp5m7 on Skill {\n  id\n  title\n  is_hobby\n  hiring_types\n  likes {\n    id\n    viewerHasLiked\n  }\n  user {\n    id\n    first_name\n    last_name\n    name\n    profile_color\n    avatar {\n      id\n      smallThumbnail\n    }\n    recommendations {\n      id\n      received {\n        totalCount\n      }\n    }\n  }\n}\n\nfragment SkillCardMiniMediaSection_skill on Skill {\n  id\n  user {\n    id\n    profile_color\n  }\n  media(first: 50) {\n    edges {\n      node {\n        id\n        image {\n          id\n          smallThumbnail\n          medium_wide\n        }\n        video {\n          id\n          url\n        }\n      }\n    }\n  }\n}\n\nfragment SkillCardMini_skill_15bC7U on Skill {\n  id\n  ...SkillCardMiniHeader_skill_1vp5m7\n  ...SkillCardMiniMediaSection_skill\n}\n"
  }
};
})();

(node as any).hash = "f00aebec6d9f2a67a0d33f35d0c77785";

export default node;
