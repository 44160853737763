/**
 * @generated SignedSource<<a12b92d634571e61880d7c4b452ab659>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetUserNotificationSettingsInput = {
  account?: SetNotificationTypeSettingsInput | null;
  activity?: SetNotificationTypeSettingsInput | null;
  clientMutationId?: string | null;
  marketing?: SetNotificationTypeSettingsInput | null;
};
export type SetNotificationTypeSettingsInput = {
  browser_push_notif?: boolean | null;
  email?: boolean | null;
  native_push_notif?: boolean | null;
  sms?: boolean | null;
};
export type SetUserNotificationSettingsMutation$variables = {
  input: SetUserNotificationSettingsInput;
};
export type SetUserNotificationSettingsMutation$data = {
  readonly setUserNotificationSettings: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly userNotificationSettings: {
      readonly account: {
        readonly email: boolean;
        readonly native_push_notif: boolean;
        readonly sms: boolean;
      } | null;
      readonly activity: {
        readonly email: boolean;
        readonly native_push_notif: boolean;
        readonly sms: boolean;
      } | null;
      readonly marketing: {
        readonly email: boolean;
        readonly native_push_notif: boolean;
        readonly sms: boolean;
      } | null;
    } | null;
  } | null;
};
export type SetUserNotificationSettingsMutation = {
  response: SetUserNotificationSettingsMutation$data;
  variables: SetUserNotificationSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sms",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "native_push_notif",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationSettings",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationSettings",
  "kind": "LinkedField",
  "name": "marketing",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "NotificationSettings",
  "kind": "LinkedField",
  "name": "activity",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetUserNotificationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserNotificationSettingsPayload",
        "kind": "LinkedField",
        "name": "setUserNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationSettings",
            "kind": "LinkedField",
            "name": "userNotificationSettings",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetUserNotificationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetUserNotificationSettingsPayload",
        "kind": "LinkedField",
        "name": "setUserNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationSettings",
            "kind": "LinkedField",
            "name": "userNotificationSettings",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63168da8b8b5550f01c47547c16be800",
    "id": null,
    "metadata": {},
    "name": "SetUserNotificationSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation SetUserNotificationSettingsMutation(\n  $input: SetUserNotificationSettingsInput!\n) {\n  setUserNotificationSettings(input: $input) {\n    error {\n      message\n      id\n    }\n    userNotificationSettings {\n      account {\n        email\n        sms\n        native_push_notif\n      }\n      marketing {\n        email\n        sms\n        native_push_notif\n      }\n      activity {\n        email\n        sms\n        native_push_notif\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b0806189652aa5b2fa0ee4b2f0a1905";

export default node;
