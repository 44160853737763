import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import { SkillMediaGalleryMini } from './components';

import type { SkillCardMiniMediaSection_skill$data } from './__generated__/SkillCardMiniMediaSection_skill.graphql';

export interface SkillCardMiniMediaSectionProps {
  /** logged in user Identifier */
  isOwner?: boolean;
  /** card has Image Identifier */
  hasImage?: boolean;
  /** skill */
  skill: SkillCardMiniMediaSection_skill$data;
  /** size */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

/**
 *
 * FIGMA v0.0.1
 * SkillCardMiniMediaSection component.
 */
const SkillCardMiniMediaSection = ({
  skill,
  ...rest
}: SkillCardMiniMediaSectionProps) => {
  const { media, user } = skill;

  const hasImage = media && media?.edges && media?.edges?.length > 0;

  return (
    <SkillMediaGalleryMini
      media={media}
      user={user}
      hasImage={hasImage || false}
      {...rest}
    />
  );
};

export default createFragmentContainer(SkillCardMiniMediaSection, {
  skill: graphql`
    fragment SkillCardMiniMediaSection_skill on Skill {
      id
      user {
        id
        profile_color
      }
      media(first: 50) {
        edges {
          node {
            id
            image {
              id
              smallThumbnail
              medium_wide
            }
            video {
              id
              url
            }
          }
        }
      }
    }
  `,
});
