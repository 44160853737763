/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (
  {
    id,
    title,
    start,
    end,
    companyName,
    companyURL,
  }: {
    id: string,
    title: string,
    start: Date,
    end: Date,
    companyName: string,
    companyURL: string,
  },
  { existingPosition }: { existingPosition: Object } = {},
): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation EditPositionMutation($input: EditPositionInput!) {
        editPosition(input: $input) {
          error {
            message
          }
          updatedPosition {
            id
            title
            start
            end
            company {
              id
              name
              url
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        positionId: id,
        title,
        start,
        end,
        companyName,
        companyURL,
      },
    };

    return commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        editPosition: {
          error: null,
          updatedPosition: {
            ...existingPosition,
            id,
            title,
            start,
            end,
            companyName,
            companyURL,
          },
        },
      },
      onCompleted: res => resolve(res),
      onError: err => reject(err),
    });
  });
