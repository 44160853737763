import { fromGlobalId } from '../graphql/utils/globalIdHelpers';

export default {
  /**
   * Converts a relay global id to a local id.
   * We may remove this in the future
   */
  fromGlobalId,
  fromGlobalIdToLocalId: gId => {
    try {
      return fromGlobalId(gId).id;
    } catch (e) {
      return undefined;
    }
  },
};
