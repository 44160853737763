/* @flow */
import googleAnalytics from '../googleAnalytics/googleAnalytics';
import mixpanelHelpers from '../mixpanelHelpers/mixpanelHelpers';
import hotjarHelpers from '../hotjarHelpers/hotjarHelpers';
import heapHelpers from '../heapHelpers/heapHelpers';
import cannyHelpers from '../cannyHelpers/cannyHelpers';
import cookiesConsent from '../cookiesConsent/cookiesConsent';

/**
 * If analytics are enabled
 */
let ENABLED = cookiesConsent.getConsentCookie()?.analytics || false;

const init = () => {
  if (!ENABLED || window.navigator.userAgent === 'ReactSnap') {
    return;
  }
  googleAnalytics.init();
  mixpanelHelpers.init();
  hotjarHelpers.init();
  heapHelpers.init();
  cannyHelpers.init();
  ENABLED = true;
};

const unInit = () => {
  if (!ENABLED) {
    return;
  }
  googleAnalytics.unInit();
  mixpanelHelpers.unInit();
  hotjarHelpers.unInit();
  heapHelpers.unInit();
  cannyHelpers.unInit();
  ENABLED = false;
};

const setUser = ({ user, roles } = {}) => {
  googleAnalytics.setUser(user);
  mixpanelHelpers.setAlias(user);
  mixpanelHelpers.setUser(user, { roles });
  hotjarHelpers.setUser(user, { roles });
  heapHelpers.setUser(user, { roles });
  cannyHelpers.setUser(user, { roles });
};

const reset = () => {
  googleAnalytics.reset();
  mixpanelHelpers.reset();
  hotjarHelpers.reset();
  heapHelpers.reset();
  cannyHelpers.reset();
};

export default {
  ENABLED,
  init,
  unInit,
  setUser,
  reset,
};
