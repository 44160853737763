/**
 * @generated SignedSource<<5294bb50d28959f0b003a54060eab6f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddFAQuestionToSkillInput = {
  answer: string;
  clientMutationId?: string | null;
  question: string;
  skill_id: string;
};
export type AddQuestionMutation$variables = {
  input: AddFAQuestionToSkillInput;
};
export type AddQuestionMutation$data = {
  readonly addFAQuestionToSkill: {
    readonly error: {
      readonly code: number | null;
      readonly id: string;
      readonly message: string | null;
      readonly technical_message: string | null;
      readonly title: string | null;
    } | null;
    readonly skill: {
      readonly id: string;
      readonly questions: ReadonlyArray<{
        readonly answer: string;
        readonly id: string;
        readonly original_question: {
          readonly id: string;
        };
        readonly question: string;
      } | null> | null;
    } | null;
    readonly skill_question: {
      readonly answer: string;
      readonly id: string;
      readonly question: string;
    } | null;
  } | null;
};
export type AddQuestionMutation = {
  response: AddQuestionMutation$data;
  variables: AddQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFAQuestionToSkillPayload",
    "kind": "LinkedField",
    "name": "addFAQuestionToSkill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "technical_message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillFAQuestion",
        "kind": "LinkedField",
        "name": "skill_question",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skill",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillFAQuestion",
            "kind": "LinkedField",
            "name": "questions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FAQuestion",
                "kind": "LinkedField",
                "name": "original_question",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddQuestionMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddQuestionMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "231b3a6eb244a7d1abd17af0509e8499",
    "id": null,
    "metadata": {},
    "name": "AddQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation AddQuestionMutation(\n  $input: AddFAQuestionToSkillInput!\n) {\n  addFAQuestionToSkill(input: $input) {\n    error {\n      id\n      title\n      technical_message\n      message\n      code\n    }\n    skill_question {\n      id\n      question\n      answer\n    }\n    skill {\n      id\n      questions {\n        id\n        question\n        answer\n        original_question {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f6b247b7e4c590608dfc56a4811025a";

export default node;
