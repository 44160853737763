/* @flow */
import React from 'react';
import urlJoin from 'url-join';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Anchor } from '@pluralcom/blueprint';

import FancyDialog from '../FancyDialog/FancyDialog';
import { getWebUrl } from '../../utils';
import { AcceptLatestTosPPMutation } from '../../graphql/mutations';
import { toggleLegalAccepted } from '../../redux/reducers/legalReducer/legalReducer';

type Props = {
  hasAcceptedLatestContracts: boolean,
  toggleLegalAccepted: Function,
};

const ModalLegal = ({
  hasAcceptedLatestContracts,
  toggleLegalAccepted: toggleAcceptedLegal,
}: Props) => {
  /**
   * Send mutation to backend and hide the modal
   * IF no errors occurred
   */
  const _acceptLegal = (): void => {
    AcceptLatestTosPPMutation().then(({ acceptLatestTosPP }) => {
      if (!acceptLatestTosPP.error) {
        toggleAcceptedLegal(true);
      }
    });
  };

  return (
    <FancyDialog
      isOpen={!hasAcceptedLatestContracts}
      primaryBtnText="Continue"
      title="Plural Terms of Use and Privacy Policy Update"
      primaryBtnOnClick={_acceptLegal}
      noCloseButton
    >
      Heads up that we’ve updated our
      <Anchor target="_blank" href={urlJoin(getWebUrl(), 'info/terms-of-use')}>
        Terms of Use
      </Anchor>
      and
      <Anchor target="_blank" to={urlJoin(getWebUrl(), 'info/privacy-policy')}>
        Privacy Policy
      </Anchor>
      . We’ve updated them to more accurately reflect our business today. We’ve
      also simplified them to make them easier for you to understand.
      <br />
      <br />
      Your continued use of the Plural Platform confirms that you’ve read,
      understood, and accepted these new terms and policies.
    </FancyDialog>
  );
};

const mapStateToProps = ({ legal: { hasAcceptedLatestContracts } }) => ({
  hasAcceptedLatestContracts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ toggleLegalAccepted }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalLegal);
export { ModalLegal as PureModalLegal };
