/* @flow */
import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import { generateOptimisticId } from '@pluralcom/plural-js-utils';

import { setNodeValues } from '../../relayUtils';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation ReferUserMutation($input: ReferUserInput!) {
    referUser(input: $input) {
      createdReferrals {
        edges {
          node {
            id
            referred {
              id
              first_name
              last_name
              profile_color
              avatar {
                id
                smallThumbnail
              }
              email
              phone
            }
            referred_to {
              id
              first_name
              last_name
              profile_color
              avatar {
                id
                smallThumbnail
              }
              email
              phone
            }
            status
            earned
            created_at
          }
        }
      }
      referrals {
        id
        totalEarned
        totalEarnableReferrals
      }
      error {
        message
      }
    }
  }
`;

const sharedUpdater = (store, newEdges, newTotalCount, referrals) => {
  const referralsField = store.get(referrals.id);

  const connection =
    referralsField &&
    ConnectionHandler.getConnection(referralsField, 'ReferralsList_referrals');

  if (connection) {
    newEdges.forEach((newEdge) => {
      ConnectionHandler.insertEdgeBefore(connection, newEdge);
    });
    connection.setValue(
      newTotalCount ?? connection.getValue('totalCount') + newEdges.length,
      'totalCount',
    );
  }
};

export default (
  {
    referred_user_id,
    referred_email,
    referred_phone,
    referred_to_phone,
    referred_to_email,
    referred_to_user_id,
    user_search_public_id,
    skill_id,
    skill_title,
    note,
  }: {
    referred_user_id?: string,
    referred_email?: string,
    referred_phone?: string,
    referred_to_phone?: string,
    referred_to_email?: string,
    referred_to_user_id?: string,
    user_search_public_id?: string,
    skill_id?: string,
    skill_title?: string,
    note?: string,
  },
  {
    referrals,
    referred,
    referred_to,
  }: {
    referrals: {
      id: string,
      totalEarned: number,
      totalEarnableReferrals: number,
    },
    referred: {
      id: string,
      first_name: string,
      avatar?: { id: string, smallThumbnail: string },
    },
    referred_to: {
      id: string,
      first_name: string,
      avatar?: { id: string, smallThumbnail: string },
    },
  } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        referred_user_id,
        referred_email,
        referred_phone,
        referred_to_phone,
        referred_to_email,
        referred_to_user_id,
        user_search_public_id,
        skill_id,
        skill_title,
        note,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticUpdater: (store) => {
        const referralsField = referrals && store.get(referrals.id);
        const connection =
          referralsField &&
          ConnectionHandler.getConnection(
            referralsField,
            'ReferralsList_referrals',
          );
        if (
          connection &&
          referrals &&
          referred &&
          // referred to object is passed or not needed
          (referred_to ||
            (!referred_to_phone && !referred_to_email && !referred_to_user_id))
        ) {
          const id = generateOptimisticId('Referral');
          const node = store.create(id, 'Referral');

          const referredNode = store.get(referred.id);
          const referredToNode = referred_to && store.get(referred_to.id);

          setNodeValues(node, {
            id: generateOptimisticId('Referral'),
            status: 'pending',
            earned: 0,
            created_at: new Date().toISOString(),
          });
          if (referredNode) {
            node.setLinkedRecord(referredNode, 'referred');
          }
          if (referredToNode) {
            node.setLinkedRecord(referredToNode, 'referred_to');
          }

          const newEdge = ConnectionHandler.createEdge(
            store,
            connection,
            node,
            'ReferralEdge',
          );

          sharedUpdater(store, [newEdge], undefined, referrals);
        }
      },
      updater: (store) => {
        const payload = store.getRootField('referUser');
        const error = payload.getLinkedRecord('error');

        const newConnection = payload.getLinkedRecord('createdReferrals');

        if (!error) {
          sharedUpdater(
            store,
            newConnection?.getLinkedRecords('edges'),
            newConnection.getValue('totalCount'),
            referrals,
          );
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
