/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation UnrecommendSkillMutation($input: UnrecommendSkillInput!) {
    unrecommendSkill(input: $input) {
      error {
        message
      }
    }
  }
`;

export default ({ skill_id }: { skill_id: string }): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_id,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
