/**
 * @generated SignedSource<<0af22e9f10ad44c376203f0e3555bf4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveEducationInput = {
  clientMutationId?: string | null;
  id: string;
};
export type RemoveEducationMutation$variables = {
  input: RemoveEducationInput;
};
export type RemoveEducationMutation$data = {
  readonly removeEducation: {
    readonly deletedEducationId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly user: {
      readonly education: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly id: string;
    } | null;
  } | null;
};
export type RemoveEducationMutation = {
  response: RemoveEducationMutation$data;
  variables: RemoveEducationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationConnection",
      "kind": "LinkedField",
      "name": "education",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EducationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Education",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedEducationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveEducationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEducationPayload",
        "kind": "LinkedField",
        "name": "removeEducation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveEducationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEducationPayload",
        "kind": "LinkedField",
        "name": "removeEducation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e7397036afb268481a55d4ef59ff233",
    "id": null,
    "metadata": {},
    "name": "RemoveEducationMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveEducationMutation(\n  $input: RemoveEducationInput!\n) {\n  removeEducation(input: $input) {\n    user {\n      id\n      education {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n    deletedEducationId\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5379266b8c6049be0eb5ea1d0721409";

export default node;
