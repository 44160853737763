/* @flow */
import { ConnectionHandler } from 'relay-runtime';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription dealsSubscribeSubscription {
    dealsSubscribe {
      id
      # extra
      other_user {
        id
      }
      deleted_at
      # used by UI
      ...DealsItem_deal
      ...DealControls_deal
    }
  }
`;

const dealsSubscribe = () =>
  requestSubscription({
    subscription,
    updater: (store) => {
      const newDoc = store.getRootField('dealsSubscribe');
      const parentProxy = store.get('client:root:viewer');
      if (!parentProxy) {
        return;
      }
      const otherUser = newDoc.getLinkedRecord('other_user');
      const otherUserId = otherUser.getValue('id');
      const connection = ConnectionHandler.getConnection(
        parentProxy,
        'DealsMessenger_deals',
        { otherUserId },
      );

      if (!connection) {
        return;
      }

      const newId = newDoc.getValue('id');

      const existingRecords = connection.getLinkedRecords('edges');
      const recordAlreadyExists = existingRecords.some((existingRecord) => {
        const node = existingRecord.getLinkedRecord('node');
        const existingId = node.getValue('id');

        return existingId === newId;
      });

      if (newDoc.getValue('deleted_at')) {
        ConnectionHandler.deleteNode(connection, newId);
        return;
      }

      if (!recordAlreadyExists) {
        const edge = ConnectionHandler.createEdge(
          store,
          connection,
          newDoc,
          'DealEdge',
        );
        ConnectionHandler.insertEdgeBefore(connection, edge);
      }
    },
  });

export default dealsSubscribe;
