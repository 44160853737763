/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation GetAppLinkMutation($input: GetAppLinkInput!) {
    getAppLink(input: $input) {
      error {
        message
      }
    }
  }
`;

const getAppLinkMutation = ({
  email,
  phone,
  route,
}: ({ email: string } | { phone: string }) & {
  route?: 'network',
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        email,
        phone,
        route,
      },
    };

    const optimisticResponse = {
      getAppLink: {
        error: null,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default getAppLinkMutation;
