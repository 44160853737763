/**
 * @generated SignedSource<<e4f90e3bda878f145863f0339c9c66c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportEntityInput = {
  clientMutationId?: string | null;
  comment?: string | null;
  entity_id: string;
  entity_type?: string | null;
  problem?: string | null;
};
export type ReportEntityMutation$variables = {
  input: ReportEntityInput;
};
export type ReportEntityMutation$data = {
  readonly reportEntity: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type ReportEntityMutation = {
  response: ReportEntityMutation$data;
  variables: ReportEntityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportEntityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReportEntityPayload",
        "kind": "LinkedField",
        "name": "reportEntity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportEntityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReportEntityPayload",
        "kind": "LinkedField",
        "name": "reportEntity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8499858bb409fc3b2d351611173c9f5",
    "id": null,
    "metadata": {},
    "name": "ReportEntityMutation",
    "operationKind": "mutation",
    "text": "mutation ReportEntityMutation(\n  $input: ReportEntityInput!\n) {\n  reportEntity(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d35ac59a3b40a1ddf538de255c44e8c0";

export default node;
