import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { authHelpers, analyticsHelpers } from '@pluralcom/plural-js-utils';
import queryString from 'query-string';
import {
  ButtonFlat,
  InputText,
  Text,
  InputTextRefHandle,
  SwitchTab,
  InputPhone,
} from '@pluralcom/blueprint';

import { useLocation } from 'react-router-dom';
import { withRouter } from '../../../hocs/withRouter/withRouter';
import { errorTexts, inputsMaxLengths } from '../../../assets/data';
import { authMainFormUpdate as authMainFormUpdateAC } from '../../../redux/reducers/authReducer/authReducer';
import { ErrorText, AlertBox } from '../../../components';

import {
  cookiesHelpers,
  logger,
  mixpanelHelpers,
  validation,
} from '../../../utils';
import { SignupMutation } from '../../../graphql/mutations';
import { getError } from '../utils/getUsernamesErrors/getUsernamesErrors';

import {
  Container,
  LegalAgreement,
  NavigationText,
  SeperatorText,
} from '../components';

import styles from './Signup.module.scss';
import ExternalAuthButtons from '../components/ExternalAuthButtons/ExternalAuthButtons';

interface SignupProps {
  /** custom className */
  className: string;
  /** callback function to be called on successful signup */
  onSuccess?: Function;
  /** callback function to be called on login click */
  onClickLogin?: Function;
  /** identifier to add vertical padding */
  withScreenSpacing?: boolean;
  /** redux action to update authMainForm */
  authMainForm: {
    /** email or phone */
    emailOrPhone: string;
    /** first name */
    firstName: string;
    /** last name */
    lastName: string;
  };
  /** redux action to update authMainForm */
  authMainFormUpdate: Function;
}

/**
 *
 * FIGMA v0.0.2
 * Signup component for user registration
 */
const Signup = ({
  authMainForm,
  authMainFormUpdate,
  onSuccess,
  onClickLogin,
  className,
  withScreenSpacing,
}: SignupProps) => {
  const location = useLocation();
  const cookiesEnabled = cookiesHelpers.isCookiesEnabled();
  const _formFields = ['firstName', 'lastName', 'emailOrPhone'];

  const _inputRefs = useRef<InputTextRefHandle>();

  const [state, setState] = useState({
    emailOrPhone: '',
    firstName: '',
    lastName: '',
    firstNameError: '',
    lastNameError: '',
    emailOrPhoneError: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [isPhoneAuthModeOpen, setIsPhoneAuthModeOpen] = useState<boolean>();

  const { firstName = '', lastName = '', emailOrPhone = '' } = authMainForm;

  /**
   * _focusRef  - sets the focus to the next field
   */
  const _focusRef = (refName: string) => {
    _inputRefs[`_${refName}InputRef`]?.inputRef?.current?.focus();
  };

  /**
   * _blurRef  - call the blur on all textfields
   */
  const _blurRef = (refName: string) => {
    _inputRefs[`_${refName}InputRef`]?.inputRef?.current?.blur();
  };

  const _setInputRef = (refName: string) => (el) => {
    _inputRefs[`_${refName}InputRef`] = el;
  };

  const _handleFocus = (field: string) => () => {
    const fieldError = `${field}Error`;
    setState((prevState) => ({
      ...prevState,
      [fieldError]: false,
    }));
  };

  const navTextActionProps = onClickLogin
    ? { linkOnClick: onClickLogin }
    : { linkTo: '/login' };

  const _handleChangeInput = ({ target: { name, value } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      [`${name}Error`]: '',
    }));
    authMainFormUpdate({ [name]: value });
  };

  const _getFieldError = (name: string, value: string) => {
    switch (name) {
      case 'emailOrPhone': {
        const _emailOrPhone = authHelpers.parseMainAuthInputField(value);

        if (!(_emailOrPhone && Object.keys(_emailOrPhone)?.length)) {
          if (!isPhoneAuthModeOpen) {
            return errorTexts.invalidEmail;
          }
          return errorTexts.invalidPhoneNumber;
        }

        return null;
      }
      default:
        return getError(value, name);
    }
  };

  const _getFormErrors = async () => {
    const stateValue = {};
    let valid = true;
    _formFields.forEach((name) => {
      const error = _getFieldError(name, authMainForm[name]);
      stateValue[`${name}Error`] = error;
      if (error) {
        valid = false;
      }
    });
    return { state: stateValue, valid };
  };

  const _onSuccess = (profile, data) => {
    if (onSuccess) {
      onSuccess(profile, data);
    }
  };

  const _submit = async (event?: Event) => {
    event?.preventDefault();

    // call textField's blur() on submit
    _blurRef('emailOrPhone');
    _blurRef('firstName');
    _blurRef('lastName');

    const formErrors = await _getFormErrors();
    if (!formErrors.valid) {
      setState((prevState) => ({
        ...prevState,
        ...formErrors.state,
      }));
      return;
    }

    setIsLoading(true);
    setState((prevState) => ({
      ...prevState,
      ...formErrors.state,
    }));
    SignupMutation({
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      ...authHelpers.parseMainAuthInputField(emailOrPhone),
    })
      .then(({ signup: { auth_reqid, profile, error: serverErrorRes } }) => {
        const success = !serverErrorRes;
        setServerError(serverErrorRes ? serverErrorRes.message : null);
        setIsLoading(false);
        authMainFormUpdate({ auth_reqid });
        if (success) {
          _onSuccess(profile, {
            emailOrPhone: emailOrPhone.replaceAll('+', '%2B'),
            auth_reqid,
          });
        }
      })
      .catch((err) => {
        logger.error(err && err.message);
        setServerError(
          `${errorTexts.networkErrorEncountered} ${errorTexts.pleaseTryAgain}`,
        );
        setIsLoading(false);
      });
  };

  /** Parse route params data and prefill form if needed */
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { u: emailOrPhone, f: firstName, l: lastName, s: shouldSubmit } =
      queryString.parse(location && location.search) || {};
    // hack to handle space in email : https://github.com/sindresorhus/query-string/issues/305
    const formattedEmailOrPhone = emailOrPhone?.replace(' ', '+');

    const _emailOrPhone = authHelpers.parseMainAuthInputField(formattedEmailOrPhone);
    /* update form fields */
    if (
      !authMainForm.emailOrPhone &&
      formattedEmailOrPhone &&
      Object.keys(_emailOrPhone)?.length
    ) {
      // if formattedEmailOrPhone is not a valid email, switch to phone tab and prefill phone
      if (!validation.isValidEmail(formattedEmailOrPhone)) {
        // switch to phone tab and prefill phone
        setIsPhoneAuthModeOpen(true);
      }
      _handleChangeInput({
        target: { name: 'emailOrPhone', value: formattedEmailOrPhone },
      });
    }
    if (firstName) {
      _handleChangeInput({
        target: { name: 'firstName', value: firstName },
      });
    }
    if (lastName) {
      _handleChangeInput({
        target: { name: 'lastName', value: lastName },
      });
    }
    /* focus on fields that need focus */
    if (!firstName) {
      _focusRef('firstName');
    }
    if (firstName && !lastName) {
      _focusRef('lastName');
    }
    /* auto submit the form if needed */
    if (shouldSubmit) {
      _submit();
    }
    if (formattedEmailOrPhone && firstName && lastName) {
      _blurRef('firstName');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      className={classNames([styles.container, className])}
      withSpacing={withScreenSpacing}
    >
      <div className={styles['sub-container']}>
        <Text fontSizeType="t2" stickToBreakpoint="lg" className={styles.text}>
          Sign up
        </Text>
        <div className={styles.content}>
          <ExternalAuthButtons
            onSuccess={_onSuccess}
            setServerError={setServerError}
          />

          <SeperatorText />
          {!cookiesEnabled && (
            <AlertBox
              title="Cookies Required"
              body="Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue."
            />
          )}
          <div className={styles.form}>
            <div
              id="sign_up-form"
              className={classNames([styles.form, styles['form-content']])}
            >
              <div
                className={classNames([
                  styles.form,
                  styles['form-switch-input'],
                ])}
              >
                <SwitchTab
                  tabs={['Email', 'Phone']}
                  activeTab={isPhoneAuthModeOpen ? 'Phone' : 'Email'}
                  className={styles['switch-tab']}
                  onClick={(tab) => {
                    const _tab = String(tab);
                    setIsPhoneAuthModeOpen(_tab === 'Phone');
                    /** clear email/phone field on tab switch
                     * clear error message as well
                     */
                    setState((prevState) => ({
                      ...prevState,
                      emailOrPhone: '',
                      emailOrPhoneError: null,
                      firstNameError: '',
                      lastNameError: '',
                    }));
                    authMainFormUpdate({ emailOrPhone: '' });
                    /** handle focus on firstName on tab switch */
                    _focusRef('firstName');
                  }}
                />
                <div
                  className={classNames([styles.form, styles['form-input']])}
                >
                  <div
                    className={classNames([
                      styles.form,
                      styles['form-inputname'],
                    ])}
                  >
                    <InputText
                      autoFocus
                      ref={_setInputRef('firstName')}
                      inputContainerClassName={styles.input}
                      className={styles['input-text-container']}
                      name="firstName"
                      helperText={state.firstNameError || ''}
                      placeholder="First name"
                      onFocus={_handleFocus('firstName')}
                      value={firstName}
                      onChange={(e: any) => {
                        if (firstName.length === 0) {
                          mixpanelHelpers.trackEvent(
                            analyticsHelpers.events
                              .SIGNUP_INPUT_FIRSTNAME_CHANGE.name,
                          );
                        }
                        _handleChangeInput(e);
                      }}
                      hasError={!!state.firstNameError}
                      maxLength={inputsMaxLengths.firstName}
                      onClick={() =>
                        mixpanelHelpers.trackEvent(
                          analyticsHelpers.events.SIGNUP_INPUT_FIRSTNAME_FOCUS
                            .name,
                        )
                      }
                      helperTextProps={{
                        'data-testid': 'sign_up__errors__first_name',
                      }}
                      {...{
                        'data-testid': 'sign_up__inputs__first_name',
                      }}
                    />
                    <InputText
                      ref={_setInputRef('lastName')}
                      name="lastName"
                      className={styles['input-text-container']}
                      placeholder="Last name"
                      helperText={state.lastNameError || ''}
                      value={lastName}
                      onFocus={_handleFocus('lastName')}
                      onChange={(e: any) => {
                        if (lastName.length === 0) {
                          mixpanelHelpers.trackEvent(
                            analyticsHelpers.events.SIGNUP_INPUT_LASTNAME_CHANGE
                              .name,
                          );
                        }
                        _handleChangeInput(e);
                      }}
                      hasError={!!state.lastNameError}
                      maxLength={inputsMaxLengths.lastName}
                      onClick={() =>
                        mixpanelHelpers.trackEvent(
                          analyticsHelpers.events.SIGNUP_INPUT_LASTNAME_FOCUS
                            .name,
                        )
                      }
                      helperTextProps={{
                        'data-testid': 'sign_up__errors__last_name',
                      }}
                      {...{
                        'data-testid': 'sign_up__inputs__last_name',
                      }}
                    />
                  </div>
                  {isPhoneAuthModeOpen ? (
                    <InputPhone
                      ref={_setInputRef('emailOrPhone')}
                      className={classNames([
                        styles['email-input'],
                        styles['phone-input'],
                        styles['input-text-container'],
                      ])}
                      // @ts-ignore-next-line
                      name="emailOrPhone"
                      helperText={state?.emailOrPhoneError || ''}
                      placeholder="Phone number"
                      value={emailOrPhone}
                      onPhoneNumberChange={({ number }) => {
                        if (emailOrPhone && emailOrPhone.length === 0) {
                          mixpanelHelpers.trackEvent(
                            analyticsHelpers.events.LOGIN_INPUT_CHANGE.name,
                          );
                        }
                        _handleChangeInput({
                          target: { name: 'emailOrPhone', value: number },
                        });
                      }}
                      hasError={!!state.emailOrPhoneError}
                      maxLength={inputsMaxLengths.email}
                      onMouseDown={() => {
                        mixpanelHelpers.trackEvent(
                          analyticsHelpers.events.LOGIN_INPUT_FOCUS.name,
                        );
                      }}
                      onKeyUp={(event: any) => {
                        const { key } = event;
                        if (key === 'Enter') {
                          _submit();
                        }
                      }}
                      formatOnInit={false}
                      {...{
                        'data-testid': 'sign_up__inputs__email',
                      }}
                    />
                  ) : (
                    <InputText
                      ref={_setInputRef('emailOrPhone')}
                      name="emailOrPhone"
                      placeholder="Email"
                      onFocus={_handleFocus('emailOrPhone')}
                      helperText={state.emailOrPhoneError || ''}
                      value={emailOrPhone}
                      className={classNames([
                        styles['email-input'],
                        styles['input-text-container'],
                      ])}
                      onChange={(e: any) => {
                        if (emailOrPhone && emailOrPhone.length) {
                          mixpanelHelpers.trackEvent(
                            analyticsHelpers.events
                              .SIGNUP_INPUT_EMAILORPHONE_CHANGE.name,
                          );
                        }
                        _handleChangeInput(e);
                      }}
                      hasError={!!state.emailOrPhoneError}
                      maxLength={inputsMaxLengths.email}
                      onClick={() =>
                        mixpanelHelpers.trackEvent(
                          analyticsHelpers.events.SIGNUP_INPUT_EMAIL_FOCUS.name,
                        )
                      }
                      onKeyUp={(event: any) => {
                        const { key } = event;
                        if (key === 'Enter') {
                          _submit();
                        }
                      }}
                      helperTextProps={{
                        'data-testid': 'sign_up__errors__email',
                      }}
                      {...{
                        'data-testid': 'sign_up__inputs__email',
                      }}
                    />
                  )}
                  <ErrorText error={serverError} />
                </div>
                <ButtonFlat
                  className={styles['submit-button']}
                  uiType="primary"
                  size="lg"
                  text="Continue"
                  data-testid="sign_up__buttons__continue"
                  disabled={!cookiesEnabled}
                  isLoading={isLoading}
                  // @ts-ignore-next-line
                  onClick={_submit}
                  onMouseDown={() =>
                    mixpanelHelpers.trackEvent(
                      analyticsHelpers.events.SIGNUP_BUTTON_CONTINUE.name,
                    )
                  }
                />
              </div>
              <div
                role="presentation"
                onMouseDown={() =>
                  mixpanelHelpers.trackEvent(
                    analyticsHelpers.events.SIGNUP_LINK_LOGIN.name,
                  )
                }
              >
                <NavigationText
                  data-testid="sign_up__links__log_in"
                  state="login"
                  linkOnClick={onClickLogin}
                  {...navTextActionProps}
                />
              </div>
            </div>
          </div>
        </div>
        <LegalAgreement
          mp_location="SignUp"
          className={styles['privacy-container']}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ auth: { authMainForm } }) => ({
  authMainForm,
});

const mapDispatchToProps = (dispatch) => ({
  authMainFormUpdate: (data) => dispatch(authMainFormUpdateAC(data)),
});

export { Signup as PureSignup };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
