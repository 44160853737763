import React, { useState } from 'react';
import timeAgo from '@pluralcom/plural-js-utils/lib/timeAgo/timeAgo';
import useInterval from 'use-interval';

interface TimeAgoProps extends React.HTMLProps<HTMLTimeElement> {
  /** dateTime */
  dateTime: string;
  /** update interval to indicate how long to recalculate and rerender */
  updateInterval?: number | null;
};

/**
 * gets the rerender interval based on the displayValue
 * not to overload resources needlessly
 */
const getInterval = (displayValue, updateInterval) => {
  if (updateInterval !== undefined) {
    return updateInterval;
  }
  // Display value is hh:mm, rerender every min
  if (displayValue.includes(':')) {
    return 60000;
  }
  // rerender every hour
  return 60000 * 60;
};

/**
 * Uses plural's timeAgo to display a human readable value of the date
 *
 * Rerenders periodically to update the displayed value
 */
const TimeAgo = ({ dateTime, updateInterval, ...rest }: TimeAgoProps) => {
  const [displayValue, setDisplayValue] = useState(timeAgo(dateTime));

  /** Update display value periodically */
  useInterval(() => {
    setDisplayValue(timeAgo(dateTime));
  }, getInterval(displayValue, updateInterval));

  return (
    <time dateTime={dateTime} {...rest}>
      {displayValue}
    </time>
  );
};

export default TimeAgo;
