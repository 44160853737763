/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeclineCollaboratorRequestMutation(
    $input: DeclineCollaboratorRequestInput!
  ) {
    declineCollaboratorRequest(input: $input) {
      collaboratorId
      error {
        message
      }
    }
  }
`;

const declineCollaboratorRequestMutation = (
  {
    collaborator_id,
  }: {
    collaborator_id: string,
  },
  {
    skill_id,
  }: {
    skill_id: string,
  },
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id: collaborator_id,
      },
    };

    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: skill_id,
        connectionKeys: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
          },
        ],
        deletedIDFieldName: 'collaboratorId',
        pathToConnection: ['skill', 'collaborators'],
      },
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'collaboratorId',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default declineCollaboratorRequestMutation;
