/* @flow */
import React from 'react';
import classNames from 'classnames';
import ReactYouTube from 'react-youtube';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { safeCall } from '@pluralcom/plural-js-utils';
import { withRouter } from '../../hocs/withRouter/withRouter';

import styles from './YouTube.module.scss';

type Props = {
  videoId: string,
  id?: string,
  className?: ?string,
  opts?: ?{
    playerVars?: {
      rel?: number,
      modestbranding?: number,
      origin?: string,
    },
  },
  location: { origin: string },
  autoPlay?: boolean,
  onContainerClick?: Function,
  onReady?: Function,
  onStateChange?: Function,
};

const YouTube = ({
  className,
  onContainerClick,
  onReady,
  onStateChange,
  opts = {},
  autoPlay = false,
  location,
  ...rest
}: Props) => {
  /** Saves the event fired from youtube to be later called by the react component */
  let eventData = {};
  const saveEventData = (event) => {
    eventData = { ...pick(event, ['data', 'target']) };
  };
  return (
    <div
      className={classNames(
        styles.container,
        className,
        autoPlay ? '' : styles['auto-play-disabled'],
      )}
      onClick={(event: Event) => {
        safeCall(
          onStateChange,
          event,
          get(eventData, 'target'),
          get(eventData, 'data'),
        );
      }}
      onKeyDown={(event: Event) => {
        safeCall(
          onStateChange,
          event,
          get(eventData, 'target'),
          get(eventData, 'data'),
        );
      }}
      role="presentation"
    >
      <ReactYouTube
        className={classNames(className)}
        opts={{
          ...opts,
          playerVars: {
            modestbranding: 1,
            origin: location.origin,
            rel: 0,
            showinfo: 0,
            ...opts.playerVars,
          },
        }}
        onReady={(event) => {
          saveEventData(event);
          if (get(opts, 'playerVars.autoPlay', false)) {
            event.target.playVideo();
          }
          safeCall(onReady, event);
        }}
        onStateChange={(event) => {
          saveEventData(event);
          safeCall(onStateChange, event);
        }}
        {...rest}
      />
    </div>
  );
};

export { YouTube as PureYouTube };

export default withRouter(YouTube);
