/* @flow */
/* eslint react/no-array-index-key: 0 */
import React from 'react';
import classNames from 'classnames';
import starsRatingConfigCalculate from '@pluralcom/plural-js-utils/lib/starsRating/starsRatingConfigCalculate';
import { Button } from '@pluralcom/blueprint';

import IconStar from '../IconStar/IconStar';

import styles from './RatingStars.module.scss';

type Props = {
  rating: number,
  className?: ?string,
  starsCount?: number,
  iconProps?: ?Object,
};

const RatingStars = ({
  className,
  rating,
  starsCount = 5,
  iconProps,
}: Props) => {
  const stars = starsRatingConfigCalculate(rating, starsCount);
  const { onClick: itemOnClick, ...restIconProps } = iconProps || {};
  let child;
  if (itemOnClick) {
    child = stars.map((star, index) => (
      <Button
        className={styles.btn}
        onClick={itemOnClick ? (e) => itemOnClick(e, index) : undefined}
        key={index}
      >
        <IconStar {...restIconProps} rating={star} />
      </Button>
    ));
  } else {
    child = stars.map((star, index) => (
      <IconStar {...restIconProps} rating={star} key={index} />
    ));
  }
  return (
    <div className={classNames([styles.container, className])}>{child}</div>
  );
};

export default RatingStars;
