import React from 'react';
import classNames from 'classnames';

import styles from './Container.module.scss';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement>{
  /** custom className */
  className?: string;
  /** identifier to add / remove spacing around view */
  withSpacing?: boolean;
  /** react node */
  children?: React.ReactNode;
}

/**
 * Figma v0.0.1
 * Container
 * - Act as a wrapper for other Auth sub components
 */

const Container = ({
  className,
  withSpacing,
  children,
  ...rest
}: ContainerProps) => (
  <div
    className={classNames([withSpacing && styles.container, className])}
    {...rest}
  >
    {children}
  </div>
);

export default Container;
