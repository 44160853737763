import { isEmail } from 'validator';
import { isValidFirstOrLastName } from '@pluralcom/plural-js-utils/lib/userHelpers/userHelpers';
import { errorTexts } from '../../../../assets/data';

const validators = {
  firstName: isValidFirstOrLastName,
  lastName: isValidFirstOrLastName,
  emailOrPhone: isEmail,
};

const errors = {
  firstName: errorTexts.invalidFirstName,
  lastName: errorTexts.invalidLastName,
  emailOrPhone: errorTexts.invalidEmail,
};

const getError = (value = '', fieldName) => {
  if (value.trim().length === 0) {
    return errorTexts.requiredFields;
  }
  const validator = validators[fieldName];

  return validator && !validator(value) ? errors[fieldName] : null;
};

export { getError };
