/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation as commitMutationRaw } from 'react-relay';
import type { Environment } from 'react-relay';

import { commitMutation } from '../..';

export default (environment: Environment) => (
  id?: string,
  value = true,
  archived = false,
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation MarkAsReadMutation($input: EditActivityInput!) {
        editActivity(input: $input) {
          activity {
            id
            read
            seen_at
          }
        }
      }
    `;

    const variables = {
      input: {
        id,
        read: value,
      },
    };

    const optimisticResponse = {
      editActivity: {
        activity: {
          seen_at: archived ? new Date().toISOString() : null,
          ...variables.input,
        },
      },
    };

    if (environment) {
      commitMutationRaw(environment, {
        mutation,
        optimisticResponse,
        variables,
        onCompleted: resolve,
        onError: reject,
      });
    } else {
      commitMutation({
        mutation,
        optimisticResponse,
        variables,
        onCompleted: resolve,
        onError: reject,
      });
    }
  });
