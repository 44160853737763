import React from 'react';
import { useMedia } from 'use-media';

import Signup from '../../Signup/Signup';

import { AuthModal } from '../../components';
import styles from './SignupModal.module.scss';
import { responsive } from '../../../../utils';

interface SignupModalProps {
  /** The status of the Modal, either open or close */
  isOpen: boolean;
  /** Function to toggle Modal visibility */
  onToggle?: Function;
  /** Function to be called on successful signup */
  onSuccess?: Function;
}
const SignupModal = ({
  isOpen,
  onToggle,
  onSuccess,
  ...rest
}: SignupModalProps) => {
  const isDesktop = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const _onSuccess = (...params) => {
    if (onSuccess) {
      onSuccess(...params);
    } else {
      // eslint-disable-next-line no-lonely-if
      onToggle?.();
    }
  };

  return (
    <AuthModal
      isOpen={isOpen}
      toggle={onToggle}
      withNavHeader
      navHeaderProps={{
        rightButtonProps: undefined,
        withBackButton: !isDesktop,
        withElevation: false,
        leftButtonProps: {
          iconProps: {
            type: 'fa',
            icon: ['fas', !isDesktop ? 'chevron-left' : 'xmark'],
          },
          onClick: (e) => {
            e.preventDefault();
            if (onToggle) {
              onToggle();
            }
          },
          className: styles['back-button'],
        },
        text: '',
      }}
      modalProps={{
        className: styles['modal-dialog'],
      }}
      modalBodyClassName={styles['modal-body']}
    >
      <Signup
        className={styles.container}
        withScreenSpacing={!isDesktop}
        onSuccess={_onSuccess}
        {...rest}
      />
    </AuthModal>
  );
};
export default SignupModal;
