/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (
  {
    skill_id,
    payment_id,
    review,
    quality_of_work_rating,
    communication_rating,
    value_rating,
  }: {
    skill_id: string,
    payment_id: string,
    review: string,
    quality_of_work_rating: number,
    communication_rating: number,
    value_rating: number,
  },
  { viewer } = {},
): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation CreateReviewMutation($input: CreateReviewInput!) {
        createReview(input: $input) {
          error {
            message
          }
          newReviewEdge {
            node {
              id
              reviewer {
                id
                name
                username
                first_name
                last_name
                profile_color
                avatar {
                  id
                  smallThumbnail
                }
              }
              review
              quality_of_work_rating
              communication_rating
              value_rating
              updated_at
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        skill_id,
        payment_id,
        review,
        quality_of_work_rating,
        communication_rating,
        value_rating,
      },
    };

    const optimisticResponse = {
      error: null,
      createReview: {
        newReviewEdge: {
          node: {
            id: `_${Math.random().toString(36).substr(2, 9)}`,
            reviewer: {
              ...viewer,
            },
            review,
            quality_of_work_rating,
            communication_rating,
            value_rating,
            updated_at: new Date().toISOString(),
          },
        },
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: skill_id,
        connectionInfo: [
          {
            key: 'ReviewsSection_SkillCard_reviews',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'newReviewEdge',
      },
    ];

    return commitMutation({
      mutation,
      variables,
      configs,
      optimisticResponse,
      onCompleted: (res) => resolve(res),
      onError: (err) => reject(err),
    });
  });
