/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { UpdateDefaultPaymentMethodMutationResponse } from './__generated__/UpdateDefaultPaymentMethodMutation.graphql';

const mutation = graphql`
  mutation UpdateDefaultPaymentMethodMutation(
    $input: UpdateDefaultPaymentMethodMutationInput!
  ) {
    updateDefaultPaymentMethod(input: $input) {
      paymentMethods {
        id
        defaultPaymentMethodId
      }
      error {
        message
      }
    }
  }
`;

/**
 * UpdateDefaultPaymentMethod
 */
const updateDefaultPaymentMethodMutation = (
  {
    id,
  }: {
    id: string,
  },
  {
    paymentMethodsId,
  }: {
    paymentMethodsId: string,
  },
): Promise<UpdateDefaultPaymentMethodMutationResponse> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        paymentMethods: {
          id: paymentMethodsId,
          defaultPaymentMethodId: id,
        },
        error: null,
      },
      onCompleted: resolve,
      onError: reject,
    });
  });

export default updateDefaultPaymentMethodMutation;
