/**
 * @generated SignedSource<<d7b2102e0f583f7c4d28edc53776ab63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestLoginPinInput = {
  auth_reqid?: string | null;
  clientMutationId?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type RequestLoginPinMutation$variables = {
  input: RequestLoginPinInput;
};
export type RequestLoginPinMutation$data = {
  readonly requestLoginPin: {
    readonly auth_reqid: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly id: string;
      readonly name: string | null;
      readonly username: string | null;
    } | null;
    readonly testPin: string | null;
  } | null;
};
export type RequestLoginPinMutation = {
  response: RequestLoginPinMutation$data;
  variables: RequestLoginPinMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auth_reqid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testPin",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestLoginPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestLoginPinPayload",
        "kind": "LinkedField",
        "name": "requestLoginPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestLoginPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestLoginPinPayload",
        "kind": "LinkedField",
        "name": "requestLoginPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0866db13409b9aa98bcfb64cf9e5273e",
    "id": null,
    "metadata": {},
    "name": "RequestLoginPinMutation",
    "operationKind": "mutation",
    "text": "mutation RequestLoginPinMutation(\n  $input: RequestLoginPinInput!\n) {\n  requestLoginPin(input: $input) {\n    auth_reqid\n    profile {\n      id\n      username\n      name\n    }\n    testPin\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "839f1c64f12eada7484c969c08f446ab";

export default node;
