/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

const KEYS = {
  socket_conn_id: 'socket_conn_id',
};

const setSocketConnId = (socket_conn_id: string): void => {
  localStorageService.setItem(
    KEYS.socket_conn_id,
    JSON.stringify(socket_conn_id)
  );
};

const getSocketConnId = (): string => {
  const socket_conn_id = localStorageService.getItem(KEYS.socket_conn_id);
  return socket_conn_id;
};

export { setSocketConnId, getSocketConnId };
