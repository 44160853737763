/* @flow */
import { FACEBOOK_PERMISSIONS } from '@pluralcom/plural-js-utils/lib/facebookHelpers';
import { dateHelpers } from '@pluralcom/plural-js-utils';
import { waitForVar } from '@pluralcom/plural-web-utils';
import sentryHelpers from '../sentryHelpers/sentryHelpers';

/**
 * If a user comes online anytime between token.created_at and 57 days in advance,
 * then we'll refresh it
 * (this ensures that we always have an up to date extended access token)
 */
const REFRESH_TOKEN_BEFORE_DAYS = 57;

/**
 * Gets the FB login status
 */
const _fbGetLoginStatus = async (): Promise<{
  status?: string,
  accessToken?: string,
  expiresIn?: number,
}> => {
  await waitForVar('FB');
  if (!window.FB || !window.location.origin?.startsWith('https')) {
    return {};
  }
  return new Promise((resolve) => {
    window.FB.getLoginStatus((response) => {
      const { authResponse, status, ...rest } = response;
      if (status === 'unknown') {
        return resolve({});
      }
      return resolve({ status, ...authResponse, ...rest });
    });
  });
};

/**
 * Get the currentAccessToken and check whether it expires
 * in less than `REFRESH_AFTER_DAYS` days. If it expires in less
 * than `REFRESH_AFTER_DAYS` days, we refresh the accessToken,
 * pass the new token up to the backend, and generate a new long lived token.
 */
const getAndExtendFbAccessToken = async ({ mutation }: { mutation: void }) => {
  sentryHelpers.addBreadcrumb({
    level: 'info',
    category: 'facebook',
    message: 'About to get and extend the facebook access token',
  });

  const { accessToken, expiresIn } = await _fbGetLoginStatus();
  const newAccessToken = accessToken;

  /** Refresh once a day */
  if (
    !dateHelpers.timestampGreaterThanOrEqualThanDays(
      expiresIn,
      REFRESH_TOKEN_BEFORE_DAYS,
    )
  ) {
    sentryHelpers.addBreadcrumb({
      level: 'info',
      category: 'facebook',
      action: 'RefreshAccessToken',
      message: 'Refreshing FB access token',
    });

    try {
      mutation({
        shortLivedFbAccessToken: newAccessToken,
      });

      sentryHelpers.addBreadcrumb({
        level: 'info',
        category: 'facebook',
        action: 'ExtendAccessToken',
        message:
          'The token has been refreshed. About to extend it and persist to server.',
      });
    } catch (err) {
      sentryHelpers.addBreadcrumb({
        level: 'error',
        category: 'facebook',
        action: 'ExtendAccessToken',
        message:
          'Attempted to extend the new refreshedAccess token but it failed',
        data: err,
      });
    }
  }
};

/**
 * Calls window.FB.login with the required permissions
 */
export const fbLogin = (func: ?Function, props?: ?Object) =>
  window.FB.login(func, {
    scope: FACEBOOK_PERMISSIONS.toString(),
    return_scopes: true,
    ...props,
  });

/**
 * Calls window.FB.logout as a promise
 */
export const fbLogout = (cb: Functio) =>
  new Promise((resolve) => {
    window.FB.logout(() => {
      resolve(cb && cb());
    });
  });

export { getAndExtendFbAccessToken };
export default {
  getAndExtendFbAccessToken,
  fbLogin,
  fbLogout,
};
