/**
 * @generated SignedSource<<80c26a6bb439bd5dc1ab711e143b36b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentStatus = "DECLINED" | "FAILED" | "PAID" | "PAID_OUT" | "PENDING" | "REFUNDED" | "REFUND_REQUEST" | "REQUESTED" | "REQUEST_CANCELLED" | "%future added value";
export type DeclinePaymentRefundRequestInput = {
  clientMutationId?: string | null;
  payment_id: string;
};
export type DeclinePaymentRefundRequestMutation$variables = {
  input: DeclinePaymentRefundRequestInput;
};
export type DeclinePaymentRefundRequestMutation$data = {
  readonly declinePaymentRefundRequest: {
    readonly error: {
      readonly id: string;
      readonly message: string | null;
      readonly title: string | null;
    } | null;
    readonly payment: {
      readonly deal: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DealControls_deal" | "DealsItem_deal">;
      } | null;
      readonly id: string;
      readonly refund_request_declined_at: any | null;
      readonly status: PaymentStatus;
    } | null;
  } | null;
};
export type DeclinePaymentRefundRequestMutation = {
  response: DeclinePaymentRefundRequestMutation$data;
  variables: DeclinePaymentRefundRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refund_request_declined_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v7 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclinePaymentRefundRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclinePaymentRefundRequestPayload",
        "kind": "LinkedField",
        "name": "declinePaymentRefundRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Deal",
                "kind": "LinkedField",
                "name": "deal",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DealsItem_deal"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DealControls_deal"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclinePaymentRefundRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeclinePaymentRefundRequestPayload",
        "kind": "LinkedField",
        "name": "declinePaymentRefundRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Deal",
                "kind": "LinkedField",
                "name": "deal",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "skill_title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillOffer",
                    "kind": "LinkedField",
                    "name": "skill_offer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hiring_type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "PaymentConnection",
                    "kind": "LinkedField",
                    "name": "payments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Payment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "viewer_pay_role",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "from",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "to",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "created_by",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "is_escrow",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "approved_by_buyer_at",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "approved_by_seller_at",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thread_id",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount_after_fee",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "service_fee",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created_at",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "payments(first:1)"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "DealControls_payments",
                    "kind": "LinkedHandle",
                    "name": "payments"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "DealsItem_payments",
                    "kind": "LinkedHandle",
                    "name": "payments"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8379fd8c4d84add7a4e64c8f4ae09da6",
    "id": null,
    "metadata": {},
    "name": "DeclinePaymentRefundRequestMutation",
    "operationKind": "mutation",
    "text": "mutation DeclinePaymentRefundRequestMutation(\n  $input: DeclinePaymentRefundRequestInput!\n) {\n  declinePaymentRefundRequest(input: $input) {\n    payment {\n      id\n      status\n      refund_request_declined_at\n      deal {\n        id\n        ...DealsItem_deal\n        ...DealControls_deal\n      }\n    }\n    error {\n      id\n      title\n      message\n    }\n  }\n}\n\nfragment DealControls_deal on Deal {\n  id\n  skill_offer {\n    id\n    hiring_type\n  }\n  payments(first: 1) {\n    edges {\n      node {\n        id\n        status\n        viewer_pay_role\n        from {\n          id\n        }\n        to {\n          id\n        }\n        created_by {\n          id\n        }\n        is_escrow\n        approved_by_buyer_at\n        approved_by_seller_at\n        refund_request_declined_at\n        thread_id\n        amount_after_fee\n        service_fee\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DealsItem_deal on Deal {\n  id\n  skill_title\n  ...DealControls_deal\n  payments(first: 1) {\n    edges {\n      node {\n        id\n        status\n        is_escrow\n        approved_by_buyer_at\n        approved_by_seller_at\n        amount\n        created_at\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed71fb00cace910d731148dadf22104d";

export default node;
