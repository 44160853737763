/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

const KEYS = {
  search_list_visited_count: 'search_list_visited_count',
  onboarding_visited_count: 'onboarding_visited_count',
};

const getSearchListCounter = (): number =>
  localStorageService.getItem(KEYS.search_list_visited_count) ?? 0;

const incrementSearchListCounter = (): void => {
  localStorageService.setItem(
    KEYS.search_list_visited_count,
    Math.min(getSearchListCounter() + 1, 10),
  );
};

const getOnboardingVisitedCounter = (): number =>
  localStorageService.getItem(KEYS.onboarding_visited_count) ?? 0;

const incrementOnboardingVisitedCounter = (): void => {
  localStorageService.setItem(
    KEYS.onboarding_visited_count,
    Math.min(getSearchListCounter() + 1, 1),
  );
};

export {
  incrementSearchListCounter,
  getSearchListCounter,
  getOnboardingVisitedCounter,
  incrementOnboardingVisitedCounter,
};
