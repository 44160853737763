/* @flow */

import { inputsMaxLengths as globalInputsMaxLengths } from '@pluralcom/plural-js-utils/lib/inputsLengths';

/**
 * Maximum lengths for different input fields.
 */
const inputsMaxLengths: { [field: string]: number } = {
  // defaults

  // global
  ...globalInputsMaxLengths,

  // override
};

export default inputsMaxLengths;
