import React from 'react';

import { Icon, Text, Button } from '@pluralcom/blueprint';
import classNames from 'classnames';

import styles from './ButtonChoiceCard.module.scss';

export interface ButtonChoiceCardProps
  extends Omit<
    React.ComponentProps<typeof Button>,
    'children' | 'isActive' | 'activeClassName' | 'isLoading'
  > {
  /** title, this will be show in bold */
  title?: string;
  /** text or description */
  text?: string;
  /** icon props */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** if True, adds selected state on card */
  selected?: boolean;
}

/**
 * - Figma v0.0.1
 * - ButtonChoiceCard is a component that represents a choice card with a title and a description.
 */
const ButtonChoiceCard = ({
  className,
  title,
  selected,
  text,
  iconProps,
  ...rest
}: ButtonChoiceCardProps) => (
  <Button
    className={classNames([
      styles.container,
      styles.button,
      { [styles['button--selected']]: selected },
      className,
    ])}
    {...rest}
  >
    <div className={styles.content}>
      {title ? (
        <Text fontSizeType="t5" element="span" className={styles.text}>
          {title}
        </Text>
      ) : null}

      {text ? (
        <Text
          fontSizeType="t7"
          element="span"
          fontWeight="regular"
          className={styles.text}
        >
          {text}
        </Text>
      ) : null}
    </div>
    {iconProps ? <Icon {...iconProps} /> : null}
  </Button>
);

export default ButtonChoiceCard;
