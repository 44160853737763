/* @flow */
import React from 'react';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import { ButtonFlat, Icon } from '@pluralcom/blueprint';

import Modal from '../Modal/Modal';
import ButtonClose from '../ButtonClose/ButtonClose';

import styles from './FancyDialog.module.scss';

type Props = {
  isOpen: boolean,
  toggle: Function,
  className?: ?string,
  title?: ?string,
  children?: ?string | ?ReactNode,
  bodyText?: ?string | ?ReactNode,
  noFooter?: ?boolean,
  primaryBtnText: ?string | ?React.ReactNode,
  secondaryBtnText?: ?string | ?React.ReactNode,
  primaryBtnOnClick?: ?Function,
  secondaryBtnOnClick?: ?Function,
  closeBtnOnClick?: ?Function,
  primaryBtnClassName?: ?string,
  secondaryBtnClassName?: ?string,
  primaryBtnProps?: ?Object,
  secondaryBtnProps?: ?Object,
  contentClassName?: ?string,
  bodyClassName?: ?string,
  footerClassName?: ?string,
  titleClassName?: ?string,
  CloseButton?: Function,
  closeBtnProps?: Object,
  UserAvatar?: Function,
  noCloseButton?: ?boolean,
  headerIcon?: Array<String>,
  renderMedia?: Function,
};

const renderers = {
  strong: ({ children }: React$Node) => (
    <span className={styles['bold-text']}>{children}</span>
  ),
};

/** Default dialog close button */
const DefaultButtonClose = ({ ...rest }) => (
  <ButtonClose
    absolute
    size="md"
    uiType="lite"
    {...rest}
    className={classNames([styles['btn-close'], rest.className])}
  />
);

const FancyDialog = ({
  isOpen,
  className,
  title,
  children,
  toggle,
  bodyText,
  noFooter,
  primaryBtnText = 'Ok',
  secondaryBtnText,
  primaryBtnOnClick,
  secondaryBtnOnClick,
  primaryBtnClassName,
  secondaryBtnClassName,
  primaryBtnProps,
  secondaryBtnProps,
  contentClassName,
  bodyClassName,
  footerClassName,
  titleClassName,
  CloseButton: InCloseButton = DefaultButtonClose,
  UserAvatar = null,
  closeBtnOnClick,
  noCloseButton = true,
  closeBtnProps,
  headerIcon,
  renderMedia,
  ...rest
}: Props) => (
  <Modal
    className={classNames([styles['fancy-dialog'], className])}
    toggle={toggle}
    isOpen={isOpen}
    {...rest}
  >
    {renderMedia && renderMedia()}
    <div
      className={classNames([
        styles.content,
        {
          [styles['has-footer']]:
            !noFooter && (primaryBtnText || secondaryBtnText),
        },
        contentClassName,
      ])}
    >
      {noCloseButton ? null : (
        <InCloseButton
          onClick={closeBtnOnClick || closeBtnProps?.onClick || toggle}
          {...closeBtnProps}
        />
      )}
      {headerIcon && <Icon icon={headerIcon} type="fa" size="5xl" />}
      {UserAvatar && <UserAvatar />}

      {title ? (
        <h3 className={classNames(styles.title, titleClassName)}>{title}</h3>
      ) : null}
      {bodyText ? (
        <div className={classNames([styles.body, bodyClassName])}>
          <Markdown source={bodyText} renderers={renderers} />
        </div>
      ) : null}
      {children ? (
        <div className={classNames([styles.body, bodyClassName])}>
          {children}
        </div>
      ) : null}
      {!noFooter && (primaryBtnText || secondaryBtnText) ? (
        <div className={classNames([styles.footer, footerClassName])}>
          {primaryBtnText ? (
            <ButtonFlat
              uiType="primary"
              className={classNames([
                styles['footer-btn'],
                primaryBtnClassName,
                styles.btnPrimary,
              ])}
              onClick={primaryBtnOnClick || primaryBtnProps?.onClick}
              size="md"
              text={primaryBtnText}
              {...primaryBtnProps}
            />
          ) : null}
          {secondaryBtnText ? (
            <ButtonFlat
              uiType="tertiary"
              className={classNames([
                secondaryBtnClassName,
                styles['footer-btn'],
                styles.btntertiary,
              ])}
              onClick={secondaryBtnOnClick}
              size="md"
              text={secondaryBtnText}
              {...secondaryBtnProps}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  </Modal>
);

export default FancyDialog;
