/**
 * @generated SignedSource<<adaa54aaa36659586eb910f7548109dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GetAppLinkInput = {
  clientMutationId?: string | null;
  email?: string | null;
  phone?: string | null;
  route?: string | null;
};
export type GetAppLinkMutation$variables = {
  input: GetAppLinkInput;
};
export type GetAppLinkMutation$data = {
  readonly getAppLink: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type GetAppLinkMutation = {
  response: GetAppLinkMutation$data;
  variables: GetAppLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetAppLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetAppLinkPayload",
        "kind": "LinkedField",
        "name": "getAppLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetAppLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetAppLinkPayload",
        "kind": "LinkedField",
        "name": "getAppLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1799d3d830f5ba9a084c21958ab85fab",
    "id": null,
    "metadata": {},
    "name": "GetAppLinkMutation",
    "operationKind": "mutation",
    "text": "mutation GetAppLinkMutation(\n  $input: GetAppLinkInput!\n) {\n  getAppLink(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e85d2aab99325fd8de3a7404e9728e8a";

export default node;
