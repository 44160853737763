/** @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

/** randomSeed key in localStorage */
const RANDOM_SEED = 'random_seed';
/** randomSeed string splitter */
const SPLITTER: string = ':';

const _nowSeconds = (): number => Math.floor(Date.now() / 1000);

const loadUserIdOrDefault = (userId?: string): string =>
  userId || localStorageService.getItem('user')?.id || 'PUBLIC';

/** generates a new random seed as `${USERID}:${TIMESTAMP}` */
const generate = (userId: string): string =>
  `${userId}${SPLITTER}${_nowSeconds()}`;

/** loads the randomSeed from the localStorage */
const load = ({ force } = {}): ?string =>
  localStorageService.getItem(RANDOM_SEED) ||
  (force ? generate('FORCED') : null);

/** saves a randomSeed to the localStorage */
const save = (randomSeed: string): string => {
  localStorageService.setItem(RANDOM_SEED, randomSeed);
  return randomSeed;
};

/** forces a refresh of the random seed */
const forceRefresh = (userId?: string): string =>
  save(generate(loadUserIdOrDefault(userId)));

/** checks if a randomSeed is older than one hour */
const shouldRefresh = (randomSeed: ?string): boolean => {
  if (!randomSeed) {
    return true;
  }
  const difference: number =
    (_nowSeconds() - Number(randomSeed.split(SPLITTER)[1])) / 60;
  return difference > 60;
};

/** initializes the randomSeed */
const init = (userId?: string) => {
  const currSeed: ?string = load();
  if (shouldRefresh(currSeed)) {
    forceRefresh(loadUserIdOrDefault(userId));
  }
};

export default {
  generate,
  load,
  forceRefresh,
  init,
};
