/* @flow */
import queryString from 'query-string';

/**
 * Updates the query string with the values from the object
 * The query string defaults to window.location.search
 */
const updateQueryString = (
  obj: Object,
  oldQueryString?: string = window.location.search
) => queryString.stringify({ ...queryString.parse(oldQueryString), ...obj });

export default {
  updateQueryString,
};
