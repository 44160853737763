/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import ImageGalleryBase from 'react-image-gallery';
import MediaQuery from 'react-responsive';
import capitalize from 'lodash/capitalize';
import { ButtonFlat, Icon } from '@pluralcom/blueprint';

import { mixpanelHelpers, youtubeUrl, responsive } from '../../utils';
import ButtonFloating from '../ButtonFloating/ButtonFloating';
import FancyDialog from '../FancyDialog/FancyDialog';
import YouTube from '../YouTube/YouTube';
import FileUpload from '../FileUpload/FileUpload';
import Spinner from '../Spinner/Spinner';
import ModalYoutubeUrl from '../SkillMediaUploader/components/ModalYoutubeUrl/ModalYoutubeUrl';

import { StyleWrapper, ButtonNav, ButtonClose } from './components';

import styles from './ImageGallery.module.scss';
import videoPlayButton from './img/video_play_btn@2x.png';

type Props = {
  items: Array<{
    original?: string,
    thumbnail?: string,
    originalClass?: string,
    thumbnailClass?: string,
    renderItem?: Function,
    renderThumbInner?: Function,
    originalAlt?: string,
    thumbnailAlt?: string,
    originalTitle?: string,
    thumbnailTitle?: string,
    thumbnailLabel?: string,
    description?: string,
    imageSet?: Array<React$Node>,
    srcSet?: string,
    sizes?: string,
    bulletClass?: string,
    bulletOnClick?: Function,
    youtubeUrl?: string,
  }>,
  className?: string,
  onUploadYoutubeUrl?: Function,
  onUploadFile?: Function,
  onSubModalToggle?: Function,
  onSlide?: Function,
  youTubeProps?: object,
  showRemoveButton?: boolean,
  removeButtonOnClick?: Function,
  showPrimaryButton?: boolean,
  primaryButtonChildren?: node,
  primaryButtonOnClick?: Function,
  showPlayButton?: boolean,
  showFullscreenButton?: boolean,
  disableNavigationControls?: boolean,
  disableSwipe?: boolean,
  showCloseButton?: boolean,
  showUploadFileButton?: boolean,
  showUploadYoutubeUrlButton?: boolean,
  mediaPreview?: boolean,
  fullScreen?: boolean,
  onToggle: Function,
  onImageLoad: Function,
  onImageError: Function,
  retryButtonOnClick: Function,
  onThumbnailError: Function,
};

class ImageGallery extends Component<*, Props, *> {
  _imageGallery = React.createRef();

  _youtubeRefs = {};

  currentImageGalleryIndex = 0;

  constructor(props) {
    super(props);
    this.state = {
      isOpenModalConfirmDelete: false,
      isOpenModalYoutubeUrl: false,
    };
  }

  _saveYoutubeRefs = (youtubeIdx) => (event) => {
    this._youtubeRefs = {
      ...this._youtubeRefs,
      [youtubeIdx]: event.target,
    };
  };

  // General Modal Functions
  _toggleModal = (modalName) => () => {
    const { onSubModalToggle } = this.props;
    const isModalOpen = this.state[`isOpen${modalName}`];
    this.setState({ [`isOpen${modalName}`]: !isModalOpen });
    return onSubModalToggle && onSubModalToggle(!isModalOpen);
  };

  _onCloseBtnClick = () => this.props.onToggle && this.props.onToggle(false);

  _onImageClick = () =>
    this._imageGallery.slideToIndex(this.currentImageGalleryIndex + 1);

  _onUploadYoutubeUrl = (url) => {
    const { onUploadYoutubeUrl } = this.props;
    if (onUploadYoutubeUrl) {
      onUploadYoutubeUrl(url);
    }
    this.setState({ isOpenModalYoutubeUrl: false });
  };

  _onUploadFile = (file) =>
    this.props.onUploadFile && this.props.onUploadFile(file);

  _renderCustomControls = () => {
    const {
      showPrimaryButton,
      primaryButtonChildren,
      primaryButtonOnClick,
      showCloseButton,
      showUploadYoutubeUrlButton,
      showUploadFileButton,
      showRemoveButton,
    } = this.props;
    return (
      <>
        {showCloseButton && <ButtonClose onClick={this._onCloseBtnClick} />}
        <div className={styles['custom-controls-container']}>
          <div className={styles['custom-controls-wrapper']}>
            {showUploadFileButton ? (
              <FileUpload
                classNameContainer={styles['file-upload-container']}
                className={styles['custom-control-button']}
                filesProps={{ onChange: this._onUploadFile }}
              >
                <Icon
                  className={styles['custom-control-button_icon']}
                  type="fa"
                  icon={['fas', 'image']}
                />
              </FileUpload>
            ) : null}
            {showUploadYoutubeUrlButton ? (
              <ButtonFloating
                className={styles['custom-control-button']}
                size="sm"
                uiType="tertiary"
                onClick={this._toggleModal('ModalYoutubeUrl')}
              >
                <Icon
                  className={styles['custom-control-button_icon']}
                  type="fa"
                  icon={['fab', 'youtube']}
                />
              </ButtonFloating>
            ) : null}
            {showRemoveButton ? (
              <ButtonFloating
                className={styles['custom-control-button']}
                size="sm"
                uiType="tertiary"
                onClick={this._toggleModal('ModalConfirmDelete')}
              >
                <Icon
                  className={styles['custom-control-button_icon']}
                  type="fa"
                  icon={['fas', 'trash']}
                />
              </ButtonFloating>
            ) : null}
          </div>
        </div>
        {showPrimaryButton ? (
          <ButtonFlat
            className={styles['button-primary']}
            size="sm"
            uiType="primary"
            onClick={primaryButtonOnClick}
            text={primaryButtonChildren}
          />
        ) : null}
      </>
    );
  };

  _renderNav = (dir, fullScreen, disableNavigationControls) => (
    onClick,
    disabled,
  ) =>
    disableNavigationControls ? null : (
      <MediaQuery minWidth={responsive.minDeviceWidthLG}>
        {(match) =>
          match ? (
            <ButtonNav
              dir={dir}
              disabled={disabled}
              isMini={!fullScreen}
              onClick={mixpanelHelpers.trackEventAndPass({
                name: `ImageGallery_Scroll${capitalize(dir)}`,
              })((event) => {
                event.preventDefault();
                event.stopPropagation();
                onClick(event);
              })}
            />
          ) : null
        }
      </MediaQuery>
    );

  /**
   * Default render _renderItem https://github.com/xiaolin/react-image-gallery/blob/master/src/ImageGallery.jsx
   *
   * Overriding the default Functiontion by copy and paste to be able to add new classes and elements around images
   */
  _renderItemImg = ({ index: itemIndex }) => (item) => {
    const {
      onImageLoad,
      onImageError: propsOnImageError,
      retryButtonOnClick,
    } = this.props;
    const onImageError =
      propsOnImageError ||
      (this._imageGallery && this._imageGallery._handleImageError);

    const { isUploading, uploadFailed } = item;

    return (
      <div
        className={classNames([
          'image-gallery-image',
          { [styles['is-uploading']]: isUploading },
          { [styles['upload-failed']]: uploadFailed && !isUploading },
        ])}
      >
        {item.imageSet ? (
          <picture onLoad={onImageLoad} onError={onImageError}>
            {item.imageSet.map((source, index) => (
              <source
                /* eslint-disable-next-line react/no-array-index-key */
                key={`gallery-picture-${index}`}
                media={source?.media}
                srcSet={source?.srcSet}
                type={source?.type}
              />
            ))}
            <img alt={item.originalAlt || 'Gallery item'} src={item.original} />
          </picture>
        ) : (
          <img
            src={item?.original}
            alt={item?.originalAlt || 'Gallery item'}
            srcSet={item?.srcSet}
            sizes={item?.sizes}
            title={item?.originalTitle}
            onLoad={onImageLoad}
            onError={onImageError}
          />
        )}
        {item.description && (
          <span className="image-gallery-description">{item.description}</span>
        )}
        {isUploading && <Spinner className={styles['loading-spinner']} />}
        {uploadFailed && !isUploading && (
          <ButtonFloating
            className={styles['button-retry']}
            size="sm"
            uiType="tertiary"
            onClick={retryButtonOnClick && retryButtonOnClick(itemIndex)}
          >
            <Icon icon={['fas', 'redo']} type="fa" />
          </ButtonFloating>
        )}
      </div>
    );
  };

  /**
   * Default _renderThumbInner Function https://github.com/xiaolin/react-image-gallery/blob/master/src/ImageGallery.jsx
   *
   * Overriding the default Functiontion by copy and paste to be able to add new classes and elements around images
   */
  _renderThumbInner = (item) => {
    const { onThumbnailError: propOnThumbnailError } = this.props;
    const onThumbnailError =
      propOnThumbnailError ||
      (this._imageGallery && this._imageGallery._handleImageError);

    const { isUploading, uploadFailed } = item;

    return (
      <div
        className={classNames([
          'image-gallery-thumbnail-inner',
          { [styles['upload-failed-thumb']]: uploadFailed && !isUploading },
        ])}
      >
        <img
          src={item.thumbnail}
          alt={item.thumbnailAlt || 'Gallery item'}
          title={item.thumbnailTitle}
          onError={onThumbnailError}
          className={classNames([
            { [styles['is-uploading-thumb']]: isUploading },
          ])}
        />
        {item.thumbnailLabel && (
          <div className="image-gallery-thumbnail-label">
            {item.thumbnailLabel}
          </div>
        )}
        {isUploading && (
          <Spinner
            className={classNames([
              styles['loading-spinner'],
              styles['loading-spinner-thumb'],
            ])}
          />
        )}
      </div>
    );
  };

  _renderYouTube = (item, youtubeIdx) => {
    const { mediaPreview, youTubeProps, fullScreen } = this.props;
    const { opts, ...restThisYouTubeProps } = youTubeProps || {};
    const { playerVars } = opts || {};

    return mediaPreview ? (
      this._renderYouTubePreview(item.thumbnail, restThisYouTubeProps)
    ) : (
      <YouTube
        showPlay
        autoPlay
        videoId={youtubeUrl.extractId(item.youtubeUrl)}
        opts={{
          playerVars: {
            controls: 0,
            iv_load_policy: 3,
            playsinline: 1,
            ...playerVars,
          },
          ...opts,
        }}
        onReady={this._saveYoutubeRefs(youtubeIdx)}
        containerClassName={classNames([
          styles['youtube-container'],
          fullScreen && styles['youtube-container-full-screen'],
        ])}
        {...restThisYouTubeProps}
      />
    );
  };

  _renderYouTubePreview = (src, { className }) => (
    <div className={classNames(styles['youtube-container'], className)}>
      <img
        src={src}
        className={styles.youtubemobile}
        alt="A preview of the youtube video"
      />
      <div className={styles['youtube-play-button-wrapper']}>
        <img
          className={styles['youtube-play-button']}
          src={videoPlayButton}
          alt="Play the youtube video"
        />
      </div>
    </div>
  );

  _updateItems = (items) =>
    items.map((item, idx) => {
      if (item.youtubeUrl) {
        return {
          thumbnail: youtubeUrl.getThumbnail(item.youtubeUrl),
          original: youtubeUrl.getHQImage(item.youtubeUrl),
          renderItem: (renderItem) => this._renderYouTube(renderItem, idx),
          ...item,
        };
      }
      return {
        renderItem: this._renderItemImg({ index: idx }),
        renderThumbInner: this._renderThumbInner,
        ...item,
      };
    });

  _handleDelete = () => {
    const { removeButtonOnClick } = this.props;
    this._toggleModal('ModalConfirmDelete')();
    if (this.currentImageGalleryIndex !== null) {
      removeButtonOnClick(this.currentImageGalleryIndex);
      this.currentImageGalleryIndex = this.currentImageGalleryIndex - 1 || 0;
      if (this.currentImageGalleryIndex < 0) {
        this.currentImageGalleryIndex = 0;
      }
      this._imageGallery.slideToIndex(this.currentImageGalleryIndex);
    }
  };

  _onSlide = (onSlide) => (currentIndex) => {
    const prevIndex = this.currentImageGalleryIndex || 0;
    this.currentImageGalleryIndex = currentIndex;
    const currentYoutube = this._youtubeRefs[prevIndex];
    if (currentYoutube) {
      /** prevent play video when user slide gallery */
      currentYoutube.pauseVideo();
    }
    if (onSlide) {
      onSlide(currentIndex);
    }
  };

  render() {
    const {
      className,
      items,
      showRemoveButton,
      showPrimaryButton,
      primaryButtonChildren,
      fullScreen,
      showPlayButton,
      showFullscreenButton,
      onSlide,
      disableNavigationControls,
      ...rest
    } = this.props;
    const { isOpenModalConfirmDelete, isOpenModalYoutubeUrl } = this.state;
    return (
      <StyleWrapper>
        <ImageGalleryBase
          ref={(gallery) => {
            this._imageGallery = gallery;
          }}
          slideDuration={0}
          items={this._updateItems(items)}
          additionalClass={classNames(
            className,
            styles.container,
            fullScreen && styles['container-full-screen'],
          )}
          renderLeftNav={this._renderNav(
            'left',
            fullScreen,
            disableNavigationControls,
          )}
          renderRightNav={this._renderNav(
            'right',
            fullScreen,
            disableNavigationControls,
          )}
          renderCustomControls={this._renderCustomControls}
          onSlide={this._onSlide(onSlide)}
          onClick={this._onImageClick}
          showPlayButton={!!showPlayButton}
          showFullscreenButton={!!showFullscreenButton}
          {...rest}
        />
        <FancyDialog
          toggle={this._toggleModal('ModalConfirmDelete')}
          isOpen={isOpenModalConfirmDelete}
          title="Are you sure?"
          primaryBtnText="Nevermind"
          primaryBtnOnClick={this._toggleModal('ModalConfirmDelete')}
          secondaryBtnText="Delete"
          secondaryBtnOnClick={this._handleDelete}
        />
        <ModalYoutubeUrl
          onAddVideoUrl={this._onUploadYoutubeUrl}
          isOpen={isOpenModalYoutubeUrl}
          toggle={this._toggleModal('ModalYoutubeUrl')}
          keybord={false}
        />
      </StyleWrapper>
    );
  }
}

export default ImageGallery;
