/* @flow */
import pickBy from 'lodash/pickBy';

import cookiesHelpers from '../cookiesHelpers/cookiesHelpers';

const COOKIES_CONSENT_COOKIE_NAME: string = 'cookie-perms';

type CONSENT_COOKIE_TYPE = {
  social?: true,
  preferences?: true,
  analytics?: true,
  advertising?: true,
  ts: number,
};

const ALL_COOKIES = {
  social: true,
  preferences: true,
  analytics: true,
  advertising: true,
};

const DEFAULT_CUSTOM_COOKIES = {
  social: true,
  preferences: true,
  analytics: false,
  advertising: true,
};

const getConsentCookie = (): CONSENT_COOKIE_TYPE | null => {
  try {
    const rawCookie = cookiesHelpers.getCookie(COOKIES_CONSENT_COOKIE_NAME);
    return rawCookie ? JSON.parse(rawCookie) : null;
  } catch (err) {
    return null;
  }
};

const setConsentCookie = (value: CONSENT_COOKIE_TYPE | null): string | null =>
  cookiesHelpers.setCookie(
    COOKIES_CONSENT_COOKIE_NAME,
    value ? JSON.stringify(pickBy(value, Boolean)) : value,
    // options
    value
      ? {
          'max-age': 315600000, // 10 years in seconds
          secure: true,
          SameSite: 'None',
        }
      : undefined,
  );

export default {
  ALL_COOKIES,
  DEFAULT_CUSTOM_COOKIES,
  getConsentCookie,
  setConsentCookie,
};
