/**
 * @generated SignedSource<<dbc3d914297db39113f619116a96cffd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProficiencyType = "ADVANCED" | "BEGINNER" | "FLUENT" | "INTERMEDIATE" | "%future added value";
export type AddLanguageInput = {
  clientMutationId?: string | null;
  language_code: string;
  proficiency?: ProficiencyType | null;
};
export type AddLanguageMutation$variables = {
  input: AddLanguageInput;
};
export type AddLanguageMutation$data = {
  readonly addLanguage: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newLanguageEdge: {
      readonly node: {
        readonly code: string | null;
        readonly id: string;
        readonly name: string | null;
        readonly native_name: string | null;
      } | null;
    } | null;
  } | null;
};
export type AddLanguageMutation = {
  response: AddLanguageMutation$data;
  variables: AddLanguageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LanguageEdge",
  "kind": "LinkedField",
  "name": "newLanguageEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Language",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "native_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddLanguagePayload",
        "kind": "LinkedField",
        "name": "addLanguage",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddLanguagePayload",
        "kind": "LinkedField",
        "name": "addLanguage",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06c33c9701dbab818087343a99835db6",
    "id": null,
    "metadata": {},
    "name": "AddLanguageMutation",
    "operationKind": "mutation",
    "text": "mutation AddLanguageMutation(\n  $input: AddLanguageInput!\n) {\n  addLanguage(input: $input) {\n    newLanguageEdge {\n      node {\n        id\n        code\n        name\n        native_name\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4f9420bfe6eff8b623e5e0116e794b5";

export default node;
