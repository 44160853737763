/* @flow */
// import pick from 'lodash/pick';

import { generateOptimisticId } from '@pluralcom/plural-js-utils';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

// import { localStorageHelpers } from '../../../utils';

const sendMessageMutation = ({
  message,
  threadId,
  ownerId,
  file,
  location,
}: {
  message?: ?string,
  threadId: string,
  ownerId: string,
  file?: ?any,
  location?: ?any,
  otherUserId?: ?string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation SendMessageMutation($input: SendMessageInput!) {
        sendMessage(input: $input) {
          error {
            message
          }
          newMessageEdge {
            node {
              id
              message
              from {
                id
              }
              optimistic_id
              is_subscription
              file {
                id
                url
                original_name
                mime_type
                size
                image {
                  id
                  medium
                  original
                  original_optimized
                }
              }
              location {
                id
                latitude
                longitude
                street_number
                street_name
                city
                postal_code
              }
              created_at
              updated_at
              # ...Message_data
            }
          }
        }
      }
    `;

    const optimistic_id = generateOptimisticId(
      `MESSAGE_${threadId}_${ownerId}`,
    );
    const variables = {
      input: {
        thread_id: threadId,
        message,
        optimistic_id,
        location,
        ...(file
          ? {
              files: [file],
            }
          : {}),
      },
    };

    // const owner = localStorageHelpers.getUser();
    // const avatar = localStorageHelpers.getAvatar();

    /** Optimistic update and socket update conflict with each other causing UI flickering before being stable */
    const optimisticResponse = {
      sendMessage: {
        error: null,
        newMessageEdge: {
          node: {
            id: optimistic_id,
            message: message || null,
            from: {
              id: ownerId,
              // ...pick(owner, ['username', 'first_name', 'last_name']),
              // avatar: avatar?.id
              //   ? pick(avatar, ['id', 'smallThumbnail'])
              //   : null,
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            optimistic_id,
            is_subscription: false,
            file: file
              ? {
                  id: optimistic_id.replace('MESSAGE', 'MESSAGE_FILE'),
                  url: file.preview.url,
                  mime_type: file.type,
                  original_name: file.name,
                  size: file.size || 0,
                  image: {
                    id: optimistic_id.replace('MESSAGE', 'FILE_IMAGE'),
                    medium: file.preview.url || '',
                    original: file.preview.url || '',
                    original_optimized: file.preview.url || '',
                  },
                }
              : null,
            location: location
              ? {
                  id: optimistic_id.replace('MESSAGE', 'MESSAGE_LOCATION'),
                  latitude: location.latitude,
                  longitude: location.longitude,
                  street_number: location.street_number || null,
                  street_name: location.street_name || null,
                  city: location.city || null,
                  postal_code: location.postal_code || null,
                }
              : null,
            // unfurl_data: null,
            // button: null,
          },
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: threadId,
          connectionInfo: [
            {
              key: 'Chat_messages',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'newMessageEdge',
        },
      ],
      onCompleted: resolve,
      onError: reject,
    });
  });

export default sendMessageMutation;
