/* @flow */
import { isLoggedOut } from '@pluralcom/plural-js-utils/lib/authHelpers';
import logger from '../../../utils/logger/logger';
import Auth from '../../Auth/Auth';
import { HelloMutation } from '../../../graphql/mutations';

const MAX_RETRY_COUNT = 10;
const DISABLE_AFTER_RETRY_TIME_REACHES = 3.6e6; // 1h
let retryCount = 0;
let lastRetryTime = 0;
let retryBackoffTime = 60000 * 3; // 3 mins

/**
 * Handles Auth events received by sockets
 * - If Backend logged the user out -> Logs user out client side
 */
const handleAuth = async ({ data, connect, close }) => {
  const { errors } = data;
  if (errors && Auth.isAuthenticated() && isLoggedOut(errors)) {
    logger.debug('Socket: Logging user out - attempting hello', {
      retryCount,
      lastRetryTime,
      retryBackoffTime,
    });
    // Auth.logout({ noBackend: true });
    // Reset retry data with backoff - do not reset after DISABLE_AFTER_RETRY_TIME_REACHES is reached
    if (
      Date.now() - lastRetryTime > retryBackoffTime &&
      retryBackoffTime < DISABLE_AFTER_RETRY_TIME_REACHES
    ) {
      retryCount = 0;
      retryBackoffTime *= 2;
    }
    // No more retries
    if (retryCount >= MAX_RETRY_COUNT) {
      return;
    }
    // Update retry data
    retryCount += 1;
    lastRetryTime = Date.now();
    try {
      close();
      await HelloMutation();
      connect();
    } catch (err) {
      logger.debugError('Error in socket authMiddleware Hello', err);
    }
  } else {
    // Reset retry data - success
    retryCount = 0;
    lastRetryTime = 0;
  }
};

export default handleAuth;
