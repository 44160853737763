/**
 * @generated SignedSource<<460b7c01cccb060d86a9c92f75f9ce04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputUsers_viewer$data = {
  readonly profile: {
    readonly id: string;
    readonly last_network_synced_timestamp: any | null;
  } | null;
  readonly search: {
    readonly " $fragmentSpreads": FragmentRefs<"InputUsersList_search">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectSection_viewer" | "ModalConnectSection_viewer">;
  readonly " $fragmentType": "InputUsers_viewer";
};
export type InputUsers_viewer$key = {
  readonly " $data"?: InputUsers_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputUsers_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "latitude",
  "variableName": "latitude"
},
v1 = {
  "kind": "Variable",
  "name": "longitude",
  "variableName": "longitude"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "latitude"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "longitude"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputUsers_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_network_synced_timestamp",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ModalConnectSection_viewer"
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "term",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "Search",
      "kind": "LinkedField",
      "name": "search",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InputUsersList_search"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ConnectSection_viewer"
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};
})();

(node as any).hash = "92d74f57ee03fda8e48fe054dd137fcf";

export default node;
