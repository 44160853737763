/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (file: string) =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation UploadProfilePhotoMutation($input: UploadProfilePhotoInput!) {
        uploadProfilePhoto(input: $input) {
          profile {
            id
            profile_color
            avatar {
              id
              original
              thumbnail
              medium
              smallThumbnail
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        file,
      },
    };

    return commitMutation({
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });
