/* @flow */
import React from 'react';
import classNames from 'classnames';

import './PlAplEmoji.scss';

type Props = {
  emojiSrc: string,
  className?: ?string,
  width?: string,
  height?: string,
};

const PlAplEmoji = ({ emojiSrc, className, width, height, ...rest }: Props) => (
  <div
    className={classNames('pl-apl-emoji', className)}
    style={{ backgroundImage: `url(${emojiSrc})`, width, height }}
    {...rest}
  />
);

export default PlAplEmoji;
