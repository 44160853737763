/* @flow */
import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import Anchor from '../Anchor/Anchor';
import H from '../H/H';

import styles from './Markdown.module.scss';

type Props = {
  level: string,
  children: React$Node,
};

const HeadingRenderer = (props: Props) => {
  switch (props.level) {
    case 1:
      return (
        <H element="h1" className={styles.titleH1}>
          {props.children}
        </H>
      );
    case 2:
      return (
        <H element="h2" className={styles.titleH2}>
          {props.children}
        </H>
      );
    case 3:
      return (
        <H element="h3" className={styles.titleH3}>
          {props.children}
        </H>
      );
    case 4:
      return (
        <H element="h4" className={styles.titleH4}>
          {props.children}
        </H>
      );
    case 5:
      return (
        <H element="h5" className={styles.titleH5}>
          {props.children}
        </H>
      );
    case 6:
      return (
        <H element="h6" className={styles.titleH6}>
          {props.children}
        </H>
      );
    default:
      return null;
  }
};

const Markdown = ({
  children,
  allowedTypes = [
    'text',
    'list',
    'listItem',
    'strong',
    'emphasis',
    'paragraph',
    'link',
    'heading',
  ],
  disableMarkdown,
  className,
  strongTextClassName,
}: {
  children: any,
  allowedTypes: Array<string>,
  disableMarkdown: boolean,
  className?: string,
  strongTextClassName?: string,
}) =>
  disableMarkdown ? (
    <div className={classNames([styles['text-wrapper'], className])}>
      {children}
    </div>
  ) : (
    <ReactMarkdown
      allowedTypes={allowedTypes}
      renderers={{
        text: (props) => <span {...props} />,
        root: (props) => <span className={styles.label} {...props} />,
        strong: (props) => (
          <span
            className={classNames(styles['label-strong'], strongTextClassName)}
            {...props}
          />
        ),
        emphasis: (props) => (
          <span className={styles['label-emphasis']} {...props} />
        ),
        list: (props) => <ul className={styles.list} {...props} />,
        listItem: (props) => <li className={styles.listItem} {...props} />,
        heading: (props) => HeadingRenderer(props),
        link: Anchor,
      }}
      className={className}
    >
      {children}
    </ReactMarkdown>
  );

export default Markdown;
