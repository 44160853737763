/* @flow */
import { Link, NavLink } from 'react-router-dom';

/* eslint jsx-a11y/anchor-has-content: 0 */
import React from 'react';
import classNames from 'classnames';
import styles from './Anchor.module.scss';

type Props = {
  navLink: ?boolean,
  exact: ?boolean,
  to:
    | ?string
    | {
        pathname: string,
        search: string,
      },
  children: ?any,
  className: ?string,
  uiType: 'clay' | 'transparent',
  inheritColor: ?boolean,
  inheritFont: ?boolean,
  activeClassName: ?string,
  buttonRef?: ?Function,
};

const Anchor = ({
  to,
  navLink,
  activeClassName,
  children,
  className,
  uiType,
  inheritColor,
  inheritFont,
  // Filter
  buttonRef,
  ...rest
}: Props) => {
  const commonProps = {
    children,
    ...rest,
  };

  const computedClassName = classNames([
    styles.a,
    styles[uiType],
    { [styles['inherit-color']]: inheritColor },
    { [styles['inherit-font']]: inheritFont },
    className,
  ]);

  if (navLink) {
    return (
      <NavLink
        className={computedClassName}
        to={to}
        activeClassName={activeClassName}
        {...commonProps}
      />
    );
  }
  if (to) {
    return <Link className={computedClassName} to={to} {...commonProps} />;
  }
  return (
    <a
      rel="nofollow noopener noreferer"
      className={computedClassName}
      {...commonProps}
    />
  );
};

export default Anchor;
