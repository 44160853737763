/* @flow */

import urlJoin from 'url-join';
import logger from '../../utils/logger/logger';

/**
 * Before using HistoryHelpers:
 * check if you the desired effect is achievable using react router's history
 */
const { history, location } = window;

const replaceState = (
  stateObject: ?Object,
  title: string,
  url: string
): void => {
  if (history.replaceState) {
    /**
     * We wrap this in a try and catch due to iOS behavior for replaceState.
     *
     * Since version 9.3 of iOS Apple has limited the number of times a page can call the pushState/replaceState API to 100 times per 30 seconds. Any further attempt causes a security exception to be thrown.
     */
    try {
      history.replaceState(stateObject, title, url);
    } catch (err) {
      logger.error(
        'Error in history.replaceState, If you are using safari on iOS this is due to iOS preventing more than 100 replaceStates in a 30 sec interval. NOTE: refreshing the page solves the issue.',
        err
      );
    }
  }
};

/**
 * Replaces the URL location without reloading
 */
const replaceUrlLocation = (
  {
    origin = location.origin,
    pathname = location.pathname,
    search = location.search.substring(1),
    state = history.state,
  } = {
    origin: location.origin,
    pathname: location.pathname,
    search: location.search.substring(1),
    state: history.state,
  }
): void => {
  const url = [urlJoin(origin, pathname), search].filter(Boolean).join('?');
  replaceState(state, '', url);
};

export default {
  replaceState,
  replaceUrlLocation,
};
