/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation RemoveEducationMutation($input: RemoveEducationInput!) {
    removeEducation(input: $input) {
      user {
        id
        education {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      deletedEducationId
      error {
        message
      }
    }
  }
`;

export default ({ id }: { id: string }, userId: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id,
      },
    };

    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: userId,
        connectionKeys: [
          {
            key: 'UserMoreInfo_education',
          },
          {
            key: 'EditProfile_education',
          },
        ],
        deletedIDFieldName: 'deletedEducationId',
        pathToConnection: ['profile', 'education'],
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: (err) => reject(err),
    });
  });
