/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription lastUnreadNotificationTimestampSubscribeSubscription {
    lastUnreadNotificationTimestampSubscribe {
      id
      last_unread_notification_timestamp
    }
  }
`;

const lastUnreadNotificationTimestampSubscribe = () =>
  requestSubscription({
    subscription,
  });

export default lastUnreadNotificationTimestampSubscribe;
