/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

type IpInfoType = {
  success: boolean,
  ip: ?string,
  type: ?string,
  city: ?string,
  countryName: ?string,
  countryCode: ?string,
  latitude: ?number,
  longitude: ?number,
  regionName: ?string,
  regionCode: ?string,
  timezone: ?string,
  zipcode: ?string,
  continentCode: ?string,
  continentName: ?string,
  languages: ?Array<string>,
  currency: ?string,
  provider: ?string,
  // extra
  updated_at: number,
};

const KEYS = {
  ipInfo: 'ipInfo',
};

const setIpInfo = (ipInfo: IpInfoType): void => {
  localStorageService.setItem(
    KEYS.ipInfo,
    JSON.stringify({ updated_at: Date.now(), ...ipInfo })
  );
};

const getIpInfo = (): IpInfoType => {
  const ipInfo = localStorageService.getItem(KEYS.ipInfo);
  return ipInfo;
};

export { setIpInfo, getIpInfo };
