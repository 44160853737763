/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation ChangePaymentStatusMutation($input: ChangePaymentStatusInput!) {
    changePaymentStatus(input: $input) {
      payment {
        id
        thread_id
        status

        # Repulling sensitive data
        amount
        amount_after_fee
        service_fee

        deal {
          id
          ...DealsItem_deal
          ...DealControls_deal
        }
      }
      error {
        id
        title
        technical_message
        message
        type
        code
      }
    }
  }
`;

const ChangePaymentStatusMutation = (
  {
    payment_id,
    new_status,
  }: {
    payment_id: string,
    new_status: string, // PAID, DECLINED, REQUEST_CANCELLED
  },
  { payment }: { payment: Object } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        payment_id,
        new_status,
      },
    };

    const optimisticResponse = payment
      ? {
          changePaymentStatus: {
            error: null,
            payment: {
              id: payment_id,
              ...pick(payment, [
                'thread_id',
                'status',
                'amount',
                'amount_after_fee',
                'service_fee',
              ]),
              status: new_status,
            },
          },
        }
      : undefined;

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default ChangePaymentStatusMutation;
