import { dateHelpers } from '@pluralcom/plural-js-utils';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { localStorageService, waitForVar } from '@pluralcom/plural-web-utils';

import environment from '../environment/environment';
import logger from '../logger/logger';
import localStorageHelpers from '../localStorageHelpers';

/** Local storage keys used by Intercom */
const LOCAL_STORAGE_KEYS = {
  /** Intercom user hash - used for identity verification */
  INTERCOM_HASH: 'ic_h',
};

/**
 * Sets the Intercom user.
 * To be called upon authentication and upon opening the website by an authenticated user
 */
const setUser = (
  user: {
    /** User ID */
    id: string;
    /** Username */
    username: string;
    /** Name */
    name: string;
    /** Email */
    email?: string | null;
    /** Birthdate */
    birthdate?: string;
    /** Published at */
    published_at?: string;
    /** Created at */
    created_at?: string;
  },
  intercom_hash: string,
) => {
  if (!user?.id || !window.Intercom) {
    return;
  }
  const created_at = user.published_at || user.created_at;
  if (intercom_hash) {
    localStorageService.setItem(
      LOCAL_STORAGE_KEYS.INTERCOM_HASH,
      intercom_hash,
    );
  }
  window.Intercom(
    'update',
    omitBy(
      {
        user_id: user.id,
        user_hash: intercom_hash,
        ...pick(user, ['username', 'name', 'email', 'birthdate']),
        created_at: created_at
          ? dateHelpers.msToS(new Date(created_at))
          : undefined,
        ...(environment.isProduction()
          ? {}
          : {
              env: environment.getHostEnv(),
            }),
      },
      isEmpty,
    ),
  );
};

/** Initializes and boots Intercom */
const init = () => {
  /** Setup */
  if (environment.isTest() || window.navigator.userAgent === 'ReactSnap') {
    return;
  }
  /** Intercom object - no destructuring to ensure reference exists */
  waitForVar('Intercom')
    .then(() => {
      if (!window.Intercom) {
        return;
      }
      /** boot Intercom */
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        hide_default_launcher: true,
      });
      /** set Intercom user */
      setUser(
        localStorageHelpers.getUser(),
        localStorageService.getItem(LOCAL_STORAGE_KEYS.INTERCOM_HASH),
      );
    })
    .catch((err) => {
      logger.error('Error waiting for Intercom', err);
    });
};

const hideLauncher = () => {
  window.Intercom?.('update', { hide_default_launcher: true });
};

/**
 * Opens the Messenger as if a new conversation was just created.
 * This function can also take an optional second parameter, used to pre-populate the message composer.
 */
const showNewMessage = (message?: string) =>
  window.Intercom?.('showNewMessage', message);

/** Opens the Messenger with the message list */
const showMessages = () => window.Intercom?.('showMessages');

/** Shuts down Intercom and deletes the cookies */
const shutdown = () => {
  localStorageService.removeItem(LOCAL_STORAGE_KEYS.INTERCOM_HASH);
  window.Intercom?.('shutdown');
};

export default {
  init,
  setUser,
  showNewMessage,
  showMessages,
  hideLauncher,
  shutdown,
};
