/**
 * @generated SignedSource<<fed2b53ef95f8f0b212e108284b4add6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditPositionInput = {
  clientMutationId?: string | null;
  companyName?: string | null;
  companyURL?: string | null;
  end?: string | null;
  positionId: string;
  start?: string | null;
  title?: string | null;
};
export type EditPositionMutation$variables = {
  input: EditPositionInput;
};
export type EditPositionMutation$data = {
  readonly editPosition: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly updatedPosition: {
      readonly company: {
        readonly id: string;
        readonly name: string | null;
        readonly url: string | null;
      } | null;
      readonly end: string | null;
      readonly id: string;
      readonly start: string | null;
      readonly title: string | null;
    } | null;
  } | null;
};
export type EditPositionMutation = {
  response: EditPositionMutation$data;
  variables: EditPositionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Position",
  "kind": "LinkedField",
  "name": "updatedPosition",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPositionPayload",
        "kind": "LinkedField",
        "name": "editPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditPositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPositionPayload",
        "kind": "LinkedField",
        "name": "editPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b15268f0b43233bec8d5a0f695e1921a",
    "id": null,
    "metadata": {},
    "name": "EditPositionMutation",
    "operationKind": "mutation",
    "text": "mutation EditPositionMutation(\n  $input: EditPositionInput!\n) {\n  editPosition(input: $input) {\n    error {\n      message\n      id\n    }\n    updatedPosition {\n      id\n      title\n      start\n      end\n      company {\n        id\n        name\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "27aa0385721dbd12f25d7bf29e8dbb3c";

export default node;
