import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeleteQuestionMutation($input: DeleteFAQuestionFromSkillInput!) {
    deleteFAQuestionFromSkill(input: $input) {
      error {
        id
        title
        technical_message
        message
        code
      }
      skill {
        id
        questions {
          id
          question
          answer
        }
      }
      deleted_skill_question_id
    }
  }
`;

interface DeleteQuestionMutationInput {
  /** skilld id */
  skill_id: string;
  /** faq question id */
  skill_question_id: string;
}

interface DeleteQuestionMutationSkill {
  /** skill */
  skill?: {
    /** skill id */
    id: string;
    /** skill questions */
    questions: Array<{
      /** skill questions node */
      node: {
        /** skill question id */
        id: string;
        /** skill question */
        question: string;
        /** skill answer */
        answer: string;
      };
    }>;
  };
}

const DeleteQuestionMutation = (
  { skill_question_id, skill_id }: DeleteQuestionMutationInput,
  { skill }: DeleteQuestionMutationSkill = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_question_id,
        skill_id,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        deleteFAQuestionFromSkill: {
          error: null,
          skill: {
            id: skill_id,
            questions: skill?.questions?.filter(
              (el) => el.node?.id !== skill_question_id,
            ),
          },
          deleted_skill_question_id: skill_question_id,
        },
      },
      onCompleted: resolve,
      onError: reject,
    });
  });

export default DeleteQuestionMutation;
