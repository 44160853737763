/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Button } from '@pluralcom/blueprint';
import Loader from './components/Loader/Loader';

import { highlightSearchTerm } from '../../utils';
import styles from './AutosuggestList.module.scss';

type Props = {
  autocompleteSearches?: [
    {
      id: string,
      title: string,
    },
  ],
  isLoading: boolean,
  isOpen: boolean,
  searchTerm?: string,
  withSearchTermAsOption?: boolean,
  onSearchTermSelect?: Function,
  onSelect: Function,
  className?: string,
  scroll?: {
    onScrollEndReached?: Function,
    scrollThreshold?: number,
    isLoadingMore?: string,
  },
  style?: Object,
  textClassName?: string,
  listItemClassName?: string,
  activeIndex?: number,
};

/**
 * Provide simple autosuggest list component for input
 * */
const AutosuggestList = React.forwardRef(
  (
    {
      searchTerm = '',
      isOpen,
      isLoading,
      autocompleteSearches,
      withSearchTermAsOption = false /** <- to show/hide Use "<searchTerm>" item */,
      onSearchTermSelect,
      onSelect,
      scroll = {},
      className,
      textClassName,
      listItemClassName,
      style,
      activeIndex,
    }: Props,
    forwardedRef,
  ) => {
    const {
      onScrollEndReached,
      scrollThreshold = 50 /** <- distance from bottom to call onScrollEndReached */,
      isLoadingMore = false,
    } = scroll;
    const onItemClick = (item) => () => onSelect && onSelect(item);
    const trimmedValue = searchTerm.trim();
    const isShowSearchTermItem =
      withSearchTermAsOption && Boolean(trimmedValue);

    function onScroll(e) {
      const element = e.target;
      const distanceToThreshold =
        element.scrollHeight -
        element.scrollTop -
        element.clientHeight -
        scrollThreshold;
      if (onScrollEndReached && distanceToThreshold <= 0) {
        onScrollEndReached();
      }
    }

    if (!isOpen) {
      return null;
    }

    return (
      <div
        className={classNames(styles['list-container'], className)}
        onScroll={onScroll}
        style={style}
        ref={forwardedRef}
      >
        {isShowSearchTermItem && (
          <Button onClick={onSearchTermSelect} className={styles.button}>
            Use &quot;
            <span className={styles['bold-text']}>{trimmedValue}</span>
            &quot;
          </Button>
        )}
        <div className={styles.list}>
          {!isLoading &&
            autocompleteSearches.map((item, i) => (
              <div
                role="presentation"
                key={item.id || i}
                className={classNames(
                  styles['list-item'],
                  { [styles['list-item--active']]: activeIndex === i },
                  listItemClassName,
                )}
                onClick={onItemClick(item)}
                onKeyDown={onItemClick(item)}
              >
                <p
                  className={classNames(
                    styles['list-item-text'],
                    textClassName,
                  )}
                >
                  {highlightSearchTerm(item.title, searchTerm).before}
                  <span className={styles['regular-text']}>
                    {highlightSearchTerm(item.title, searchTerm).highlight}
                  </span>
                  {highlightSearchTerm(item.title, searchTerm).after}
                </p>
              </div>
            ))}
          <Loader isLoading={isLoading || isLoadingMore} />
        </div>
      </div>
    );
  },
);

export default AutosuggestList;
