/* @flow */
import React, { type Node } from 'react';
import classNames from 'classnames';

import styles from './Text.module.scss';

type Props = {
  element?: string,
  className?: ?string,
  children?: ?Node,
  fontSizeType?: 't1' | 't2',
  fontFamily?: 'opensans' | 'gochihand',
  fontWeight?:
    | 'bold'
    | 'bolditalic'
    | 'extrabold'
    | 'italic'
    | 'light'
    | 'regular'
    | 'semibold',
};

/**
 * Basic Styled Text
 *
 * - a new fontFamily prop can be added to easily support more fonts
 */
const Text = ({
  element = 'p',
  fontFamily = 'opensans',
  fontWeight = 'regular',
  fontSizeType,
  className,
  children,
  ...rest
}: Props) =>
  React.createElement(
    element,
    {
      className: classNames([
        styles.text,
        { [styles[element]]: styles[element] },
        {
          [styles[`f-${fontFamily}-${fontWeight}`]]:
            styles[`f-${fontFamily}-${fontWeight}`],
        },
        { [styles[fontSizeType]]: styles[fontSizeType] },
        className,
      ]),
      ...rest,
    },
    children,
  );

export default Text;
