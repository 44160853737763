/* @flow */
import React, { Component } from 'react';
import ClassNames from 'classnames';

import styles from './ErrorAlert.module.scss';

type Props = {
  className?: ?string,
};

type HelperProps = {
  errors?: Array<string>,
  singleError?: boolean,
  text?: string,
};

const getErrorsFromProps = ({
  text,
  errors: propErrors = [],
  singleError,
}: HelperProps) => {
  // If custom text is provided, show it as is
  if (text) {
    return [{ type: 'custom', text }];
  }
  // If multiple error types are provided, show them
  const errors = [];
  for (let i = 0; i < propErrors.length; i += 1) {
    switch (propErrors[i]) {
      case 'required':
        errors.push({ type: 'required', text: 'This field is required.' });
        break;
      case 'isEmail':
        errors.push({
          type: 'isEmail',
          text: 'This needs to be a valid email address.',
        });
        break;
      case 'isMobilePhone':
        errors.push({
          type: 'isMobilePhone',
          text: 'This needs to be a valid phone number.',
        });
        break;
      case 'alreadySignedUp':
        errors.push({
          type: 'alreadySignedUp',
          text: "You've already signed up.",
        });
        break;
      default:
    }
    if (singleError && errors.length > 0) {
      break;
    }
  }
  return errors;
};

class ErrorAlert extends Component<*, Props, *> {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { ...prevState, errors: getErrorsFromProps(nextProps) };
  }

  constructor(props) {
    super(props);
    this.state = { errors: getErrorsFromProps(props) };
  }

  render() {
    const { className } = this.props;
    const displayedErrors = this.state.errors.map((error) => (
      <p key={error.type}>{error.text}</p>
    ));
    return (
      <div
        className={ClassNames(styles['pl-error'], className, {
          'display-none': !displayedErrors.length,
        })}
      >
        {displayedErrors}
      </div>
    );
  }
}

export default ErrorAlert;
