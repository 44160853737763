/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeclinePaymentRefundRequestMutation(
    $input: DeclinePaymentRefundRequestInput!
  ) {
    declinePaymentRefundRequest(input: $input) {
      payment {
        id
        status
        refund_request_declined_at
        # is_support_required
        deal {
          id
          ...DealsItem_deal
          ...DealControls_deal
        }
      }
      error {
        id
        title
        message
      }
    }
  }
`;

const DeclinePaymentRefundRequestMutation = (
  {
    payment_id,
  }: {
    payment_id: string,
  },
  { payment }: { payment: Object } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        payment_id,
      },
    };

    const optimisticResponse = {
      declinePaymentRefundRequest: {
        error: null,
        payment: {
          id: payment.id,
          status: payment.status,
          // is_support_required: true,
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default DeclinePaymentRefundRequestMutation;
