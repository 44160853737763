/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../../../hocs/withRouter/withRouter';

import { authRootObjUpdate as authRootObjUpdateAC } from '../../../../redux/reducers/authReducer/authReducer';
import { VerifyCodeModal } from '../../../../containers/authentication';

type Props = {
  authRootObj: {
    isOpenModalVerifyCode: boolean,
    verifyCodeOnSuccess?: ?Function,
    verifyCodeOnFail?: ?Function,
  },
  authRootObjUpdate: Function,
};

const ModalVerifyCode = ({ authRootObj, authRootObjUpdate }: Props) => {
  const {
    isOpenModalVerifyCode,
    verifyCodeOnSuccess,
    verifyCodeOnFail,
  } = authRootObj;
  return (
    <VerifyCodeModal
      isVerification
      isOpen={isOpenModalVerifyCode}
      onToggle={() => {
        authRootObjUpdate({
          isOpenModalVerifyCode: false,
        });
        if (verifyCodeOnFail) {
          verifyCodeOnFail();
        }
      }}
      onSuccess={(profile, { emailOrPhone }) => {
        authRootObjUpdate({
          isOpenModalVerifyCode: false,
        });
        if (verifyCodeOnSuccess) {
          verifyCodeOnSuccess(profile, { emailOrPhone });
        }
      }}
    />
  );
};

const mapStateToProps = ({ auth: authRootObj }) => ({
  authRootObj,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ authRootObjUpdate: authRootObjUpdateAC }, dispatch);

export { ModalVerifyCode as PureModalVerifyCode };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalVerifyCode),
);
