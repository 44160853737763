/* @flow */
/** @todo @vova @test */
import { useEffect } from 'react';
import { useParams, useResolvedPath } from 'react-router-dom';
import HistoryHelpers from '../../services/HistoryHelpers/HistoryHelpers';

/**
 * useUpdateUrlWithUsernameEffect hook
 *
 * Updates the `/profile` part of the pathname with the actual username
 */
const useUpdateUrlWithUsernameEffect = (username) => {
  const params = useParams();
  const url = useResolvedPath('').pathname;
  /** Update url with username */
  useEffect(() => {
    if (username && [`/${params.username}`, '/profile'].includes(url)) {
      HistoryHelpers.replaceUrlLocation({
        pathname: `/${username}`,
      });
    }
  }, [username, url, params.username]);
};

export default useUpdateUrlWithUsernameEffect;
