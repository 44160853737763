import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { ModalForm } from '@pluralcom/blueprint';
import classNames from 'classnames';
import { useMedia } from 'use-media';

import { QueryRenderer } from '../../graphql';
import InputUsers from '../InputUsers/InputUsers';
import LoadingLayout from '../LoadingLayout/LoadingLayout';

import styles from './ModalPeopleSelector.module.scss';
import { responsive } from '../../utils';

/**
 * Provide modal for people selector list
 * */

interface ModalPeopleSelectorProps {
  /** modal title */
  title?: string;
  /** function to be call on Item select */
  onSelectPerson?: Function;
  /** function to be call on input change */
  onChangeInput?: Function;
  /** function to be call on tags change */
  onChangeTags?: Function;
  /** modal open state */
  isOpen?: boolean;
  /** function to toggle modal */
  toggle?: Function;
  /** viewer object */
  viewer?: any;
}

/**
 *
 * FIGMA v0.0.1
 * ModalPeopleSelector to show people selector modal
 */
const ModalPeopleSelector = ({
  title = 'Select',
  onSelectPerson,
  onChangeInput,
  onChangeTags,
  viewer,
  ...rest
}: ModalPeopleSelectorProps) => {
  const isDesktop = useMedia({ minWidth: responsive.minDeviceWidthLG });
  return (
    <ModalForm
      modalProps={{
        wrapClassName: styles.peopleSelectorModal,
      }}
      className={styles.content}
      containerProps={{
        noPadding: true,
        className: classNames(['gx-0', styles.container]),
      }}
      bodyClassName={styles.body}
      isMaxHeight
      navHeaderProps={{
        text: title,
        // @ts-ignore
        leftButtonProps: !isDesktop && {
          text: 'Cancel',
          onClick: rest.toggle,
        },
        // @ts-ignore
        rightButtonProps: isDesktop && {
          iconProps: {
            icon: ['fas', 'times'],
            type: 'fa',
          },
          onClick: rest.toggle,
        },
      }}
      {...rest}
      modalBody={
        <QueryRenderer
          // @ts-ignore
          query={graphql`
            query ModalPeopleSelectorQuery {
              viewer {
                ...InputUsers_viewer
              }
            }
          `}
          render={({ props: queryProps }) => {
            if (queryProps || viewer) {
              return (
                <InputUsers
                  viewer={viewer || queryProps.viewer}
                  onSelectPerson={onSelectPerson}
                  onChangeInput={onChangeInput}
                  onChangeTags={onChangeTags}
                  autoFocus
                  inputTagsProps={{
                    inputTextProps: {
                      className: styles.inputTags,
                    },
                  }}
                />
              );
            }
            return <LoadingLayout />;
          }}
        />
      }
    />
  );
};
export default ModalPeopleSelector;
