/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation EditAnswerMutation($input: EditSkillFAQuestionInput!) {
    editSkillFAQuestion(input: $input) {
      skill_question {
        id
        answer
      }
      error {
        id
        title
        technical_message
        message
        code
      }
    }
  }
`;

export default ({
  skill_question_id,
  answer,
}: {
  skill_question_id: string,
  answer: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_question_id,
        answer,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        editSkillFAQuestion: {
          skill_question: {
            id: skill_question_id,
            answer,
          },
          error: null,
        },
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
