/* @flow */

import { logger, sentryHelpers } from '../../utils';

type PropsType = {
  relay: {
    hasMore: () => boolean,
    loadMore: (count: number, err: (err?: ?Object) => void) => void,
    isLoading: () => boolean,
  },
};

type OptionsType = { defaultCount: number, disableErrorMiddleware?: boolean };

const _defaultOptions: OptionsType = {
  defaultCount: 20,
  disableErrorMiddleware: false,
};

const errorMiddleware = err => {
  logger.error('loadMorePromise Error: ', err);
  sentryHelpers.addBreadcrumb({
    level: 'error',
    category: 'graphql_error_loadmore_response',
    message: 'Load More Error',
    data: err,
  });
  sentryHelpers.captureException(err);
};

/**
 * A promisified version of relay's loadMore implemented as a curried function.
 *
 * The last function returned is the equivilant of loadMore as a promise.
 * The first function takes props and default options as parameters to initialize the loadMore promise.
 */
const loadMorePromise = (
  props: PropsType,
  {
    defaultCount = _defaultOptions.defaultCount,
    disableErrorMiddleware,
  }: OptionsType = _defaultOptions
) => (count: number = defaultCount): Promise<Object | void> =>
  new Promise((resolve, reject) => {
    const {
      relay: { hasMore, loadMore, isLoading },
    } = props;
    if (!hasMore() || isLoading()) {
      resolve();
    }
    loadMore(count, err => {
      if (err) {
        if (!disableErrorMiddleware) {
          errorMiddleware(err);
        }
        reject(err);
      }
      resolve();
    });
  });

export default loadMorePromise;
