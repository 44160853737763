import React, { useMemo } from 'react';
import { ButtonFlat, Icon } from '@pluralcom/blueprint';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { IconProps } from '@pluralcom/blueprint/dist/react/molecules/Icon';

import { RecommendUserMutation } from '../../graphql/mutations';
import { logger } from '../../utils';

import { withAuthModals } from '../../hocs';
import styles from './ButtonRecommend.module.scss';

/**
 * Profile interface
 */
declare interface Profile {
  /** The profile id */
  id: string;
  /** viewerHasRecommended */
  viewerHasRecommended?: boolean;
  /** recommendations */
  recommendations: {
    /** recommendations id */
    id: string;
    /** recommendations totalCount */
    totalCount: number;
  };
}

/**
 * OwnProfile interface
 */
declare interface OwnProfile {
  /** The profile id */
  id: string;
  /** The profile first name */
  first_name: string;
  /** The profile last name */
  last_name: string;
  /** The profile avatar */
  avatar: {
    /** The profile avatar id */
    id: string;
    /** The profile avatar thumbnail url */
    smallThumbnail: string | null;
  } | null;
}

/**
 * Props for the ButtonRecommend component
 */
export interface ButtonRecommendProps {
  /** Custom class name */
  className?: string;
  /** Whether the owner profile should be shown */
  isOwner?: boolean;
  /** The location of the MP */
  mp_location?: string;
  /** The size of the button */
  size?: 'sm' | 'md' | 'lg';
  /** Whether or not to show an emoji */
  withEmoji?: boolean;
  /** The profile object */
  profile: Profile;
  /** The own profile object */
  ownProfile: OwnProfile;
  /** If True, Renders only Emoji */
  onlyEmoji?: boolean;
}

/**
 * Props for the ButtonRecommend component with Hocs
 */
export interface ButtonRecommendWithHocsProps extends ButtonRecommendProps {
  /** Whether the user is authenticated */
  isAuthenticated: boolean;
  /** Function to toggle the authentication modal */
  toggleAuthModal?: Function;
}

/**
 * Custom icon map for ButtonRecommend
 */
const ICON_SIZE_MAP: {
  [key: string]: React.ComponentProps<typeof Icon>['size'];
} = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

/**
 * - Figma v0.0.2
 * - ButtonRecommend component is used to recommend a user profile
 * - Emoji Icon are custom size
 * - @see ButtonFlat
 */
const ButtonRecommend = ({
  className,
  isOwner,
  mp_location,
  size = 'sm',
  withEmoji,
  profile,
  ownProfile,
  isAuthenticated,
  toggleAuthModal = () => {},
  onlyEmoji = false,
  ...rest
}: ButtonRecommendWithHocsProps) => {
  const { viewerHasRecommended } = profile || {};

  let _text: string = '';

  if (!onlyEmoji) {
    _text = viewerHasRecommended ? 'Recommended' : 'Recommend';
  }

  const _handleRecommendClick = async (event?: Event) => {
    event?.preventDefault();
    event?.stopPropagation();

    RecommendUserMutation(
      {
        user_id: profile.id,
        value: !profile.viewerHasRecommended,
        location: { id: '' },
      },
      { ownProfile, profile },
    ).catch((err) => logger.debugError('Error RecommendUserMutation', err));
  };

  const _getButtonProps = () => ({
    onClick: !isAuthenticated
      ? toggleAuthModal('Signup')
      : (e) => _handleRecommendClick(e),
  });

  // This manages the visiblity of the heart icon
  const _buttonIcon: IconProps | undefined = useMemo(() => {
    /**
     * - If onlyEmoji is true and viewerHasRecommended is true
     */
    if (onlyEmoji && viewerHasRecommended) {
      return {
        icon: '🤍',
        type: 'emoji',
        size: ICON_SIZE_MAP[size],
      };
    }

    /**
     * - If onlyEmoji is true and viewerHasRecommended is false
     */
    if (withEmoji && !viewerHasRecommended) {
      return {
        icon: '💙',
        type: 'emoji',
        size: ICON_SIZE_MAP[size],
      };
    }
    return undefined;
  }, [onlyEmoji, size, viewerHasRecommended, withEmoji]);

  return (
    <ButtonFlat
      className={classNames([styles[`container--${size}`], className])}
      uiType={viewerHasRecommended ? 'tertiary' : 'secondary'}
      size={size}
      iconProps={_buttonIcon}
      {...rest}
      {..._getButtonProps()}
    >
      {_text}
    </ButtonFlat>
  );
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export { ButtonRecommend as PureButtonRecommend };

export default compose(
  connect(mapStateToProps),
  withAuthModals(),
)(ButtonRecommend) as React.ComponentType<ButtonRecommendProps>;
