/* @flow */
/* eslint-disable */
import React, { PureComponent } from 'react';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import classNames from 'classnames';

import { siteWrapperHelpers, uiDomHelpers } from '../../utils';

import styles from './PortalMenu.module.scss';

type Props = {
  showMenu?: boolean,
  associatedElementRef?: Object,
  children?: node,
  className?: string,
  disableDefaultInlineStyle?: boolean,
  extraTopSpacing?: number,
  renderInPlace?: boolean,
  onClose?: Function,
};

export default class PortalMenu extends PureComponent<*, Props, *> {
  _menuElement = null;

  _asscRefBoundingClientRect = null;

  componentDidMount = () => {
    this._registerWindowListeners();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.showMenu) {
      if (this.props !== prevProps) {
        this._renderMenu();
      }
    } else {
      this._unrenderMenu();
    }
  };

  componentWillUnmount = () => {
    this._removeWindowListeners();
    this._unrenderMenu();
  };

  _registerWindowListeners = () => {
    window.addEventListener('click', this._onWindowClick);
    window.addEventListener('touchstart', this._onWindowClick);
  };

  _removeWindowListeners = () => {
    window.removeEventListener('click', this._onWindowClick);
    window.removeEventListener('touchstart', this._onWindowClick);
  };

  _onWindowClick = (event) => {
    const { showMenu, onClose } = this.props;
    if (!showMenu) {
      return;
    }
    if (this.props.associatedElementRef) {
      this._asscRefBoundingClientRect = this.props.associatedElementRef.getBoundingClientRect();
    }
    if (
      this._asscRefBoundingClientRect &&
      !uiDomHelpers.clickInBoundingClientRect(
        event,
        this._asscRefBoundingClientRect,
      ) &&
      onClose
    ) {
      onClose(event);
    }
  };

  _renderMenu = () => {
    const {
      renderInPlace,
      showMenu,
      associatedElementRef,
      disableDefaultInlineStyle,
      extraTopSpacing,
      children,
      className,
      ...rest
    } = this.props;
    if (renderInPlace || !children) {
      return;
    }
    const menuElement = this._menuElement || document.createElement('div');
    this._menuElement = siteWrapperHelpers.appendChild(menuElement);
    let style = {};
    if (associatedElementRef && !disableDefaultInlineStyle) {
      if (!this._asscRefBoundingClientRect) {
        this._asscRefBoundingClientRect = associatedElementRef.getBoundingClientRect();
      }
      style = {
        ...style,
        top: `${
          this._asscRefBoundingClientRect.top +
          this._asscRefBoundingClientRect.height +
          extraTopSpacing
        }px`,
        left: `${this._asscRefBoundingClientRect.left}px`,
        width: `${this._asscRefBoundingClientRect.width}px`,
      };
    }
    const elementProps = {
      className: classNames([styles.menu, className]),
      style,
      ...rest,
    };
    // eslint-disable-next-line react/no-deprecated
    render(
      React.createElement('div', elementProps, this.props.children),
      this._menuElement,
    );
  };

  _unrenderMenu = () => {
    if (this._menuElement) {
      siteWrapperHelpers.removeChild(this._menuElement);
      this._menuElement = null;
      this._asscRefBoundingClientRect = null;
    }
  };

  render() {
    const {
      renderInPlace,
      showMenu,
      associatedElementRef,
      disableDefaultInlineStyle,
      extraTopSpacing,
      children,
      className,
      ...rest
    } = this.props;
    if (renderInPlace && children) {
      return (
        <div
          className={classNames([
            styles.menu,
            { [styles.none]: !showMenu },
            className,
          ])}
        >
          {React.cloneElement(children, rest)}
        </div>
      );
    }
    return null;
  }
}

PortalMenu.defaultProps = {
  extraTopSpacing: 1,
};
