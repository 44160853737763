/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './H.module.scss';

type Props = {
  element?: string,
  className?: ?string,
  children?: ?React.Node,
  seoHeader?: boolean,
};

/**
 * Enhanced h element
 */
const H = ({
  element = 'h2',
  className,
  children,
  seoHeader,
  ...rest
}: Props) =>
  React.createElement(
    element,
    {
      className: classNames([
        styles.h,
        { [styles[element]]: styles[element] },
        { [styles['seo-header']]: seoHeader },
        className,
      ]),
      ...rest,
    },
    children,
  );

export default H;
