/* @flow */

/**
 * Checks if the click event was fired withing the bounds of a rectangle on screen.
 */
const clickInBoundingClientRect = (event: MouseEvent, rect: ClientRect) =>
  event.clientX >= rect.left &&
  event.clientX < rect.left + rect.width &&
  event.clientY >= rect.top &&
  event.clientY < rect.top + rect.height;

export default clickInBoundingClientRect;
