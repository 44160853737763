/* @flow */

/** A valid url with protocol https? */
const url: RegExp = /((h|H)(t|T)(t|T)(p|P)(s|S)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

/** matches a lose version of googl's libphonenumber's AsYouType to be used with email or phone fields */
const emailOrPhoneAsYouType = /^([ ()+]*([0-9][ ()+]*))+$/;

export default {
  url,
  emailOrPhoneAsYouType,
};
