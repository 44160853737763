/* @flow */
import queryString from 'query-string';
import isUndefined from 'lodash/isUndefined';

/**
 * Decodes the query string of the url to an object
 */
const decodeUrlQuery = (
  queryStr: string
): {
  username: ?string,
  code: ?string,
  auth_reqid: ?string,
  submit: ?boolean,
} => {
  const parsedQueryString = queryString.parse(queryStr);
  const {
    u: username,
    c: code,
    s: submit,
    ari: auth_reqid,
  } = parsedQueryString;
  return {
    username,
    code,
    auth_reqid,
    submit: !isUndefined(submit),
  };
};

export default {
  decodeUrlQuery,
};
