/* @flow */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { withRouter } from '../../hocs/withRouter/withRouter';

import { sentryHelpers } from '../../utils';

const RedirectNotFound = ({ location }: { location: Location }) => {
  sentryHelpers.captureEvent(
    {
      message: 'Redirecting to 404 Not found',
      level: 'warning',
    },
    {
      extra: {
        previousPathname: location.pathname,
      },
    },
  );
  return <Navigate to="/404" />;
};

export default withRouter(RedirectNotFound);
