/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './ImagePlaceholder.module.scss';

type Props = {
  color?: string,
  uuid?: ?string,
  className?: ?string,
  children?: ?React.Node,
};

/**
 * Use this component if an image doesn't exist
 * @example
 *  <ImagePlaceholder uuid={user.id} className={style['img-placeholder']} >
 * */

const ImagePlaceholder = ({
  color: inColor,
  uuid,
  className,
  children,
}: Props) => (
  /**
   * - Now the gradient color is constant.
   * - Removed the profile gradient selector.
   */
  <div className={classNames(styles.placeholder, className)}>{children}</div>
);

export default ImagePlaceholder;
