/**
 * @generated SignedSource<<a2fd61bb350807bd024d85c82fbb3079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModalConnectSection_viewer$data = {
  readonly profile: {
    readonly verified_facebook: boolean | null;
  } | null;
  readonly userGrantedPermissions: {
    readonly phonebook: {
      readonly id: string;
      readonly value: boolean | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ModalConnectSection_viewer";
};
export type ModalConnectSection_viewer$key = {
  readonly " $data"?: ModalConnectSection_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModalConnectSection_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModalConnectSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verified_facebook",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserGrantedPermission",
      "kind": "LinkedField",
      "name": "userGrantedPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "phonebook",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node as any).hash = "851dbd608975ece1795518082d697ec8";

export default node;
