/* @flow */
import React from 'react';
/** need to be here to allow redefine react-popper-tooltip style */
import 'react-popper-tooltip/dist/styles.css';
import { TouchableOpacity } from '@pluralcom/blueprint';

import SkillCardMini from '../../../../SkillCardMini/SkillCardMini';

import styles from './SkillInfoWindow.module.scss';

type Props = {
  skill: any,
};

const SkillInfoWindow = ({ skill }: Props) => (
  <TouchableOpacity
    className={styles.container}
    to={`/skills/${skill?.id}`}
    target="blank"
  >
    <SkillCardMini skill={skill} className={styles.content} />
  </TouchableOpacity>
);

export default SkillInfoWindow;
