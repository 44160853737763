/**
 * @generated SignedSource<<37a397438677c20906827c67f6d54795>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type lastUnreadNotificationTimestampSubscribeSubscription$variables = {};
export type lastUnreadNotificationTimestampSubscribeSubscription$data = {
  readonly lastUnreadNotificationTimestampSubscribe: {
    readonly id: string;
    readonly last_unread_notification_timestamp: any | null;
  } | null;
};
export type lastUnreadNotificationTimestampSubscribeSubscription = {
  response: lastUnreadNotificationTimestampSubscribeSubscription$data;
  variables: lastUnreadNotificationTimestampSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "lastUnreadNotificationTimestampSubscribe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_unread_notification_timestamp",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "lastUnreadNotificationTimestampSubscribeSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "lastUnreadNotificationTimestampSubscribeSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2ae7c7090e3af6818a2f59bff3ff6841",
    "id": null,
    "metadata": {},
    "name": "lastUnreadNotificationTimestampSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription lastUnreadNotificationTimestampSubscribeSubscription {\n  lastUnreadNotificationTimestampSubscribe {\n    id\n    last_unread_notification_timestamp\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb40bc4a84798b4d01627be7ca60805b";

export default node;
