/* @flow */
import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { localStorageHelpers } from '../../../utils';

import { commitMutation } from '../..';

/**
 * Provide mutation for ToggleUserLikedSkillInput
 */
const mutation = graphql`
  mutation ToggleUserLikedSkillMutation($input: ToggleUserLikedSkillInput!) {
    toggleUserLikedSkill(input: $input) {
      skill {
        id
        likes {
          id
          viewerHasLiked
          users {
            totalCount
            edges {
              node {
                id
                first_name
                last_name
                profile_color
                avatar {
                  id
                  smallThumbnail
                }
              }
            }
          }
        }
      }
      skillEdge {
        node {
          id
          likes {
            id
            viewerHasLiked
            users(first: 3) {
              totalCount
              edges {
                node {
                  id
                  first_name
                  last_name
                  profile_color
                  avatar {
                    id
                    smallThumbnail
                  }
                }
              }
            }
          }
        }
      }
      deletedSkillId
      deletedUserId
      viewerEdge {
        node {
          id
          first_name
          last_name
          profile_color
          avatar {
            id
            smallThumbnail
          }
        }
      }
    }
  }
`;

/* used to update totalCount field since Optimistic update alone doesn't work but works on web */
const sharedUpdater = (
  store: RelayModernStore,
  likesId: String,
  totalCount: number,
) => {
  if (!likesId) {
    return;
  }
  /** Fetch user.likes */
  const likesTopLevel = store.get(likesId);
  ['SkillCardMiniHeader_likes_users', 'SaveSkillCount_likes_users'].forEach(
    (connectionName) => {
      /** Fetch user.likes.received */
      const connection = ConnectionHandler.getConnection(
        likesTopLevel,
        connectionName,
        {},
      );
      /** Set total count field */
      if (connection) {
        connection.setValue(totalCount, 'totalCount');
      }
    },
  );
};

export default (skill_id: string, skill: Object): Promise<Object> =>
  new Promise((resolve, reject) => {
    const viewer = pick(localStorageHelpers.getUser(), [
      'id',
      'first_name',
      'last_name',
      'profile_color',
      'avatar.id',
      'avatar.smallThumbnail',
    ]);
    const totalCount = Number(skill.likes.users?.totalCount) ?? 1;
    const skillNode = skill?.likes
      ? {
          id: skill_id,
          likes: {
            id: skill.likes.id,
            viewerHasLiked: !skill.likes.viewerHasLiked,
            users: {
              totalCount: skill.likes.viewerHasLiked
                ? totalCount - 1
                : totalCount + 1,
              edges: skill.likes.users?.edges
                ? [
                    ...(skill.likes.viewerHasLiked
                      ? skill.likes.users.edges
                          .filter((el) => el.node.id !== viewer.id)
                          .map((el) => ({
                            node: omit(el.node, ['__typename']),
                          }))
                      : skill.likes.users.edges
                          .concat({ node: viewer })
                          .map((el) => ({
                            node: omit(el.node, ['__typename']),
                          }))),
                  ]
                : [],
            },
          },
        }
      : undefined;

    const optimisticResponse = skillNode
      ? {
          toggleUserLikedSkill: {
            skill: skillNode,
            skillEdge: skill.likes.viewerHasLiked ? null : { node: skillNode },
            deletedSkillId: skill.likes.viewerHasLiked ? skill_id : null,
            deletedUserId: skill.likes.viewerHasLiked ? viewer.id : null,
            viewerEdge: skill.likes.viewerHasLiked
              ? null
              : viewer && {
                  node: viewer,
                },
          },
        }
      : undefined;

    const configs = [
      ...(skill?.likes?.viewerHasLiked
        ? [
            {
              type: 'RANGE_DELETE',
              parentID: skill.likes.id,
              connectionKeys: [
                {
                  key: 'SkillCardMiniHeader_likes_users',
                },
              ],
              deletedIDFieldName: 'deletedUserId',
              pathToConnection: ['likes', 'users'],
            },
            {
              type: 'RANGE_DELETE',
              parentID: viewer.id,
              connectionKeys: [
                {
                  key: 'LikesTab_likedSkills',
                },
              ],
              deletedIDFieldName: 'deletedSkillId',
              pathToConnection: ['profile', 'likedSkills'],
            },
          ]
        : [
            {
              type: 'RANGE_ADD',
              parentID: skill.likes.id,
              connectionInfo: [
                {
                  key: 'SkillCardMiniHeader_likes_users',
                  rangeBehavior: 'prepend',
                },
              ],
              edgeName: 'viewerEdge',
            },
            {
              type: 'RANGE_ADD',
              parentID: viewer.id,
              connectionInfo: [
                {
                  key: 'LikesTab_likedSkills',
                  rangeBehavior: 'prepend',
                },
              ],
              edgeName: 'skillEdge',
            },
          ]),
    ];

    commitMutation({
      mutation,
      variables: { input: { skill_id } },
      /* Due to Relay not auto updating connection.totalCount, we use a combination of optimisticResponse and optimisticUpdater */
      optimisticResponse,
      optimisticUpdater: (store) => {
        sharedUpdater(
          store,
          skill?.likes?.id,
          skillNode?.likes?.users?.totalCount || 0,
        );
      },
      updater: (store) => {
        const payload = store.getRootField('toggleUserLikedSkill');
        const error = payload.getLinkedRecord('error');

        if (error) {
          return;
        }

        sharedUpdater(
          store,
          skill?.likes?.id,
          payload
            .getLinkedRecord('skill')
            ?.getLinkedRecord('likes')
            ?.getLinkedRecord('users')
            ?.getValue('totalCount'),
        );
      },
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
