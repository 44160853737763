/* @flow */
import { ConnectionHandler } from 'relay-runtime';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription messagesSubscribeSubscription {
    messagesSubscribe {
      # extra
      thread_id
      optimistic_id
      deleted_at
      # used by UI
      id
      is_subscription
      created_at
      from {
        id
      }
      file {
        id
        url
        mime_type
        image {
          id
          medium
          original
          original_optimized
        }
      }
      ...Message_data
    }
  }
`;

const messagesSubscribe = () =>
  requestSubscription({
    subscription,
    updater: (store) => {
      const newMessage = store.getRootField('messagesSubscribe');
      const optimisticId = newMessage.getValue('optimistic_id');

      const newMessagethreadId = newMessage.getValue('thread_id');
      const parentProxy = store.get(newMessagethreadId);
      const connection =
        parentProxy &&
        ConnectionHandler.getConnection(parentProxy, 'Chat_messages');

      if (!connection) {
        return;
      }

      const newId = newMessage.getValue('id');

      const existingRecords = connection.getLinkedRecords('edges');
      const recordAlreadyExists = existingRecords.some((existingRecord) => {
        const node = existingRecord.getLinkedRecord('node');
        const existingId = node.getValue('id');

        return existingId === newId || existingId === optimisticId; // If the message was already added to the store via ID or optimistic update we don't want to add it again
      });

      if (newMessage.getValue('deleted_at')) {
        ConnectionHandler.deleteNode(connection, newId);
        return;
      }

      if (!recordAlreadyExists) {
        const edge = ConnectionHandler.createEdge(
          store,
          connection,
          newMessage,
          'MessageEdge',
        );
        ConnectionHandler.insertEdgeBefore(connection, edge);
      }
    },
  });

export default messagesSubscribe;
