/* @flow */
import React from 'react';
import classNames from 'classnames';

import PortalMenu from '../../../PortalMenu/PortalMenu';

import styles from './TileItemMenu.module.scss';

type Props = {
  className?: string,
};

const TileItemMenu = ({ className, ...rest }: Props) => (
  <PortalMenu
    renderInPlace
    className={classNames([styles.menu, className])}
    {...rest}
  />
);

export default TileItemMenu;
