/**
 * @generated SignedSource<<7054a5fbcfdabb19afb28ec0aeab81d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleUserLikedSkillInput = {
  clientMutationId?: string | null;
  skill_id: string;
};
export type ToggleUserLikedSkillMutation$variables = {
  input: ToggleUserLikedSkillInput;
};
export type ToggleUserLikedSkillMutation$data = {
  readonly toggleUserLikedSkill: {
    readonly deletedSkillId: string | null;
    readonly deletedUserId: string | null;
    readonly skill: {
      readonly id: string;
      readonly likes: {
        readonly id: string;
        readonly users: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar: {
                readonly id: string;
                readonly smallThumbnail: string | null;
              } | null;
              readonly first_name: string | null;
              readonly id: string;
              readonly last_name: string | null;
              readonly profile_color: string | null;
            } | null;
          } | null> | null;
          readonly totalCount: number | null;
        } | null;
        readonly viewerHasLiked: boolean | null;
      } | null;
    } | null;
    readonly skillEdge: {
      readonly node: {
        readonly id: string;
        readonly likes: {
          readonly id: string;
          readonly users: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly avatar: {
                  readonly id: string;
                  readonly smallThumbnail: string | null;
                } | null;
                readonly first_name: string | null;
                readonly id: string;
                readonly last_name: string | null;
                readonly profile_color: string | null;
              } | null;
            } | null> | null;
            readonly totalCount: number | null;
          } | null;
          readonly viewerHasLiked: boolean | null;
        } | null;
      } | null;
    } | null;
    readonly viewerEdge: {
      readonly node: {
        readonly avatar: {
          readonly id: string;
          readonly smallThumbnail: string | null;
        } | null;
        readonly first_name: string | null;
        readonly id: string;
        readonly last_name: string | null;
        readonly profile_color: string | null;
      } | null;
    } | null;
  } | null;
};
export type ToggleUserLikedSkillMutation = {
  response: ToggleUserLikedSkillMutation$data;
  variables: ToggleUserLikedSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasLiked",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profile_color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "smallThumbnail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleUserLikedSkillPayload",
    "kind": "LinkedField",
    "name": "toggleUserLikedSkill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skill",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillLikes",
            "kind": "LinkedField",
            "name": "likes",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillEdge",
        "kind": "LinkedField",
        "name": "skillEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillLikes",
                "kind": "LinkedField",
                "name": "likes",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 3
                      }
                    ],
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": "users(first:3)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedSkillId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedUserId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "viewerEdge",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToggleUserLikedSkillMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToggleUserLikedSkillMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5b1b17ebbd31f9a682a5ff4342589f5c",
    "id": null,
    "metadata": {},
    "name": "ToggleUserLikedSkillMutation",
    "operationKind": "mutation",
    "text": "mutation ToggleUserLikedSkillMutation(\n  $input: ToggleUserLikedSkillInput!\n) {\n  toggleUserLikedSkill(input: $input) {\n    skill {\n      id\n      likes {\n        id\n        viewerHasLiked\n        users {\n          totalCount\n          edges {\n            node {\n              id\n              first_name\n              last_name\n              profile_color\n              avatar {\n                id\n                smallThumbnail\n              }\n            }\n          }\n        }\n      }\n    }\n    skillEdge {\n      node {\n        id\n        likes {\n          id\n          viewerHasLiked\n          users(first: 3) {\n            totalCount\n            edges {\n              node {\n                id\n                first_name\n                last_name\n                profile_color\n                avatar {\n                  id\n                  smallThumbnail\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    deletedSkillId\n    deletedUserId\n    viewerEdge {\n      node {\n        id\n        first_name\n        last_name\n        profile_color\n        avatar {\n          id\n          smallThumbnail\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1937fd8468065e0d231b19d69139e994";

export default node;
