/* @flow */
import { getClientIPInfo } from '@pluralcom/plural-js-utils';

import localStorageHelpers from '../localStorageHelpers';
import logger from '../logger/logger';

const DEFAULT_MAX_AGE = 21600000; // 6 hours

/** Fetches the ip Info and caches it in the localStorage */
const fetchAndSetIpInfo = async ({
  force,
  maximumAge,
}: { force?: boolean, maximumAge?: number } = {}) => {
  const currIpInfo = await localStorageHelpers.getIpInfo();
  if (
    currIpInfo &&
    (!force ||
      Date.now() - currIpInfo.updated_at < (maximumAge ?? DEFAULT_MAX_AGE))
  ) {
    return currIpInfo;
  }
  let ipInfo;
  try {
    ipInfo = await getClientIPInfo();
  } catch (err) {
    logger.debugError('Error getClientIPInfo in fetchAndSetIpInfo', err);
  }
  if (ipInfo) {
    localStorageHelpers.setIpInfo(ipInfo);
    return ipInfo;
  }
  return currIpInfo;
};

export default {
  fetchAndSetIpInfo,
};
