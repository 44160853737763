/* @flow */
const generateCommonParamsString = (params: Object): string => `
    term: ${params.term || ''},
    zoom_level: ${params.zoomLevel || ''},
    longitude: ${params.longitude || ''},
    latitude: ${params.latitude || ''},
    latitudeTopLeft: ${params.latitudeTopLeft || ''},
    longitudeTopLeft: ${params.longitudeTopLeft || ''},
    latitudeBottomRight: ${params.latitudeBottomRight || ''},
    longitudeBottomRight: ${params.longitudeBottomRight || ''},
    isCategorySearch: ${params.isCategorySearch || false}
`;
const getSearchParentIDList = (params: Object): string =>
  `client:root:viewer:listSearch(
    ${generateCommonParamsString(params)},
    randomSeed: ${params.randomSeed || ''},
    isMapSearch: false
   )`.replace(/\s/g, '');

const getSearchParentIDMap = (params: Object): string =>
  `client:root:viewer:mapSearch(
    ${generateCommonParamsString(params)},
    isMapSearch: true,
    isClustersSearch: ${params.isClustersSearch || false}
)`.replace(/\s/g, '');

export { getSearchParentIDList, getSearchParentIDMap };
