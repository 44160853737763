/**
 * @generated SignedSource<<f7a893e573c5cadc5430f6890107a196>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCollaboratorRequestInput = {
  clientMutationId?: string | null;
  email?: string | null;
  other_skill_id?: string | null;
  other_skill_title?: string | null;
  phone?: string | null;
  skill_id?: string | null;
  user_id?: string | null;
};
export type CreateCollaboratorRequestMutation$variables = {
  input: CreateCollaboratorRequestInput;
};
export type CreateCollaboratorRequestMutation$data = {
  readonly createCollaboratorRequest: {
    readonly collaboratorEdge: {
      readonly node: {
        readonly accepted_at: any | null;
        readonly created_at: any;
        readonly id: string;
        readonly owner_skill: {
          readonly id: string;
          readonly title: string | null;
        } | null;
        readonly requested_at: any | null;
        readonly skill: {
          readonly id: string;
          readonly is_ghost: boolean | null;
          readonly published: boolean | null;
          readonly title: string | null;
        } | null;
        readonly user: {
          readonly avatar: {
            readonly id: string;
            readonly thumbnail: string | null;
          } | null;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
          readonly profile_color: string | null;
        } | null;
      } | null;
    } | null;
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type CreateCollaboratorRequestMutation = {
  response: CreateCollaboratorRequestMutation$data;
  variables: CreateCollaboratorRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCollaboratorEdge",
  "kind": "LinkedField",
  "name": "collaboratorEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillCollaborator",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profile_color",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "skill",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "is_ghost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "published",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "owner_skill",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requested_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accepted_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "createCollaboratorRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "createCollaboratorRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b71ba7bec4f31526dcd6321c414d4f6",
    "id": null,
    "metadata": {},
    "name": "CreateCollaboratorRequestMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCollaboratorRequestMutation(\n  $input: CreateCollaboratorRequestInput!\n) {\n  createCollaboratorRequest(input: $input) {\n    collaboratorEdge {\n      node {\n        id\n        user {\n          id\n          first_name\n          last_name\n          profile_color\n          avatar {\n            id\n            thumbnail\n          }\n        }\n        skill {\n          id\n          title\n          is_ghost\n          published\n        }\n        owner_skill {\n          id\n          title\n        }\n        requested_at\n        accepted_at\n        created_at\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e41fbba483fa7bfa4c5925c571f345e";

export default node;
