/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import 'regenerator-runtime/runtime';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import 'babel-plugin-relay/macro';
import React, { StrictMode } from 'react';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { hydrateRoot } from 'react-dom/client';

import { polyfill } from '@pluralcom/plural-web-utils';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-virtualized/styles.css';
/** to load react-flags-select styles before app styles */
import 'react-flags-select';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';

polyfill.polyfill();

const rootElement = document.getElementById('root');

const FinalApp = (
  <StrictMode>
    <App />
  </StrictMode>
);

if (rootElement?.hasChildNodes()) {
  // hydrate
  hydrateRoot(rootElement, FinalApp);
} else {
  // eslint-disable-next-line react/no-deprecated
  render(FinalApp, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
