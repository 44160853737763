/* @flow */
import { appleHelpers } from '@pluralcom/plural-js-utils';
import appleAuthHelpersBase from 'react-apple-signin-auth/dist/appleAuthHelpers';
import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';

/**
 * Apple ID signin
 *
 * - uses default auth options
 *
 * @note Needs own web instance. ie: does not work locally, on netlify or on heroku.
 */
const signin = ({ authOptions } = {}): Promise<{
  authorization: {
    id_token: string,
    code: string,
    state?: string,
  },
  /** Only provided when the user signs up for the first time */
  user?: {
    email: string,
    name?: {
      firstName: string,
      lastName: string,
    },
  },
  /** Extra response data */
  /** nonce */
  nonce: string,
}> => {
  const { nonce: inNonce } = authOptions || {};
  /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
  const nonce = sha256(inNonce || uuidv4());
  /** Call apple signin */
  return (
    appleAuthHelpersBase
      .signIn({
        authOptions: {
          /** Apple Service ID */
          clientId: appleHelpers.getAppleWebServiceId(),
          /** Default scope, email and name */
          scope: 'email name',
          /** Will not work unless the redirecURI is declared as an pple service ID redirect URI - ie: won't work on netlify */
          redirectURI: `https://${window.location.hostname}`,
          /** Random secure value */
          state: uuidv4(),
          /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
          nonce,
          /** By default, usePopup instead of redirection since we do client side auth */
          usePopup: true,
          /** Override default options with input */
        },
      })
      /** Customize res */
      .then((res) => ({ nonce, ...res }))
  );
};

export default {
  signin,
  script: appleAuthHelpersBase.APPLE_SCRIPT_SRC,
};
