/* @flow */
import { hotjar } from 'react-hotjar';

import cookiesHelpers from '../cookiesHelpers/cookiesHelpers';
import environment from '../environment/environment';
import localStorageHelpers from '../localStorageHelpers';
import removeElementFromDom from '../uiDomHelpers/removeElementFromDom';

const HOTJAR_SV = 6;

/**
 * Gets the hotjar id based on the hostname or environment
 */
const getHjid = (): number => {
  if (environment.isStaging()) {
    return 1057313;
  }
  if (environment.isProduction()) {
    return Number(process.env.REACT_APP_PRODUCTION_HOTJAR_ID);
  }
  return 0;
};

/**
 * Sets the hotjar user.
 * To be called upon authentication and upon opening the website by an authenticated user
 */
const setUser = (user, { roles }: { roles: Array<string> } = {}) => {
  if (!window.hj) {
    return;
  }
  hotjar.identify(
    user ? user.id : null,
    user
      ? {
          id: user.id,
          username: user.username,
          birthdate: user.birthdate,
          gender: user.gender,
          created_at: user.created_at,
          published_at: user.published_at,
          website: user.website,
          roles,
          email: user.email,
          phone: user.phone,
          avatar: user?.avatar?.thumbnail || user?.avatar?.smallThumbnail,
          first_name: user.first_name,
          last_name: user.last_name,
          name: user.name,
        }
      : undefined,
  );
};

const init = () => {
  const HOTJAR_ID = getHjid();
  if (!HOTJAR_ID || process.env.REACT_APP_DISABLE_HOTJAR) {
    return;
  }
  hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
  const user = localStorageHelpers.getUser();
  if (user) {
    setUser(user, {
      roles: localStorageHelpers.getUserRoles(),
    });
  }
};

/** Fully Resets the hotjar user's data */
const reset = () => setUser(null);

const _usedCookies = [
  'hjClosedSurveyInvites',
  '_hjDonePolls',
  '_hjMinimizedPolls',
  '_hjShownFeedbackMessage',
  '_hjid',
  '_hjRecordingLastActivity',
  '_hjTLDTest',
  '_hjUserAttributesHash',
  '_hjCachedUserAttributes',
  '_hjLocalStorageTest',
  '_hjIncludedInPageviewSample',
  '_hjIncludedInSessionSample',
  '_hjAbsoluteSessionInProgress',
  '_hjFirstSeen',
  'hjViewportId',
  '_hjRecordingEnabled',
];

/** Uninitializes the module and removes script and cookies */
const unInit = () => {
  removeElementFromDom(document.querySelector('script[src*="hotjar"]'));
  _usedCookies.forEach((cookieName) => {
    cookiesHelpers.deleteCookie(cookieName);
  });
};

export default {
  init,
  setUser,
  reset,
  unInit,
};
