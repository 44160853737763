/* @flow */
import React from 'react';

export type ModalNameType = 'Login' | 'Signup' | 'VerifyCode';

export default () => (WrappedComponent) =>
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpenSignup: false,
        isOpenLogin: false,
        isOpenVerifyCode: false,
        modalProps: {
          Signup: {},
          Login: {},
          VerifyCode: {},
        },
      };
      import('../../containers/authentication/modals').then(
        ({ LoginModal, SignupModal, VerifyCodeModal }) => {
          this.LoginModal = LoginModal;
          this.SignupModal = SignupModal;
          this.VerifyCodeModal = VerifyCodeModal;
          this.forceUpdate();
        },
      );
    }

    _getModalPropsState = (newProps) => ({
      modalProps: { ...this.state.modalProps, ...newProps },
    });

    /**
     * Toggles the visibility of the AuthModal modalName.
     */
    _toggleAuthModal = (modalName: ModalNameType, modalProps = {}) => (
      event?: ?{ preventDefault: Function },
    ) => {
      if (event) {
        event.preventDefault();
      }
      this.setState((state) => {
        const newIsOpen = !state[`isOpen${modalName}`];
        const newState = {
          [`isOpen${modalName}`]: newIsOpen,
          modalProps: {
            ...state.modalProps,
            ...modalProps,
          },
        };
        /**
         * reset specific modal props upon close
         */
        if (!newIsOpen) {
          newState.modalProps = {
            ...newState.modalProps,
            [modalName]: {},
          };
        }
        return newState;
      });
    };

    /**
     * Toggles the visibility of the AuthModal array modalNames.
     */
    _toggleAuthModals = (modalNames: Array<ModalNameType>) => (
      event?: ?{ preventDefault: Function },
    ) => {
      if (event) {
        event.preventDefault();
      }

      this.setState((state) => {
        const newState = {
          modalProps: state.modalProps,
        };
        modalNames.forEach((modalName) => {
          const newIsOpen = !state[`isOpen${modalName}`];
          newState[`isOpen${modalName}`] = newIsOpen;
          /**
           * reset specific modal props upon close
           */
          if (!newIsOpen) {
            newState.modalProps = {
              ...newState.modalProps,
              [modalName]: {},
            };
          }
        });
        return newState;
      });
    };

    render() {
      const { LoginModal, SignupModal, VerifyCodeModal } = this;
      const { ...restProps } = this.props;
      const {
        isOpenSignup,
        isOpenLogin,
        isOpenVerifyCode,
        modalProps,
      } = this.state;
      const {
        Signup: signupProps,
        Login: loginProps,
        VerifyCode: verifyCodeProps,
      } = modalProps;

      return (
        <>
          <WrappedComponent
            toggleAuthModal={this._toggleAuthModal}
            toggleAuthModals={this._toggleAuthModals}
            {...restProps}
          />
          {LoginModal && (
            <LoginModal
              isOpen={isOpenLogin}
              onToggle={this._toggleAuthModal('Login')}
              onClickSignup={this._toggleAuthModals(['Login', 'Signup'])}
              forgotPasswordBtnProps={{
                onClick: this._toggleAuthModals(['Login', 'ForgotPassword']),
              }}
              onSuccess={(
                profile,
                { emailOrPhone, auth_reqid, noVerificationRequired },
              ) => {
                let modalsToToggle = ['Login'];
                if (!noVerificationRequired) {
                  modalsToToggle = modalsToToggle.concat(['VerifyCode']);
                }
                this._toggleAuthModals(modalsToToggle)();
                if (loginProps.onSuccess) {
                  loginProps.onSuccess(profile, {
                    emailOrPhone,
                    auth_reqid,
                    noVerificationRequired,
                  });
                }
              }}
            />
          )}
          {SignupModal && (
            <SignupModal
              isOpen={isOpenSignup}
              onToggle={this._toggleAuthModal('Signup')}
              onClickLogin={this._toggleAuthModals(['Signup', 'Login'])}
              onSuccess={(
                profile,
                { emailOrPhone, auth_reqid, noVerificationRequired },
              ) => {
                let modalsToToggle = ['Signup'];
                if (!noVerificationRequired) {
                  modalsToToggle = modalsToToggle.concat(['VerifyCode']);
                }
                this._toggleAuthModals(modalsToToggle)();
                if (signupProps.onSuccess) {
                  signupProps.onSuccess(profile, {
                    emailOrPhone,
                    auth_reqid,
                    noVerificationRequired,
                  });
                }
              }}
            />
          )}
          {VerifyCodeModal && (
            <VerifyCodeModal
              isOpen={isOpenVerifyCode}
              onToggle={this._toggleAuthModal('VerifyCode')}
              helpDialogButtonsProps={{
                edit: {
                  onClick: this._toggleAuthModals(['VerifyCode', 'Login']),
                },
              }}
              onClickLogin={this._toggleAuthModals(['Signup', 'Login'])}
              onSuccess={(profile, { emailOrPhone }) => {
                this._toggleAuthModal('VerifyCode')();
                if (verifyCodeProps.onSuccess) {
                  verifyCodeProps.onSuccess(profile, { emailOrPhone });
                }
              }}
            />
          )}
        </>
      );
    }
  };
