/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Marker } from 'react-map-gl';
import { MapMarkerDot as MapMarkerDotBlueprint } from '@pluralcom/blueprint';

import styles from './MapMarkerDot.module.scss';

type Props = {
  className?: string,
  uiType?: 'red' | 'blue',
  lat?: number,
  lng?: number,
};

const MapMarkerDot = ({ className, uiType = 'demand', lat, lng }: Props) => (
  <Marker
    className={styles['map-marker-wrapper']}
    latitude={lat}
    longitude={lng}
    anchor="top-left"
  >
    <MapMarkerDotBlueprint
      type={uiType}
      className={classNames([styles['map-marker-dot'], className])}
    />
  </Marker>
);

export default MapMarkerDot;
