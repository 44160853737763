/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Svg } from '@pluralcom/blueprint';

import iconStar from './img/icon-star.svg';
import styles from './IconStar.module.scss';

type Props = {
  className?: ?string,
  rating: 'empty' | 'half' | 'full',
};

const getStyle = (rating: Props.rating): string =>
  rating === 'empty' ? '' : rating;

const IconStar = ({ rating = 'empty', className, ...rest }: Props) => (
  <Svg
    src={iconStar}
    width="14px"
    height="15px"
    center
    className={classNames(styles.star, styles[getStyle(rating)], className)}
    {...rest}
  />
);

export default IconStar;
