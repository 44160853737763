/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeleteSkillMutation($input: DeleteSkillInput!) {
    deleteSkill(input: $input) {
      deletedSkillId
      profile {
        id
        skills {
          totalCount
        }
      }
      error {
        message
      }
    }
  }
`;

export default (
  id: string,
  { parentID, totalCount }: { parentID: string, totalCount: number } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id,
      },
    };

    /**
     * NOTE: This causes the edge to update to: { cursor: 'xx', node: null } which
     * is why we have to filter out the skills with node: null (see SkillsTab.tsx)
     */
    const configs = [
      // @todo add all connection keys
      {
        type: 'RANGE_DELETE',
        parentID,
        connectionKeys: [
          {
            key: 'SkillsTab_skills',
          },
          {
            key: 'OnboardingSkillsTab_skills',
          },
        ],
        deletedIDFieldName: 'deletedSkillId',
        pathToConnection: ['profile', 'skills'],
      },
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'deletedSkillId',
      },
    ];

    const optimisticResponse = {
      deleteSkill: {
        deletedSkillId: id,
        profile: {
          id: parentID,
          skills: {
            totalCount: Math.max((totalCount || 0) - 1, 0),
          },
        },
        error: null,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
