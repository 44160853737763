import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';
import { localStorageHelpers } from '../../utils';
import { getCache } from '../Environment';

const subscription = graphql`
  subscription lastNetworkSyncedTimestampSubscribeSubscription {
    lastNetworkSyncedTimestampSubscribe {
      id
      last_network_synced_timestamp
    }
  }
`;

const lastNetworkSyncedTimestampSubscribe = () =>
  requestSubscription({
    subscription,
    onNext: (response) => {
      const user = localStorageHelpers.getUser();
      const oldValue = user?.last_network_synced_timestamp;
      const newValue =
        response.lastNetworkSyncedTimestampSubscribe
          .last_network_synced_timestamp;
      // Clear cache if the new value is greater than the old value
      if (!oldValue || (newValue && newValue > oldValue)) {
        /**
         * @todo @optimize - Clear only queries that rely on network:
         * 1. unifiedNetworkQuery
         * 1. searchQuery
         */
        getCache().clear();
      }
      /* update localStorage value */
      localStorageHelpers.setUser({
        ...user,
        last_network_synced_timestamp: newValue,
      });
    },
  });

export default lastNetworkSyncedTimestampSubscribe;
