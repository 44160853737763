/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './ScreenLayout.module.scss';

type Props = {
  children: React$Element<any>,
  layout?: 'nav-fixed-top',
  render?: Function,
  className?: string,
};

const ScreenLayout = ({ children, layout, render, className }: Props) => {
  const commonProps = {
    className: classNames([styles.container, styles[layout], className]),
  };
  if (render) {
    return render(commonProps);
  }
  return (
    <article {...commonProps}>
      {typeof children === 'function' ? children(commonProps) : children}
    </article>
  );
};

export default ScreenLayout;
