/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { DeletePaymentMethodMutationResponse } from './__generated__/DeletePaymentMethodMutation.graphql';

const mutation = graphql`
  mutation DeletePaymentMethodMutation($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      paymentMethodId
      paymentMethods {
        id
        defaultPaymentMethodId
      }
      user {
        id
        settings {
          id
          has_valid_payment_method
        }
      }
      error {
        message
      }
    }
  }
`;

/**
 * DeletePaymentMethod
 */
const deletePaymentMethodMutation = (
  {
    id,
  }: {
    id: string,
  },
  {
    paymentMethods,
    user,
  }: {
    paymentMethods: {
      id: string,
      paymentMethods: {
        edges: Array<{
          node: {
            id: string,
          },
        }>,
      },
    },
    user: {
      id: string,
      settings: {
        id: string,
      },
    },
  },
): Promise<DeletePaymentMethodMutationResponse> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id,
      },
    };

    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: paymentMethods.id,
        connectionKeys: [
          {
            key: 'PaymentMethods_paymentMethods',
          },
        ],
        deletedIDFieldName: 'paymentMethodId',
        pathToConnection: ['paymentMethods', 'paymentMethods'],
      },
    ];

    const newDefaultPaymentMethodId =
      paymentMethods?.paymentMethods?.edges
        ?.filter((edge) => edge && edge.node?.id !== id)
        .sort((a, b) => a.node.created_at > b.node.created_at)[0]?.node?.id ||
      null;

    const optimisticResponse = {
      deletePaymentMethod: {
        paymentMethodId: id,
        paymentMethods: {
          id: paymentMethods.id,
          defaultPaymentMethodId: newDefaultPaymentMethodId,
        },
        user: user
          ? {
              id: user.id,
              settings: {
                id: user?.settings?.id,
                has_valid_payment_method: Boolean(newDefaultPaymentMethodId),
              },
            }
          : null,
        error: null,
      },
    };

    commitMutation({
      mutation,
      variables,
      configs,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default deletePaymentMethodMutation;
