/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity, Icon } from '@pluralcom/blueprint';

import styles from './ButtonNav.module.scss';
import stylesImageGallery from '../../ImageGallery.module.scss';

type Props = {
  dir: 'left' | 'right',
  className: ?string,
  isMini: ?boolean,
};

const ButtonNav = ({ dir, isMini, className, ...rest }: Props) => (
  <TouchableOpacity
    className={classNames([
      { [styles[dir]]: dir },
      isMini && styles['button-nav-mini'],
      isMini && stylesImageGallery['button-nav-mini'],
      className,
    ])}
    {...rest}
  >
    <div
      className={classNames(styles.content, isMini && styles['content-mini'])}
    >
      <Icon className={styles.icon} icon={['fas', `angle-${dir}`]} type="fa" />
    </div>
  </TouchableOpacity>
);

export default ButtonNav;
