import React, { Fragment, useState } from 'react';
import type { Environment } from 'react-relay';
import { useMedia } from 'use-media';
import { Col, InputText, ModalForm, Row, Text } from '@pluralcom/blueprint';

import styles from './ModalReport.module.scss';
import { responsive, logger } from '../../utils';
import {
  BlockUserMutation,
  ReportEntityMutation,
} from '../../graphql/mutations';
import {
  Entity,
  Problem,
} from '../../graphql/mutations/activities/ReportEntity';
import { inputsMaxLengths, inputsMinLengths } from '../../assets/data';
import ButtonChoiceCard from '../ButtonChoiceCard';

export interface ModalReportProps {
  /** Boolean whether modal is open or not */
  isOpen?: boolean;
  /** Function to toggle modal */
  toggle: Function;
  /** Environment */
  environment: Environment;
  /** Profile ID */
  id: string;
  /** Entity Type */
  entityType?: Entity;
}

/** Constants to easily refer via variables */
const SPAM_SCAM_CHOICE_KEY: Problem = 'spam_scam';
const OFFENSIVE_CHOICE_KEY: Problem = 'offensive';
const IMPERSONATION_CHOICE_KEY: Problem = 'impersonation';
const OTHER_CHOICE_KEY: Problem = 'other';
const BLOCK_USER_CHOICE_KEY: string = 'block_user';

/** List of choices to be displayed
 * - key: unique key to identify the choice
 * - title: title of the choice
 * - text: description of the choice
 *
 *  used for easier mapping of choices
 */
const CHOICES = [
  {
    key: SPAM_SCAM_CHOICE_KEY,
    title: 'Spam or scam',
    text: 'Report unwanted or deceptive user behavior.',
  },
  {
    key: OFFENSIVE_CHOICE_KEY,
    title: 'Offensive',
    text: 'Report hurtful or upsetting user behavior.',
  },
  {
    key: IMPERSONATION_CHOICE_KEY,
    title: 'Pretending to be someone',
    text: 'Report a user who is impersonating another person.',
  },
  {
    key: OTHER_CHOICE_KEY,
    title: 'Other',
    text: "Report user behavior that doesn't fit the other categories.",
  },
  {
    key: BLOCK_USER_CHOICE_KEY,
    title: 'Block user',
    text: 'Prevent this user from further interactions with you.',
  },
];

/**
 * - Figma v0.0.1
 * - ModalReport
 * - Used to report for entityType
 */
const ModalReport = ({
  isOpen = false,
  toggle,
  environment = {},
  id,
  entityType,
}: ModalReportProps) => {
  const [choice, setChoice] = useState<Problem | string | null>(null);
  const [comment, setComment] = useState('');
  const isMinLG = useMedia({ minWidth: responsive.minDeviceWidthLG });

  /** Method to close the modal & clear selected choice */
  const toggleModal = () => {
    setChoice(null);
    setComment('');
    toggle();
  };

  /** Method to be called when selectedChoice exists
   *  Close the modal & clear selectedChoice
   */
  const onConfirm = (event) => {
    event.stopPropagation();
    if (
      !choice ||
      (choice === OTHER_CHOICE_KEY &&
        (!comment || comment.length < inputsMinLengths.reportBody))
    ) {
      return;
    }
    if (choice === BLOCK_USER_CHOICE_KEY) {
      /** Block user */
      BlockUserMutation(environment)(id, true).catch((error) => {
        logger.warn(
          `Error in: [BlockUserMutation]: ${(error && error.message) || ''}`,
        );
      });
    } else {
      /** Report entity */
      ReportEntityMutation(environment)(
        id,
        entityType,
        choice as Problem,
        comment,
      ).catch((error) => {
        logger.warn(
          `Error in: [ReportEntityMutation]: ${(error && error.message) || ''}`,
        );
      });
    }
    toggleModal();
  };
  return (
    <ModalForm
      isOpen={isOpen}
      toggle={toggleModal}
      modalProps={{ wrapClassName: styles['modal-report'] }}
      containerProps={{ noPadding: true }}
      className={styles.modal}
      modalBody={
        <Row gx={0}>
          <Col xs={10} xsOffset={1}>
            <div className={styles.content}>
              <div className={styles['info-container']}>
                <Text
                  element="span"
                  fontSizeType="t2"
                  stickToBreakpoint="lg"
                  className={styles.text}
                >
                  Let us know what’s going on
                </Text>
                <Text
                  element="span"
                  fontSizeType="t7"
                  stickToBreakpoint="lg"
                  className={styles.text}
                >
                  We use your feedback to help us learn when something’s not
                  right. This will only be shared with Plural.
                </Text>
              </div>
              <div className={styles['choice-container']}>
                {CHOICES.map((choiceItem) => (
                  <Fragment key={choiceItem.key}>
                    <ButtonChoiceCard
                      {...choiceItem}
                      onClick={() => setChoice(choiceItem.key)}
                      selected={choiceItem.key === choice}
                    />
                    {choice === OTHER_CHOICE_KEY &&
                      choiceItem.key === OTHER_CHOICE_KEY && (
                        <InputText
                          autoFocus
                          multiline
                          withCharCount
                          charCountSuffix={`min ${inputsMinLengths.reportBody} characters`}
                          maxLength={inputsMaxLengths.reportBody}
                          placeholder="Write a report"
                          value={comment || ''}
                          onChange={(e) => setComment(e.target.value || '')}
                        />
                      )}
                  </Fragment>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      }
      navHeaderProps={{
        size: isMinLG ? 'lg' : 'xs',
        text: 'Report',
        leftButtonProps: {
          className: styles.icon,
          iconProps: {
            type: 'fa',
            icon: ['fas', 'close'],
          },
          onClick: toggleModal,
        },
        rightButtonProps: {},
      }}
      bottomBarProps={{
        size: 'md',
        paddingType: 'small',
        withElevation: false,
        isBaseVisible: true,
        buttonCount: 2,
        primaryButtonProps: {
          size: 'md',
          text: 'Submit',
          disabled:
            !choice ||
            (choice === OTHER_CHOICE_KEY &&
              (!comment || comment.length < inputsMinLengths.reportBody)),
          onClick: onConfirm,
        },
        tertiaryButtonProps: {
          size: 'md',
          text: 'Cancel',
          onClick: toggleModal,
        },
      }}
    />
  );
};

export default ModalReport;
