/**
 * @generated SignedSource<<296f5ee9047d57e3dc0ca8a134a56f84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dealsSubscribeSubscription$variables = {};
export type dealsSubscribeSubscription$data = {
  readonly dealsSubscribe: {
    readonly deleted_at: any | null;
    readonly id: string;
    readonly other_user: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"DealControls_deal" | "DealsItem_deal">;
  } | null;
};
export type dealsSubscribeSubscription = {
  response: dealsSubscribeSubscription$data;
  variables: dealsSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "other_user",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dealsSubscribeSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Deal",
        "kind": "LinkedField",
        "name": "dealsSubscribe",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DealsItem_deal"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DealControls_deal"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dealsSubscribeSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Deal",
        "kind": "LinkedField",
        "name": "dealsSubscribe",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skill_title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillOffer",
            "kind": "LinkedField",
            "name": "skill_offer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hiring_type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaymentConnection",
            "kind": "LinkedField",
            "name": "payments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PaymentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Payment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewer_pay_role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "from",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "to",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "created_by",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "is_escrow",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approved_by_buyer_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approved_by_seller_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "refund_request_declined_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thread_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount_after_fee",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "service_fee",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created_at",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "payments(first:1)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "DealControls_payments",
            "kind": "LinkedHandle",
            "name": "payments"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "DealsItem_payments",
            "kind": "LinkedHandle",
            "name": "payments"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5dc9f0091ee2ceae0fca638c6437e94",
    "id": null,
    "metadata": {},
    "name": "dealsSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription dealsSubscribeSubscription {\n  dealsSubscribe {\n    id\n    other_user {\n      id\n    }\n    deleted_at\n    ...DealsItem_deal\n    ...DealControls_deal\n  }\n}\n\nfragment DealControls_deal on Deal {\n  id\n  skill_offer {\n    id\n    hiring_type\n  }\n  payments(first: 1) {\n    edges {\n      node {\n        id\n        status\n        viewer_pay_role\n        from {\n          id\n        }\n        to {\n          id\n        }\n        created_by {\n          id\n        }\n        is_escrow\n        approved_by_buyer_at\n        approved_by_seller_at\n        refund_request_declined_at\n        thread_id\n        amount_after_fee\n        service_fee\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DealsItem_deal on Deal {\n  id\n  skill_title\n  ...DealControls_deal\n  payments(first: 1) {\n    edges {\n      node {\n        id\n        status\n        is_escrow\n        approved_by_buyer_at\n        approved_by_seller_at\n        amount\n        created_at\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6b337e7f5fece17560e6d717b2bb5bc";

export default node;
