/* @flow */
import React from 'react';
import classNames from 'classnames';
import { ButtonFlat } from '@pluralcom/blueprint';

import styles from './ButtonFloating.module.scss';

export interface Props {
  children: React$Node;
  className?: ?string;
  size: 'xs' | 'lg' | 'sm' | 'md';
  uiType: 'secondary' | 'tertiary';
}

const ButtonFloating = ({
  className,
  children,
  size = 'xs',
  uiType = 'tertiary',
  ...rest
}: Props) => (
  <ButtonFlat
    size={size}
    uiType={uiType}
    borderType
    className={classNames([
      styles.container,
      { [styles[size]]: size },
      { [styles[uiType]]: uiType },

      className,
    ])}
    {...rest}
  >
    {children}
  </ButtonFlat>
);

export default ButtonFloating;
