/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (skillId: string, file: string) =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation UploadSkillImageMutation($input: UploadSkillImageInput!) {
        uploadSkillImage(input: $input) {
          newImageEdge {
            cursor
            node {
              id
              image {
                id
                original
                thumbnail
                medium
              }
            }
          }
          error {
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        id: skillId,
        images: [file],
      },
    };

    return commitMutation({
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });
