import React, {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { useMedia } from 'use-media';
import _ from 'lodash';
import { InputText, Form, FormGroup } from '@pluralcom/blueprint';

import { inputsMaxLengths } from '../../assets/data';

import { ReferUserMutation } from '../../graphql/mutations';

import InputSkill from '../InputSkill/InputSkill';
import FancyDialog from '../FancyDialog/FancyDialog';
import styles from './FormReferUser.module.scss';
import ReferralsInfoCard from '../../containers/Referrals/components/ReferralsInfoCard/ReferralsInfoCard';
import InputUsers from '../InputUsers/InputUsers';
import { FormReferUser_viewer$data } from './__generated__/FormReferUser_viewer.graphql';
import ModalPeopleSelector from '../ModalPeopleSelector/ModalPeopleSelector';
import { responsive } from '../../utils';

interface FormReferUserProps {
  /** viewer object */
  viewer?: FormReferUser_viewer$data;
  /** unique ID of user  */
  otherUserId?: string | null;
  /** user search public */
  userSearchPublic?: {
    /** user search public ID */
    id: string;
    /** user */
    user: {
      /** user ID */
      id: string;
    };
    /** tag */
    tag: {
      /** tag ID */
      id: string;
      /** tag title */
      title: string;
    };
  };
  /** called on form validation change */
  onValidationChange?: (var1?: boolean) => void;
  /** className */
  className?: string;
}

interface SelectedUser {
  /** selected user ID */
  id: string;
  /** selected user title */
  title: string;
  /** selected user  */
  user: {
    /** user ID */
    id: string;
    /** user avatar */
    avatar: {
      /** avatar ID */
      id: string;
      /** smallThumbnail */
      smallThumbnail: string;
    };
  };
}

interface ReferredUserTag {
  /** Referred user ID */
  id: string;
  /** Referred user email */
  email: string;
  /** Referred user phone  */
  phone: string;
  /** Referred user first name */
  first_name: string;
  /** user avatar */
  avatar: {
    /** avatar ID */
    id: string;
    /** smallThumbnail */
    smallThumbnail: string;
  };
}

export interface FromReferUserRefHandle {
  /** imperative handle for submit function */
  submit?: (event?: React.KeyboardEvent) => void;
}

/**
 * FIGMA v0.0.1
 * FormReferUser component
 */
const FormReferUser = React.forwardRef<
  FromReferUserRefHandle,
  FormReferUserProps
>(
  (
    {
      viewer: inViewer,
      otherUserId = null,
      userSearchPublic,
      onValidationChange,
      className,
    }: FormReferUserProps,
    ref,
  ) => {
    const [
      referredUserTag,
      setReferredUserTag,
    ] = useState<ReferredUserTag | null>(null);
    const [selectedSkill, setSelectedSkill] = useState<SelectedUser | null>(
      null,
    );
    const [note, setNote] = useState('');
    const [serverError, setServerError] = useState(false);
    const [showModalPeopleSelector, setShowModalPeopleSelector] = useState(
      false,
    );

    const isDesktop = useMedia({ minWidth: responsive.gridBreakpointLG });

    const viewer = useFragment(
      graphql`
        fragment FormReferUser_viewer on ViewerType
        @argumentDefinitions(
          otherUserId: { type: "String", defaultValue: "" }
          skipOwnSkills: { type: "Boolean", defaultValue: false }
        ) {
          referrals {
            id
            totalEarned
            totalEarnableReferrals
          }
          ...InputUsers_viewer
          ...InputSkill_viewer
            @arguments(
              other_user_id: $otherUserId
              skipOwnSkills: $skipOwnSkills
            )
        }
      `,
      inViewer || null,
    );

    /** Reset selected skill when user id changes */
    useEffect(() => {
      if (
        selectedSkill?.user &&
        referredUserTag?.id !== selectedSkill?.user?.id
      ) {
        setSelectedSkill(null);
      }
    }, [referredUserTag, selectedSkill]);

    useImperativeHandle(ref, () => ({
      /** submit form - call ReferUserMutation */
      submit: async () => {
        try {
          const result: any = await ReferUserMutation(
            {
              referred_user_id: referredUserTag?.id,
              referred_email: referredUserTag?.id
                ? undefined
                : referredUserTag?.email,
              referred_phone: referredUserTag?.id
                ? undefined
                : referredUserTag?.phone,
              referred_to_user_id: userSearchPublic?.user?.id,
              user_search_public_id: userSearchPublic?.id,
              skill_id: selectedSkill?.id,
              skill_title: selectedSkill?.id ? undefined : selectedSkill?.title,
              note,
            },
            {
              referrals: viewer?.referrals,
              // @ts-ignore
              referred: referredUserTag,
              // @ts-ignore
              referred_to: userSearchPublic?.user,
            },
          );
          const { error } = result.referUser;
          if (error) {
            setServerError(error?.message || error.toString());
          }
        } catch (error: any) {
          setServerError(error?.message || error.toString());
        }
      },
    }));

    /**
     * handle change of skill input
     */
    const handleChangeInputSkill = useCallback(
      (skill) => setSelectedSkill(skill),
      [],
    );

    return (
      <div className={className}>
        <Form>
          <FormGroup className={styles.container}>
            <ReferralsInfoCard uiType="base-gray" />
            <InputUsers
              inputTagsProps={{
                inputProps: {
                  onClick: () => {
                    if (!isDesktop) {
                      setShowModalPeopleSelector(true);
                    }
                  },
                },
              }}
              tagItem={referredUserTag && _.concat(referredUserTag)}
              dropdown={isDesktop}
              className={styles['form-element']}
              viewer={viewer}
              hasModal
              // passing blank function to onClickOutside to handle dropdown visibility state in click outside
              onClickOutside={() => {}}
              onChangeTags={(tagsValue) => {
                const person = tagsValue[0];
                setReferredUserTag(person);
                onValidationChange?.(
                  Boolean(person?.id || person?.email || person?.phone),
                );
              }}
            />
            <InputSkill
              className={styles['form-element']}
              viewer={viewer}
              otherUserId={referredUserTag?.id || otherUserId || ''}
              selectedSkill={selectedSkill}
              onSelectAutosuggest={handleChangeInputSkill}
              placeholder="Select skill"
              skipOwnSkills
              skillTitleToPreselect={userSearchPublic?.tag?.title}
              initialValue={userSearchPublic?.tag?.title || ''}
            />
            <InputText
              multiline
              placeholder="Why do you recommend working with them? (Optional and will show on profile)"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              maxLength={inputsMaxLengths.referralNote}
              withCharCount
              charCountSuffix="min 30 characters"
            />
          </FormGroup>
        </Form>
        {/* @todo - replace with new modals */}
        <FancyDialog
          toggle={() => setServerError(false)}
          isOpen={serverError}
          title="Oops!"
          bodyText={serverError}
          primaryBtnText="Got it!"
          primaryBtnOnClick={() => setServerError(false)}
        />
        <ModalPeopleSelector
          isOpen={showModalPeopleSelector}
          toggle={() => setShowModalPeopleSelector(false)}
          onChangeTags={(tagsValue) => {
            const person = tagsValue[0];
            setReferredUserTag(person);
            onValidationChange?.(
              Boolean(person?.id || person?.email || person?.phone),
            );
            setShowModalPeopleSelector(false);
          }}
        />
      </div>
    );
  },
);

export default FormReferUser;
