/**
 * @generated SignedSource<<55be91fb9d3da773700e7f6afa8c796c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExtendRemoteAccessTokenInput = {
  clientMutationId?: string | null;
  shortLivedFbAccessToken?: string | null;
};
export type ExtendRemoteAccessTokenMutation$variables = {
  input: ExtendRemoteAccessTokenInput;
};
export type ExtendRemoteAccessTokenMutation$data = {
  readonly extendRemoteAccessToken: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type ExtendRemoteAccessTokenMutation = {
  response: ExtendRemoteAccessTokenMutation$data;
  variables: ExtendRemoteAccessTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExtendRemoteAccessTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExtendRemoteAccessTokenPayload",
        "kind": "LinkedField",
        "name": "extendRemoteAccessToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExtendRemoteAccessTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExtendRemoteAccessTokenPayload",
        "kind": "LinkedField",
        "name": "extendRemoteAccessToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5d2f376e62a71593955318711f8e41c",
    "id": null,
    "metadata": {},
    "name": "ExtendRemoteAccessTokenMutation",
    "operationKind": "mutation",
    "text": "mutation ExtendRemoteAccessTokenMutation(\n  $input: ExtendRemoteAccessTokenInput!\n) {\n  extendRemoteAccessToken(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fde805e7c5c00c1182be7ac2d205040c";

export default node;
