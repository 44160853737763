/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './AppContentFullHeight.module.scss';

type Props = {
  children: React$Node,
  className?: ?string,
  minHeight?: ?boolean,
  navbar?: ?boolean,
  footer?: ?boolean,
  appNavigator?: ?boolean,
  noScroll?: ?boolean,
};

const AppContentFullHeight = ({
  className,
  children,
  navbar,
  footer,
  appNavigator,
  minHeight,
  noScroll,
}: Props) => (
  <div
    className={classNames([
      { [styles['min-height']]: minHeight },
      { [styles['static-height']]: !minHeight },
      { [styles['with-navbar']]: navbar },
      { [styles['with-footer']]: footer },
      { [styles['with-appnavigator']]: appNavigator },
      { [styles['no-scroll']]: noScroll },
      className,
    ])}
  >
    {children}
  </div>
);

export default AppContentFullHeight;
