/**
 * @generated SignedSource<<9dc1de33778c2d0effb17a182a7a46ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteFAQuestionFromSkillInput = {
  clientMutationId?: string | null;
  skill_id: string;
  skill_question_id: string;
};
export type DeleteQuestionMutation$variables = {
  input: DeleteFAQuestionFromSkillInput;
};
export type DeleteQuestionMutation$data = {
  readonly deleteFAQuestionFromSkill: {
    readonly deleted_skill_question_id: string | null;
    readonly error: {
      readonly code: number | null;
      readonly id: string;
      readonly message: string | null;
      readonly technical_message: string | null;
      readonly title: string | null;
    } | null;
    readonly skill: {
      readonly id: string;
      readonly questions: ReadonlyArray<{
        readonly answer: string;
        readonly id: string;
        readonly question: string;
      } | null> | null;
    } | null;
  } | null;
};
export type DeleteQuestionMutation = {
  response: DeleteQuestionMutation$data;
  variables: DeleteQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteFAQuestionFromSkillPayload",
    "kind": "LinkedField",
    "name": "deleteFAQuestionFromSkill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "technical_message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skill",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillFAQuestion",
            "kind": "LinkedField",
            "name": "questions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answer",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted_skill_question_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteQuestionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteQuestionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e56d9abdd6044575fc3620f2a101c51a",
    "id": null,
    "metadata": {},
    "name": "DeleteQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteQuestionMutation(\n  $input: DeleteFAQuestionFromSkillInput!\n) {\n  deleteFAQuestionFromSkill(input: $input) {\n    error {\n      id\n      title\n      technical_message\n      message\n      code\n    }\n    skill {\n      id\n      questions {\n        id\n        question\n        answer\n      }\n    }\n    deleted_skill_question_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c00b3f1303188e09acc6b57e114366f";

export default node;
