/* @flow */

const shareFb = ({
  href,
  quote = '',
}: {
  href: string,
  quote: string,
}): void => {
  if (window.FB) {
    window.FB.ui({
      method: 'share',
      href,
      quote,
    });
  } else {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${href}`,
      'share_facebook',
      'width=658,height=354,scrollbars=no'
    );
  }
};

/**
 * For sharing by messenger facebook app
 *  - https://developers.facebook.com/docs/sharing/messenger/web
 * */
const shareMessenger = ({ href }: { href: string }): void => {
  if (window.FB) {
    window.FB.ui({
      method: 'send',
      link: href,
    });
  } else {
    // FALLBACK if window.FB isn't avail
    window.open(
      `fb-messenger://share?link=${encodeURIComponent(
        href
      )}&app_id=${encodeURIComponent(process.env.REACT_APP_FB_APP_ID)}`
    );
  }
};

const shareTwitter = ({ text }: { text: string }): void => {
  window.open(
    `https://twitter.com/intent/tweet?text=${text}`,
    'share_twitter',
    'width=550, height=350, scrollbars=no'
  );
};

export default {
  shareFb,
  shareTwitter,
  shareMessenger,
};
