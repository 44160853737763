import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { createPaginationContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import sortBy from 'lodash/sortBy';

import type { RelayPaginationProp } from 'react-relay';
import {
  FlatList,
  FlatListRefHandle,
  EmptyPlaceholder,
} from '@pluralcom/blueprint';

import CommunityActivitiesListItem from './components/CommunityActivitiesListItem/CommunityActivitiesListItem';

import { CommunityActivitiesList_data$data } from './__generated__/CommunityActivitiesList_data.graphql';

import styles from './CommunityActivitiesList.module.scss';
import { logger } from '../../utils';

interface CommunityActivitiesListProps {
  /** fragmented data */
  data: CommunityActivitiesList_data$data;
  /** relay */
  relay: RelayPaginationProp;
}

const isItemLoaded: Array<boolean> = [true];
const PER_PAGE = 10;
const LOAD_MORE_COUNT = PER_PAGE / 2;

/**
 * - Figma v0.0.1
 * - Coummunity Activity list
 */
const CommunityActivitiesList = ({
  data,
  relay,
}: CommunityActivitiesListProps) => {
  const { communityActivities } = data || {};
  const _flatListRef = React.useRef<FlatListRefHandle>(null);

  const { edges = [] } = useMemo(
    () =>
      communityActivities || {
        edges: [],
      },
    [communityActivities],
  );

  /**
   * items - sorted list of activities
   * @type {Array}
   * @return {Array}
   */
  const items = useMemo(
    () =>
      sortBy(
        edges?.map((el) => el?.node || {}) || [],
        // @ts-ignore-next-line
        ({ created_at }) => -1 * new Date(created_at).getTime(),
      ),
    [edges],
  );

  /**
   * isLoadingMore - flag to show loading spinner for pagination
   */
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const _firstItemId = data?.[0]?.id;

  /** scroll to top when first activity id changes - case: new message is sent or received */
  useEffect(() => {
    _flatListRef?.current?.scrollToItem?.({ index: 0 });
  }, [_firstItemId]);

  /**
   * - Load more items
   * - Will be called on scroll
   * - Will load more items if there are more items to load
   */
  const loadMore = (e) => {
    if (relay.hasMore() && !relay.isLoading()) {
      setIsLoadingMore(true);
      // This will prevent the same index from being loaded multiple times
      isItemLoaded[e.loadIndex] = true;
      relay.loadMore(PER_PAGE, (error: any) => {
        if (error) {
          logger.error(
            'Error in CommunityActivitiesListPaginationQuery:',
            error && error?.message,
          );
          isItemLoaded[e.loadIndex] = false;
        }
        setIsLoadingMore(false);
      });
    }
  };

  /**
   * Render list item
   */
  const _renderListItem = useCallback(
    ({ data: _data, index, key, style, measureRef }: any) => {
      const activity: any = items?.[index];

      return activity ? (
        <div key={key} style={style} ref={measureRef}>
          <CommunityActivitiesListItem
            data={activity}
            className={styles.item}
          />
        </div>
      ) : null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  return (
    <div className={classNames(styles.container)}>
      <div className={styles['list-container']}>
        {items.length === 0 && (
          <EmptyPlaceholder placeholderText="No Results" />
        )}
        <FlatList
          useVirtualProps={{
            // Provide the loaded state of a batch items to the callback for telling the hook
            // whether the `loadMore` should be trigged or not
            isItemLoaded: (index) => isItemLoaded[index],
          }}
          ref={_flatListRef}
          className={styles.list}
          loadMore={loadMore}
          isLoadingMore={isLoadingMore}
          data={items}
          renderItem={_renderListItem}
          loadMoreThresholdCount={LOAD_MORE_COUNT}
        />
      </div>
    </div>
  );
};

export { CommunityActivitiesList as PureCommunityActivities };

export default createPaginationContainer(
  CommunityActivitiesList,
  {
    data: graphql`
      fragment CommunityActivitiesList_data on ViewerType
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
      ) {
        communityActivities(first: $count, after: $cursor)
          @connection(key: "CommunityActivities_communityActivities") {
          edges {
            node {
              id
              type
              created_at
              ...CommunityActivitiesListItem_data
            }
          }
        }
      }
    `,
  },
  {
    getVariables(props, { count, cursor }, ...rest) {
      return {
        ...rest,
        count,
        cursor,
      };
    },
    query: graphql`
      query CommunityActivitiesListPaginationQuery(
        $count: Int!
        $cursor: String
      ) {
        viewer {
          ...CommunityActivitiesList_data
            @arguments(count: $count, cursor: $cursor)
        }
      }
    `,
  },
);
