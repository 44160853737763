import React from 'react';
import classNames from 'classnames';
import { BottomBar, Modal, NavHeader } from '@pluralcom/blueprint';
import { useMedia } from 'use-media';

import styles from './AuthModal.module.scss';
import { responsive } from '../../../../utils';

interface AuthModalProps extends React.ComponentProps<typeof Modal> {
  /** react node */
  children?: React.ReactNode;
  /** Custom css class name for card */
  className?: string;
  /** Custom css class name for modal body */
  modalBodyClassName?: string;
  /** The status of the Modal, either open or close */
  isOpen?: boolean;
  /** When notDismissible is set to static, the Modal will not close when clicking outside it */
  notDismissible?: boolean;
  /** Function to toggle Modal visibility */
  toggle?: Function | any;
  /** NavHeaderProps to set Nav Header properties */
  navHeaderProps?: React.ComponentProps<typeof NavHeader>;
  /** BottomBarProps to set Bottom Bar properties */
  bottomBarProps?: React.ComponentProps<typeof BottomBar>;
  /**
   * Any content that has to be added to the ModalForm body
   * Note: The modal body uses Container fluid with 40px/ 2.5rem padding
   */
  modalBody?: any;
  /** If true, the Modal will have a NavHeader */
  withNavHeader?: boolean;
  /** If true, the Modal will have a BottomBar */
  withBottomBar?: boolean;
}

/**
 * Figma v0.0.1
 * AuthModal
 * */

const AuthModal = ({
  children,
  isOpen,
  toggle,
  className,
  navHeaderProps,
  bottomBarProps,
  withNavHeader,
  withBottomBar,
  modalProps,
  modalBodyClassName,
  ...rest
}: AuthModalProps) => {
  const isDesktop = useMedia({ minWidth: responsive.gridBreakpointLG });
  const _onToggle = () => {
    if (toggle) {
      toggle();
    }
  };

  const _handleCloseClick = (e) => {
    e.preventDefault();
    _onToggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={_onToggle}
      borderType="border-radius-type-4"
      className={classNames([
        styles.container,
        {
          [styles['container-mobile']]: !isDesktop,
        },
        className,
      ])}
      modalProps={{
        ...modalProps,
        contentClassName: classNames(
          [styles['modal-content']],
          modalProps?.contentClassName,
        ),
        className: classNames([styles['modal-dialog']], modalProps?.className),
      }}
      {...rest}
    >
      {withNavHeader && (
        <NavHeader
          className={classNames([styles['nav-header']])}
          isBaseVisible
          withElevation={!isDesktop}
          size={isDesktop ? 'lg' : 'xs'}
          withBackButton
          rightButtonProps={{
            onClick: _handleCloseClick,
            ...navHeaderProps?.rightButtonProps,
          }}
          text={navHeaderProps?.text}
          {...navHeaderProps}
        />
      )}
      <div className={classNames([styles['modal-body'], modalBodyClassName])}>
        {children}
      </div>

      {withBottomBar && (
        <BottomBar
          className={styles['bottom-bar']}
          buttonCount={2}
          size="md"
          paddingType="small"
          isBaseVisible
          withElevation
          {...bottomBarProps}
        />
      )}
    </Modal>
  );
};
export default AuthModal;
