/**
 * @generated SignedSource<<853967527a20f352c385e277f067c02a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HiringTypeEnum = "custom" | "customize" | "direct" | "flat_rate" | "full_time" | "hourly" | "intern" | "intro_call" | "part_time" | "video_call" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SkillCardMiniHeader_skill$data = {
  readonly hiring_types: ReadonlyArray<HiringTypeEnum | null> | null;
  readonly id: string;
  readonly is_draft?: boolean | null;
  readonly is_ghost?: boolean | null;
  readonly is_hobby: boolean | null;
  readonly likes: {
    readonly id: string;
    readonly users?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar: {
            readonly id: string;
            readonly smallThumbnail: string | null;
          } | null;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
          readonly profile_color: string | null;
        } | null;
      } | null> | null;
      readonly totalCount: number | null;
    } | null;
    readonly viewerHasLiked: boolean | null;
  } | null;
  readonly published?: boolean | null;
  readonly title: string | null;
  readonly user?: {
    readonly avatar: {
      readonly id: string;
      readonly smallThumbnail: string | null;
    } | null;
    readonly first_name: string | null;
    readonly id: string;
    readonly last_name: string | null;
    readonly name: string | null;
    readonly profile_color: string | null;
    readonly recommendations: {
      readonly id: string;
      readonly received: {
        readonly totalCount: number | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "SkillCardMiniHeader_skill";
};
export type SkillCardMiniHeader_skill$key = {
  readonly " $data"?: SkillCardMiniHeader_skill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCardMiniHeader_skill">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallThumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSkillCardLean"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSkillCardPreview"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "likes",
          "users"
        ]
      }
    ]
  },
  "name": "SkillCardMiniHeader_skill",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_hobby",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hiring_types",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillLikes",
      "kind": "LinkedField",
      "name": "likes",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasLiked",
          "storageKey": null
        },
        {
          "condition": "isSkillCardPreview",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "users",
              "args": null,
              "concreteType": "UserConnection",
              "kind": "LinkedField",
              "name": "__SkillCardMiniHeader_likes_users_connection",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isSkillCardLean",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserRecommendations",
              "kind": "LinkedField",
              "name": "recommendations",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserConnection",
                  "kind": "LinkedField",
                  "name": "received",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isSkillCardPreview",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_draft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_ghost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "published",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Skill",
  "abstractKey": null
};
})();

(node as any).hash = "ecd5ed502edb0de84179d4445da8610b";

export default node;
