/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation RemoveCollaboratorMutation($input: RemoveCollaboratorInput!) {
    removeCollaborator(input: $input) {
      collaboratorId
      otherCollaboratorId
      error {
        message
      }
    }
  }
`;

const removeCollaboratorMutation = (
  {
    collaborator_id,
  }: {
    collaborator_id?: string,
  },
  {
    skill_id,
    other_skill_id,
  }: {
    skill_id?: string,
    other_skill_id?: string,
  },
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id: collaborator_id,
      },
    };

    const optimisticResponse = {
      removeCollaborator: {
        collaboratorId: collaborator_id,
        otherCollaboratorId: null, // we can't find it here
        error: null,
      },
    };

    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: other_skill_id,
        connectionKeys: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
          },
        ],
        deletedIDFieldName: 'collaboratorId',
        pathToConnection: ['skill', 'collaborators'],
      },
      // other_skill
      {
        type: 'RANGE_DELETE',
        parentID: skill_id,
        connectionKeys: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
          },
        ],
        deletedIDFieldName: 'otherCollaboratorId',
        pathToConnection: ['skill', 'collaborators'],
      },
      // In Case deleting on other user profile
      {
        type: 'RANGE_DELETE',
        parentID: other_skill_id,
        connectionKeys: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
          },
        ],
        deletedIDFieldName: 'otherCollaboratorId',
        pathToConnection: ['skill', 'collaborators'],
      },
      {
        type: 'RANGE_DELETE',
        parentID: skill_id,
        connectionKeys: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
          },
        ],
        deletedIDFieldName: 'collaboratorId',
        pathToConnection: ['skill', 'collaborators'],
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default removeCollaboratorMutation;
