/**
 * @generated SignedSource<<20bcff65976b86b1e2adcb27b2667586>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditActivityInput = {
  archived?: boolean | null;
  clientMutationId?: string | null;
  id: string;
  read?: boolean | null;
};
export type MarkAsReadMutation$variables = {
  input: EditActivityInput;
};
export type MarkAsReadMutation$data = {
  readonly editActivity: {
    readonly activity: {
      readonly id: string;
      readonly read: boolean | null;
      readonly seen_at: string | null;
    } | null;
  } | null;
};
export type MarkAsReadMutation = {
  response: MarkAsReadMutation$data;
  variables: MarkAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditActivityPayload",
    "kind": "LinkedField",
    "name": "editActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "read",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seen_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1f868ee198eb7a45f7f2b075eb1d50d",
    "id": null,
    "metadata": {},
    "name": "MarkAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkAsReadMutation(\n  $input: EditActivityInput!\n) {\n  editActivity(input: $input) {\n    activity {\n      id\n      read\n      seen_at\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fa42d710d5a7cee5d7913762b61343b";

export default node;
