/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';
import { localStorageHelpers } from '../../../utils';

const mutation = graphql`
  mutation EditProfileLocationMutation($input: EditProfileInput!) {
    editProfile(input: $input) {
      profile {
        id
        location {
          id
          latitude
          longitude
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export default ({
  id,
  location,
}: {
  id: string,
  location?: {
    longitude: number,
    latitude: number,
  },
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        location,
      },
    };

    const optimisticResponse = {
      editProfile: {
        error: null,
        profile: {
          id,
          location,
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: (data, errors) => {
        if (data?.editProfile?.profile) {
          localStorageHelpers.setUser((user) => ({
            ...user,
            ...data.editProfile.profile,
          }));
        }
        resolve(data, errors);
      },
      onError: reject,
    });
  });
