import React from 'react';
import classNames from 'classnames';
import { Anchor, Card, Text } from '@pluralcom/blueprint';

import styles from './ReferralsInfoCard.module.scss';

interface ReferralsInfoCardProps
  extends Omit<React.ComponentProps<typeof Card>, 'isSkeleton'> {
  /** custom classname */
  className?: string;
}

/**
 * - Figma v0.0.1
 * - ReferralsInfoCard component is used to display information about referrals and how to earn money
 */
const ReferralsInfoCard = ({
  className,
  uiType ="border-elevation",
  borderRadiusType = 'border-radius-type-4',
  ...rest
}: ReferralsInfoCardProps) => (
  <Card
    className={classNames([styles.container, className])}
    uiType={uiType}
    borderRadiusType={borderRadiusType}
    {...rest}
  >
    <Text
      fontSizeType="t3"
      stickToBreakpoint="xs"
      element="span"
      className={styles.text}
    >
      🌀 Refer and Earn
    </Text>
    <Text fontSizeType="t7" element="span" className={styles.text}>
      Refer your friends to Plural to get them new clients. You can earn up to
      $50 per referral.{' '}
      <Anchor
        to="/info/terms-of-use"
        target="_blank"
        className={classNames([styles.anchor])}
      >
        <Text
          fontSizeType="t7"
          element="span"
          fontWeight="bold"
          className={styles.text}
        >
          Terms and conditions
        </Text>
      </Anchor>
    </Text>
  </Card>
);

export default ReferralsInfoCard;
