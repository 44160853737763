/* @flow */
import React from 'react';
import get from 'lodash/get';
import { TouchableOpacity } from '@pluralcom/blueprint';

import TilePersonItemLegacy from '../../../../TilePersonItemLegacy/TilePersonItemLegacy';
/** need to be here to allow redefine react-popper-tooltip style */
import 'react-popper-tooltip/dist/styles.css';

import styles from './UserInfoWindow.module.scss';

type Props = {
  user: any,
  userLocation: any,
};

const UserInfoWindow = ({ user, userLocation }: Props) => (
  <TouchableOpacity
    className={styles.container}
    to={`/${get(user, 'username', '')}`}
    target="blank"
  >
    <TilePersonItemLegacy
      noXBtn
      user={user}
      className={styles.content}
      userLocation={userLocation}
      mp_location="Search_MapView"
    />
  </TouchableOpacity>
);

export default UserInfoWindow;
