/**
 * @generated SignedSource<<1b9aa39d864691320ca3bc3c3127b2e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMessageReactionType = "ADD" | "REMOVE" | "%future added value";
export type UpdateMessageReactionInput = {
  clientMutationId?: string | null;
  messageId: string;
  type?: UpdateMessageReactionType | null;
  value: string;
};
export type UpdateMessageReactionMutation$variables = {
  input: UpdateMessageReactionInput;
};
export type UpdateMessageReactionMutation$data = {
  readonly updateMessageReaction: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newMessageEdge: {
      readonly node: {
        readonly id: string;
        readonly reactions: ReadonlyArray<{
          readonly users: ReadonlyArray<{
            readonly id: string;
          } | null> | null;
          readonly value: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type UpdateMessageReactionMutation = {
  response: UpdateMessageReactionMutation$data;
  variables: UpdateMessageReactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MessageEdge",
  "kind": "LinkedField",
  "name": "newMessageEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Message",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageReaction",
          "kind": "LinkedField",
          "name": "reactions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "users",
              "plural": true,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMessageReactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessageReactionPayload",
        "kind": "LinkedField",
        "name": "updateMessageReaction",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMessageReactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessageReactionPayload",
        "kind": "LinkedField",
        "name": "updateMessageReaction",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47fef49f2d49116573a7636e8b9c6125",
    "id": null,
    "metadata": {},
    "name": "UpdateMessageReactionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMessageReactionMutation(\n  $input: UpdateMessageReactionInput!\n) {\n  updateMessageReaction(input: $input) {\n    newMessageEdge {\n      node {\n        id\n        reactions {\n          value\n          users {\n            id\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c3791f5d3405d9ed65ea118635ca043";

export default node;
