/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import pick from 'lodash/pick';

import { base64Helpers } from '@pluralcom/plural-js-utils';
import { commitMutation } from '../..';
import type { LocationType } from '../../../../flow';

const mutation = graphql`
  mutation RecommendUserMutation($input: RecommendUserInput!) {
    recommendUser(input: $input) {
      error {
        message
      }
      viewerEdge {
        node {
          id
          first_name
          last_name
          username
          profile_color
          avatar {
            id
            smallThumbnail
          }
        }
      }
      userEdge {
        node {
          id
          first_name
          last_name
          username
          subtitle
          profile_color
          avatar {
            id
            smallThumbnail
            thumbnail
          }
          recommendations {
            id
            received {
              totalCount
            }
          }
        }
      }
      user {
        id
        viewerHasRecommended
        recommendations {
          id
          received {
            totalCount
          }
        }
      }
      deletedViewerId
      deletedUserId
    }
  }
`;

/* used to update totalCount field since Optimistic update alone doesn't work but works on web */
const sharedUpdater = (
  store: RelayModernStore,
  recommendationsId: String,
  toAdd: number,
) => {
  if (!recommendationsId) {
    return;
  }
  /** Fetch user.recommendations */
  const recommendationsTopLevel = store.get(recommendationsId);
  [
    'RecommendedBy_UserCard_recommendations_received',
    'Recommendations_received',
  ].forEach((connectionName) => {
    /** Fetch user.recommendations.received */
    const connection = ConnectionHandler.getConnection(
      recommendationsTopLevel,
      connectionName,
      {},
    );
    /** Increment/Decrement total count field */
    if (connection) {
      connection.setValue(
        connection.getValue('totalCount') + toAdd,
        'totalCount',
      );
    }
  });
};

export default (
  {
    user_id,
    email,
    value = true,
    skill_title = '',
    location = {},
  }: {
    user_id?: string,
    email?: string,
    value?: boolean,
    skill_title?: string,
    location: LocationType,
  },
  {
    ownProfile = {},
    profile = {},
  }: {
    ownProfile?: {
      id: string,
      first_name: string,
      last_name: string,
      avatar: ?{
        id: string,
        smallThumbnail: ?string,
      },
    },
    profile?: {
      recommendations: {
        id: string,
        totalCount: number,
      },
    },
  } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        user_id,
        email,
        value,
        skill_title,
        location: pick(location, ['latitude', 'longitude']),
      },
    };

    const { recommendations } = profile;

    const optimisticRecommendations =
      !email &&
      (recommendations || {
        id: base64Helpers.base64(
          `UserRecommendations:${base64Helpers.unbase64(
            user_id || profile.id,
          )}`,
        ),
        received: {
          totalCount: 0,
        },
      });

    const optimisticResponse = email
      ? {
          recommendUser: {
            error: null,
            user: null,
            viewerEdge: null,
            deletedViewerId: null,
            userEdge: null,
            deletedUserId: null,
          },
        }
      : {
          recommendUser: {
            error: null,
            user: {
              id: user_id,
              viewerHasRecommended: value,
              recommendations: {
                id: optimisticRecommendations?.id,
                received: {
                  totalCount: value
                    ? optimisticRecommendations?.received?.totalCount + 1
                    : optimisticRecommendations?.received?.totalCount - 1 || 0,
                },
              },
            },
            viewerEdge: value
              ? {
                  node: {
                    ...pick(ownProfile, [
                      'id',
                      'first_name',
                      'last_name',
                      'profile_color',
                      'avatar',
                      'recommendations',
                    ]),
                  },
                }
              : null,
            userEdge: value
              ? {
                  node: {
                    ...pick(
                      {
                        ...profile,
                        ...(profile?.recommendations?.received
                          ? {
                              recommendations: {
                                ...profile.recommendations,
                                received: {
                                  ...profile.recommendations.received,
                                  totalCount:
                                    profile.recommendations.received
                                      .totalCount + 1,
                                },
                              },
                            }
                          : {}),
                      },
                      [
                        'id',
                        'first_name',
                        'last_name',
                        'subtitle',
                        'profile_color',
                        'avatar',
                        'recommendations.id',
                        'recommendations.received.totalCount',
                      ],
                    ),
                  },
                }
              : null,
            deletedViewerId: value ? null : ownProfile?.id,
            deletedUserId: value ? null : user_id,
          },
        };

    const configs = !email
      ? [
          value
            ? {
                type: 'RANGE_ADD',
                parentID: optimisticRecommendations?.id,
                connectionInfo: [
                  {
                    key: 'RecommendedBy_UserCard_recommendations_received',
                    rangeBehavior: 'prepend',
                  },
                ],
                edgeName: 'viewerEdge',
              }
            : {
                type: 'RANGE_DELETE',
                parentID: optimisticRecommendations?.id,
                connectionKeys: [
                  {
                    key: 'RecommendedBy_UserCard_recommendations_received',
                  },
                ],
                deletedIDFieldName: 'deletedViewerId',
                pathToConnection: ['recommendations', 'received'],
              },
          // eslint-disable-next-line no-nested-ternary
          ownProfile.recommendations
            ? value
              ? {
                  type: 'RANGE_ADD',
                  parentID: ownProfile.recommendations.id,
                  connectionInfo: [
                    {
                      key: 'RecommendedSection_recommendations_given',
                      rangeBehavior: 'prepend',
                    },
                  ],
                  edgeName: 'userEdge',
                }
              : {
                  type: 'RANGE_DELETE',
                  parentID: ownProfile.recommendations.id,
                  connectionKeys: [
                    {
                      key: 'RecommendedSection_recommendations_given',
                    },
                  ],
                  deletedIDFieldName: 'deletedUserId',
                  pathToConnection: ['recommendations', 'given'],
                }
            : null,
        ].filter(Boolean)
      : null;

    commitMutation({
      mutation,
      variables,
      /* Due to Relay not auto updating connection.totalCount, we use a combination of optimisticResponse and optimisticUpdater */
      optimisticResponse,
      optimisticUpdater: (store) => {
        sharedUpdater(store, recommendations?.id, value ? 1 : -1);
      },
      updater: (store) => {
        sharedUpdater(store, recommendations?.id, value ? 1 : -1);
      },
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
