/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation RemoveLanguageMutation($input: RemoveLanguageInput!) {
    removeLanguage(input: $input) {
      user {
        languages {
          edges {
            node {
              id
              code
              name
              native_name
            }
          }
        }
      }
      deletedLanguageId
      error {
        message
      }
    }
  }
`;

export default (
  { code, id }: { code: string },
  userId: string,
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        language_code: code,
        id,
      },
    };
    const configs = [
      {
        type: 'RANGE_DELETE',
        parentID: userId,
        connectionKeys: [
          {
            key: 'UserMoreInfo_languages',
          },
          {
            key: 'EditProfile_languages',
          },
        ],
        deletedIDFieldName: 'deletedLanguageId',
        pathToConnection: ['profile', 'languages'],
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });
