/* @flow */
import io from 'socket.io-client';
import { pluralUrls } from '@pluralcom/plural-js-utils';

import Auth from '../Auth/Auth';
import { sentryHelpers, logger, sessionStorageHelpers } from '../../utils';

import { authMiddleware, skipMiddleware } from './middlewares';

/**
 * Vision for the Socket
 * One method: getData(ACTION_TYPE)
 * It returns whatever data that ACTION_TYPE is setup to
 * return on the server.
 * E.g if we wanted to setup - notifications - It'd return
 * an array of notifications of action type: 'notifications', this action type
 * would be setup in the below switch case to do something with that
 * data
 */
class Socket {
  constructor() {
    this.callbacks = [];
    this.socket = null;
    /*
      No reconnection logic needed because Socket.io automatically tries to reconnect
      infinitely until a connection is established
    */
    this.connectToServer();
  }

  /** Connects the socket to the server */
  connectToServer = ({ force }: { force: boolean } = { force: false }) => {
    if (
      /** Don't connect if not Authenticated yet */
      !Auth.isAuthenticated() ||
      /** Don't connect if already connected */
      (this.socket && this.socket.connected && !force) ||
      /** Don't connect if in e2e */
      process.env.REACT_APP_IS_E2E
    ) {
      return;
    }
    if (this.socket) {
      this.close();
    }
    /*
      No need to pass any auth info, as cookies stored in the browser get
      sent automatically with the initial handshake HTTP 'upgrade' request
      and the auth middleware on the server-side prevents establishing the connection
      in case of unauthorized auth credentials
    */
    this.socket = io(pluralUrls.getBackendUrl(), {
      path: '/socket',
      transports: ['websocket'],
    });
    this.socket.on('message', this._onMessage);
    this.socket.on('error', this._onError);
    sessionStorageHelpers.setSocketConnId(this.socket.id);
    // @todo @test @remove
    window.TEST_SOCKET = this.socket;
  };

  /**
   * @param {object} data will contain a message object from the server. Example object:
   * {
   *    action: '',
   *    type: '',
   *    payload: {
   *      ...messageData...
   *    }
   * }
   */
  _onMessage = async (data) => {
    if (!data) {
      return;
    }
    logger.debug('Socket: Received message: ', { data, id: this.socket.id });
    sessionStorageHelpers.setSocketConnId(this.socket.id);

    await authMiddleware({
      data,
      connect: () => this.connectToServer({ force: true }),
      close: this.close,
    });

    const shouldProceed = skipMiddleware({ data, socket: this.socket });
    if (!shouldProceed) {
      return;
    }

    this.callbacks.forEach(({ cb, selector }) => {
      if (selector(data)) {
        cb(data.payload);
      }
    });
  };

  _onError = (err) => {
    logger.error('Socket error: ', err);
    sentryHelpers.addBreadcrumb({
      level: 'error',
      message: `Socket error: ${err}`,
    });
    sentryHelpers.captureException(err);
  };

  /** Sends a socket message */
  send = (data) => {
    if (this.socket) {
      this.socket.emit('message', data);
    }
  };

  /** Closes the socket */
  close = () => {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.close();
      this.socket = null;
    }
  };

  /** Adds a socket callback listener */
  addCallback = (cb) => {
    this.callbacks.push(cb);
  };

  /** Removes a socket callback listener */
  removeCallback = (id) => {
    this.callbacks = this.callbacks.filter((cb) => cb.id !== id);
  };
}

export default new Socket();
