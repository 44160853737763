/**
 * @generated SignedSource<<83fbbc00e9cd426f8d1845200ed7b2a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditUserSearchPublicInput = {
  clientMutationId?: string | null;
  id?: string | null;
  is_public: boolean;
  search_term?: string | null;
};
export type EditUserSearchPublicMutation$variables = {
  input: EditUserSearchPublicInput;
};
export type EditUserSearchPublicMutation$data = {
  readonly editUserSearchPublic: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly userSearchPublicEdge: {
      readonly node: {
        readonly id: string;
        readonly is_public: boolean | null;
        readonly location: {
          readonly id: string;
          readonly latitude: number;
          readonly longitude: number;
        } | null;
        readonly tag: {
          readonly id: string;
          readonly title: string | null;
        } | null;
        readonly user: {
          readonly avatar: {
            readonly id: string;
            readonly thumbnail: string | null;
          } | null;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
          readonly profile_color: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type EditUserSearchPublicMutation = {
  response: EditUserSearchPublicMutation$data;
  variables: EditUserSearchPublicMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserSearchPublicEdge",
  "kind": "LinkedField",
  "name": "userSearchPublicEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSearchPublic",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tag",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "is_public",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "first_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last_name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profile_color",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditUserSearchPublicMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditUserSearchPublicPayload",
        "kind": "LinkedField",
        "name": "editUserSearchPublic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditUserSearchPublicMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditUserSearchPublicPayload",
        "kind": "LinkedField",
        "name": "editUserSearchPublic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26e83cabe795bee265d42770cce78217",
    "id": null,
    "metadata": {},
    "name": "EditUserSearchPublicMutation",
    "operationKind": "mutation",
    "text": "mutation EditUserSearchPublicMutation(\n  $input: EditUserSearchPublicInput!\n) {\n  editUserSearchPublic(input: $input) {\n    userSearchPublicEdge {\n      node {\n        id\n        tag {\n          id\n          title\n        }\n        location {\n          id\n          latitude\n          longitude\n        }\n        is_public\n        user {\n          id\n          first_name\n          last_name\n          profile_color\n          avatar {\n            id\n            thumbnail\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e38f410803dae8afc4e7f50a9d19742";

export default node;
