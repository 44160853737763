import React from 'react';
import { COLOR_BRAND_BLUE } from '@pluralcom/blueprint/dist/styles/js/index';

import styles from './CustomTextListItem.module.scss';
import ListItemComplex from '../../../ListItemComplex/ListItemComplex';

interface CustomTextListItemProps {
  /** EMail or Phone string */
  emailOrPhone: string;
  /** custom CSS */
  className?: string;
}

/**
 *
 * FIGMA v0.0.1
 *  CustomTextListItem component is used to render a list item with an email or phone number.
 */
const CustomTextListItem = ({
  className,
  emailOrPhone,
  ...rest
}: CustomTextListItemProps) => (
  <div className={styles.container}>
    <ListItemComplex
      buttonLinkProps={{
        className: styles['button-link'],
      }}
      title={`Use ${emailOrPhone}`}
      avatarProps={{
        icon: { type: 'fa', icon: ['fas', 'plus'] },
        size: 'md',
        color: COLOR_BRAND_BLUE,
      }}
      titleClassName={styles.text}
      {...rest}
    />
  </div>
);

export default CustomTextListItem;
