import React, { useMemo } from 'react';

import { Card, Tag, Text } from '@pluralcom/blueprint';
import offersHelpers from '@pluralcom/plural-js-utils/lib/offers/offersHelpers';
import dateHelpers from '@pluralcom/plural-js-utils/lib/dateHelpers/dateHelpers';

// components
import TagHiringType from '../../../../../../../TagHiringType/TagHiringType';

// styles
import styles from './ModalViewOffersItem.module.scss';

interface ModalViewOffersItemProps {
  /** offer */
  offer: {
    /** hiring type */
    hiring_type: string | null;
    /** price */
    price: number | null;
    /** service duration */
    service_duration: number | null;
    /** description */
    description: string | null;
    /** title */
    title: string | null;
  } | null;
}

// Price is visible for the following hiring types
// - Intro Call
// - Video Call
// - Flat Rate
// - Hourly
const IS_PRICE_VISIBLE = (hiring_type: string = '') =>
  [
    offersHelpers.HIRING_TYPES.INTRO_CALL,
    offersHelpers.HIRING_TYPES.VIDEO_CALL,
    offersHelpers.HIRING_TYPES.FLAT_RATE,
    offersHelpers.HIRING_TYPES.HOURLY,
  ].includes(hiring_type);

// Duration is visible for the following hiring types
// - Intro Call
// - Video Call
// - Flat Rate
const IS_DURATION_VISIBLE = (hiring_type: string = '') =>
  [
    offersHelpers.HIRING_TYPES.INTRO_CALL,
    offersHelpers.HIRING_TYPES.VIDEO_CALL,
    offersHelpers.HIRING_TYPES.FLAT_RATE,
  ].includes(hiring_type);

/**
 * - Figma v0.0.1
 * - ModalViewOffersItem component
 * - Renders the content of the offer item in the modal view offer
 * @see ModalViewOffers
 */
const ModalViewOffersItem = ({ offer }: ModalViewOffersItemProps) => {
  const {
    hiring_type,
    price,
    service_duration,
    description: inDescription,
    title: inTitle,
  } = offer || {};

  // convert minutes to duration string
  // i.e '60' to 1 hour
  // i.e '15' to 15 minutes
  const duration: string = dateHelpers.mToDurationString(service_duration || 0);

  // Price
  let _price: string = price ? `$${price}` : '';

  // Description based on hiring type
  const description = useMemo(() => {
    // for direct payment we have a fixed description
    if (hiring_type === offersHelpers.HIRING_TYPES.DIRECT) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.DIRECT
      ].subtitle;
    }

    // for intro call we have a fixed description
    if (hiring_type === offersHelpers.HIRING_TYPES.INTRO_CALL) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.INTRO_CALL
      ].description;
    }

    // for customize deals we have a fixed description
    if (hiring_type === offersHelpers.HIRING_TYPES.CUSTOMIZE) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.CUSTOMIZE
      ].description;
    }

    // for custom deals we have a fixed description
    if (hiring_type === offersHelpers.HIRING_TYPES.CUSTOM) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.CUSTOM
      ].description;
    }

    // forremaining hiring types we use the description
    return inDescription || '';
  }, [hiring_type, inDescription]);

  // Description based on hiring type
  const title = useMemo(() => {
    // for direct payment we have a fixed title
    if (hiring_type === offersHelpers.HIRING_TYPES.DIRECT) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.DIRECT
      ].title;
    }

    // for intro call we have a fixed title
    if (hiring_type === offersHelpers.HIRING_TYPES.INTRO_CALL) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.INTRO_CALL
      ].tagTitle;
    }

    // for customize deals we have a fixed title
    if (hiring_type === offersHelpers.HIRING_TYPES.CUSTOMIZE) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.CUSTOMIZE
      ].title;
    }

    // for custom deals we have a fixed title
    if (hiring_type === offersHelpers.HIRING_TYPES.CUSTOM) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.CUSTOM
      ].title;
    }

    // for Intern deals we have a fixed title
    if (hiring_type === offersHelpers.HIRING_TYPES.INTERN) {
      return offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[
        offersHelpers.HIRING_TYPES.INTERN
      ].title;
    }

    // forremaining hiring types we use the description
    return inTitle || '';
  }, [hiring_type, inTitle]);

  /**
   * - For `INTRO` hiring type, we are setting the title and description
   */
  if (hiring_type === offersHelpers.HIRING_TYPES.INTRO_CALL) {
    _price = 'Free';
  }

  /**
   * - For `HOURLY` hiring type, we are setting the price
   * - with `per hour` suffix
   */
  if (hiring_type === offersHelpers.HIRING_TYPES.HOURLY) {
    _price = `$${price} per hour`;
  }

  return (
    <Card
      className={styles.container}
      borderRadiusType="border-radius-type-4"
      uiType="border-elevation"
    >
      <div className={styles['top-container']}>
        <TagHiringType size="md" type={hiring_type || ''} />

        <div className={styles['price-duration-container']}>
          {_price && IS_PRICE_VISIBLE(hiring_type || '') ? (
            <Tag size="sm">{_price}</Tag>
          ) : null}

          {service_duration && IS_DURATION_VISIBLE(hiring_type || '') ? (
            <Tag size="sm">{duration}</Tag>
          ) : null}
        </div>
      </div>

      <Text fontSizeType="t4" stickToBreakpoint="lg" className={styles.text}>
        {title}
      </Text>

      <Text fontSizeType="t7" className={styles.text}>
        {description}
      </Text>
    </Card>
  );
};

export default ModalViewOffersItem;
