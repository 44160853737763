/* @flow */
import React from 'react';
import { isFunction } from 'lodash';

import alertMessages from '../../assets/data/alertMessages';

import { mixpanelHelpers } from '../../utils';

import { Beforeunload, NavigationPromptFancyDialog } from './components';

type Props = {
  when: boolean,
  nativeWhen?: boolean,
  nativeUnloadPrompt?: (Event) => ?(string | boolean),
  navPromptProps?: ?Object,
  children?: ?React$Node,
  analyticsEvent?: { name: string, properties?: ?Object },
  disableNative?: ?boolean,
  disableNav?: ?boolean,
};

const _parseChildren = ({
  children,
  analyticsEvent,
}: {
  children?: ?React$Node,
  analyticsEvent?: { name: string, properties?: ?Object },
}) => {
  if (isFunction(children)) {
    return ({ onConfirm: onConfirmBase, onCancel }) =>
      children({
        onConfirm: analyticsEvent
          ? mixpanelHelpers.trackEventAndPass(analyticsEvent)(onConfirmBase)
          : onConfirmBase,
        onCancel,
      });
  }
  return children;
};

const _parseNativeUnloadPrompt = ({
  nativeUnloadPrompt,
  analyticsEvent,
}: {
  nativeUnloadPrompt?: (Event) => ?(string | boolean),
  analyticsEvent?: { name: string, properties?: ?Object },
}) => {
  if (analyticsEvent && nativeUnloadPrompt) {
    return (e) => {
      mixpanelHelpers.trackEvent(
        analyticsEvent.name,
        analyticsEvent?.properties,
      );
      if (isFunction(nativeUnloadPrompt)) {
        return nativeUnloadPrompt(e);
      }
      return nativeUnloadPrompt;
    };
  }
  return nativeUnloadPrompt;
};

const PageLeavePrompt = ({
  when,
  nativeWhen,
  nativeUnloadPrompt = () => alertMessages.changesMayNotBeSaved,
  navPromptProps,
  children,
  /** The analytics event to track when the user is leaving the page */
  analyticsEvent,
  // disable
  /** Disables the native browser prompt */
  disableNative,
  /** Disables the navigation internal app prompt */
  disableNav,
}: Props) => (
  <>
    {disableNative ? null : (
      <Beforeunload
        when={nativeWhen || when}
        nativeUnloadPrompt={_parseNativeUnloadPrompt({
          nativeUnloadPrompt,
          analyticsEvent,
        })}
      />
    )}
    {disableNav ? null : (
      <NavigationPromptFancyDialog
        when={when}
        {...navPromptProps}
        /* eslint-disable-next-line react/no-children-prop */
        children={_parseChildren({ children, analyticsEvent })}
      />
    )}
  </>
);

export default PageLeavePrompt;
