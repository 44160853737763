import React, { useState, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { ModalForm, Row, Col } from '@pluralcom/blueprint';
import classNames from 'classnames';

import { QueryRenderer } from '../../graphql';

import LoadingLayout from '../LoadingLayout/LoadingLayout';
import FormReferUser, {
  FromReferUserRefHandle,
} from '../FormReferUser/FormReferUser';

import styles from './ModalReferUser.module.scss';

/**
 * Provide modal for people selector list
 * */

interface ModalReferUserProps {
  /** is modal open */
  isOpen?: boolean;
  /** user Unique ID */
  otherUserId?: string | null;
  /** called on person select */
  onSelectPerson?: Function;
  /** toggle modal */
  toggle?: Function;
  /** user search public */
  userSearchPublic?: {
    /** user search public ID */
    id: string;
    /** user */
    user: {
      /** user ID */
      id: string;
    };
    /** tag */
    tag: {
      /** tag ID */
      id: string;
      /** tag title */
      title: string;
    };
  };
}

/**
 * FIGMA v0.0.1
 * ModalReferUser component
 */
const ModalReferUser = ({
  otherUserId = null,
  onSelectPerson,
  toggle,
  userSearchPublic,
  isOpen,
  ...rest
}: ModalReferUserProps) => {
  const formReferUserRef = useRef<FromReferUserRefHandle>(null);
  const [isValid, setIsValid] = useState(false);

  /**
   * handle click next button
   * submit form and close modal
   */
  const _onNext = async () => {
    formReferUserRef?.current?.submit?.();
    toggle?.();
  };

  return (
    <ModalForm
      modalProps={{
        wrapClassName: styles.referUserModal,
      }}
      toggle={toggle}
      className={styles['modal-base']}
      containerProps={{
        noPadding: true,
        className: classNames(['gx-0', styles['modal-container']]),
      }}
      navHeaderProps={{
        text: 'New Referral',
      }}
      modalBody={
        <Row className="gx-0">
          <Col xsOffset={1} xs={10} className="align-self-center">
            <QueryRenderer
              variables={{
                otherUserId,
                skipOwnSkills: !otherUserId,
              }}
              // @ts-ignore
              query={graphql`
                query ModalReferUserQuery(
                  $otherUserId: String
                  $skipOwnSkills: Boolean!
                ) {
                  viewer {
                    ...FormReferUser_viewer
                      @arguments(
                        otherUserId: $otherUserId
                        skipOwnSkills: $skipOwnSkills
                      )
                  }
                }
              `}
              render={({ props: queryProps }) => {
                if (queryProps) {
                  return (
                    <FormReferUser
                      viewer={queryProps.viewer}
                      otherUserId={otherUserId}
                      userSearchPublic={userSearchPublic}
                      ref={formReferUserRef}
                      // @ts-ignore
                      onValidationChange={setIsValid}
                      {...{
                        onSelectPerson: { onSelectPerson },
                      }}
                    />
                  );
                }
                return <LoadingLayout />;
              }}
            />
          </Col>
        </Row>
      }
      isOpen={isOpen}
      bottomBarProps={{
        tertiaryButtonProps: {
          text: 'Cancel',
          className: styles['btn-back'],
          // @ts-ignore
          onClick: toggle,
        },
        primaryButtonProps: {
          text: 'Send',
          className: styles['btn-send'],
          onClick: _onNext,
          disabled: !isValid,
        },
      }}
      {...rest}
    />
  );
};

export default ModalReferUser;
