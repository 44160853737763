/* @flow */
import React from 'react';
import classNames from 'classnames';

import Modal from '../Modal/Modal';
import ButtonClose from '../ButtonClose/ButtonClose';

import styles from './ModalMediaBase.module.scss';

/**
 * Provide modal to display modal with two sides.
 * Left side will be hidden for devices with resolution less then lg
 * See example in share skill or profile.
 * @example
 *    <ModalMediaBase
 *      LeftComponent={()=><div></div>}
 *      RightComponent={()=><div></div>}
 *      {...modalProps}
 *    />
 * */

type Props = {
  toggle: Function,
  LeftComponent: any,
  RightComponent?: any,
  children?: any,
  className?: string,
  leftComponentWrapperClassName?: string,
  rightComponentWrapperClassName?: string,
  modalContainerClassName?: string,
};

const ModalMediaBase = ({
  toggle,
  LeftComponent,
  RightComponent,
  className,
  leftComponentWrapperClassName,
  rightComponentWrapperClassName,
  modalContainerClassName,
  children,
  ...rest
}: Props) => (
  <Modal
    {...rest}
    toggle={toggle}
    className={classNames(styles['modal-container'], modalContainerClassName)}
  >
    <ButtonClose
      absolute
      size="md"
      uiType="lite"
      onClick={toggle}
      className={styles['btn-close']}
    />
    <div
      className={classNames([styles['modal-media-base-container'], className])}
    >
      <div
        className={classNames(
          styles['left-container'],
          leftComponentWrapperClassName,
        )}
      >
        {LeftComponent && <LeftComponent {...rest} />}
      </div>
      <div
        className={classNames(
          styles['right-container'],
          rightComponentWrapperClassName,
        )}
      >
        {children || (RightComponent && <RightComponent {...rest} />)}
      </div>
    </div>
  </Modal>
);

export default ModalMediaBase;
