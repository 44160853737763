/* @flow */

const GOOGLE_API_KEY: string =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  'AIzaSyDXh9j2nJkA12KTfMfzfVVTpsxq4BqWjYo';

const MAPBOX_ACCESS_TOKEN: string =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  'pk.eyJ1IjoicGx1cmFsY29tLWRldiIsImEiOiJja3JtdnQ0MXoxbmg4MnVydjU5cHJzZWU3In0.6iFBBIsnfZEdrOFYRbpwjw';

const MAPBOX_STYLE_URL: string =
  process.env.REACT_APP_MAPBOX_STYLE_URL ||
  'mapbox://styles/pluralcom-dev/ckvs1ljwo0mgj14ujunyzmyjg';

export { GOOGLE_API_KEY, MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE_URL };
