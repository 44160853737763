/**
 * @generated SignedSource<<1ab3bfcff85f61ba32870997ce98ff2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EducationCategory = "HighSchool" | "University" | "%future added value";
export type AddEducationInput = {
  clientMutationId?: string | null;
  major?: string | null;
  name: string;
  type: EducationCategory;
};
export type AddEducationMutation$variables = {
  input: AddEducationInput;
};
export type AddEducationMutation$data = {
  readonly addEducation: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly newEducationEdge: {
      readonly node: {
        readonly id: string;
        readonly name: string | null;
        readonly url: string | null;
      } | null;
    } | null;
  } | null;
};
export type AddEducationMutation = {
  response: AddEducationMutation$data;
  variables: AddEducationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EducationEdge",
  "kind": "LinkedField",
  "name": "newEducationEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Education",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddEducationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEducationPayload",
        "kind": "LinkedField",
        "name": "addEducation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddEducationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddEducationPayload",
        "kind": "LinkedField",
        "name": "addEducation",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c751d6eb97c7367c0db4c4ab4c2c12c",
    "id": null,
    "metadata": {},
    "name": "AddEducationMutation",
    "operationKind": "mutation",
    "text": "mutation AddEducationMutation(\n  $input: AddEducationInput!\n) {\n  addEducation(input: $input) {\n    newEducationEdge {\n      node {\n        id\n        name\n        url\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fa03458c33dd830f0e3dbb803d144e2";

export default node;
