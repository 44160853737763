/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation EditUserSearchPublicMutation($input: EditUserSearchPublicInput!) {
    editUserSearchPublic(input: $input) {
      userSearchPublicEdge {
        node {
          id
          tag {
            id
            title
          }
          location {
            id
            latitude
            longitude
          }
          is_public
          user {
            id
            first_name
            last_name
            profile_color
            avatar {
              id
              thumbnail
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const editUserSearchPublic = (
  {
    search_term,
    is_public,
  }: {
    search_term: string,
    is_public: boolean,
  },
  {
    parentIDList,
    parentIDMap,
  }: { parentIDList: string, parentIDMap: string } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        search_term,
        is_public,
      },
    };

    const configs = [
      is_public && parentIDList
        ? {
            type: 'RANGE_ADD',
            parentID: parentIDList,
            connectionInfo: [
              {
                key: 'SearchListView_UserSearches_userSearchesPublic',
                rangeBehavior: 'prepend',
              },
            ],
            edgeName: 'userSearchPublicEdge',
          }
        : undefined,
      is_public && parentIDMap
        ? {
            type: 'RANGE_ADD',
            parentID: parentIDMap,
            connectionInfo: [
              {
                key: 'SearchMapView_UserSearches_userSearchesPublic',
                rangeBehavior: 'prepend',
              },
            ],
            edgeName: 'userSearchPublicEdge',
          }
        : undefined,
    ].filter(Boolean);

    commitMutation({
      mutation,
      variables,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default editUserSearchPublic;
