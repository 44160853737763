/**
 * Deletes a cookie by force expiring it clientside
 */
const deleteCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

/**
 * Use to check if cookies are allowed in browser
 * @example cookiesHelpers.isCookiesEnabled()
 *
 */
const isCookiesEnabled = (): boolean => window.navigator.cookieEnabled;

/**
 * Gets a cookie by name
 */
const getCookie = (name: string): string | undefined => {
  const parts = ` ${document.cookie}`.split(` ${name}=`);
  return parts.length < 2 ? undefined : parts.pop()?.split(';').shift();
};

/**
 * Sets a cookie
 */
const setCookie = (
  name: string,
  value: string,
  options: {
    /** cookie secure option */
    secure?: boolean;
    /** cookie httpOnly option */
    httpOnly?: boolean;
    /** cookie sameSite option */
    sameSite?: 'Lax' | 'Strict' | 'None' | undefined;
  } & (
    | {
        /** cookie expires option */
        expires?: string;
      }
    | {
        /** cookie max-age option */
        'max-age'?: number;
      }
  ) = {},
): string => {
  const optionsStr = Object.keys(options).reduce(
    (valueSoFar, key) => `${valueSoFar};${key}=${options[key]}`,
    '',
  );
  document.cookie = `${name}=${value}${optionsStr}`;
  return document.cookie;
};

export default {
  isCookiesEnabled,
  getCookie,
  setCookie,
  deleteCookie,
};
