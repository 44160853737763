import React from 'react';
import { MenuItem, Avatar } from '@pluralcom/blueprint';

import { COLOR_NEUTRAL_0 } from '@pluralcom/blueprint/dist/styles/js/index';
import styles from './InputSkillListItem.module.scss';

interface InputSkillListItemProps {
  /** viewer */
  data?: any;
}

/**
 *
 * FIGMA v0.0.1
 *  InputSkillListItem component to display a skill item in the input skill list
 */
const InputSkillListItem = ({ data, ...rest }: InputSkillListItemProps) => (
  <MenuItem
    size="md"
    className={styles.container}
    renderLeftChild={
      <Avatar
        user={data?.user}
        size="xs"
        icon={{
          icon: ['fas', 'plus'],
          type: 'fa',
          className: styles.icon,
        }}
        color={COLOR_NEUTRAL_0}
      />
    }
    {...rest}
  >
    {data?.title}
  </MenuItem>
);

export default InputSkillListItem;
