/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation SignupMutation($input: SignupInput!) {
    signup(input: $input) {
      auth_reqid
      profile {
        id
        username
        name
      }
      error {
        message
      }
      # @todo remove when production
      testPin
    }
  }
`;

export default ({
  first_name,
  last_name,
  email,
  phone,
}: {
  first_name: string,
  last_name: string,
  email?: ?string,
  phone?: ?string,
}): Promise<any> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        first_name,
        last_name,
        email,
        phone,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
