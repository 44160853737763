/**
 * @TODO globalize plural-web-utils
 */
/* @flow */
export default {
  isIOSSafari: (): boolean =>
    // eslint-disable-next-line
    Boolean(window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)),
  isIOS: (): boolean =>
    /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream,
  isIphoneX: (): boolean => {
    // Really basic check for the ios platform
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Get the device pixel ratio
    const ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    const screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    // iPhone X Detection
    return iOS && screen.width === 1125 && screen.height === 2436;
  },
};
