/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';
import { analyticsHelpers } from '@pluralcom/plural-js-utils';

import SigninAppleMutation from '../../graphql/mutations/auth/SigninApple';
import SigninFacebookMutation from '../../graphql/mutations/auth/SigninFacebook';
import VerifyPinMutation from '../../graphql/mutations/auth/VerifyPin';
import LogoutMutation from '../../graphql/mutations/auth/Logout';

import {
  SET_AUTHENTICATED,
  RESET_AUTHENTICATED,
  authMainFormReset,
} from '../../redux/reducers/authReducer/authReducer';
import store from '../../redux/store';
import RandomSeed from '../RandomSeed/RandomSeed';
import sentryHelpers from '../../utils/sentryHelpers/sentryHelpers';
import {
  mixpanelHelpers,
  heapHelpers,
  localStorageHelpers,
  logger,
  analyticsHelpers as analyticsHelpersBrowser,
  cookiesConsent,
  intercomHelpers,
} from '../../utils';

const AUTH_CHECK_INTERVAL: number = 1000;

/** checks whether the user is authenticated by checking for the token in the local storage */
const isAuthenticated = (): boolean => localStorageService.getItem('authed');

/** gets the acces token from the local storage */
const getToken = (): string => localStorageService.getItem('token');

/** sets the authed value to true in the local storage */
const setAuthed = () => {
  localStorageService.setItem('authed', true);
  store.dispatch({ type: SET_AUTHENTICATED });
  authMainFormReset()(store.dispatch);
};

/** Checks if redux is authenticated */
const isAuthenticatedRedux = () => store.getState().auth.isAuthenticated;

/** sets the user's data in the local storage */
const setUser = (user) => {
  localStorageHelpers.setUser(user);
};

/** gets the user's data in the local storage */
const getUser = (): Object => localStorageHelpers.getUser();

/** sets the user roles data in the local storage */
const setUserRoles = (roles) => {
  localStorageHelpers.setUserRoles(roles);
};

/**
 * Gets the socket using global require to avoid circular deps
 */
const _getSocket = () =>
  // eslint-disable-next-line global-require
  require('../Socket/Socket').default;

/** To be run post authentication to populate the localstorage and update the state of the app based on the user */
const postAuth = ({
  user,
  roles = [],
  intercom_hash,
}: {
  user: { id: string, username: string, name: string },
  roles: Array<string>,
  intercom_hash: string,
}) => {
  setAuthed();
  setUser(user);
  setUserRoles(roles);
  const randomseed = RandomSeed.forceRefresh(user.id);
  sentryHelpers.setScopeUser(user);
  sentryHelpers.setScopeRandomSeed(randomseed);
  sentryHelpers.addBreadcrumb({
    category: 'auth',
    message: `Authenticated user ${user.username} id: ${user.id}`,
    level: 'info',
  });
  analyticsHelpersBrowser.setUser({ user, roles });
  intercomHelpers.setUser(user, intercom_hash);
  mixpanelHelpers.trackEvent(analyticsHelpers.events.AUTH_AUTHED.name);
  heapHelpers.trackEvent(analyticsHelpers.events.AUTH_AUTHED.name);
  /** Init the socket - to make sure it's connected after auth */
  _getSocket().connectToServer();
};

/** authenticates a user based on a pin code sent to the user */
const verifyPin = ({
  pin,
  email,
  phone,
  auth_reqid,
  ...rest
}: {
  pin: string,
  email?: ?string,
  phone?: ?string,
}): Promise<any> =>
  new Promise((resolve, reject) => {
    VerifyPinMutation({ pin, email, phone, auth_reqid, ...rest })
      .then((res) => {
        if (res.verifyPin.error) {
          return resolve({ ...res, success: false });
        }
        postAuth({
          user: res.verifyPin.profile,
          roles: res.verifyPin.roles,
          intercom_hash: res.verifyPin.intercom_hash,
        });
        return resolve({ ...res, success: true });
      })
      .catch(reject);
  });

/** authenticates a user based on a facebook access token */
const connectFacebook = (
  accessToken: string,
  with_auth?: boolean,
): Promise<any> =>
  new Promise((resolve, reject) => {
    SigninFacebookMutation({ accessToken, with_auth })
      .then((res) => {
        if (res.signinFacebook.error) {
          return resolve({ ...res, success: false });
        }
        if (with_auth) {
          postAuth({
            user: res.signinFacebook.profile,
            roles: res.signinFacebook.roles,
            intercom_hash: res.signinFacebook.intercom_hash,
          });
        }
        return resolve({ ...res, success: true });
      })
      .catch((err) => reject(err));
  });

/** authenticates a user based on a apple auth response */
const connectApple = (
  data: {
    nonce: string,
    authorization: {
      id_token: string,
      code: string,
      state: string,
    },
    user?: {
      email: string,
      name?: {
        firstName: string,
        lastName: string,
      },
    },
  },
  connectOnly: boolean,
): Promise<any> =>
  new Promise((resolve, reject) => {
    const with_auth = !connectOnly;
    SigninAppleMutation({
      with_auth,
      id_token: data.authorization.id_token,
      code: data.authorization.code,
      first_name: data.user?.name?.firstName,
      last_name: data.user?.name?.lastName,
      nonce: data.nonce,
    })
      .then((res) => {
        if (res.signinApple.error) {
          return resolve({ ...res, success: false });
        }
        if (!connectOnly) {
          postAuth({
            user: res.signinApple.profile,
            roles: res.signinApple.roles,
            intercom_hash: res.signinApple.intercom_hash,
          });
        }
        return resolve({ ...res, success: true });
      })
      .catch((err) => reject(err));
  });

/** logs the user out from plural and fb and purges the local storage */
const logout = async ({ reload, noBackend } = {}): void => {
  /** Issue logout mutation */
  if (!noBackend) {
    await LogoutMutation();
  }
  sentryHelpers.addBreadcrumb({
    category: 'auth',
    message: `Logged out user`,
    level: 'info',
  });
  mixpanelHelpers.trackEvent(analyticsHelpers.events.LOGOUT.name);
  heapHelpers.trackEvent(analyticsHelpers.events.LOGOUT.name);
  analyticsHelpersBrowser.reset();
  intercomHelpers.shutdown();
  // @todo @postmvp remove specific keys?
  localStorageService.clear();
  sessionStorage.clear();
  store.dispatch({ type: RESET_AUTHENTICATED });
  if (window.FB?.getLoginStatus()) {
    window.FB.logout();
  }
  /** Close the socket - using dynamic require to prevent cycle since socket file imports Auth service */
  _getSocket().close();
  // @todo proper sentry logout
  sentryHelpers.setScopeUser({
    id: undefined,
    email: undefined,
    username: undefined,
  });
  sentryHelpers.setScopeRandomSeed(RandomSeed.forceRefresh());
  cookiesConsent.setConsentCookie(null);
  if (reload) {
    window.location.reload();
  }
  /** reset relay env */
  // eslint-disable-next-line global-require
  require('../../graphql/Environment').initEnvironment();
};

const init = () => {
  /** if the user is already authenticated, dispatch the SET_AUTHENTICATED action to update the store */
  if (isAuthenticated()) {
    store.dispatch({ type: SET_AUTHENTICATED });
  }
};

/** Check in the background if user has logged out from another tab, if so -> logout in curr tab */
setInterval(() => {
  if (isAuthenticatedRedux() && !isAuthenticated()) {
    logout({ noBackend: true, reload: true });
  }
}, AUTH_CHECK_INTERVAL);

const Auth = {
  verifyPin,
  connectFacebook,
  connectApple,
  logout,
  isAuthenticated,
  getToken,
  getUser,
  init,
};

export default Auth;
