/* @flow */
import { useState } from 'react';

/**
 * useModals hook
 *
 * Allows toggling of modals via state
 */
const useModals = (
  initialState: { [isOpenModal: string]: boolean },
  { prefix = 'isOpen' } = { prefix: 'isOpen' }
): {
  modalsState: { [isOpenModal: string]: boolean },
  setModals: (newPartialModalsState: { [string]: boolean }) => void,
  toggleModals: (modalNamesToToggle: string | Array<string>) => void,
  toggleModalsCurr: (modalNamesToToggle: string | Array<string>) => () => void,
} => {
  const [modalsState, setModalsState] = useState(initialState);

  const setModals = (newPartialModalsState: {
    [isOpenModal: string]: boolean,
  }) => {
    setModalsState({ ...modalsState, ...newPartialModalsState });
  };

  const toggleModals = (modalNamesToToggle: string | Array<string>) => {
    const newState = {};
    if (Array.isArray(modalNamesToToggle)) {
      modalNamesToToggle.forEach(modalName => {
        newState[`${prefix}${modalName}`] = !modalsState[
          `${prefix}${modalName}`
        ];
      });
    } else {
      newState[`${modalNamesToToggle}`] = !modalsState[`${modalNamesToToggle}`];
    }
    setModalsState({ ...modalsState, ...newState });
  };

  const toggleModalsCurr = (modalNamesToToggle: string | Array<string>) => () =>
    toggleModals(modalNamesToToggle);

  return {
    modalsState,
    setModals,
    toggleModals,
    toggleModalsCurr,
  };
};

export default useModals;
