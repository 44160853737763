import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../../../hocs/withRouter/withRouter';

import { authRootObjUpdate as authRootObjUpdateAC } from '../../../../redux/reducers/authReducer/authReducer';
import { AddModalPhone } from '../../../../containers/Settings/AccountTab/components';
import { localStorageHelpers } from '../../../../utils';

interface ModalAddPhoneProps {
  /** Auth root object */
  authRootObj: {
    /** Boolean whether modal is open or not */
    isOpenModalAddPhone: boolean;
  };
  /** Update action for redux */
  authRootObjUpdate: Function;
}

const ModalAddPhone = ({ authRootObj, authRootObjUpdate }: ModalAddPhoneProps) => {
  const { isOpenModalAddPhone = false } = authRootObj || {};
  return (
    <AddModalPhone
      isOpen={isOpenModalAddPhone}
      auth_reqid=""
      toggle={() => {
        /** Close modal */
        authRootObjUpdate({
          isOpenModalAddPhone: false,
        });
      }}
      onSuccess={({ value, auth_reqid }) => {
        const user = localStorageHelpers.getUser();
        authRootObjUpdate({
          /** Verify code form info */
          authMainForm: {
            first_name: user.first_name,
            last_name: user.last_name,
            emailOrPhone: value,
            auth_reqid,
          },
          /** Close modal */
          isOpenModalAddPhone: false,
          /** Open VerifyCode modal */
          isOpenModalVerifyCode: true,
        });
      }}
    />
  );
};

const mapStateToProps = ({ auth: authRootObj }) => ({
  authRootObj,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ authRootObjUpdate: authRootObjUpdateAC }, dispatch);

export { ModalAddPhone as PureModalAddPhone };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalAddPhone),
);
