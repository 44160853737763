import React from 'react';
import classNames from 'classnames';

import styles from './SaveSkillCountButton.module.scss';
import SkillCardMiniFloatingButton from '../SkillCardMini/components/SkillCardMiniFloatingButton/SkillCardMiniFloatingButton';
import { SkillCardMiniHeader_skill$data } from '../SkillCardMini/components/SkillCardMiniHeader/__generated__/SkillCardMiniHeader_skill.graphql';

export interface Props {
  /** The size of the button */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  /** The class name of the button */
  className?: string,
  /** The total count of the skill */
  totalCount?: number,
  /** The skill object */
  skill?: SkillCardMiniHeader_skill$data,
};

const SaveSkillCountButton = ({ skill, className, size = 'xs', ...rest }: Props) => (
  <SkillCardMiniFloatingButton
    size={size}
    className={classNames([
      {
        [styles['icon--active']]: skill?.likes?.viewerHasLiked,
      },
      styles.container,
      className,
    ])}
    iconProps={{
      type: 'fa',
      icon: ['fas', 'heart']
    }}
    {...rest}

  />);

export default SaveSkillCountButton;
