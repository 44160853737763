/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation UpdateTagDemandSelectedMutation(
    $input: UpdateTagDemandSelectedInput!
  ) {
    updateTagDemandSelected(input: $input) {
      updated_tag {
        id
        is_demand_selected
      }
    }
  }
`;

export default ({
  tag_id,
  value,
}: {
  tag_id: string,
  value: boolean,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        tag_id,
        value,
      },
    };

    const optimisticResponse = {
      updateTagDemandSelected: {
        updated_tag: {
          id: tag_id,
          is_demand_selected: value,
        },
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });
