/* @flow */
import React, { useState } from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import stylesMapMarker from '../MapMarker.module.scss';

type Props = {
  InfoModalComponent: any,
  infoModalProps: ?{
    placement: ?string,
    className: ?string,
    trigger?: 'click' | 'hover' | 'right-click' | 'none',
  },
  zIndexHover?: Boolean,
  zIndex?: Boolean,
  markerStyle: Object,
  children: React$Node,
};

/**
 * Renders an InfoWindow component that displays additional information when a marker is clicked or hovered over.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {React.Component} props.InfoModalComponent - The component to render inside the InfoWindow.
 * @param {Object} [props.infoModalProps] - The props to pass to the InfoModalComponent.
 * @param {string} [props.infoModalProps.placement='top'] - The placement of the InfoWindow relative to the marker.
 * @param {string} [props.infoModalProps.className] - The class name to apply to the InfoWindow.
 * @param {string} [props.infoModalProps.trigger='hover'] - The event that triggers the InfoWindow to appear.
 * @param {boolean} [props.zIndexHover] - The z-index to apply to the InfoWindow when it is being hovered over.
 * @param {boolean} [props.zIndex] - The z-index to apply to the InfoWindow.
 * @param {Object} props.markerStyle - The style object to apply to the marker.
 * @param {React.Node} props.children - The children to render inside the marker.
 */
function InfoWindow(props: Props) {
  const {
    children,
    InfoModalComponent,
    infoModalProps = {},
    zIndexHover,
    zIndex,
    ...rest
  } = props;
  const defaultPlacement = infoModalProps.placement || 'top';
  const [isHover, setIsHover] = useState(false);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: defaultPlacement,
    trigger: infoModalProps.trigger || 'hover',
    interactive: infoModalProps.trigger || true,
  });

  return InfoModalComponent ? (
    <>
      <div
        ref={setTriggerRef}
        onMouseOut={() => setIsHover(false)}
        onBlur={() => setIsHover(false)}
        onMouseOver={() => setIsHover(true)}
        onFocus={() => setIsHover(true)}
        style={{ zIndex: isHover ? zIndexHover || zIndex : zIndex }}
      >
        {children}
      </div>

      <div
        ref={setTooltipRef}
        {...getTooltipProps({
          className: classNames(
            stylesMapMarker['map-marker-info-window'],
            visible && stylesMapMarker['map-marker-info-window_visible'],
            'tooltip-container',
            infoModalProps?.className,
          ),
        })}
      >
        {/* <div
          {...getArrowProps({
            className: classNames(
              'tooltip-arrow',
              stylesMapMarker['tooltip-arrow'],
            ),
          })}
        /> */}
        <InfoModalComponent {...rest} />
      </div>
    </>
  ) : (
    children
  );
}

export default InfoWindow;
