/* @flow */
/* eslint no-useless-escape: 0 */
import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ButtonFlat } from '@pluralcom/blueprint';
import classNames from 'classnames';

import Auth from '../../../../services/Auth/Auth';

import { cookiesConsent } from '../../../../utils';

import Analytics from '../Analytics/Analytics';

import styles from './CookiesConsent.module.scss';

const COOKIES_SETTINGS_PATH = 'cookie-settings';

const CookiesConsent = () => {
  const [currentCookie, setCurrentCookie] = useState(
    cookiesConsent.getConsentCookie(),
  );

  /** Listen to cookie changes - eg: from other tabs */
  useEffect(() => {
    /* optimization - no need for comprehensive checking if Authed and constened before */
    if (Auth.isAuthenticated() && currentCookie) {
      return () => {};
    }
    /** listen to cookie changes via interval */
    const interval = setInterval(() => {
      const currDocumentCooke = cookiesConsent.getConsentCookie();
      if (currDocumentCooke?.ts !== currentCookie?.ts) {
        setCurrentCookie(currDocumentCooke);
      }
    }, 5000);
    /** clear interval */
    return () => {
      clearInterval(interval);
    };
  }, [currentCookie]);

  /** Handles saving the cookies consent state */
  const _handleSave = useCallback(
    (cookiesPreferencesToSave) => () => {
      cookiesConsent.setConsentCookie({
        ...cookiesPreferencesToSave,
        ts: Date.now(),
      });
      // Failsafe
      const newConsentCookie = cookiesConsent.getConsentCookie();
      if (newConsentCookie) {
        setCurrentCookie(newConsentCookie);
      }
    },
    [],
  );

  const _handleCustomize = useCallback(
    (event) => {
      /** Only set to default on other pages - since cookie settings page sets a cookie by default */
      if (window.location.pathname !== `/${COOKIES_SETTINGS_PATH}`) {
        _handleSave(cookiesConsent.DEFAULT_CUSTOM_COOKIES)(event);
        window.open(
          `${window.location.origin}/${COOKIES_SETTINGS_PATH}`,
          '_blank',
        );
      }
    },
    [_handleSave],
  );

  return (
    <>
      {!currentCookie && document.readyState === 'complete' && (
        <aside className={styles.container}>
          <p className={styles.text}>
            <span role="img" aria-label="cookie">
              🍪
            </span>{' '}
            We use cookies for better service. Learn more in our{' '}
            <Link
              className={styles.link}
              to="/info/privacy-policy"
              target="_blank"
              rel="nofollow noopener noreferer"
            >
              cookie policy
            </Link>
            .
          </p>
          <div className={classNames([styles['button-container'], 'd-flex'])}>
            <ButtonFlat
              uiType="secondary"
              type="button"
              className={styles['button-secondary']}
              onClick={_handleCustomize}
            >
              Manage Cookies
            </ButtonFlat>
            <ButtonFlat
              onClick={_handleSave(cookiesConsent.ALL_COOKIES)}
              uiType="primary"
              type="submit"
            >
              Accept All
            </ButtonFlat>
          </div>
        </aside>
      )}
      <Analytics consentCookie={currentCookie} />
    </>
  );
};

export default CookiesConsent;
