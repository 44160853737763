import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { stripeHelpers } from '@pluralcom/plural-js-utils';

/**
 * Stripe Price Input Mask
 *
 * Adds decimal points and , thousandth seperators
 * Has an Integer Limit of stripe max price
 *
 * @param {Object} overrides
 */
const stripePriceMask = ({ ...rest }) =>
  createNumberMask({
    allowDecimal: true,
    prefix: '',
    includeThousandsSeparator: true,
    integerLimit: stripeHelpers.MAX_STRIPE_PRICE,
    ...rest,
  });

export default {
  stripePriceMask,
};
