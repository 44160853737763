import React from 'react';
import classNames from 'classnames';

import { TagColorful } from '@pluralcom/blueprint';
import offersHelpers from '@pluralcom/plural-js-utils/lib/offers/offersHelpers';

import styles from './TagHiringType.module.scss';

interface TagHiringTypeProps {
  /** class name */
  className?: string;
  /** hiring type */
  type?: string;
  /** size */
  size?: 'xs' | 'md' | 'lg';
  /** if true, disable hover state */
  disableHoverState?: boolean;
}

/**
 * - Figmva v0.0.2
 *
 * - This component is used to display the hiring type of an offer.
 * - use the `type` prop to pass the hiring type.
 * - uses <TagColorful /> from @pluralcom/blueprint
 */
const TagHiringType = ({
  className,
  type,
  size = 'md',
  disableHoverState = false,
}: TagHiringTypeProps) => {
  // Get the colors for the hiring type
  const {
    textColor,
    backgroundColor,
    backgroundColorActive,
    backgroundColorHover,
    textColorActive,
    textColorHover,
  } = offersHelpers.HIRING_TYPES_COLORS[type || ''] || {};

  // Get the `tagTitle` for the hiring type
  // If the `tagTitle` is not found, use the title
  const { title, tagTitle } =
    offersHelpers.HIRING_TYPES_DISPLAY_TEXTS[type || ''] || {};

  return (
    <TagColorful
      className={classNames([styles.container, className])}
      size={size}
      textColor={textColor}
      backgroundColor={backgroundColor}
      backgroundColorActive={backgroundColorActive}
      backgroundColorHover={disableHoverState ? '' : backgroundColorHover}
      textColorActive={textColorActive}
      textColorHover={disableHoverState ? '' : textColorHover}
    >
      {tagTitle || title || type}
    </TagColorful>
  );
};

export default TagHiringType;
