/* @flow */
/* eslint no-plusplus: 0 */

/**
 * Crawls up the dom tree to retrieve the closest parent with selector.
 */
const getClosestParent = (element: Object, selector: string) => {
  let elem = element;
  const pfFunc = s => {
    // $FlowFixMe
    const matches = (this.document || this.ownerDocument).querySelectorAll(s);
    let i = matches.length;
    while (--i >= 0 && matches.item(i) !== this);
    return i > -1;
  };
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    // $FlowFixMe
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      pfFunc;
  }
  // Get closest match
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export default getClosestParent;
