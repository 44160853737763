import graphql from 'babel-plugin-relay/macro';

import { generateOptimisticId } from '@pluralcom/plural-js-utils';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation AddQuestionMutation($input: AddFAQuestionToSkillInput!) {
    addFAQuestionToSkill(input: $input) {
      error {
        id
        title
        technical_message
        message
        code
      }
      skill_question {
        id
        question
        answer
      }
      skill {
        id
        questions {
          id
          question
          answer
          original_question {
            id
          }
        }
      }
    }
  }
`;

interface AddQuestionMutationInput {
  /** skilld id */
  skill_id: string;
  /** faq question string */
  question: string;
  /** faq answer */
  answer: string;
}

interface AddQuestionMutationSkill {
  /** skill */
  skill?: {
    /** skill id */
    id: string;
    /** skill questions */
    questions: Array<{
      /** skill questions node */
      node: {
        /** skill question id */
        id: string;
        /** skill question */
        question: string;
        /** skill answer */
        answer: string;
      };
    }>;
  };
}

const AddQuestionMutation = (
  { skill_id, question, answer }: AddQuestionMutationInput,
  { skill }: AddQuestionMutationSkill = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_id,
        question,
        answer,
      },
    };

    return commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        addFAQuestionToSkill: {
          error: null,
          skill_question: {
            id: skill_id,
            question,
            answer,
          },
          skill: {
            id: skill_id,
            questions: (skill?.questions || []).concat({
              node: {
                id: generateOptimisticId('FAQuestion'),
                question,
                answer,
              },
            }),
          },
        },
      },
      onCompleted: resolve,
      onError: reject,
    });
  });

export default AddQuestionMutation;
