import React from 'react';

import offersHelpers from '@pluralcom/plural-js-utils/lib/offers/offersHelpers';
import {
  Avatar,
  Col,
  ModalForm,
  Row,
  Text,
  TouchableOpacity,
} from '@pluralcom/blueprint';

// components
import ModalViewOffersItem from './components';

// styles
import styles from './ModalViewOffers.module.scss';

// Default hiring type i.e Direct
const DEFAULT_HIRING_TYPE = offersHelpers.HIRING_TYPES.DIRECT;

// Default offer
const DEFAULT_OFFER = {
  node: {
    description: null,
    price: null,
    service_duration: null,
    title: null,
    hiring_type: DEFAULT_HIRING_TYPE,
  },
};

interface Offer {
  /** node */
  node: {
    /** id */
    id: string;
    /** description */
    description: string | null;
    /** hiring type */
    hiring_type: string | null;
    /** price */
    price: number | null;
    /** service duration */
    service_duration: number | null;
    /** title */
    title: string | null;
  };
}

interface ModalViewOffersProps {
  /** if True, means the modal will be visible */
  isOpen?: boolean;
  /** toggle */
  onToggle?: Function;
  /** toggle */
  onOfferClick?: (offer: Offer) => void;
  /** offers */
  offers: Array<Offer>;
  /** skill owner user */
  ownerUser: {
    /** id */
    id: string;
    /** first name */
    first_name: string | null;
    /** avatar */
    avatar: {
      /** id */
      id: string;
      /** small thumbnail */
      smallThumbnail: string | null;
    } | null;
  } | null;
}

/**
 * - Figma v0.0.1
 * - This is the ModalViewOffers component.
 */
const ModalViewOffers = ({
  isOpen,
  onToggle,
  offers,
  ownerUser: user,
  onOfferClick,
}: ModalViewOffersProps) => {
  /**
   * - Toggle modal
   */
  const _toggle = () => {
    onToggle?.();
  };

  return (
    <ModalForm
      isOpen={isOpen}
      toggle={_toggle}
      isMaxHeight
      className={styles.container}
      containerProps={{
        noPadding: true,
        gx: 0,
        className: styles['modal-container'],
      }}
      modalBody={
        <Row gx={0}>
          <Col xsOffset={1} xs={10}>
            <div className={styles['avatar-title-container']}>
              <Avatar
                // @ts-ignore
                user={user}
                size="xl"
                borderType="white-border"
                shadowType
              />
              <Text fontSizeType="t2" className={styles.title}>
                {user?.first_name}&apos;s Offers
              </Text>
            </div>
            <div className={styles['offers-list']}>
              <TouchableOpacity
                key={DEFAULT_HIRING_TYPE}
                onClick={() => {
                  // @ts-ignore
                  onOfferClick?.(DEFAULT_OFFER);
                }}
                className={styles['offers-items']}
              >
                <ModalViewOffersItem offer={DEFAULT_OFFER.node} />
              </TouchableOpacity>

              {offers.map((offer: Offer) => (
                <TouchableOpacity
                  key={offer?.node?.id}
                  onClick={() => {
                    onOfferClick?.(offer);
                  }}
                  className={styles['offers-items']}
                >
                  <ModalViewOffersItem offer={offer?.node} />
                </TouchableOpacity>
              ))}
            </div>
          </Col>
        </Row>
      }
    />
  );
};

export default ModalViewOffers;
