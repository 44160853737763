import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

import type { CreateeSetupIntentMutation$data } from './__generated__/CreateSetupIntentMutation.graphql';

const mutation = graphql`
  mutation CreateSetupIntentMutation($input: CreateSetupIntentInput!) {
    createSetupIntent(input: $input) {
      intent {
        id
        raw_id
        intent
      }
      error {
        message
      }
    }
  }
`;

const createSetupIntent = (): Promise<CreateeSetupIntentMutation$data> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {},
    };

    commitMutation({
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default createSetupIntent;
