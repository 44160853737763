import React from 'react';
import classNames from 'classnames';
import { Divider, Text } from '@pluralcom/blueprint';

import styles from './SeperatorText.module.scss';

interface SeperatorTextProps {
  /** The text to display between the seperators */
  children?: string;
  /** The custom class name */
  className?: string;
}

/**
  * Figma: v0.0.1
  */

const SeperatorText = ({ children = 'or', className }: SeperatorTextProps) => (
  <div className={classNames([styles.wrapper, className])}>
    <Divider uiType="gray" orientation="horizontal" gx="0" gy="0" />
    <Text
    fontSizeType="t7"
    stickToBreakpoint="md"
    element="span"
    className={styles.text}>{children}</Text>
    <Divider uiType="gray" orientation="horizontal" gx="0" gy="0" />
  </div>
);

export default SeperatorText;
