/**
 * @note: @shared @web @native
 */
import { isLoggedOut } from '@pluralcom/plural-js-utils/lib/authHelpers';

import Auth from '../../services/Auth/Auth';
import { logger, sentryHelpers } from '../../utils';

import { HelloMutation } from '../mutations';

const MAX_RETRIES_COUNT = 1;

const _onErrorFactory = ({ config, retry, retryCount = 0 }) => async (err) => {
  const { onError } = config;
  logger.debugError('Graphql Request Subscription error', err, {
    config,
    retryCount,
  });

  /** Handle socket no longer authenticated */
  if (
    Auth.isAuthenticated() &&
    err &&
    (isLoggedOut(err?.res?.json?.errors || err) ||
      isLoggedOut(err?.message?.errors))
  ) {
    logger.debug('Socket Subscriptions: Logging user out - attempting hello', {
      retryCount,
    });
    try {
      await HelloMutation();
    } catch (errHello) {
      logger.debugError(
        'Error in socket subscriptions authMiddleware Hello',
        errHello,
      );
    }
  }

  /** Retry mutation - normal retries */
  if (retryCount < MAX_RETRIES_COUNT) {
    return retry({
      ...config,
      onError: _onErrorFactory({
        config,
        retry,
        retryCount: retryCount + 1,
      }),
    });
  }

  sentryHelpers.addBreadcrumb({
    level: 'error',
    category: 'graphql_error_subscription',
    message: `Request Subscription Error: ${
      err.message?.message || err.message || `${err}`
    }`,
    // data: config,
  });
  sentryHelpers.captureException(err);

  /** Reject */
  return onError && onError(err);
};

const configMiddlwareError = (config, { retry } = {}) => ({
  ...config,
  onError: _onErrorFactory({ config, retry }),
});

const configMiddleware = (config, { retry } = {}) => {
  if (!config) {
    return config;
  }
  return configMiddlwareError(config, { retry });
};

export default configMiddleware;
