/**
 * @generated SignedSource<<6777ef40e7c1f756cb35a0bfee5a8010>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfirmCollaboratorRequestInput = {
  clientMutationId?: string | null;
  id: string;
};
export type ConfirmCollaboratorRequestMutation$variables = {
  input: ConfirmCollaboratorRequestInput;
};
export type ConfirmCollaboratorRequestMutation$data = {
  readonly confirmCollaboratorRequest: {
    readonly collaboratorEdge: {
      readonly node: {
        readonly accepted_at: any | null;
        readonly created_at: any;
        readonly id: string;
        readonly requested_at: any | null;
        readonly skill: {
          readonly id: string;
          readonly is_ghost: boolean | null;
          readonly published: boolean | null;
          readonly title: string | null;
        } | null;
        readonly user: {
          readonly avatar: {
            readonly id: string;
            readonly thumbnail: string | null;
          } | null;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
          readonly profile_color: string | null;
        } | null;
      } | null;
    } | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly otherCollaborator: {
      readonly accepted_at: any | null;
      readonly created_at: any;
      readonly id: string;
      readonly requested_at: any | null;
      readonly skill: {
        readonly id: string;
        readonly is_ghost: boolean | null;
        readonly published: boolean | null;
        readonly title: string | null;
      } | null;
      readonly user: {
        readonly avatar: {
          readonly id: string;
          readonly thumbnail: string | null;
        } | null;
        readonly first_name: string | null;
        readonly id: string;
        readonly last_name: string | null;
        readonly profile_color: string | null;
      } | null;
    } | null;
  } | null;
};
export type ConfirmCollaboratorRequestMutation = {
  response: ConfirmCollaboratorRequestMutation$data;
  variables: ConfirmCollaboratorRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "first_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profile_color",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Skill",
    "kind": "LinkedField",
    "name": "skill",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_ghost",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requested_at",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accepted_at",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "created_at",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCollaboratorEdge",
  "kind": "LinkedField",
  "name": "collaboratorEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillCollaborator",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCollaborator",
  "kind": "LinkedField",
  "name": "otherCollaborator",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "confirmCollaboratorRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmCollaboratorRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmCollaboratorRequestPayload",
        "kind": "LinkedField",
        "name": "confirmCollaboratorRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fc35452fb3d9b66cc0a1a27c92b625b",
    "id": null,
    "metadata": {},
    "name": "ConfirmCollaboratorRequestMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmCollaboratorRequestMutation(\n  $input: ConfirmCollaboratorRequestInput!\n) {\n  confirmCollaboratorRequest(input: $input) {\n    collaboratorEdge {\n      node {\n        id\n        user {\n          id\n          first_name\n          last_name\n          profile_color\n          avatar {\n            id\n            thumbnail\n          }\n        }\n        skill {\n          id\n          title\n          is_ghost\n          published\n        }\n        requested_at\n        accepted_at\n        created_at\n      }\n    }\n    otherCollaborator {\n      id\n      user {\n        id\n        first_name\n        last_name\n        profile_color\n        avatar {\n          id\n          thumbnail\n        }\n      }\n      skill {\n        id\n        title\n        is_ghost\n        published\n      }\n      requested_at\n      accepted_at\n      created_at\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "98db7d52c1fbb25f0473c12767979bf6";

export default node;
