/**
 * @generated SignedSource<<9e148500d5ce35fca6b6d7b2b4db048e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteSkillInput = {
  clientMutationId?: string | null;
  id?: string | null;
};
export type DeleteSkillMutation$variables = {
  input: DeleteSkillInput;
};
export type DeleteSkillMutation$data = {
  readonly deleteSkill: {
    readonly deletedSkillId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly id: string;
      readonly skills: {
        readonly totalCount: number | null;
      } | null;
    } | null;
  } | null;
};
export type DeleteSkillMutation = {
  response: DeleteSkillMutation$data;
  variables: DeleteSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedSkillId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillConnection",
      "kind": "LinkedField",
      "name": "skills",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSkillPayload",
        "kind": "LinkedField",
        "name": "deleteSkill",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSkillPayload",
        "kind": "LinkedField",
        "name": "deleteSkill",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "710978ebc2e6a4a111099a28aa3ffae1",
    "id": null,
    "metadata": {},
    "name": "DeleteSkillMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSkillMutation(\n  $input: DeleteSkillInput!\n) {\n  deleteSkill(input: $input) {\n    deletedSkillId\n    profile {\n      id\n      skills {\n        totalCount\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cedaa38e36772b3e681083a2dc6ec3e2";

export default node;
