/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import type { Environment } from 'react-relay';

const BlockUser = (environment: Environment) => (
  user_id: string,
  block: boolean,
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation BlockUserMutation($input: BlockUserInput!) {
        blockUser(input: $input) {
          error {
            id
            message
          }
          blocked_user {
            id
            viewer_blocked_at
          }
          blocked_user_id
        }
      }
    `;

    const variables = {
      input: {
        user_id,
        block,
      },
    };

    const optimisticResponse = {
      blockUser: {
        blocked_user: {
          id: user_id,
          viewer_blocked_at: new Date().toISOString(),
        },
        error: null,
        blocked_user_id: user_id,
      },
    };

    const configs = [
      {
        type: 'NODE_DELETE',
        deletedIDFieldName: 'blocked_user_id',
      },
    ];

    commitMutation(environment, {
      mutation,
      variables,
      configs,
      optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default BlockUser;
