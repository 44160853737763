/* @flow */
/* eslint react/no-array-index-key: 0 */
import React from 'react';
import classNames from 'classnames';

import styles from './PageIndicators.module.scss';

type Props = {
  length?: number,
  activeIndex?: number,
  className?: ?string,
  indicatorClassName?: ?string,
  activeClassName?: ?string,
};

const PageIndicators = ({
  length = 0,
  activeIndex,
  className,
  indicatorClassName,
  activeClassName,
}: Props) => {
  const indicators = Array(length).fill(0);
  /** we do not show page indicator when it only one page */
  return length > 1 ? (
    <div className={classNames([styles.container, className])}>
      {indicators.map((val, index) => (
        <div
          key={index}
          className={classNames([
            styles.indicator,
            {
              [styles.active]: activeIndex === index + 1,
              [activeClassName]: activeIndex === index + 1,
            },
            indicatorClassName,
          ])}
        />
      ))}
    </div>
  ) : null;
};

export default PageIndicators;
