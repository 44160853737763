import React from 'react';

import { ModalForm, Row, Col } from '@pluralcom/blueprint';
import classNames from 'classnames';
import styles from './AddModal.module.scss';

export declare interface AddModalProps
  extends React.ComponentProps<typeof ModalForm> {}

/**
 * - Figma v0.0.1
 * - This is the AddModal compoent for settings/AccountTab
 * - wraps the <AddModalEmail/> and <AddModalPhone/> componet
 * - @see ModalForm
 */
const AddModal = ({ modalBody, ...rest }: AddModalProps) => (
  <ModalForm
    isMaxHeight
    navHeaderProps={{
      text: 'Title',
    }}
    containerProps={{
      noPadding: true,
      className: classNames(['gx-0', styles['modal-container']]),
    }}
    {...rest}
    modalBody={
      <Row className="gx-0">
        <Col
          xs={10}
          xsOffset={1}
          md={8}
          mdOffset={2}
          className={styles['modal-input']}
        >
          {modalBody}
        </Col>
      </Row>
    }
    modalProps={{
      wrapClassName: classNames([styles.modalForm]),
    }}
    bottomBarProps={{
      buttonCount: 2,
      ...rest?.bottomBarProps,
      tertiaryButtonProps: {
        ...rest?.bottomBarProps?.tertiaryButtonProps,
        uiType: 'tertiary',
        text: 'Cancel',
      },
      primaryButtonProps: {
        ...rest?.bottomBarProps?.primaryButtonProps,
        uiType: 'primary',
        text: 'Next',
      },
    }}
    className={classNames([styles.modalbody, rest?.className])}
  />
);

export default AddModal;
