/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Button } from '@pluralcom/blueprint';

import { EmojiSmile, EmojiSad } from '../../assets/img/emojis';
import PlAplEmoji from '../PlAplEmoji/PlAplEmoji';

import styles from './RadioButton.module.scss';

type Props = {
  value?: ?boolean,
  onChange?: ?Function,
  leftEmojiSrc?: ?string,
  rightEmojiSrc?: ?string,
  className?: ?string,
  disabled?: boolean,
};

const RadioButton = ({
  className,
  value,
  onChange,
  leftEmojiSrc = EmojiSad,
  rightEmojiSrc = EmojiSmile,
  disabled,
}: Props) => (
  <div className={classNames([styles.container, className])}>
    <Button
      className={classNames([
        styles['emoji-wrapper'],
        { [styles.highlighted]: value === false },
      ])}
      onClick={() =>
        typeof onChange === 'function' ? onChange(false) : undefined
      }
      disabled={disabled}
    >
      <PlAplEmoji emojiSrc={leftEmojiSrc} className={styles['emoji-img']} />
    </Button>
    <Button
      className={classNames([
        styles['emoji-wrapper'],
        { [styles.highlighted]: value === true },
      ])}
      onClick={() =>
        typeof onChange === 'function' ? onChange(true) : undefined
      }
      disabled={disabled}
    >
      <PlAplEmoji emojiSrc={rightEmojiSrc} className={styles['emoji-img']} />
    </Button>
  </div>
);

export default RadioButton;
