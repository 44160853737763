/* @flow */
import React, { PureComponent } from 'react';
import { sentryHelpers, logger } from '../../utils';

import { ErrorView } from './components';

type Props = {
  children?: any,
  safe?: boolean,
  renderErrorView?: Function,
};

type State = {
  error: ?Object,
};

export default class ErrorBoundary extends PureComponent<*, Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch = (error, errorInfo) => {
    logger.error('Render Error, caught by ErrorBoundary', error, errorInfo);
    sentryHelpers.captureException(error, { errorInfo });
  };

  render() {
    const { children, safe, renderErrorView } = this.props;
    const { error } = this.state;
    if (error && !safe) {
      /** A non Safe error was encountered */
      if (renderErrorView) {
        return typeof renderErrorView === 'function'
          ? renderErrorView({ error })
          : renderErrorView;
      }
      return <ErrorView />;
    }
    /** No errors or safe errors */
    return children;
  }
}
