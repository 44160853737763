/* @flow */
// import { ConnectionHandler } from 'relay-runtime';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription messageReactionsSubscribeSubscription {
    messageReactionsSubscribe {
      reactions {
        value
        users {
          id
        }
      }
      messageId
    }
  }
`;

const messagesSubscribe = () =>
  requestSubscription({
    subscription,
    updater: (store) => {
      const updateedReaction = store.getRootField('messageReactionsSubscribe');
      const messageId = updateedReaction.getValue('messageId');
      const reactions = updateedReaction.getLinkedRecords('reactions');
      const messageProxy = store.get(messageId);
      if (messageProxy) {
        messageProxy.setLinkedRecords(reactions, 'reactions');
      }
    },
  });

export default messagesSubscribe;
