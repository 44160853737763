/* @flow */
import { combineReducers } from 'redux';

import {
  appSettingsReducer,
  authReducer,
  legalReducer,
  modalsReducer,
} from '..';

export type StateType = {};
export type ActionType = { type: string, payload?: any, [string]: any };

const initialState: StateType = {};

export { initialState };

export default combineReducers({
  appSettings: appSettingsReducer,
  auth: authReducer,
  legal: legalReducer,
  modals: modalsReducer,
  /** Add more reducers above */
});
