/**
 * @generated SignedSource<<3f40bf6339bdaa22ad27a7e3b57bfca8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveLanguageInput = {
  clientMutationId?: string | null;
  id: string;
  language_code: string;
};
export type RemoveLanguageMutation$variables = {
  input: RemoveLanguageInput;
};
export type RemoveLanguageMutation$data = {
  readonly removeLanguage: {
    readonly deletedLanguageId: string | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly user: {
      readonly languages: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly code: string | null;
            readonly id: string;
            readonly name: string | null;
            readonly native_name: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type RemoveLanguageMutation = {
  response: RemoveLanguageMutation$data;
  variables: RemoveLanguageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LanguageConnection",
  "kind": "LinkedField",
  "name": "languages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LanguageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Language",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "native_name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedLanguageId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveLanguagePayload",
        "kind": "LinkedField",
        "name": "removeLanguage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveLanguageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveLanguagePayload",
        "kind": "LinkedField",
        "name": "removeLanguage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df62b0000096d0fc8ee15f6665b9e540",
    "id": null,
    "metadata": {},
    "name": "RemoveLanguageMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveLanguageMutation(\n  $input: RemoveLanguageInput!\n) {\n  removeLanguage(input: $input) {\n    user {\n      languages {\n        edges {\n          node {\n            id\n            code\n            name\n            native_name\n          }\n        }\n      }\n      id\n    }\n    deletedLanguageId\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a15f9e719caa4439b64c812bb20f54b";

export default node;
