/**
 * @generated SignedSource<<2ab76586575e1257a0ec375091ccde8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReferralStatusEnum = "ineligible" | "pending" | "success" | "%future added value";
export type ReferUserInput = {
  clientMutationId?: string | null;
  note?: string | null;
  referred_email?: string | null;
  referred_phone?: string | null;
  referred_to_email?: string | null;
  referred_to_phone?: string | null;
  referred_to_user_id?: string | null;
  referred_user_id?: string | null;
  skill_id?: string | null;
  skill_title?: string | null;
  user_search_public_id?: string | null;
};
export type ReferUserMutation$variables = {
  input: ReferUserInput;
};
export type ReferUserMutation$data = {
  readonly referUser: {
    readonly createdReferrals: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly created_at: any;
          readonly earned: number | null;
          readonly id: string;
          readonly referred: {
            readonly avatar: {
              readonly id: string;
              readonly smallThumbnail: string | null;
            } | null;
            readonly email: string | null;
            readonly first_name: string | null;
            readonly id: string;
            readonly last_name: string | null;
            readonly phone: string | null;
            readonly profile_color: string | null;
          };
          readonly referred_to: {
            readonly avatar: {
              readonly id: string;
              readonly smallThumbnail: string | null;
            } | null;
            readonly email: string | null;
            readonly first_name: string | null;
            readonly id: string;
            readonly last_name: string | null;
            readonly phone: string | null;
            readonly profile_color: string | null;
          } | null;
          readonly status: ReferralStatusEnum | null;
        } | null;
      } | null> | null;
    } | null;
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly referrals: {
      readonly id: string;
      readonly totalEarnableReferrals: number | null;
      readonly totalEarned: number | null;
    } | null;
  } | null;
};
export type ReferUserMutation = {
  response: ReferUserMutation$data;
  variables: ReferUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "first_name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "last_name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "profile_color",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "smallThumbnail",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ReferralConnection",
  "kind": "LinkedField",
  "name": "createdReferrals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReferralEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Referral",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "referred",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "referred_to",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "earned",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created_at",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Referrals",
  "kind": "LinkedField",
  "name": "referrals",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalEarned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalEarnableReferrals",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferUserPayload",
        "kind": "LinkedField",
        "name": "referUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReferUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferUserPayload",
        "kind": "LinkedField",
        "name": "referUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f3a36cb48348b0a923a57d05bea353f",
    "id": null,
    "metadata": {},
    "name": "ReferUserMutation",
    "operationKind": "mutation",
    "text": "mutation ReferUserMutation(\n  $input: ReferUserInput!\n) {\n  referUser(input: $input) {\n    createdReferrals {\n      edges {\n        node {\n          id\n          referred {\n            id\n            first_name\n            last_name\n            profile_color\n            avatar {\n              id\n              smallThumbnail\n            }\n            email\n            phone\n          }\n          referred_to {\n            id\n            first_name\n            last_name\n            profile_color\n            avatar {\n              id\n              smallThumbnail\n            }\n            email\n            phone\n          }\n          status\n          earned\n          created_at\n        }\n      }\n    }\n    referrals {\n      id\n      totalEarned\n      totalEarnableReferrals\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a964233f15ba769f9aceacdd33f83c66";

export default node;
