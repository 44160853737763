/**
 * @generated SignedSource<<9b908329a2892f229b68805242941abc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModalPeopleSelectorQuery$variables = {};
export type ModalPeopleSelectorQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"InputUsers_viewer">;
  } | null;
};
export type ModalPeopleSelectorQuery = {
  response: ModalPeopleSelectorQuery$data;
  variables: ModalPeopleSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "term",
    "value": ""
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_color",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "smallThumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModalPeopleSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InputUsers_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModalPeopleSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_network_synced_timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified_facebook",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGrantedPermission",
            "kind": "LinkedField",
            "name": "userGrantedPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "phonebook",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Search",
            "kind": "LinkedField",
            "name": "search",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "unifiedNetwork",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserNetworkInfo",
                            "kind": "LinkedField",
                            "name": "network_info",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "subtitle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "known_emails_phones",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "published_at",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "unifiedNetwork(first:20)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "searchTerm"
                ],
                "handle": "connection",
                "key": "PeopleSelector_InputUsersList_unifiedNetwork",
                "kind": "LinkedHandle",
                "name": "unifiedNetwork"
              }
            ],
            "storageKey": "search(term:\"\")"
          },
          {
            "alias": "listSearch",
            "args": (v1/*: any*/),
            "concreteType": "Search",
            "kind": "LinkedField",
            "name": "search",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 3
                  }
                ],
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "peopleYouMayKnow",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "peopleYouMayKnow(first:3)"
              }
            ],
            "storageKey": "search(term:\"\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f99fb6517bc555608b09d24f593cbe07",
    "id": null,
    "metadata": {},
    "name": "ModalPeopleSelectorQuery",
    "operationKind": "query",
    "text": "query ModalPeopleSelectorQuery {\n  viewer {\n    ...InputUsers_viewer\n  }\n}\n\nfragment ConnectSection_viewer_EZokl on ViewerType {\n  listSearch: search(term: \"\") {\n    peopleYouMayKnow(first: 3) {\n      edges {\n        node {\n          id\n          first_name\n          last_name\n          profile_color\n          avatar {\n            id\n            smallThumbnail\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment InputUsersListItem_user on User {\n  id\n  username\n  first_name\n  last_name\n  name\n  profile_color\n  avatar {\n    id\n    smallThumbnail\n  }\n  location {\n    id\n    name\n  }\n  network_info {\n    id\n    subtitle\n    known_emails_phones\n  }\n  published_at\n}\n\nfragment InputUsersList_search on Search {\n  unifiedNetwork(first: 20) {\n    totalCount\n    edges {\n      node {\n        id\n        ...InputUsersListItem_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment InputUsers_viewer on ViewerType {\n  profile {\n    id\n    last_network_synced_timestamp\n  }\n  ...ModalConnectSection_viewer\n  search(term: \"\") {\n    ...InputUsersList_search\n  }\n  ...ConnectSection_viewer_EZokl\n}\n\nfragment ModalConnectSection_viewer on ViewerType {\n  profile {\n    verified_facebook\n    id\n  }\n  userGrantedPermissions {\n    phonebook {\n      id\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "498ea81213bb89017d727781905a73e1";

export default node;
