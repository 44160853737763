/**
 * @generated SignedSource<<d4da56309591d0fa43d5829c1741337c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditSkillInput = {
  clientMutationId?: string | null;
  description?: string | null;
  experience_description?: string | null;
  id: string;
  is_ghost?: boolean | null;
  is_hireable?: boolean | null;
  is_hobby?: boolean | null;
  published?: boolean | null;
  skillTags?: ReadonlyArray<string | null> | null;
  title?: string | null;
};
export type EditSkillMutation$variables = {
  input: EditSkillInput;
};
export type EditSkillMutation$data = {
  readonly editSkill: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly skill: {
      readonly description: string | null;
      readonly experience: {
        readonly description: string | null;
        readonly id: string;
      } | null;
      readonly id: string;
      readonly is_draft: boolean | null;
      readonly is_ghost: boolean | null;
      readonly is_hireable: boolean | null;
      readonly is_hobby: boolean | null;
      readonly published: boolean | null;
      readonly skillTags: ReadonlyArray<{
        readonly id: string;
        readonly title: string | null;
      } | null> | null;
      readonly title: string | null;
    } | null;
  } | null;
};
export type EditSkillMutation = {
  response: EditSkillMutation$data;
  variables: EditSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_ghost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_draft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_hobby",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Experience",
      "kind": "LinkedField",
      "name": "experience",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "skillTags",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_hireable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillPayload",
        "kind": "LinkedField",
        "name": "editSkill",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillPayload",
        "kind": "LinkedField",
        "name": "editSkill",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57844c53208e895617df1dbf2644b82a",
    "id": null,
    "metadata": {},
    "name": "EditSkillMutation",
    "operationKind": "mutation",
    "text": "mutation EditSkillMutation(\n  $input: EditSkillInput!\n) {\n  editSkill(input: $input) {\n    skill {\n      id\n      title\n      description\n      is_ghost\n      published\n      is_draft\n      is_hobby\n      experience {\n        id\n        description\n      }\n      skillTags {\n        id\n        title\n      }\n      is_hireable\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6a747b755466ad064db59cc6d78ca4b";

export default node;
