/* @flow */

import cloneDeep from 'lodash/cloneDeep';
import { RecordSourceInspector } from 'relay-runtime';

export const setNodeValues = (node: Object, data: Object) => {
  Object.keys(data).forEach((key) => {
    node.setValue(data[key], key);
  });
};

export const setNodeLinkedRecords = (node: Object, data: Object) => {
  Object.keys(data).forEach((key) => {
    node.setLinkedRecords(data[key], key);
  });
};

export const stripId = (item: Object) => {
  const newItem = cloneDeep(item);
  delete newItem.id;
  return newItem;
};

export const getSourceRecords = (source) => {
  try {
    return new RecordSourceInspector(source)._source._records;
  } catch (e) {
    return {};
  }
};
