/* @flow */
import {
  addBreadcrumb,
  captureEvent,
  captureException as captureExceptionRaw,
  captureMessage,
  configureScope,
  showReportDialog,
  init as initSentry,
} from '@sentry/browser';
import { localStorageService } from '@pluralcom/plural-web-utils';
import pick from 'lodash/pick';

import logger from '../logger/logger';
import environment from '../environment/environment';
import RandomSeed from '../../services/RandomSeed/RandomSeed';
// import localStorageHelpers from '../localStorageHelpers';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// /**
//  * Show sentry report dialog with user data from localstorage
//  */
// const showReportDialogWithUser = (options?: Object) => {
//   const { name, email, username } = localStorageService.getItem('user') || {};
//   let user = {
//     name: name || username,
//   };
//   if (email) {
//     user = {
//       ...user,
//       email,
//     };
//   }
//   showReportDialog({
//     user,
//     ...options,
//   });
// };

// /**
//  * Fired before sentry sends an event
//  */
// const beforeSend = (event) => {
//   // Check if it is an exception and the user is an admin, if so, show the report dialog
//   if (
//     event.exception &&
//     localStorageHelpers.isAdmin() &&
//     !environment.isStaging()
//   ) {
//     showReportDialogWithUser({ eventId: event.event_id });
//   }
//   return event;
// };

/**
 * Sentry configurations
 * https://sentry.io/settings/plural/web
 */
const sentryConfig = {
  dsn: SENTRY_DSN,
  environment: environment.getHostEnv(),
  release: process.env.REACT_APP_VERSION,
  // beforeSend, // disabled for tim
};

const setScopeUser = (user?: {
  id: ?string,
  username: ?string,
  email: ?string,
}) => {
  const currUser = user || localStorageService.getItem('user');
  if (currUser) {
    configureScope((scope) => {
      const { username, id, email } = currUser;
      let scopeUser = { username, id };
      if (email) {
        scopeUser = {
          ...user,
          email,
        };
      }
      scope.setUser(scopeUser);
      scope.setTag('username', username);
      scope.setTag('user.id', id);
    });
  }
};

const setScopeRandomSeed = (seed?: ?string) => {
  const randomseed = seed || RandomSeed.load();
  if (randomseed) {
    configureScope((scope) => {
      scope.setTag('randomseed', randomseed);
    });
  }
};

const setScopeGeolocation = (
  geolocation: {
    longitude?: number,
    latitude?: number,
    city?: string,
    geolocationByIP?: boolean,
  } = {},
) => {
  configureScope((scope) => {
    const filteredData = pick(geolocation, [
      'longitude',
      'latitude',
      'city',
      'geolocationByIP',
    ]);
    Object.keys(filteredData).forEach((key) => {
      const item = filteredData[key];
      if (item) {
        scope.setTag(
          key,
          typeof item === 'object' ? JSON.stringify(item) : `${item}`,
        );
      }
    });
  });
};

const initialConfigureScope = () => {
  configureScope((scope) => {
    scope.setTag('hostname', window.location.hostname);
  });
  setScopeUser();
  setScopeRandomSeed();
};

/**
 * Sets up sentry in case of a remote deployment
 */
const init = (config: Object = {}) => {
  if (
    !environment.isRemote() ||
    environment.isTest() ||
    process.env.REACT_APP_IS_E2E || // disable for e2e tests
    window.navigator.userAgent === 'ReactSnap'
  ) {
    return;
  }
  if (!SENTRY_DSN) {
    logger.error(
      '❌ 🚨 Did not setup sentry due to missing SENTRY_DSN variable.',
    );
    return;
  }
  initSentry({
    ...sentryConfig,
    ...config,
  });
  initialConfigureScope();
};

const captureException = (err, ...rest) => {
  let error = err;
  if (typeof error === 'object') {
    error = JSON.stringify(error);
  }
  return captureExceptionRaw(error, ...rest);
};

export default {
  addBreadcrumb,
  init,
  captureEvent,
  captureException,
  captureMessage,
  configureScope,
  showReportDialog,
  setScopeUser,
  setScopeRandomSeed,
  setScopeGeolocation,
};
