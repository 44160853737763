import React from 'react';
import classNames from 'classnames';

import styles from './TabItem.module.scss';

interface TabItemProps extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * - Figma v0.0.1
 * - TabItem component for settings/AccountTab
 * - This wraps the content of the tab
 */
const TabItem = ({ className, ...rest }: TabItemProps) => (
  <div className={classNames([styles.container, className])} {...rest} />
);

export default TabItem;
