/**
 * @generated SignedSource<<b76a8e7ba50a28caa881f435cb99ea99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectSection_viewer$data = {
  readonly listSearch: {
    readonly peopleYouMayKnow: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar: {
            readonly id: string;
            readonly smallThumbnail: string | null;
          } | null;
          readonly first_name: string | null;
          readonly id: string;
          readonly last_name: string | null;
          readonly profile_color: string | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "ConnectSection_viewer";
};
export type ConnectSection_viewer$key = {
  readonly " $data"?: ConnectSection_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectSection_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "latitude"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "longitude"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectSection_viewer",
  "selections": [
    {
      "alias": "listSearch",
      "args": [
        {
          "kind": "Variable",
          "name": "latitude",
          "variableName": "latitude"
        },
        {
          "kind": "Variable",
          "name": "longitude",
          "variableName": "longitude"
        },
        {
          "kind": "Literal",
          "name": "term",
          "value": ""
        }
      ],
      "concreteType": "Search",
      "kind": "LinkedField",
      "name": "search",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            }
          ],
          "concreteType": "UserConnection",
          "kind": "LinkedField",
          "name": "peopleYouMayKnow",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "first_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profile_color",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Image",
                      "kind": "LinkedField",
                      "name": "avatar",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "smallThumbnail",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "peopleYouMayKnow(first:3)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};
})();

(node as any).hash = "745c2d221b25da2ebeb0fcc9bcbd2595";

export default node;
