/**
 * @note: @shared @web @native
 */
import {
  requiresExtraAuthVerification,
  requiresPhoneVerification,
} from '@pluralcom/plural-js-utils/lib/authHelpers';

import { logger, sentryHelpers, localStorageHelpers } from '../../utils';
import reduxStore from '../../redux/store';
import {
  authRootObjUpdate,
  authMainFormReset,
} from '../../redux/reducers/authReducer/authReducer';

const MAX_RETRIES_COUNT = 0;

const _onErrorFactory = ({ config, retryCommitMutation, retryCount = 0 }) => (
  err,
) => {
  const { onError } = config;
  logger.debugError('Graphql Commit Mutation error', err, {
    config,
    retryCount,
  });
  sentryHelpers.addBreadcrumb({
    level: 'error',
    category: 'graphql_error_mutation',
    message: `Commit Mutation Error: ${err?.message || err}`,
    // data: config,
  });

  const verifyCodeOnFail = (resolve, error) => {
    authMainFormReset()(reduxStore.dispatch);
    if (config.onError) {
      const formattedError = err;
      formattedError.message = error?.message || formattedError.message;
      /**
       * Pass the error to the onCompleted function as if it came in the mutation
       * to trigger the .then and inMutation error checking instead of relying on .catch
       * --
       * Comment the if condition below to rely on .catch
       */
      if (err.res.json.errors.length === 1) {
        const dataKeys = Object.keys(err.res.json.data);
        if (dataKeys.length === 1) {
          const newData = {
            [dataKeys[0]]: {
              error: formattedError,
              ...err.res.json.data[dataKeys[0]],
            },
          };
          if (config.onCompleted) {
            config.onCompleted(newData, []);
          }
          return resolve({ error: err });
        }
      }
      config.onError(formattedError);
    }
    return resolve({ error: err });
  };
  const verifyCodeOnSuccess = (resolve) =>
    retryCommitMutation({
      ...config,
      onCompleted: (...args) => {
        if (config.onCompleted) {
          config.onCompleted(...args);
        }
        return resolve({ data: args[0], errors: args[1] });
      },
      onError: _onErrorFactory({
        config,
        retryCommitMutation,
        /** Keep retry count as is since this is the first retry after auth */
        retryCount,
      }),
    });

  /** Handle Requires Extra Auth Verification */
  const {
    isAllowed: isAllowedExtraAuthVerification,
    auth_reqid,
    email_or_phone,
    error: errorExtraAuthVerification,
  } = requiresExtraAuthVerification(err?.res?.json?.errors || err);
  /** Handle errors that require client action */
  if (!isAllowedExtraAuthVerification) {
    const user = localStorageHelpers.getUser();
    /** Returning a promise to simulate a thread, and pause the parent calling function till auth is done */
    return new Promise((resolve) => {
      authRootObjUpdate({
        authMainForm: {
          first_name: user.first_name,
          last_name: user.last_name,
          emailOrPhone: email_or_phone,
          auth_reqid,
        },
        isOpenModalVerifyCode: true,
        verifyCodeOnFail: () =>
          verifyCodeOnFail(resolve, errorExtraAuthVerification),
        verifyCodeOnSuccess: () => verifyCodeOnSuccess(resolve),
      })(reduxStore.dispatch);
    });
  }

  /** Handle Requires Phone Verification */
  const {
    isAllowed: isAllowedPhoneVerification,
    error: errorPhoneVerification,
  } = requiresPhoneVerification(err?.res?.json?.errors || err);
  /** Handle errors that require client action */
  if (!isAllowedPhoneVerification) {
    return new Promise((resolve) => {
      authRootObjUpdate({
        /** Open ModalAddPhone */
        isOpenModalAddPhone: true,
        verifyCodeOnFail: () =>
          verifyCodeOnFail(resolve, errorPhoneVerification),
        verifyCodeOnSuccess: () => verifyCodeOnSuccess(resolve),
      })(reduxStore.dispatch);
    });
  }

  sentryHelpers.captureException(err);

  /** Retry mutation - normal retries */
  if (retryCount < MAX_RETRIES_COUNT) {
    return retryCommitMutation({
      ...config,
      onError: _onErrorFactory({
        config,
        retryCommitMutation,
        retryCount: retryCount + 1,
      }),
    });
  }

  /** Reject */
  return onError && onError(err);
};

const configMiddlwareError = (config, { retryCommitMutation } = {}) => ({
  ...config,
  onError: _onErrorFactory({ config, retryCommitMutation }),
});

const configMiddleware = (config, { retryCommitMutation } = {}) => {
  if (!config) {
    return config;
  }
  return configMiddlwareError(config, { retryCommitMutation });
};

export default configMiddleware;
