import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { authHelpers, analyticsHelpers } from '@pluralcom/plural-js-utils';
import { get, toArray } from 'lodash';
import validator from 'validator';
import {
  ButtonFlat,
  Text,
  InputCode,
  InputTextRefHandle,
} from '@pluralcom/blueprint';
import { useLocation, useNavigate } from 'react-router-dom';
import { withRouter } from '../../../hocs/withRouter/withRouter';

import { authMainFormReset as authMainFormResetAC } from '../../../redux/reducers/authReducer/authReducer';
import { RequestLoginPinMutation } from '../../../graphql/mutations';
import { errorTexts, inputsMaxLengths } from '../../../assets/data';
import { Socket } from '../../../services';
import Auth from '../../../services/Auth/Auth';
import { logger, mixpanelHelpers, validation } from '../../../utils';

import { Container } from '../components';
import VerifyCodeHelpModal from '../modals/VerifyCodeHelpModal/VerifyCodeHelpModal';
import verifyCodeUrlHelpers from './verifyCodeUrlHelpers';

import styles from './VerifyCode.module.scss';

interface VerifyCodeProps {
  /** Custom class name */
  className?: string;
  /** Function to call on success */
  onSuccess?: Function;
  /** Email or phone number */
  emailOrPhone?: string;
  /** Verification code */
  code?: string;
  /** Auth request id */
  auth_reqid?: string;
  /** Props for footer button */
  footerButtonProps?: Object;
  /** Props for submit button */
  submitBtnProps?: {
    /** Children */
    children?: React.ReactNode;
  };
  /** Props for help dialog buttons */
  helpDialogButtonsProps?: Object;
  /** Function to reset auth main form */
  authMainFormReset: Function;
  /** identifier to add vertical padding */
  withScreenSpacing?: boolean;
  /** Whether this is a verification */
  isVerification?: boolean;
  /** Auth main form */
  authMainForm: {
    /** Email or phone number */
    emailOrPhone: string;
    /** Auth request id */
    auth_reqid: string;
  };
}

const VerifyCode = ({
  emailOrPhone: pEmailOrPhone,
  code: pCode,
  auth_reqid: p_auth_reqid,
  authMainForm,
  onSuccess,
  className,
  withScreenSpacing,
  isVerification,
  submitBtnProps,
  footerButtonProps,
  helpDialogButtonsProps,
  authMainFormReset,
}: VerifyCodeProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<InputTextRefHandle>(null);

  const {
    username: qUsername,
    code: qCode,
    auth_reqid: q_auth_reqid,
    submit: shouldSubmit,
  } = verifyCodeUrlHelpers.decodeUrlQuery(location?.search);

  const [emailOrPhone, setEmailOrPhone] = useState<string>(
    pEmailOrPhone ||
      qUsername ||
      (authMainForm && authMainForm.emailOrPhone) ||
      '',
  );
  const [code, setCode] = useState<Array<string>>(
    toArray(pCode) || toArray(qCode) || [],
  );
  const [auth_reqid, setAuth_reqid] = useState<string>(
    p_auth_reqid ||
      q_auth_reqid ||
      (authMainForm && authMainForm.auth_reqid) ||
      '',
  );
  const [codeError, setCodeError] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenVerifyCodeHelp, setIsOpenVerifyCodeHelp] = useState(false);

  /** Focus on input on mount */
  useEffect(() => {
    setTimeout(() => inputRef?.current?.inputRef?.current?.focus?.(), 100);
  }, []);

  /** handle paste on load */
  useEffect(() => {
    if (shouldSubmit) {
      // eslint-disable-next-line no-use-before-define
      _submit();
    }
    setAuth_reqid(
      p_auth_reqid ||
        q_auth_reqid ||
        (authMainForm && authMainForm.auth_reqid) ||
        '',
    );
    setEmailOrPhone(
      pEmailOrPhone ||
        qUsername ||
        (authMainForm && authMainForm.emailOrPhone) ||
        '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleCodeInputChange = () => (event) => {
    const { value } = event.target;
    setCode(value);
  };

  const _getFieldError = (name: string, value: string) => {
    switch (name) {
      case 'code':
        if (!validation.isValidAuthVerificationCode(value)) {
          return errorTexts.invalidAuthVerificationCode;
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const _getFormErrors = () => {
    const error = _getFieldError('code', code?.join(''));
    setCodeError(error || '');
    if (error) {
      return true;
    }
    return false;
  };

  const _onSuccess = (profile, data) => {
    if (onSuccess) {
      onSuccess(profile, data);
    }
    /** redirect for user profile if login by modal */
    const pathname = get(location, 'pathname', '');
    authMainFormReset();
    return (
      ['/login', '/signup', '404', '401'].includes(pathname) &&
      navigate(`./${profile.username}`)
    );
  };

  const _submit = (e?: {
    /** Event */
    preventDefault: Function;
  }) => {
    if (e) {
      e.preventDefault();
    }
    const hasError = _getFormErrors();
    if (hasError) {
      return;
    }
    setIsLoading(true);
    Auth.verifyPin({
      pin: code?.join(''),
      auth_reqid,
      ...authHelpers.parseMainAuthInputField(emailOrPhone),
    })
      .then(({ verifyPin: { profile, error: serverErrorRes } }) => {
        const response = !serverErrorRes;
        setServerError(serverErrorRes ? serverErrorRes.message : null);
        setIsLoading(false);
        if (response) {
          /** Init socket */
          Socket.connectToServer();
          _onSuccess(profile, { emailOrPhone });
        }
      })
      .catch((err) => {
        logger.error(err && err.message);
        setServerError(
          `${errorTexts.networkErrorEncountered} ${errorTexts.pleaseTryAgain}`,
        );
        setIsLoading(false);
      });
  };

  /**
   * Toggles the visibility of a modal by modalName.
   */
  const _toggleModal = (e?: {
    /** Event */
    preventDefault: Function;
  }) => {
    if (e) {
      e.preventDefault();
    }
    if (codeError || serverError) {
      setCodeError('');
      setServerError('');
    }
    setIsOpenVerifyCodeHelp(!isOpenVerifyCodeHelp);
    inputRef?.current?.inputRef?.current?.focus();
  };

  return (
    <Container
      className={classNames([styles.container, className])}
      withSpacing={withScreenSpacing}
    >
      <div className={styles['sub-container']} data-testid="verify_code__form">
        <div className={styles['container-content']}>
          {isVerification ? (
            <>
              <Text
                fontSizeType="t2"
                element="span"
                stickToBreakpoint="lg"
                className={styles.text}
              >
                Verification Required
              </Text>
              <Text
                fontSizeType="t7"
                stickToBreakpoint="lg"
                className={styles['verification-p']}
              >
                Please verify this action by entering the code sent to{' '}
                <strong>{emailOrPhone || 'you'}</strong>.{' '}
                {validator.isEmail(emailOrPhone)
                  ? "If you can't find it, please check your spam folder."
                  : ''}
              </Text>
            </>
          ) : (
            <>
              <Text
                fontSizeType="t2"
                stickToBreakpoint="lg"
                element="span"
                className={styles.text}
              >
                {` Enter the code sent to ${emailOrPhone}`}
              </Text>
              {validator.isEmail(emailOrPhone) && (
                <Text
                  fontSizeType="t7"
                  stickToBreakpoint="lg"
                  element="span"
                  className={styles.text}
                >
                  {`If you can't find it, please check your spam folder.`}
                </Text>
              )}
            </>
          )}
        </div>
        <div className={styles['container-code']}>
          <InputCode
            ref={inputRef}
            // @todo @monica - Check if we need it or not, if we do need then fix on InputCode
            // @ts-ignore-next-line
            fields={inputsMaxLengths.authVerificationCode}
            name="code"
            code={code}
            type="number"
            hasError={codeError}
            setCode={setCode}
            onMouseDown={() =>
              mixpanelHelpers.trackEvent(
                analyticsHelpers.events.VERIFYCODE_INPUT_FOCUS.name,
              )
            }
            onChange={() => {
              if (code.length === 0) {
                mixpanelHelpers.trackEvent(
                  analyticsHelpers.events.VERIFYCODE_INPUT_CHANGE.name,
                );
                _handleCodeInputChange();
              } else _handleCodeInputChange();
            }}
          />
          {(codeError || serverError) && (
            <Text
              fontSizeType="t9"
              element="span"
              className={styles['text-error']}
              stickToBreakpoint="xs"
              data-testid="verify_code__errors__email"
            >
              {codeError || serverError}
            </Text>
          )}
        </div>
        <ButtonFlat
          data-testid="verify_code__buttons_continue"
          isLoading={isLoading}
          fullWidth
          uiType="primary"
          size="lg"
          onClick={(event) => {
            mixpanelHelpers.trackEvent(
              analyticsHelpers.events.VERIFYCODE_BUTTON_SIGNIN.name,
            );
            _submit(event);
          }}
          className={styles.button}
          {...submitBtnProps}
        >
          {submitBtnProps?.children || 'Verify'}
        </ButtonFlat>

        <ButtonFlat
          {...footerButtonProps}
          uiType="ghost"
          size="lg"
          data-testid="verify_code__buttons_help"
          className={styles.button}
          onClick={() => {
            mixpanelHelpers.trackEvent(
              analyticsHelpers.events.VERIFYCODE_LINK_HELP.name,
            );
            _toggleModal();
          }}
          text="Help"
        />
        <VerifyCodeHelpModal
          isOpen={isOpenVerifyCodeHelp}
          onToggle={_toggleModal}
          {...{
            onSuccess: _onSuccess,
            emailOrPhone,
            auth_reqid,
            resendCodeMutation: RequestLoginPinMutation,
            resendCodeMutationName: 'requestLoginPin',
            isVerification,
            buttonsProps: helpDialogButtonsProps,
            footerButtonProps: {
              onClick: _toggleModal,
            },
          }}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = ({ auth: { authMainForm } }) => ({
  authMainForm,
});

const mapDispatchToProps = (dispatch) => ({
  authMainFormReset: (payload) => dispatch(authMainFormResetAC(payload)),
});

export { VerifyCode as PureVerifyCode };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyCode),
);
