/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default (): void =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation AcceptLatestTosPPMutation($input: AcceptLatestTosPPInput!) {
        acceptLatestTosPP(input: $input) {
          error {
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        accepted: true,
      },
    };

    commitMutation({
      mutation,
      variables,
      onCompleted: (res) => resolve(res),
      onError: (err) => reject(err),
    });
  });
