/* @flow */

/**
 * Checks if the string starts with any of the values in the array
 *
 * @param {*} string
 * @param {*} array
 */
const startsWithAnyOf = (string: string, array: Array<string>): boolean => {
  for (let i = 0; i < array.length; i += 1) {
    if (string.startsWith(array[i])) {
      return true;
    }
  }
  return false;
};

export default startsWithAnyOf;
