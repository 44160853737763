/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import { ButtonFlat } from '@pluralcom/blueprint';

import { errorTexts } from '../../../../assets/data';
import Tile from '../../../Tile/Tile';
import FancyDialog from '../../../FancyDialog/FancyDialog';
import Form from '../../../Form/Form';
import Text from '../../../Text/Text';
import TileInputItem from '../../../TileInputItem/TileInputItem';
import { youtubeUrl } from '../../../../utils';

import styles from './ModalYoutubeUrl.module.scss';

type Props = {
  className?: string,
  onAddVideoUrl: Function,
};

class ModalYoutubeUrl extends Component<*, Props, *> {
  constructor(props) {
    super(props);
    this.state = { videoUrl: '', videoUrlError: null };
  }

  _handleChangeInput = (e) => {
    e.preventDefault();
    this.setState({
      videoUrl: e.target.value,
      [`${e.target.name}Error`]: null,
    });
  };

  _resetForm = () => this.setState({ videoUrl: '', videoUrlError: null });

  _onAddVideoUrl = (e) => {
    const { videoUrl } = this.state;
    const { onAddVideoUrl } = this.props;
    e.preventDefault();

    if (youtubeUrl.isValidVideoUrl(videoUrl) && onAddVideoUrl) {
      onAddVideoUrl(videoUrl);
      this._resetForm();
    } else {
      this.setState({ videoUrlError: errorTexts.invalidYoutubeLink });
    }
  };

  render() {
    const { className, onAddVideoUrl, ...rest } = this.props;
    const { videoUrl, videoUrlError } = this.state;
    return (
      <FancyDialog
        className={classNames(className)}
        title="YouTube Video"
        primaryBtnText={null}
        {...rest}
      >
        <Text>
          Simply paste or type a YouTube video link showcasing your skill. Other
          users can then play this video when viewing your skill.
        </Text>
        <Form className={styles['form-container']}>
          <Tile uiType="clay">
            <TileInputItem
              name="videoUrl"
              placeholder="Enter a link to your youtube video..."
              value={videoUrl}
              onChange={this._handleChangeInput}
              error={videoUrlError}
              autoFocus
            />
          </Tile>
          <ButtonFlat
            uiType="primary"
            size="md"
            className={styles['button-save']}
            type="submit"
            onClick={this._onAddVideoUrl}
          >
            Save
          </ButtonFlat>
        </Form>
      </FancyDialog>
    );
  }
}

export default ModalYoutubeUrl;
