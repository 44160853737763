import stripeHelpers from '@pluralcom/plural-js-utils/lib/stripeHelpers/stripeHelpers';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const initStripe = () => {
  if (window.navigator.userAgent === 'ReactSnap') {
    return;
  }
  if (STRIPE_PUBLIC_KEY) {
    stripeHelpers.setStripePublicKey(STRIPE_PUBLIC_KEY);
  }
};

export default initStripe;
