import React from 'react';
import classNames from 'classnames';

import styles from './EditButton.module.scss';
import SkillCardMiniFloatingButton from '../SkillCardMini/components/SkillCardMiniFloatingButton/SkillCardMiniFloatingButton';

export interface Props {
  /**  The size of the button */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  /** The class name of the button */
  className?: string;
}

const MAP_ICON_SIZE = {
  xs: 'xs',
  sm: 'xs',
  md: 'xs',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};

const EditButton = ({ className, size = 'xs', ...rest }: Props) => {
  const icon_size = MAP_ICON_SIZE[size];

  return (
    <SkillCardMiniFloatingButton
      size={size}
      className={classNames([
        styles.button,
        styles[`button-${icon_size}`],
        className,
      ])}
      iconProps={{
        type: 'fa',
        icon: ['fas', 'edit'],
      }}
      {...rest}
    />
  );
};

export default EditButton;
