/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation CreateCollaboratorRequestMutation(
    $input: CreateCollaboratorRequestInput!
  ) {
    createCollaboratorRequest(input: $input) {
      collaboratorEdge {
        node {
          id
          user {
            id
            first_name
            last_name
            profile_color
            avatar {
              id
              thumbnail
            }
          }
          skill {
            id
            title
            is_ghost
            published
          }
          owner_skill {
            id
            title
          }
          requested_at
          accepted_at
          created_at
        }
      }
      error {
        message
      }
    }
  }
`;

const createCollaboratorRequestMutation = ({
  skill_id,
  user_id,
  email,
  phone,
  other_skill_id,
  other_skill_title,
}: {
  skill_id?: string,
  user_id?: string,
  email?: string,
  phone?: string,
  other_skill_id?: string,
  other_skill_title?: string,
}): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        skill_id,
        user_id,
        email,
        phone,
        other_skill_id,
        other_skill_title,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: skill_id,
        connectionInfo: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'collaboratorEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default createCollaboratorRequestMutation;
