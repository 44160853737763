/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import styles from './ButtonBar.module.scss';

const ButtonBar = ({
  children,
  className,
  ...rest
}: {
  children: any,
  className?: ?string,
}) => (
  <TouchableOpacity
    className={classNames([styles.container, className])}
    {...rest}
  >
    {children}
  </TouchableOpacity>
);

export default ButtonBar;
