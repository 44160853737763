/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription settingsSubscribeSubscription {
    settingsSubscribe {
      id
      has_valid_payment_method
      has_valid_payout_method
    }
  }
`;

const settingsSubscribe = () =>
  requestSubscription({
    subscription,
  });

export default settingsSubscribe;
