/* @flow */
const ROOT_PREFIX: string = 'LEGAL/';

/** Typechecking for javascript */
export type SettingsStateType = {};
const initialState: SettingsStateType = { hasAcceptedLatestContracts: true };

// Actions
export type AuthActionType = { type: string, [string]: any };
export const TOGGLE_ACCEPTED = `${ROOT_PREFIX}TOGGLE_ACCEPTED`;
export const RESET_ACCEPTED = `${ROOT_PREFIX}RESET_ACCEPTED`;

// Action creators
export const toggleLegalAccepted = (value: any) => (dispatch: Function) => {
  dispatch({
    type: TOGGLE_ACCEPTED,
    payload: value,
  });
};

// Reducer
export default (
  state: SettingsStateType = initialState,
  action: AuthActionType
) => {
  switch (action.type) {
    case TOGGLE_ACCEPTED:
      return {
        ...state,
        hasAcceptedLatestContracts: action.payload,
      };
    case RESET_ACCEPTED:
      return {
        ...state,
        hasAcceptedLatestContracts: false,
      };
    default:
      return state;
  }
};
