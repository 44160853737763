import React, { useCallback } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { COMMUNITY_ACTIVITY_TYPES } from '@pluralcom/plural-js-utils/lib/activityHelpers/constants';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { MapMarker } from '@pluralcom/blueprint';
import { toggleSearchPublicModal as toggleSearchPublicModalAction } from '../../../../redux/reducers/modalsReducer/modalsReducer';
import PluralMap from '../../../Maps/PluralMap/PluralMap';

import styles from './CommunityActivitiesListItem.module.scss';

import type { CommunityActivitiesListItem_data$key } from './__generated__/CommunityActivitiesListItem_data.graphql';
import ListItemUserInteraction from '../../../ListItemUserInteraction';

interface CommunityActivitiesListItemProps {
  /** fragmented data */
  data: CommunityActivitiesListItem_data$key;
  /** className */
  className?: string;
  /** createdAt */
  createdAt?: string;
  /** callback to toggle modal */
  toggleSearchPublicModal: Function;
}

/**
 * Passes back the location to route to as well as
 * whether it should open up in a new tab.
 * Currently all notifications will just open in
 * by default
 */
const getCommunityActivityRouteConfig = (
  data,
): {
  /** target */
  target?: '_blank' | '';
  /** route */
  route?: string;
  /** onClick */
  onClick?: Function;
} => {
  let websiteRoute;
  const target = '';
  const { route } = data || {};

  const { to, user_id, skill_id } = route || {};

  switch (to) {
    case 'skill':
      websiteRoute = `/skills/${skill_id}`;
      break;
    // USER_PUBLISHED
    // POSITION_ADDED
    // LOCATION_CHANGED
    case 'profile':
      websiteRoute = `/${user_id}`;
      break;
    // SEARCH_LOOKING_FOR <--- implemented in CommunityActivityListItemSearchLookingFor
    default:
      websiteRoute = '';
      break;
  }

  return {
    route: websiteRoute,
    target: websiteRoute && websiteRoute !== '#' ? target : '',
  };
};

/**
 * - Figmva v0.0.1
 * - CommunityActivitiesListItem component for displaying community activities in the list
 */
const CommunityActivitiesListItem = ({
  data: inViewer,
  className,
  createdAt: propCreatedAt,
  toggleSearchPublicModal,
}: CommunityActivitiesListItemProps) => {
  /**
   * Fragment data
   */
  const data = useFragment(
    graphql`
      # @todo @cleanup move into sub-fragments
      fragment CommunityActivitiesListItem_data on CommunityActivity {
        id
        type
        route {
          to
          user_id
          skill_id
        }
        created_at
        title_short
        emoji
        users {
          id
          first_name
          last_name
          profile_color
          avatar {
            id
            smallThumbnail
          }
        }
        location {
          id
          longitude
          latitude
        }
        user_search {
          id
          tag {
            id
            title
          }
          term
        }
      }
    `,
    inViewer || null,
  );

  const { type, created_at, title_short, title, emoji, location } = data || {};
  const { route = '', target: linkTarget } = getCommunityActivityRouteConfig(
    data,
  );

  /**
   * Returns the extra props based on the type of the activity
   */
  const _getTypeBasedProps = useCallback(() => {
    switch (type) {
      case COMMUNITY_ACTIVITY_TYPES.SEARCH_LOOKING_FOR:
        return {
          primaryButtonProps: {
            onClick: (e) => {
              const { users = [], user_search } = data;
              e.preventDefault();
              e.stopPropagation();

              toggleSearchPublicModal({
                isOpen: true,
                payload: {
                  title: user_search?.tag?.title || user_search?.term,
                  user: users,
                },
              });
            },
            children: 'Refer',
          },
        };
      case COMMUNITY_ACTIVITY_TYPES.LOCATION_CHANGED:
        return {
          children: (
            <div className={styles['map-container']}>
              <PluralMap
                center={location}
                withLocateMeBtn={false}
                disableFullscreen
                renderSiblings={() => (
                  <MapMarker
                    isAbsoluteCenter
                    withoutAnimation
                    className={styles['map-marker']}
                    user={data?.users?.[0]}
                  />
                )}
                // @ts-ignore
                options={{
                  draggable: false,
                  draggableCursor: 'pointer',
                  zoomControl: false,
                  scrollwheel: false,
                }}
                height="200px"
                width="100%"
                className={styles.map}
                withMarkerPopUp={false}
                nonInteractive
              />
            </div>
          ),
        };
      default:
        return {};
    }
  }, [data, location, toggleSearchPublicModal, type]);

  return (
    <ListItemUserInteraction
      activity={data}
      title={data?.users?.[0]?.first_name || ''}
      className={className}
      {...(route ? { to: route, target: linkTarget } : {})}
      users={data?.users || []}
      bodyText={`${emoji || ''}  ${title_short || title}`.trim()}
      dateTime={propCreatedAt || created_at}
      {..._getTypeBasedProps()}
      withoutMoreOptions
      bodyTextProps={{
        fontSizeType: 't7',
      }}
    />
  );
};

export { CommunityActivitiesListItem as PureCommunityActivitiesListItem };

export default compose(
  connect(null, {
    toggleSearchPublicModal: toggleSearchPublicModalAction,
  }),
)(CommunityActivitiesListItem);
