import React from 'react';
import isNil from 'lodash/isNil';
import ImageGallery from '../ImageGallery/ImageGallery';
import { SkillCardMiniMediaSection_skill$data } from '../SkillCardMini/components/SkillCardMiniMediaSection/__generated__/SkillCardMiniMediaSection_skill.graphql';

interface SkillMediaGalleryProps {
  /** media */
  media: SkillCardMiniMediaSection_skill$data['media'];
  /** classname */
  className: string;
  /** media Preview indentifer */
  mediaPreview: boolean;
}

const isDefined = (object) => !isNil(object);
const updateItems = (media) =>
  media.edges
    .map(({ node }) => {
      if (node.image) {
        return {
          // spread node to get uploading info
          ...node,
          original:
            node?.image?.original ||
            node?.image?.medium ||
            node?.image?.medium_wide,
          thumbnail: node?.image?.thumbnail || node?.image?.smallThumbnail,
          originalAlt: 'Skill preview',
          thumbnailAlt: 'Skill preview',
        };
      }
      if (node.video) {
        return {
          youtubeUrl: node.video.url,
        };
      }
      return null;
    })
    .filter(isDefined);

const SkillMediaGallery = ({ media, ...rest }: SkillMediaGalleryProps) => (
  <ImageGallery items={updateItems(media)} {...rest} />
);

export default SkillMediaGallery;
