/* @flow */

import { inputsMinLengths as globalInputsMinLengths } from '@pluralcom/plural-js-utils/lib/inputsLengths';

/**
 * Minimum lengths for different input fields.
 */
const inputsMinLengths: { [field: string]: number } = {
  // defaults

  // global
  ...globalInputsMinLengths,

  // override
};

export default inputsMinLengths;
