/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation ConfirmCollaboratorRequestMutation(
    $input: ConfirmCollaboratorRequestInput!
  ) {
    confirmCollaboratorRequest(input: $input) {
      collaboratorEdge {
        node {
          id
          user {
            id
            first_name
            last_name
            profile_color
            avatar {
              id
              thumbnail
            }
          }
          skill {
            id
            title
            is_ghost
            published
          }
          requested_at
          accepted_at
          created_at
        }
      }
      otherCollaborator {
        id
        user {
          id
          first_name
          last_name
          profile_color
          avatar {
            id
            thumbnail
          }
        }
        skill {
          id
          title
          is_ghost
          published
        }
        requested_at
        accepted_at
        created_at
      }
      error {
        message
      }
    }
  }
`;

const confirmCollaboratorRequestMutation = (
  {
    collaborator_id,
  }: {
    collaborator_id: string,
  },
  {
    other_skill_id,
  }: {
    other_skill_id: string,
  },
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id: collaborator_id,
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: other_skill_id,
        connectionInfo: [
          {
            key: 'CollaboratorsSection_SkillCard_collaborators',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'collaboratorEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      configs,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default confirmCollaboratorRequestMutation;
