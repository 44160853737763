/* @flow */

import { base64Helpers } from '@pluralcom/plural-js-utils';

type ResolvedGlobalId = {
  type: string,
  id: string,
};

/**
 * Takes a type name and an ID specific to that type name, and returns a
 * "global ID" that is unique among all types.
 */
const toGlobalId = (type: string, id: string): string =>
  base64Helpers.base64([type, id].join(':'));

/**
 * Takes the "global ID" created by toGlobalID, and returns the type name and ID
 * used to create it.
 */
const fromGlobalId = (globalId: string): ResolvedGlobalId => {
  const unbasedGlobalId = base64Helpers.unbase64(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(':');
  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: unbasedGlobalId.substring(delimiterPos + 1),
  };
};

export { toGlobalId, fromGlobalId };

export default {
  toGlobalId,
  fromGlobalId,
};
