import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  ButtonFlat,
  Avatar,
  AvatarDiagonalOverlay,
  Text,
  ButtonLink,
  ModalDialog,
  ModalForm,
  MenuItem,
  Icon,
} from '@pluralcom/blueprint';

import { useMedia } from 'use-media';
import { PLURALBOT_USER_ID } from '@pluralcom/plural-js-utils/lib/constants';

import { useSwipeable } from 'react-swipeable';
import { useRelayEnvironment } from 'react-relay';

import ListItemUserInteractionTimeAgo from './components';

import styles from './ListItemUserInteraction.module.scss';
import { responsive } from '../../utils';
import ActivityOptionsTooltip from '../ActivityOptionsTooltip/ActivityOptionsTooltip';
import ModalReport from '../ModalReport/ModalReport';
import {
  ArchiveActivityMutation,
  MarkAsReadMutation,
} from '../../graphql/mutations';

interface ListItemUserInteractionProps
  extends Omit<React.ComponentProps<typeof ButtonLink>, 'children'> {
  /** dataId */
  activity: any;
  /** custom className */
  className?: string;
  /** render left child */
  renderLeftChild?: React.ReactNode;
  /** users */
  users?: Array<{
    /** user id */
    id: string;
    /** user first name */
    first_name: string;
    /** avatar */
    avatar?: {
      /** avatar id */
      id: string;
      /** smallThumbnail */
      smallThumbnail?: string | null;
      /** thumbnail */
      thumbnail?: string | null;
    } | null;
  }>;
  /** title  */
  title: string;
  /** titleProps */
  titleProps?: React.ComponentProps<typeof Text>;
  /** body text */
  bodyText?: string;
  /** bodyTextProps */
  bodyTextProps?: React.ComponentProps<typeof Text>;
  /** date time */
  dateTime?: string;
  /** avatarProps */
  avatarProps?: React.ComponentProps<typeof Avatar>;
  /** children to render within the component */
  children?: React.ReactNode;
  /** if True, means message is read */
  isRead?: boolean;
  /** button Props */
  primaryButtonProps?: React.ComponentProps<typeof ButtonFlat>;
  /**
   * If true, this will disable the more options button and the swipe to reveal action
   */
  withoutMoreOptions?: boolean;
}

/**
 * - Figma v0.0.1
 * - ListItemUserInteraction component to show the user interaction
 */
const ListItemUserInteraction = ({
  className,
  users = [],
  bodyText,
  bodyTextProps,
  dateTime,
  primaryButtonProps,
  title,
  titleProps,
  children,
  avatarProps,
  isRead,
  renderLeftChild,
  activity,
  withoutMoreOptions,
  ...rest
}: ListItemUserInteractionProps) => {
  const isMinLG = useMedia({ minWidth: responsive.minDeviceWidthLG });

  /**
   * = States
   */
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * - Modal States
   */
  const [isOpenMoreOptionsModal, setIsOpenMoreOptionsModal] = useState(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);

  // Toggle Modals
  const _toggleMoreOptionsModal = useCallback(
    () => setIsOpenMoreOptionsModal((prev) => !prev),
    [],
  );
  const _toggleArchiveModal = useCallback(
    () => setIsOpenArchiveModal((prev) => !prev),
    [],
  );
  const _toggleReportModal = useCallback(
    () => setIsOpenReportModal((prev) => !prev),
    [],
  );

  // Ref
  const _activityOptionsTriggerRef = useRef(null);

  // Relay environment
  const relayEnvironment = useRelayEnvironment();

  // @todo @chitrank - Move this to global component
  // `ActivityOptionsTooltip` should be popovermenu on LG+ screen
  // and should change to `ModalDialog` on LG- screen
  // handle with designer
  const isPluralBot =
    activity?.user &&
    PLURALBOT_USER_ID === activity?.user &&
    activity?.user?.id;

  /**
   * - Will be handled when user click on 'Something's Wrong' option
   */
  const onMarkAsWrong = (e) => {
    e.stopPropagation();
    e.preventDefault();
    _toggleReportModal();
  };

  /**
   * - Will be handled when user click on 'Archive' option
   */
  const onArchiveOptionClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    _toggleArchiveModal();
  };

  /**
   * - Will be handled when user click on 'Mark as Read' option
   */
  const onMarkAsRead = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpenMoreOptionsModal(false);
    MarkAsReadMutation(relayEnvironment)(
      activity?.id,
      !activity?.seen_at,
      !!activity?.archived_at,
    );
  };

  const _items: Array<React.ComponentProps<typeof MenuItem>> = [
    {
      children: `Mark as ${activity?.seen_at ? 'Unread' : 'Read'}`,
      onClick: (e) => onMarkAsRead(e),
      leftIconProps: {
        icon: ['fas', activity?.seen_at ? 'envelope-open' : 'envelope'],
        type: 'fa',
      },
    },
    ...(!isPluralBot
      ? [
          {
            children: 'Something’s Wrong',
            onClick: (e) => onMarkAsWrong(e),
            leftIconProps: {
              icon: ['fas', 'flag'] as React.ComponentProps<
                typeof Icon
              >['icon'],
              type: 'fa' as React.ComponentProps<typeof Icon>['type'],
            },
          },
        ]
      : []),
    {
      children: 'Archive',
      onClick: (e) => onArchiveOptionClick(e),
      leftIconProps: {
        icon: ['fas', 'trash-can'],
        type: 'fa',
      },
    },
  ];

  const _pluralBotItems: Array<React.ComponentProps<typeof MenuItem>> = [
    {
      children: !activity?.seen_at ? 'Mark as Read' : 'Mark as Unread',
      leftIconProps: {
        icon: ['fas', 'envelope'],
        type: 'fa',
      },
      onClick: (e) => onMarkAsRead(e),
    },
  ];

  const _menuItem = isPluralBot ? _pluralBotItems : _items;

  /**
   * - Handle More Options Click
   */
  const _handleMoreOptionsClick = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      _toggleMoreOptionsModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // More Options Button
  const optionButton = useMemo(
    () =>
      isMinLG ? (
        <div className={styles['more-option']}>
          <ActivityOptionsTooltip
            environment={relayEnvironment}
            acivity={activity}
            onSomethingWrongClick={_toggleReportModal}
            onArchiveClick={_toggleArchiveModal}
            // @ts-ignore-next-line
            getTriggerRef={(ref) => {
              _activityOptionsTriggerRef.current = ref;
            }}
            buttonProps={{
              onClick: (e) => {
                e?.preventDefault();
                e?.stopPropagation();
              },
            }}
            buttonClassName={styles['more-option']}
          />
        </div>
      ) : (
        <ButtonFlat
          size="md"
          uiType="secondary"
          borderType
          iconProps={{
            icon: ['fas', 'bars'],
            type: 'fa',
            iconClassName: styles['bars-icon'],
          }}
          onClick={_handleMoreOptionsClick}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMinLG, activity, relayEnvironment, activity],
  );

  // Swipe Handlers
  const handlePanStart = useCallback(
    (e: any) => {
      if (isMinLG) {
        return;
      }

      if (e.dir === 'Down' || e.dir === 'Up') {
        setIsScrolling(true);
      }
    },
    [isMinLG],
  );

  // Swipe Handlers
  const handlePanEnd = useCallback(() => {
    if (isMinLG) {
      return;
    }

    setIsScrolling(false);
  }, [isMinLG]);

  /**
   * Swipe Handler
   */
  const handleSwipe = useCallback(
    (e: any) => {
      if (isMinLG) {
        return;
      }

      if (!isScrolling) {
        if (e.dir === 'Left' && !isExpanded) {
          // LEFT SWIPE...
          setIsExpanded(true);
        } else if (e.dir === 'Right' && isExpanded) {
          // RIGHT SWIPE...
          setIsExpanded(false);
        }
      }
    },
    [isExpanded, isScrolling, isMinLG],
  );

  const handleArchive = useCallback(() => {
    setIsLoading(true);
    ArchiveActivityMutation(relayEnvironment)(activity?.id || '', true).then(
      () => {
        setIsLoading((prev) => !prev);
        setIsOpenArchiveModal(false);
      },
    );
  }, [activity?.id, relayEnvironment]);

  // Move this logic to global component
  // The swipe to reveal action component under 'Messenger' works with static height
  // @todo @chitrank: Connect with designer for generic swipeable global componenet
  const swipeHandler = useSwipeable({
    onSwiped: () => handlePanEnd(),
    onSwipeStart: (eventData) => handlePanStart(eventData),
    onSwiping: (eventData) => handleSwipe(eventData),
    trackMouse: true,
  });

  /**
   * If `withoutMoreOptions` is true, then we don't need to show the more options button
   * and the swipe to reveal action
   */
  const handlers = withoutMoreOptions ? {} : swipeHandler;

  return (
    <ButtonLink
      fallbackToDiv
      {...rest}
      key={activity?.id}
      className={classNames([styles.container, className])}
    >
      <div {...handlers} className={styles['container-outer']}>
        <div
          className={classNames([
            styles['container-inner'],
            {
              [styles['container-inner--expanded']]: isExpanded,
            },
          ])}
        >
          {renderLeftChild && renderLeftChild}

          {users?.length > 1 ? (
            <AvatarDiagonalOverlay
              users={users}
              size="sm"
              {...avatarProps}
              className={classNames([styles.avatar, avatarProps?.className])}
            />
          ) : null}

          {users?.length === 1 ? (
            <Avatar
              user={users[0] as React.ComponentProps<typeof Avatar>['user']}
              size="md"
              {...avatarProps}
              className={classNames([styles.avatar, avatarProps?.className])}
            />
          ) : null}

          <div className={styles.content}>
            <div className={classNames([styles['details-container']])}>
              <div className={classNames([styles.details])}>
                <div className={styles['title-with-timestamp-container']}>
                  <Text
                    fontSizeType={isRead ? 't7' : 't6'}
                    {...titleProps}
                    className={classNames([styles.text, titleProps?.className])}
                  >
                    {title}
                  </Text>

                  {dateTime ? (
                    <ListItemUserInteractionTimeAgo dateTime={dateTime} />
                  ) : undefined}
                </div>

                {bodyText ? (
                  <Text
                    fontSizeType={isRead ? 't7' : 't6'}
                    {...bodyTextProps}
                    className={classNames([
                      styles.text,
                      {
                        [styles['text--read']]: isRead,
                      },
                      bodyTextProps?.className,
                    ])}
                  >
                    {bodyText}
                  </Text>
                ) : null}
              </div>

              <div className={styles.buttons}>
                {primaryButtonProps ? (
                  <ButtonFlat
                    size="sm"
                    uiType="primary"
                    {...primaryButtonProps}
                    className={classNames([
                      styles.button,
                      primaryButtonProps.className,
                    ])}
                  />
                ) : null}
                {isMinLG && !withoutMoreOptions ? optionButton : null}
              </div>
            </div>
            {children}
          </div>
        </div>

        {withoutMoreOptions ? (
          <div className={styles['more-options-swipeable']}>{optionButton}</div>
        ) : null}

        <ModalReport
          environment={relayEnvironment}
          isOpen={isOpenReportModal}
          toggle={_toggleReportModal}
          id={activity?.id || ''}
          entityType="activity"
        />

        <ModalDialog
          isOpen={isOpenArchiveModal}
          toggle={_toggleArchiveModal}
          title="Are you sure?"
          bodyText="Are you sure you want to archive this thread? You won't be able to access it anymore."
          bottomBarProps={{
            buttonCount: 2,
            primaryButtonProps: {
              text: 'Archive',
              onClick: handleArchive,
              isLoading,
            },
            secondaryButtonProps: {
              disabled: isLoading,
              text: 'Cancel',
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenArchiveModal(false);
              },
            },
          }}
        />

        <ModalForm
          modalProps={{
            wrapClassName: styles.moreOptionModal,
          }}
          isOpen={isOpenMoreOptionsModal}
          toggle={_toggleMoreOptionsModal}
          modalBody={_menuItem.map(
            (item: React.ComponentProps<typeof MenuItem>) => (
              <div key={`Key-${item.children}`}>
                <MenuItem size="lg" {...item} />
              </div>
            ),
          )}
          navHeaderProps={{
            rightButtonProps: {
              iconProps: {
                icon: ['fas', 'times'],
                type: 'fa',
              },
              onClick: () => setIsOpenMoreOptionsModal(false),
            },
          }}
          containerProps={{
            className: styles['report-modal'],
            noPadding: true,
            fluid: false,
          }}
        />
      </div>
    </ButtonLink>
  );
};

export default ListItemUserInteraction;
