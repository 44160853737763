/**
 * @generated SignedSource<<4518b719a695922dee9e1765505dc82b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type settingsSubscribeSubscription$variables = {};
export type settingsSubscribeSubscription$data = {
  readonly settingsSubscribe: {
    readonly has_valid_payment_method: boolean | null;
    readonly has_valid_payout_method: boolean | null;
    readonly id: string;
  } | null;
};
export type settingsSubscribeSubscription = {
  response: settingsSubscribeSubscription$data;
  variables: settingsSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Settings",
    "kind": "LinkedField",
    "name": "settingsSubscribe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "has_valid_payment_method",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "has_valid_payout_method",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsSubscribeSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsSubscribeSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c2a9eaaba6e0d57d8f931052ceabefeb",
    "id": null,
    "metadata": {},
    "name": "settingsSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription settingsSubscribeSubscription {\n  settingsSubscribe {\n    id\n    has_valid_payment_method\n    has_valid_payout_method\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b974d9f11be6cc0b7ec61a003d16044";

export default node;
