/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import { mixpanelHelpers } from '../../utils';
import imgPlayStore from './google-play-badge.png';
import styles from './ButtonPlayStore.module.scss';

const ButtonPlayStore = ({
  className,
  onClick,
  to,
  mp_location,
  ...rest
}: {
  className?: ?string,
  mp_location?: ?string,
  onClick?: Function,
  to?: string | Object,
}) => (
  <TouchableOpacity
    {...(onClick || to
      ? {
          onClick,
          to,
        }
      : {
          href:
            'https://play.google.com/store/apps/details?id=com.plural.plural&hl=en_US',
          target: '_blank',
        })}
    className={classNames([styles.button, className])}
    {...rest}
    onClick={mixpanelHelpers.trackEventAndPass(
      {
        name: `${mp_location ? `${mp_location}` : ''}Button_PlayStore`,
      },
      { forceTrack: true },
    )(onClick)}
  >
    <img
      src={imgPlayStore}
      alt="Download Plural.com from the Play Store"
      width="135px"
      height="40px"
    />
  </TouchableOpacity>
);

export default ButtonPlayStore;
