/* @flow */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from '../..';

export default ({
  skillId,
  title,
  start,
  end,
  companyName,
  companyURL,
}: {
  skillId: string,
  title: string,
  start: Date,
  end: Date,
  companyName: string,
  companyURL: string,
}): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation CreatePositionMutation($input: CreatePositionInput!) {
        createPosition(input: $input) {
          error {
            message
          }
          newPositionEdge {
            node {
              title
              start
              end
              company {
                id
                name
                url
              }
            }
          }
          experience {
            id
          }
        }
      }
    `;

    const variables = {
      input: {
        skillId,
        title,
        start,
        end,
        companyName,
        companyURL,
      },
    };

    const optimisticResponse = {
      createPosition: {
        newPositionEdge: {
          node: {
            id: `_${Math.random().toString(36).substr(2, 9)}`,
            title,
            start,
            end,
            isCurrent: !end,
            company: {
              id: 'Company:mock_id',
              name: companyName,
              url: companyURL,
              favicon: `${companyURL}/favicon.ico`,
            },
            skill_id: skillId,
          },
        },
      },
    };

    return commitMutation({
      mutation,
      optimisticResponse,
      variables,
      onCompleted: (res) => resolve(res),
      onError: (err) => reject(err),
    });
  });
