/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import requestSubscription from '../requestSubscription/requestSubscription';

const subscription = graphql`
  subscription lastUnreadThreadTimestampSubscribeSubscription {
    lastUnreadThreadTimestampSubscribe {
      id
      last_unread_thread_timestamp
    }
  }
`;

const lastUnreadThreadTimestampSubscribe = () =>
  requestSubscription({
    subscription,
  });

export default lastUnreadThreadTimestampSubscribe;
