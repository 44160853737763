/* @flow */
import React from 'react';
import { TouchableOpacity, Icon } from '@pluralcom/blueprint';

import styles from './ButtonClose.module.scss';

const ButtonClose = ({
  className,
  ...rest
}: {
  className: string,
  rest: any,
}) => (
  <TouchableOpacity className={styles['button-close']} {...rest}>
    <Icon icon={['fas', 'times']} type="fa" />
  </TouchableOpacity>
);

export default ButtonClose;
