/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import Truncate from 'react-truncate';
import { TouchableOpacity } from '@pluralcom/blueprint';

import Text from '../Text/Text';

import styles from './TextReadMore.module.scss';

type Props = {
  children?: React$Node,
  lines?: number,
  more?: React$Node,
  less?: React$Node,
  preMore?: React$Node,
  preLess?: React$Node,
  className?: string,
  moreClassName?: string,
  lessClassName?: string,
  Wrapper?: Function,
};

class TextReadMore extends Component<*, Props, *> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      truncated: false,
    };
  }

  handleTruncate = (truncated) => {
    if (this.state.truncated !== truncated) {
      this.setState({ truncated });
    }
  };

  toggleLines = (event) => {
    event.preventDefault();
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      children,
      more,
      less,
      lines,
      preMore,
      preLess,
      className,
      moreClassName,
      lessClassName,
      Wrapper,
      ...rest
    } = this.props;

    const { expanded, truncated } = this.state;

    return (
      <Wrapper className={classNames(className)} {...rest}>
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <span>
              {preMore}
              <TouchableOpacity
                className={classNames(styles.button, moreClassName)}
                onClick={this.toggleLines}
              >
                {more}
              </TouchableOpacity>
            </span>
          }
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <span>
            {preLess}
            <TouchableOpacity
              className={classNames(styles.button, lessClassName)}
              onClick={this.toggleLines}
            >
              {less}
            </TouchableOpacity>
          </span>
        )}
      </Wrapper>
    );
  }
}

TextReadMore.defaultProps = {
  lines: 3,
  more: 'read more',
  less: 'show less',
  preMore: '... ',
  preLess: ' ',
  Wrapper: Text,
};

export default TextReadMore;
