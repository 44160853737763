/* @flow */
import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import BsModal from 'reactstrap/lib/Modal';

import responsive from '../../utils/Responsive/Responsive';
import usePrevious from '../../hooks/usePrevious/usePrevious';

import styles from './Modal.module.scss';

const bsBodyModalOpenClassName: string = 'modal-open';

export { styles, bsBodyModalOpenClassName };

type Props = {
  children: any,
  isOpen: boolean,
  toggle: () => any,
  className?: ?string,
  disableScrolling?: boolean,
};

const _addBodyCss = () => {
  document.body.classList.add(styles[bsBodyModalOpenClassName]);
};

const _removeBodyCss = () => {
  document.body.classList.remove(
    styles[bsBodyModalOpenClassName],
    bsBodyModalOpenClassName,
  );
};

const Modal = ({
  className,
  isOpen,
  toggle,
  disableScrolling,
  ...rest
}: Props) => {
  const _initialScrollPosition = useRef(null);
  const prevIsOpen = usePrevious(isOpen);

  const _setupInitialScrollPos = () => {
    _initialScrollPosition.current = responsive.getScroll();
  };

  const _scrollAndCleanupInitialScrollPos = () => {
    if (_initialScrollPosition.current) {
      responsive.scrollTo(_initialScrollPosition.current);
      _initialScrollPosition.current = null;
    }
  };

  /** Body + scroll handling */
  useLayoutEffect(() => {
    if (Boolean(prevIsOpen) !== Boolean(isOpen)) {
      if (isOpen) {
        _setupInitialScrollPos();
        _addBodyCss();
      }
      /** disableScrolling - is need to not remove scroll disabling,
       *  when we have bunch of modals opening one each other,
       *  in this case closing previous modal can unable scrolling
       *  after next modal is opened */
      if (!isOpen && !disableScrolling) {
        _removeBodyCss();
        _scrollAndCleanupInitialScrollPos();
      }
    }
    /** ensure the body class is removed upon unmount */
    return () => _removeBodyCss();
    // disabled since is prevIsOpen is a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, disableScrolling]);

  return (
    <BsModal
      isOpen={isOpen}
      toggle={toggle}
      className={classNames(className)}
      fade={false}
      {...rest}
    />
  );
};

export default Modal;
