/* @flow */
/* eslint no-console: 0 */
import { useEffect } from 'react';

import { environment } from '../../../../utils';
import { COLOR_BLUE } from '../../../../assets/styles/js/colors';

const useConsoleWelcomeLogs = () => {
  /** Console welcome logs */
  useEffect(() => {
    if (environment.isDevelopmentAny()) {
      return;
    }
    /** eslint-disable no-console */
    console.log(
      '%cDid you know that you can get paid to pick around in our code?',
      `color: ${COLOR_BLUE}; font-size: x-large; font-weight: bold;`,
    );
    console.log(
      '%cJoin us at Plural! View open positions here: https://plural.com/info/careers',
      'color: #34495E; font-size: medium; font-style: italic;',
    );
  }, []);
};

export default useConsoleWelcomeLogs;
