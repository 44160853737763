/* eslint global-require: 0 import/no-extraneous-dependencies: 0 */

import { createStore as createStoreRaw, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { localStorageService } from '@pluralcom/plural-web-utils';
import merge from 'lodash/merge';

import environment from '../utils/environment/environment';
import rootReducer, { initialState } from './reducers/rootReducer/rootReducer';

const REDUX_DEV_MODE =
  environment.isDevelopmentAny() || localStorageService.getItem('dev_redux');

/** local configurations of the file */
const config = {
  withDevTools: false,
  withLogger: true,
};

let enhancers = [];
let middleware = [thunk];

/**
 * Add development middlewares and enhancers
 */
if (REDUX_DEV_MODE) {
  // const { composeWithDevTools } = require('redux-devtools-extension');
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function' && config.withDevTools) {
    enhancers = enhancers.concat(devToolsExtension());
  }
  if (config.withLogger) {
    const { logger } = require('redux-logger');
    middleware = middleware.concat(logger);
  }
}

/**
 * Redux' composed enhancers and middleware
 */
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

/**
 * Store creation function
 *
 * Creates base store and allows to pass custom configuration
 *
 * - Used by App
 * – Used by Storybook
 */
export const createCustomStore = (customConfig?: {
  /** initial state, if not provided defaults to App's initial state */
  initialState?: Object,
  /** extra state, spread to initial state if provided */
  extraState?: Object,
}) =>
  createStoreRaw(
    rootReducer,
    customConfig?.initialState ||
      merge(initialState, customConfig?.extraState || {}),
    composedEnhancers,
  );

/**
 * Redux store
 */
const store = createCustomStore();

export default store;
