/* @flow */
import {
  // fetchJson,
  setFetch,
  setFetchURLPrefix,
} from '@pluralcom/plural-js-utils/lib/fetcher/fetcher';
import { pluralUrls } from '@pluralcom/plural-js-utils';

import fetchWithMiddleware from './fetchWithMiddleware';

/**
 * If in development no url prefix needed since proxy is used, otherwise set proper url prefix
 */
setFetchURLPrefix(pluralUrls.getBackendUrl());
setFetch(fetchWithMiddleware);

export default {
  // Add more API functions above
};
