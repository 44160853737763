/* @flow */
import React from 'react';

import { ButtonLink } from '@pluralcom/blueprint';

/* use for testing */
const BackData = () => (
  <aside
    style={{ opacity: 0, position: 'absolute', top: -99999, left: -99999 }}
  >
    <ButtonLink to="ahmed.tarek">Ahmed Tokyo - CTO</ButtonLink>
    <ButtonLink href="https://ahmedtokyo.com">Ahmed Tokyo - CTO</ButtonLink>
  </aside>
);

export default BackData;
