import React from 'react';
import classNames from 'classnames';
import { Anchor, Text } from '@pluralcom/blueprint';

import { mixpanelHelpers } from '../../../../utils';

import styles from './LegalAgreement.module.scss';

interface LegalAgreementProps {
  /** location string */
  mp_location?: string;
  /** custom className */
  className?: string;
}

/**
 * Figma v0.0.1
 * LegalAgreement
 * - Tos & Privacy module
 */

const LegalAgreement = ({ mp_location, className }: LegalAgreementProps) => (
  <div className={styles['text-container']}>
    <Text
      fontSizeType="t7"
      stickToBreakpoint="lg"
      className={classNames([styles.container, className])}
      element="span"
    >
      By continuing, you agree to the{' '}
    </Text>
    <Text
      fontSizeType="t7"
      stickToBreakpoint="lg"
      className={classNames([styles.container, className])}
      element="span"
    >
      <Anchor
        className={styles['pp-link']}
        target="_blank"
        to="/info/terms-of-use"
        onClick={() =>
          mixpanelHelpers.trackEvent(`${mp_location}_Legal_Link_TermsOfUse`)
        }
        data-testid="legal_agreement__links__terms_of_use"
      >
        Terms of Service
      </Anchor>{' '}
      and{' '}
      <Anchor
        className={styles['pp-link']}
        target="_blank"
        to="/info/privacy-policy"
        data-testid="legal_agreement__links__privacy_policy"
        onClick={() =>
          mixpanelHelpers.trackEvent(`${mp_location}_Legal_Link_PrivacyPolicy`)
        }
      >
        Privacy Policy
      </Anchor>
    </Text>
  </div>
);

export default LegalAgreement;
