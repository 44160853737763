import React, { useState } from 'react';
import { useMedia } from 'use-media';
import classNames from 'classnames';
import { ModalConfirmation } from '@pluralcom/blueprint';

import TooltipOptions from '../../../TooltipOptions/TooltipOptions';

import SkillCardMiniFloatingButtonStyles from '../SkillCardMiniFloatingButton/SkillCardMiniFloatingButton.module.scss';
import styles from './EditButton.module.scss';
import { innerRoutesHelpers, responsive } from '../../../../utils';
import SkillEditButton from '../../../EditButton/EditButton';
import { DeleteSkillMutation } from '../../../../graphql/mutations';
import { SkillCardMiniHeader_skill$data } from '../SkillCardMiniHeader/__generated__/SkillCardMiniHeader_skill.graphql';

interface EditButtonProps {
  /** skill object  */
  skill: SkillCardMiniHeader_skill$data;
  /** parent ID */
  parentID?: string;
  /** total count */
  totalCount: number;
  /** size */
  size: 'xs' | 'lg';
}

const EditButton = ({
  skill,
  parentID,
  totalCount,
  size,
  ...rest
}: EditButtonProps) => {
  const [isOpenDialogDeleteSkill, setIsOpenDialogDeleteSkill] = useState(false);
  const toggleDialogDeleteSkill = () =>
    setIsOpenDialogDeleteSkill((val) => !val);
  const isLg = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const screen_size = size ?? (!isLg ? 'xs' : 'lg');

  return (
    <div
      className={classNames([
        SkillCardMiniFloatingButtonStyles.container,
        SkillCardMiniFloatingButtonStyles[`container-${screen_size}`],
      ])}
    >
      <TooltipOptions
        renderTrigger={() => (
          <SkillEditButton
            // @ts-ignore
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            size={screen_size}
            className={styles.button}
            {...rest}
          />
        )}
        options={[
          {
            id: 'edit',
            text: 'Edit',
            // @ts-ignore
            to: {
              pathname: innerRoutesHelpers.getEditSkillLink(skill),
              state: {
                isEditFullPath: true,
              },
            },
            iconProps: {
              icon: ['fas', 'edit'],
            },
          },
          {
            id: 'delete',
            text: 'Delete',
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleDialogDeleteSkill();
            },
            iconProps: {
              icon: ['fas', 'trash'],
            },
          },
        ]}
      />
      <ModalConfirmation
        isOpen={isOpenDialogDeleteSkill}
        toggle={toggleDialogDeleteSkill}
        onConfirm={() => {
          toggleDialogDeleteSkill();
          DeleteSkillMutation(skill.id, {
            parentID: parentID || skill?.user?.id || '',
            totalCount,
          });
        }}
      />
    </div>
  );
};

export default EditButton;
