/* @flow */
import { useRef, useEffect } from 'react';

/**
 * UsePrevious effect
 *
 * https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
 */
const usePrevious = (value) => {
  const ref = useRef();
  /** Store current value in ref */
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
