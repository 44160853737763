import { logger, sentryHelpers } from '../../utils';

const trackAndLogErr = err => {
  logger.debugError('Fetcher Error', err);
  sentryHelpers.addBreadcrumb({
    level: 'error',
    category: 'fetcher_error',
    message: 'Error in Fetcher fetch function',
    data: err,
  });
  sentryHelpers.captureException(err);
  return err;
};

const fetchWithMiddleware = (...params) =>
  fetch(...params).catch(err => trackAndLogErr(err));

export default fetchWithMiddleware;
