import React from 'react';
import classNames from 'classnames';

import SkillMediaGallery from '../../../../../SkillMediaGallery/SkillMediaGallery';
import ImagePlaceholder from '../../../../../ImagePlaceholder/ImagePlaceholder';

import styles from './SkillMediaGalleryMini.module.scss';
import { SkillCardMiniMediaSection_skill$data } from '../../__generated__/SkillCardMiniMediaSection_skill.graphql';

interface SkillMediaGalleryMiniProps {
  /** user */
  user?: SkillCardMiniMediaSection_skill$data['user'];
  /** media */
  media: SkillCardMiniMediaSection_skill$data['media'];
  /** custom classname */
  className?: string;
  /** custom image placeholder classname */
  imgPlaceholderClassName?: string;
  /** size */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  /** has image */
  hasImage?: boolean;
}

const MEDIA_SIZE_MAP = {
  xs: 'xs',
  sm: 'xs',
  md: 'xs',
  lg: 'lg',
  xl: 'lg',
  xxl: 'lg',
  xxxl: 'lg',
};

/**
 *
 * FIGMA v0.0.1
 * SkillMediaGalleryMini component.
 */
const SkillMediaGalleryMini = ({
  media,
  className,
  imgPlaceholderClassName,
  user,
  size,
  hasImage = true,
}: SkillMediaGalleryMiniProps) => {
  const media_size = MEDIA_SIZE_MAP[size];

  return (
    <section
      className={classNames([
        styles.container,
        styles[`container-${media_size}`],
        className,
      ])}
    >
      {hasImage && media?.edges && media?.edges?.length > 0 ? (
        <SkillMediaGallery
          className={classNames([
            styles.gallery,
            styles[`gallery-${media_size}`],
            className,
          ])}
          media={media}
          mediaPreview
          {...{
            showThumbnails: false,
            width: '100%',
            youTubeClassName: styles.youtube,
            youTubeProps: {
              className: styles.youtube,
              autoPlay: false,
            },
            disableNavigationControls: true,
            disableSwipe: true,
          }}
        />
      ) : (
        <ImagePlaceholder
          color={user?.profile_color || ''}
          uuid={user?.id}
          className={classNames(
            styles.placeholder,
            styles[`placeholder-${media_size}`],
            imgPlaceholderClassName,
          )}
        />
      )}
    </section>
  );
};

export default SkillMediaGalleryMini;
