/* @flow */

/**
 * Gets the site wrapper HTMLElement
 * This is where you sould append stuff you usually would append to the body
 */
const getSiteWrapper = (): HTMLElement | null =>
  document.getElementById('site-wrapper');

/**
 * Appends an element to the site wrapper HTMLElement and returns the element
 */
const appendChild = (element: HTMLElement): HTMLElement | null => {
  const siteWrapper = getSiteWrapper();
  if (siteWrapper) {
    return siteWrapper.appendChild(element);
  }
  return null;
};

/**
 * Removes an element from the site wrapper HTMLElement and returns the element
 */
const removeChild = (element: HTMLElement): HTMLElement | null => {
  const siteWrapper = getSiteWrapper();
  if (siteWrapper) {
    return siteWrapper.removeChild(element);
  }
  return null;
};

export default {
  getSiteWrapper,
  appendChild,
  removeChild,
};
