import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Card } from '@pluralcom/blueprint';
import styles from './Tab.module.scss';

interface TabProps {
  /** class name */
  className?: string;
  /** children */
  children?: ReactNode;
}

/**
 * - Figma v0.0.1
 * - This is the Tab compoent for settings/AccountTab
 * - wraps the <Card/> molecule and <TabItem/> componet
 * - @see TabItem
 * - @see Card
 */
const Tab = ({ className, ...rest }: TabProps) => (
  <Card
    borderRadiusType="border-radius-type-5"
    uiType="base-white"
    className={classNames([styles.container, className])}
    {...rest}
  />
);

export default Tab;
