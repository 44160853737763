/**
 * @generated SignedSource<<9848b2d1e56c813955339fecaf520010>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type lastNetworkSyncedTimestampSubscribeSubscription$variables = {};
export type lastNetworkSyncedTimestampSubscribeSubscription$data = {
  readonly lastNetworkSyncedTimestampSubscribe: {
    readonly id: string;
    readonly last_network_synced_timestamp: any | null;
  } | null;
};
export type lastNetworkSyncedTimestampSubscribeSubscription = {
  response: lastNetworkSyncedTimestampSubscribeSubscription$data;
  variables: lastNetworkSyncedTimestampSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "lastNetworkSyncedTimestampSubscribe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_network_synced_timestamp",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "lastNetworkSyncedTimestampSubscribeSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "lastNetworkSyncedTimestampSubscribeSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ff3990323813b93cef63500b202e08e8",
    "id": null,
    "metadata": {},
    "name": "lastNetworkSyncedTimestampSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription lastNetworkSyncedTimestampSubscribeSubscription {\n  lastNetworkSyncedTimestampSubscribe {\n    id\n    last_network_synced_timestamp\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fc151212e12589b56390a680afc61a2";

export default node;
