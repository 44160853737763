import React from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

/**
 * Props for the Input component
 */
declare interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Additional class name for the input element */
  className?: string;
}

const Input = React.forwardRef(
  (
    { className, ...rest }: InputProps,
    ref: React.Ref<HTMLInputElement> | null,
  ) => (
    <input
      {...rest}
      ref={ref}
      className={classNames([styles.input, className])}
    />
  ),
);

export default Input;
