/* @flow */
import { Environment, RecordSource, Store, Observable } from 'relay-runtime';
import { localStorageService } from '@pluralcom/plural-web-utils';
import { analyticsHelpers, pluralUrls } from '@pluralcom/plural-js-utils';
import { isLoggedOut } from '@pluralcom/plural-js-utils/lib/authHelpers';
import {
  RelayNetworkLayer,
  urlMiddleware,
  cacheMiddleware,
  retryMiddleware,
  authMiddleware as relayAuthMiddleware,
  // batchMiddleware,
  uploadMiddleware,
  loggerMiddleware,
  perfMiddleware,
  errorMiddleware,
} from 'react-relay-network-modern/lib';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import { createClient as createClientSubscriptions } from 'graphql-ws';
import pick from 'lodash/pick';

import { browserHistory, sentryHelpers, mixpanelHelpers } from '../utils';
import logger, { LOGGER_IS_ENABLED } from '../utils/logger/logger';
import reduxStore from '../redux/store';
import { toggleLegalAccepted } from '../redux/reducers/legalReducer/legalReducer';
import { setSubscriptionCmdTimestamp } from '../redux/reducers/appSettingsReducer/appSettingsReducer';

import getDeviceInfoHeader from './utils/getDeviceInfoHeader';
import windowPostMessageHotfix from './utils/windowPostMessageHotfix';

windowPostMessageHotfix();

const URL = urlJoin(pluralUrls.getBackendUrl(), 'graphql');

/**
 * Relay Subscription client - https://github.com/enisdenjo/graphql-ws#relay
 */
let subscriptionsClient;

/**
 * Creates a new graphql subscriptions client
 *
 * - disposes of old client if exists
 * - Note that we create the client upon ensuring the user is authenticated by the backend - to ensure we don't send an expired token
 *
 * Future plan:
 * – Server checks for auth on every result emission -> if token is expired server will close the socket with auth error and code forbidden
 * once the socket is closed with auth error -> client subscription middleware calls helloMutation to update authentication state
 * if authenticated successfully -> socket reconnects
 *
 * @todo:
 * - handle refreshing without using graphql HelloMutation. ie: via on close event check for reason and refresh
 */
const _createClientSubscriptions = () => {
  /* dispose of existing subscriptionsClient */
  if (subscriptionsClient) {
    subscriptionsClient.dispose();
  }
  /** used to check for connections vs re-connections */
  let _subscriptionsConnectionCount = 0;
  /* create subscriptionsClient */
  subscriptionsClient = createClientSubscriptions({
    url: URL.replace('http', 'ws'),
    /** only connect with first subscribe -- ie: when authenticated */
    lazy: true,
    /** How long should the client wait before closing the socket after the last oparation has completed. This is meant to be used in combination with lazy. You might want to have a calmdown time before actually closing the connection. Kinda' like a lazy close "debounce". */
    lazyCloseTimeout: 30000,
    /** event listeners */
    on: {
      /** subscriptionsClient on connected listener */
      connected: () => {
        /* increment subscriptions connections count */
        _subscriptionsConnectionCount += 1;
        /** if _subscriptionsConnectionCount > 1 or a previous subscriptionsClient exists then it's a reconnection. */
        if (_subscriptionsConnectionCount > 1 || subscriptionsClient) {
          /** Update the subscription command timestamp redux value to retrigger the subscriptions effect in AppWrapper resulting in global subscriptions resubscribing upon reconnection */
          reduxStore.dispatch(setSubscriptionCmdTimestamp());
        }
      },
    },
  });
  /** set subscriptionCmdTimestamp to trigger GQL subs -> to trigger lazy client connection. This is required since lazy is set to true */
  reduxStore.dispatch(setSubscriptionCmdTimestamp());
};

/**
 * Relay Subscription function - https://github.com/enisdenjo/graphql-ws#relay
 */
const fetchOrSubscribe = (operation: RequestParameters, variables: Variables) =>
  Observable.create((sink) => {
    if (!operation.text) {
      return sink.error(new Error('Operation text cannot be empty'));
    }
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      {
        ...sink,
        error: (err) => {
          if (Array.isArray(err)) {
            // GraphQLError[]
            return sink.error(
              new Error(err.map(({ message }) => message).join(', ')),
            );
          }
          if (err instanceof CloseEvent) {
            return sink.error(
              new Error(
                `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
              ),
            );
          }
          return sink.error(err);
        },
      },
    );
  });

// Common request headers
const _fetchHeaders: { [string]: string } = {
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers':
    'Cache-Control, Pragma, Origin, Authorization, Content-Type, csrf-token, must-accept-latest-contracts, last-read-activity-timestamp, x-request-id',
  'Apollo-Require-Preflight': true,
  // to support multipart responses, eg: @defer and @stream. Note: react-relay-network-modern doesn't support this properly yet, issue: https://github.com/relay-tools/react-relay-network-modern/issues/32
  // Accept: 'multipart/mixed; deferSpec=20220824',
};

/**
 * Gets the request headers
 */
const _getHeaders = () => ({
  ..._fetchHeaders,
  'X-Device-Info': getDeviceInfoHeader(),
  'X-Request-Id': uuidv4(),
});

// eslint-disable-next-line global-require
const _getAuth = () => import('../services/Auth/Auth');

const validateLegalAndDispatch = (newLegalVisibleStatus: boolean) => {
  const { getState } = reduxStore;
  const { legal } = getState();

  if (legal.hasAcceptedLatestContracts !== newLegalVisibleStatus) {
    reduxStore.dispatch(toggleLegalAccepted(newLegalVisibleStatus));
  }
};

/**
 * authMiddleware
 *
 * - Logs the user out clientside if the server logged the user out
 */
const authMiddleware = (next) => async (req) => {
  const res = await next(req);
  const errors = res?.json?.errors || res?.errors;
  // eslint-disable-next-line global-require
  const Auth = (await _getAuth()).default;
  /**
   * If the user is authenticated clientside, check if authenticated serverside
   */
  if (Auth.isAuthenticated() && errors && isLoggedOut(errors)) {
    logger.debugError('Auth: Viewer is not a user, logging out', errors);
    await Auth.logout({ noBackend: true });
    browserHistory.push('/login', { from: browserHistory.location });
  } else if (!subscriptionsClient) {
    /**
     * If user is authenticated by backend -> Create subscriptions client if doesn't already exist
     *
     * Reason we create the client here is to ensure that we only subscribe AFTER ensuring that the user is authenticated
     * Otherwise we might fall into edge cases eg:
     * - user opens the app with an expired token -> subscription client tries to connects BEFORE relay gets to refresh token -> subscriptions fail
     */
    _createClientSubscriptions();
  }
  return res;
};

/**
 * token refresh promise
 *
 * Refreshes the token via the backend's rest API
 */
const _tokenRefreshPromise = async (req) => {
  /** request headers */
  const headers = _getHeaders();
  /** Analytics event props */
  const eventProps = pick(headers, ['X-Request-Id']);
  /** refresh token promise */
  const result = fetch(
    urlJoin(pluralUrls.getBackendUrl(), '/rest/auth/refresh'),
    {
      method: 'POST',
      headers,
      /**
       * CORS credentials must be included to accept cookies.
       */
      credentials: 'include',
    },
  )
    .then((res) => {
      /** Success */
      if (res.status === 200) {
        logger.debug('Refreshing auth token - success');
        mixpanelHelpers.trackEvent(
          analyticsHelpers.events.AUTH_REFRESH_SUCCESS.name,
          eventProps,
        );
        return '';
      }
      /** Failure to refresh */
      authMiddleware(() => res.json())(req);
      logger.debug('Refreshing auth token - fail');
      mixpanelHelpers.trackEvent(
        analyticsHelpers.events.AUTH_REFRESH_FAIL.name,
        eventProps,
      );
      return '';
    })
    .catch((err) => {
      logger.debug('Refreshing auth token - error', err);
      mixpanelHelpers.trackEvent(
        analyticsHelpers.events.AUTH_REFRESH_ERROR.name,
        {
          ...eventProps,
          'Error Message': err.message,
        },
      );
    });
  /** Async track the event after issuing the refresh promise */
  logger.debug('Refreshing auth token');
  mixpanelHelpers.trackEvent(
    analyticsHelpers.events.AUTH_REFRESH_REQUEST.name,
    eventProps,
  );
  /** Return refresh promise */
  return result;
};

/**
 * legalContractsMiddleware
 *
 * Checks if the user should accept the latest legal contracts
 * - Is fired when ToUs or PP is updated
 */
const legalContractsMiddleware = (next) => async (req) => {
  const res = await next(req);
  const mustAcceptLegalContracts = res.headers.get(
    'must-accept-latest-contracts',
  );
  if (mustAcceptLegalContracts) {
    const mustAcceptLegal = mustAcceptLegalContracts === 'true';
    validateLegalAndDispatch(!mustAcceptLegal);
  }
  return res;
};

/** Cache instance */
let cacheInstance;

const RELAY_CACHE_SIZE = Number(process.env.REACT_APP_RELAY_CACHE_SIZE) || 200;
const RELAY_CACHE_TTL = Number(process.env.REACT_APP_RELAY_CACHE_TTL) || 300000; // 5 minutes
const RELAY_FETCH_TIMEOUT =
  Number(process.env.REACT_APP_RELAY_FETCH_TIMEOUT) || 15000;
const RELAY_MAX_RETRIES = Number(process.env.REACT_APP_RELAY_MAX_RETRIES) || 3;

const isCacheEnabled =
  !process.env.REACT_APP_RELAY_DISABLE_CACHE ||
  !localStorageService.getItem('RELAY_DISABLE_CACHE');

logger.debug('Relay config', {
  RELAY_CACHE_SIZE,
  RELAY_CACHE_TTL,
  RELAY_FETCH_TIMEOUT,
  RELAY_MAX_RETRIES,
  isCacheEnabled,
  URL,
});

const network = new RelayNetworkLayer(
  [
    urlMiddleware({
      url: URL,
      headers: _getHeaders,
      /**
       * Since the backend is contacted at `backend.XXX.com` and web is hosted at `XXX.com`
       * credentials must be include to accept cookies.
       */
      credentials: 'include',
    }),
    isCacheEnabled
      ? cacheMiddleware({
          size: RELAY_CACHE_SIZE,
          ttl: RELAY_CACHE_TTL, // 5 minutes
          // clearOnMutation: true,
          onInit: (newCache) => {
            cacheInstance = newCache;
          },
        })
      : null,
    // /** Note, we are yet to support batching in the backend */
    // batchMiddleware({
    //   batchUrl: urlJoin(URL, 'batch'),
    // }),
    LOGGER_IS_ENABLED
      ? loggerMiddleware({
          logger: logger.info.bind(console, '[RELAY-NETWORK]'),
        })
      : null,
    LOGGER_IS_ENABLED
      ? perfMiddleware({
          logger: logger.info.bind(console, '[RELAY-NETWORK] - Perf'),
        })
      : null,
    /** uploadMiddleware */
    uploadMiddleware(),
    /** retry */
    retryMiddleware({
      fetchTimeout: RELAY_FETCH_TIMEOUT,
      retryDelays: [300, 800, 1500],
      beforeRetry: ({
        abort,
        attempt,
        lastError,
        // delay
      }) => {
        if (attempt > RELAY_MAX_RETRIES) {
          abort();
          sentryHelpers.captureEvent({
            level: 'error',
            message: `[RELAY-NETWORK] - Aborted retrying query, hit max retry attempts. attempt: ${attempt}. Lasterror: ${String(
              lastError,
            )}`,
          });
          // return;
        }
        /* disabled to avoid extra sentry usage @todo @enable when we have unlimited */
        // sentryHelpers.captureEvent({
        //   level: 'warning',
        //   message: `[RELAY-NETWORK] - Retrying query. attempt: ${attempt}.  Next attempt is ${delay}. Lasterror: ${String(
        //     lastError,
        //   )}`,
        // });
      },
    }),
    /** Relay auth - used to refresh tokens */
    relayAuthMiddleware({
      /** allow empty token in headers since we rely on cookies */
      allowEmptyToken: true,
      /** token refresh promise */
      tokenRefreshPromise: _tokenRefreshPromise,
    }),
    /** auth */
    authMiddleware,
    /** legal contracts - ToUs and PP */
    legalContractsMiddleware,
    /**
     * Middlewares called after logger middleware are not executed in case of error
     * Has to be after middlewares that update redux state - eg: appUpdateMiddleware
     */
    LOGGER_IS_ENABLED
      ? errorMiddleware({
          logger: logger.debugError,
        })
      : null,
  ],
  {
    subscribeFn: fetchOrSubscribe,
  },
);

let source: RecordSource;
let store: Store;
let environment: Environment;

/** gets the relay environment */
const getEnvironment = (): Environment => environment;

/** initializes the relay environment - new source, store and network */
const initEnvironment = (): void => {
  if (window.navigator.userAgent === 'ReactSnap') {
    return;
  }
  if (cacheInstance) {
    cacheInstance.clear();
  }
  /* dispose of existing subscriptionsClient */
  if (subscriptionsClient) {
    subscriptionsClient.dispose();
    subscriptionsClient = null;
  }
  source = new RecordSource();
  store = new Store(source);
  environment = new Environment({ network, store });
};

/** initialize relay environment on startup */
initEnvironment();

/** Gets the current cache instance */
const getCache = () => cacheInstance;

export { getEnvironment, initEnvironment, getCache };
