/* @flow */
import React from 'react';
import classNames from 'classnames';

import styles from './AlertBox.module.scss';

const facebookErrors = [
  'This Facebook account is already linked to another user.',
];

const AlertBox = ({
  className,
  title,
  body,
  type = 'error',
  fbAuthError,
}: {
  className?: ?string,
  title: string,
  body: string,
  type?: string,
  fbAuthError?: string,
}) =>
  facebookErrors.indexOf(fbAuthError) !== -1 ? (
    <div
      className={classNames(
        styles.content,
        type === 'success' && styles['success-content'],
        className,
      )}
    >
      <h3
        className={classNames(
          styles.text,
          styles.title,
          type === 'success' && styles['success-text'],
        )}
      >
        {title}
      </h3>
      <p
        className={classNames([
          styles.text,
          type === 'success' && styles['success-text'],
        ])}
      >
        {body}
      </p>
    </div>
  ) : null;

export default AlertBox;
