/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { PAYMENT_STATUSES } from '@pluralcom/plural-js-utils';
import {
  BUYER,
  SELLER,
} from '@pluralcom/plural-js-utils/lib/plPaymentHelpers/statuses';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation MarkPaymentAsCompleteMutation($input: MarkPaymentAsCompleteInput!) {
    markPaymentAsComplete(input: $input) {
      payment {
        id
        status
        approved_by_buyer_at
        approved_by_seller_at
        deal {
          id
          ...DealsItem_deal
          ...DealControls_deal
        }
      }
      error {
        id
        title
        message
      }
    }
  }
`;

const MarkPaymentAsCompleteMutation = (
  {
    payment_id,
  }: {
    payment_id: string,
  },
  { payment }: { payment: Object } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        payment_id,
      },
    };

    let optimisticResponse;
    const nowIso = new Date().toISOString();
    if (BUYER && !payment.approved_by_buyer_at) {
      optimisticResponse = {
        markPaymentAsComplete: {
          error: null,
          payment: {
            id: payment.id,
            status: PAYMENT_STATUSES.PAID,
            approved_by_buyer_at: nowIso,
            approved_by_seller_at: payment.approved_by_seller_at,
          },
        },
      };
    } else if (SELLER && !payment.approved_by_seller_at) {
      optimisticResponse = {
        error: null,
        payment: {
          markPaymentAsComplete: {
            id: payment.id,
            status: payment.status,
            approved_by_buyer_at: payment.approved_by_buyer_at,
            approved_by_seller_at: nowIso,
          },
        },
      };
    }

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });

export default MarkPaymentAsCompleteMutation;
