/* @flow */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import intersection from 'lodash/intersection';

import localStorageHelpers from '../../utils/localStorageHelpers';

/** Default authentication route to be routed to */
const DEFAULT_AUTH_ROUTE = '/signup';

/**
 * Protected Route Component has the exact API as React-Router's Browser Router.
 * Except that if the User is not authenticated, they will be redirected to the auth page
 * With the redirection, information about the protected route is passed
 * to the auth page to route to it post auth
 */
const ProtectedRoute = ({
  children,
  redirectTo = {
    pathname: DEFAULT_AUTH_ROUTE,
  },
  disableRedirect,
  accessRoles: inAccessRoles = ['user'],
  ...rest
}: {
  children?: React$Node,
  redirectTo:
    | ?{
        pathname: string,
        state?: ?Object,
      }
    | ?string,
  disableRedirect?: ?boolean,
  accessRoles: Array<string>,
}) => {
  const location = useLocation();
  const accessRoles = inAccessRoles.filter(Boolean);
  if (
    intersection(accessRoles, localStorageHelpers.getUserRoles() || [])
      .length !== accessRoles.length &&
    !disableRedirect
  ) {
    let to = typeof redirectTo === 'string' ? redirectTo : DEFAULT_AUTH_ROUTE;
    let state = { from: location };
    if (typeof redirectTo === 'object') {
      to = redirectTo.pathname;
      if (redirectTo.state) {
        state = redirectTo.state;
      }
    }
    return <Navigate to={to} state={state} />;
  }
  return children;
};

export { ProtectedRoute as PureProtectedRoute };

export default ProtectedRoute;
