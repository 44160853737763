/* @flow */

/** Removes an element from the dom */
const removeElementFromDom = (element: ?HTMLElement) => {
  if (element) {
    element.parentNode.removeChild(element);
  }
};

export default removeElementFromDom;
