/* @flow */
import * as sharing from './sharing';

export * from './sharing';

const universalLinkingHelpers = {
  ...sharing,
};

export default universalLinkingHelpers;
