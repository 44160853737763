/**
 * @generated SignedSource<<820954996712678d3799014a8c28c670>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type threadsSubscribeSubscription$variables = {};
export type threadsSubscribeSubscription$data = {
  readonly activitiesSubscribe: {
    readonly archived_at: string | null;
    readonly id: string;
    readonly seen_at: string | null;
    readonly sorted_by: string | null;
    readonly thread_id: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"MessengerListItem_data">;
  } | null;
};
export type threadsSubscribeSubscription = {
  response: threadsSubscribeSubscription$data;
  variables: threadsSubscribeSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "THREADS"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sorted_by",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thread_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seen_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "threadsSubscribeSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activitiesSubscribe",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MessengerListItem_data"
          }
        ],
        "storageKey": "activitiesSubscribe(type:\"THREADS\")"
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "threadsSubscribeSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activitiesSubscribe",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subtitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profile_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "smallThumbnail",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "route",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "activitiesSubscribe(type:\"THREADS\")"
      }
    ]
  },
  "params": {
    "cacheID": "417c5cd06c61cad1172d125cb9f302df",
    "id": null,
    "metadata": {},
    "name": "threadsSubscribeSubscription",
    "operationKind": "subscription",
    "text": "subscription threadsSubscribeSubscription {\n  activitiesSubscribe(type: THREADS) {\n    id\n    sorted_by\n    thread_id\n    seen_at\n    archived_at\n    ...MessengerListItem_data\n  }\n}\n\nfragment MessengerListItem_data on Activity {\n  id\n  title\n  subtitle\n  archived_at\n  user {\n    id\n    first_name\n    last_name\n    username\n    profile_color\n    avatar {\n      id\n      smallThumbnail\n    }\n  }\n  route {\n    thread_id\n    message_id\n  }\n  thread_id\n  seen_at\n  sorted_by\n}\n"
  }
};
})();

(node as any).hash = "077a883b74608b5b473568715a6e77f7";

export default node;
