/* @flow */
import { sessionStorageService } from '@pluralcom/plural-web-utils';

const KEYS = {
  socket_conn_id: 'socket_conn_id',
};

const setSocketConnId = (socket_conn_id: string): void => {
  sessionStorageService.setItem(KEYS.socket_conn_id, socket_conn_id);
};

const getSocketConnId = (): string => {
  const socket_conn_id = sessionStorageService.getItem(KEYS.socket_conn_id);
  return socket_conn_id;
};

export { setSocketConnId, getSocketConnId };
