/* @flow */
import React from 'react';
import { QueryRenderer } from 'react-relay';
// import QueryRenderer from 'relay-query-lookup-renderer';

import { getEnvironment } from '../Environment';
import middlewareRender from './middlewareRender';

type Props = {
  render: Function,
  disableMiddleware?: ?boolean,
  variables?: Object,
};

export default ({ render, disableMiddleware, ...rest }: Props) => {
  const environement = getEnvironment();
  return (
    <QueryRenderer
      environment={environement}
      /** Variables need to be passed even if empty as of relay-runtime 1.7 */
      variables={{}}
      render={
        disableMiddleware
          ? render
          : middlewareRender(render, { environement, ...rest })
      }
      {...rest}
    />
  );
};
