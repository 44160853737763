/* @flow */

import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';

import styles from './TileItemRow.module.scss';

type Props = {
  leftElement: Function | React.Element<any>,
  centerElement: Function | React.Element<any>,
  rightElement: Function | React.Element<any>,
  className?: string,
  leftElementClassName?: string,
  centerElementClassName?: string,
  rightElementClassName?: string,
};

export default ({
  leftElement,
  centerElement,
  rightElement,

  className,
  leftElementClassName,
  centerElementClassName,
  rightElementClassName,

  ...rest
}: Props) => (
  <div className={classNames([styles.container, className])} {...rest}>
    <div
      className={classNames([
        styles.leftContentContainer,
        leftElementClassName,
      ])}
    >
      {isFunction(leftElement) ? leftElement() : leftElement}
    </div>
    <div
      className={classNames([styles.contentContainer, centerElementClassName])}
    >
      {isFunction(centerElement) ? centerElement() : centerElement}
    </div>
    <div
      className={classNames([
        styles.rightContentContainer,
        rightElementClassName,
      ])}
    >
      {isFunction(rightElement) ? rightElement() : rightElement}
    </div>
  </div>
);
