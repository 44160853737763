/** @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

import environment from '../environment/environment';

import Logger from './Logger/Logger';

import localStorageHelpers from '../localStorageHelpers';

const isEnabled = Boolean(
  (localStorageService.getItem('logger_isenabled') ||
    localStorageHelpers.isAdmin() ||
    environment.isDevelopmentAny()) &&
    !process.env.REACT_APP_LOGGER_DISABLE
);

let loggerLevel = process.env.REACT_APP_LOGGER_LEVEL || 'info';
if (
  localStorageHelpers.isAdmin() ||
  window.location.hostname === 'localhost' ||
  environment.isDevelopmentAny() ||
  environment.isTest()
) {
  loggerLevel = 'trace';
}
if (localStorageService.getItem('logger_level')) {
  loggerLevel = localStorageService.getItem('logger_level');
}

export const LOGGER_IS_ENABLED = isEnabled;

/**
 * Export a singelton instance of the Logger
 */
export default new Logger({
  isEnabled,
  loggerLevel,
});
