/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation DeletePositionMutation($input: DeletePositionInput!) {
    deletePosition(input: $input) {
      error {
        message
      }
    }
  }
`;

export default (positionId: string): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        positionId,
      },
    };

    commitMutation({
      mutation,
      variables,

      onCompleted: (res) => resolve(res),
      onError: (err) => reject(err),
    });
  });
