/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

const KEYS = {
  community_notification_find_friends_banner:
    'community_notification_find_friends_banner',
};

const setCommunityNotificationFindFriendsBanner = (isSaw: boolean): void => {
  localStorageService.setItem(
    KEYS.community_notification_find_friends_banner,
    JSON.stringify(isSaw),
  );
};

const getCommunityNotificationFindFriendsBanner = (): string => {
  const value = localStorageService.getItem(
    KEYS.community_notification_find_friends_banner,
  );
  return value;
};

export {
  setCommunityNotificationFindFriendsBanner,
  getCommunityNotificationFindFriendsBanner,
};
