import React, { useState } from 'react';
import classNames from 'classnames';
import { ModalForm, Row, Col, Text, ButtonConnect } from '@pluralcom/blueprint';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import styles from './ModalConnectSection.module.scss';
import { withFacebook } from '../../../../hocs';
import ModalComingSoon from '../../../ModalComingSoon';

interface ModalConnectSectionProps
  extends React.ComponentProps<typeof ModalForm> {
  /** custom classname */
  className?: string;
  /** Function to toggle modal */
  toggleModal?: () => void;
  /** Boolean to check if modal is open */
  isOpen: boolean;
  /** Viewer object */
  viewer: {
    /** Profile object */
    profile?: {
      /** Facebook verification status */
      verified_facebook: boolean;
    };
    /** User granted permissions object */
    userGrantedPermissions?: {
      /** Phonebook permission object */
      phonebook?: {
        /** Phonebook permission id */
        id: string;
        /** Phonebook permission value */
        value: boolean;
      };
    };
  };
  /** Function to handle login */
  handleLogin: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * - Figma v0.0.1
 * - ModalConnectSection component use show connect options
 *
 */
const ModalConnectSection = ({
  className,
  toggleModal,
  isOpen,
  viewer,
  handleLogin,
  ...rest
}: ModalConnectSectionProps) => {
  const { profile, userGrantedPermissions } = viewer || {};
  const { verified_facebook = false } = profile || {};
  const { phonebook } = userGrantedPermissions || {};
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);

  return (
    <>
      <ModalForm
        modalProps={{
          wrapClassName: styles.connectSectionModal,
          contentClassName: styles['modal-base'],
        }}
        toggle={toggleModal}
        className={styles.modalbody}
        containerProps={{
          noPadding: true,
          className: classNames(['gx-0', styles['modal-container']]),
        }}
        navHeaderProps={{
          text: 'Grow Network',
          rightButtonProps: {
            iconProps: {
              icon: ['fas', 'times'],
              type: 'fa',
            },
            onClick: toggleModal,
          },
        }}
        modalBody={
          <Row className="gx-0">
            <Col
              smOffset={2}
              xsOffset={1}
              xs={10}
              sm={8}
              className="align-self-center"
            >
              <div className={styles.container}>
                <Text
                  fontSizeType="t7"
                  element="span"
                  stickToBreakpoint="lg"
                  className={styles.text}
                >
                  Connect other networks to find your trusted friends.
                </Text>
                <ButtonConnect
                  platform="phonebook"
                  disabled={phonebook?.value}
                  isConnected={phonebook?.value}
                  size="lg"
                />
                <ButtonConnect
                  platform="facebook"
                  isConnected={verified_facebook}
                  onClick={handleLogin}
                  disabled={verified_facebook}
                  size="lg"
                />
                <ButtonConnect
                  platform="linkedin"
                  onClick={() => setIsComingSoonModalOpen(true)}
                  disabled={false}
                  size="lg"
                />
                <ButtonConnect
                  onClick={() => setIsComingSoonModalOpen(true)}
                  platform="google"
                  disabled={false}
                  size="lg"
                />
                <ModalComingSoon
                  // @ts-ignore
                  isOpen={isComingSoonModalOpen}
                  toggle={() => setIsComingSoonModalOpen(false)}
                />
              </div>
            </Col>
          </Row>
        }
        isOpen={isOpen}
        {...rest}
      />
    </>
  );
};
export { ModalConnectSection as PureModalConnectSection };
export default createFragmentContainer(
  withFacebook({ triggerRelayRefresh: true })(ModalConnectSection),
  {
    viewer: graphql`
      fragment ModalConnectSection_viewer on ViewerType {
        profile {
          verified_facebook
        }
        userGrantedPermissions {
          phonebook {
            id
            value
          }
        }
      }
    `,
  },
);
