import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */

import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMedia } from 'use-media';
import classNames from 'classnames';

import { ToggleUserLikedSkillMutation } from '../../../../graphql/mutations';

import styles from './SaveButton.module.scss';
import withAuthModals from '../../../../hocs/withAuthModals/withAuthModals';
import { logger, responsive } from '../../../../utils';
import SaveSkillCountButton from '../../../SaveSkillCountButton/SaveSkillCountButton';
import { SkillCardMiniHeader_skill$data } from '../SkillCardMiniHeader/__generated__/SkillCardMiniHeader_skill.graphql';

interface SaveButtonProps {
  /** skill */
  skill: SkillCardMiniHeader_skill$data;
  /** is authenticated */
  isAuthenticated?: boolean;
  /** toggle auth modal */
  toggleAuthModal: Function;
  /** size */
  size?: 'xs' | 'lg';
}

const SaveButton = ({
  skill,
  isAuthenticated,
  toggleAuthModal,
  size,
}: SaveButtonProps) => {
  const isLg = useMedia({ minWidth: responsive.minDeviceWidthLG });
  const screen_size = size ?? (!isLg ? 'xs' : 'lg');

  const likeSkill = () =>
    ToggleUserLikedSkillMutation(skill.id, skill).catch((error) =>
      logger.error('Error in ToggleUserLikedSkillMutation', error),
    );

  const onSaveButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isAuthenticated) {
      toggleAuthModal('Signup', {
        VerifyCode: {
          onSuccess: likeSkill,
        },
      })();
    } else {
      likeSkill();
    }
  };

  return (
    <SaveSkillCountButton
      className={classNames([
        styles.container,
        styles[`container-${screen_size}`],
      ])}
      size={screen_size}
      skill={skill}
      {...{
        onClick: onSaveButtonClick,
      }}
    />
  );
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({
  isAuthenticated,
});

export { SaveButton as PureSaveButton };
export default compose(
  connect(mapStateToProps),
  withAuthModals(),
)(SaveButton) as React.ComponentType<SaveButtonProps>;
