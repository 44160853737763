/**
 * @generated SignedSource<<f050a8b7e31b370fe6078af0214385e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillCardMini_skill$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCardMiniHeader_skill" | "SkillCardMiniMediaSection_skill">;
  readonly " $fragmentType": "SkillCardMini_skill";
};
export type SkillCardMini_skill$key = {
  readonly " $data"?: SkillCardMini_skill$data;
  readonly " $fragmentSpreads": FragmentRefs<"SkillCardMini_skill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSkillCardLean"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isSkillCardPreview"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillCardMini_skill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isSkillCardLean",
          "variableName": "isSkillCardLean"
        },
        {
          "kind": "Variable",
          "name": "isSkillCardPreview",
          "variableName": "isSkillCardPreview"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SkillCardMiniHeader_skill"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillCardMiniMediaSection_skill"
    }
  ],
  "type": "Skill",
  "abstractKey": null
};

(node as any).hash = "c5f59f097735ec315585a5bac51776f4";

export default node;
