/* @flow */
import { localStorageService } from '@pluralcom/plural-web-utils';

type USER_ROLE_TYPE = 'user' | 'admin';

const USER_ROLE = 'user';
const ADMIN_ROLE = 'admin';

const KEYS = {
  user_roles: 'user_roles',
};

const setUserRoles = (roles: ?Array<USER_ROLE_TYPE>): void => {
  localStorageService.setItem(KEYS.user_roles, roles);
};

const getUserRoles = (): Array<USER_ROLE_TYPE> =>
  localStorageService.getItem(KEYS.user_roles);

const isUser = () => {
  const roles = getUserRoles() || [];
  return roles.includes(USER_ROLE);
};

const isAdmin = () => {
  const roles = getUserRoles() || [];
  return roles.includes(ADMIN_ROLE);
};

export { setUserRoles, getUserRoles, isUser, isAdmin };
