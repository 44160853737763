/* @flow */

import { generateOptimisticId } from '@pluralcom/plural-js-utils';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation SigninAppleMutation($input: SigninAppleInput!) {
    signinApple(input: $input) {
      profile {
        id
        username
        name
        first_name
        last_name
        profile_color
        avatar {
          id
          smallThumbnail
        }
        # For mixpanel
        created_at
        # For mixpanel
        published_at
      }
      roles
      intercom_hash
      justSignedUp
      error {
        message
      }
    }
  }
`;

const connectMutation = graphql`
  mutation SigninAppleConnectMutation($input: SigninAppleInput!) {
    signinApple(input: $input) {
      profile {
        id
        remote_user_apple {
          id
          name
          email
        }
      }
      error {
        message
      }
    }
  }
`;

/**
 * Pass with_auth if you wish to show an optimistic update
 */
const signinAppleMutation = (
  {
    id_token,
    with_auth,
    code,
    nonce,
    first_name,
    last_name,
    real_user_status,
  }: {
    id_token: string,
    nonce?: string,
    with_auth?: boolean,
    first_name: string,
    last_name: string,
    real_user_status: number,
  },
  {
    user,
    email,
  }: {
    user?: {
      id: string,
    },
    email?: ?string,
  } = {},
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        id_token,
        with_auth,
        code,
        nonce,
        first_name,
        last_name,
        real_user_status,
        client: 'web',
      },
    };

    const optimisticResponse = user?.id
      ? {
          signinApple: {
            profile: {
              id: user.id,
              remote_user_apple: {
                id: generateOptimisticId(`remote_user_apple_${user.id}`, ''),
                name: `${first_name} ${last_name}`.trim() || user.name,
                email:
                  email ||
                  `${user.name.split(' ').join('') || 'plural'}@icloud.com`,
              },
            },
            error: null,
          },
        }
      : null;

    commitMutation({
      mutation: with_auth ? mutation : connectMutation,
      variables,
      // no optimistic response on purpose in case of signup/login
      optimisticResponse: with_auth ? null : optimisticResponse,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default signinAppleMutation;
