/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import graphql from 'babel-plugin-relay/macro';
import { generateOptimisticId } from '@pluralcom/plural-js-utils';

import { commitMutation, getCache } from '../..';

const mutation = graphql`
  mutation EditSkillMutation($input: EditSkillInput!) {
    editSkill(input: $input) {
      skill {
        id
        title
        description
        is_ghost
        published
        is_draft
        is_hobby
        experience {
          id
          description
        }
        skillTags {
          id
          title
        }
        is_hireable
      }
      error {
        message
      }
    }
  }
`;

export default (
  input: {
    id: string,
    title: string,
    is_hobby?: boolean,
    skillTags: Array<string>,
    description: string,
    experience_description: string,
    // Hiring types
    is_hireable?: boolean,
    published: boolean,
  },
  optimisticSkill,
): Promise<Object> =>
  new Promise((resolve, reject) => {
    const { experience, published, experience_description } =
      optimisticSkill || {};

    const optimisticResponse = {
      editSkill: {
        error: null,
        skill: {
          id: optimisticSkill?.id || generateOptimisticId('Skill'),
          title: optimisticSkill?.title || '',
          is_hobby: input.is_hobby || optimisticSkill?.is_hobby || false,
          description: optimisticSkill?.description || '',
          is_hireable: optimisticSkill?.is_hireable || false,
          skillTags: input.skillTags
            ? input.skillTags.map((clientTag) => ({
                id:
                  optimisticSkill?.skillTags?.find(
                    (el) => el.title === clientTag,
                  )?.id || generateOptimisticId('Tag'),
                title: clientTag,
              }))
            : optimisticSkill?.skillTags || [],
          experience: {
            id: experience?.id || generateOptimisticId('Skill_experience'),
            description:
              experience?.description || experience_description || '',
          },
          published: optimisticSkill?.published || false,
          is_draft: !published,
          is_ghost: false,
        },
      },
    };

    commitMutation({
      mutation,
      variables: { input },
      optimisticResponse,
      // configs,
      onCompleted: (...args) => {
        /* @todo @tempfix @cache clear */
        getCache().clear();
        resolve(...args);
      },
      onError: reject,
    });
  });
