/**
 * @generated SignedSource<<01fe27c59f7b81b9e9957dcbdb63bd91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelloMutationInput = {
  clientMutationId?: string | null;
  message?: string | null;
};
export type HelloMutation$variables = {
  input: HelloMutationInput;
};
export type HelloMutation$data = {
  readonly hello: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type HelloMutation = {
  response: HelloMutation$data;
  variables: HelloMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HelloMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelloMutationPayload",
        "kind": "LinkedField",
        "name": "hello",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HelloMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelloMutationPayload",
        "kind": "LinkedField",
        "name": "hello",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc0ede9e761bf9eb3594ffb1b4e2e331",
    "id": null,
    "metadata": {},
    "name": "HelloMutation",
    "operationKind": "mutation",
    "text": "mutation HelloMutation(\n  $input: HelloMutationInput!\n) {\n  hello(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf813d65ad2dd17c084685aed1a38128";

export default node;
