/* @flow */
import { createOperationDescriptor, getRequest } from 'relay-runtime';

import { getEnvironment } from '../Environment';

/**
 * Retains data in the relay store to avoid garbage collection
 *
 * useful in case of subscriptions
 *
 * https://stackoverflow.com/questions/58022925/disable-relayjs-garbage-collection
 */
const retainInStore = ({
  query,
  variables,
}: {
  query: string,
  variables?: Object,
}): Disposable => {
  // Create a relay request from the query
  const request = getRequest(query);
  // Create an operation descriptor for the query
  const operation = createOperationDescriptor(request, variables);
  // Tell Relay to retain this operation so any data referenced by it isn't garbage collected and return the disposable
  return getEnvironment().retain(operation);
};

export default retainInStore;
