import React, { useState } from 'react';
import classNames from 'classnames';
import { ButtonConnect } from '@pluralcom/blueprint';
import { v4 as uuidv4 } from 'uuid';

import useScript from 'react-script-hook';
import { useNavigate } from 'react-router-dom';
import { appleAuthHelpers, logger, mixpanelHelpers } from '../../../../utils';

import styles from './ExternalAuthButtons.module.scss';
import { fbLogin } from '../../../../utils/facebookHelpers/facebookHelpers';
import Auth from '../../../../services/Auth/Auth';
import { errorTexts } from '../../../../assets/data';
import { ModalComingSoon } from '../../../../components';

interface ExternalAuthButtonsProps {
  /** custom className */
  className?: string;
  /** Set server error */
  setServerError?: Function;
  /** On success function */
  onSuccess?: Function;
}

/**
 * Figma v0.0.2
 * ExternalAuthButtons component is used to render the social media auth buttons
 */

const ExternalAuthButtons = ({
  className,
  onSuccess,
  setServerError,
}: ExternalAuthButtonsProps) => {
  const navigate = useNavigate();
  const [fbLoading, setFbLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);

  /**
   * - Load Apple Auth JS SDK
   */
  useScript({
    src: appleAuthHelpers.script,
    checkForExisting: true,
    defer: true,
  });

  const _fbLogin = () => {
    setFbLoading(true);
    fbLogin(({ authResponse }) => {
      const { accessToken } = authResponse || {};
      if (accessToken) {
        Auth.connectFacebook(accessToken, true).then(
          ({ success, signinFacebook: { profile, error: serverErrorRes } }) => {
            setServerError?.(
              (serverErrorRes && serverErrorRes.message) || serverErrorRes,
            );
            setFbLoading(false);
            if (success) {
              onSuccess?.(profile, { noVerificationRequired: true });
              navigate(`/${profile.username}`);
            }
          },
        );
      } else {
        setFbLoading(false);
        setServerError?.(
          `${errorTexts.facebookErrorEncountered} ${errorTexts.pleaseTryAgain}`,
        );
      }
    });
  };

  const _appleLogin = async () => {
    // setAppleLoading(true);
    let response;
    const nonce = uuidv4();
    try {
      response = await appleAuthHelpers.signin({ authOptions: { nonce } });
    } catch (err) {
      logger.error('Error _appleLogin', err);
      setAppleLoading(false);
      setServerError?.(
        `${errorTexts.appleErrorEncountered} ${errorTexts.pleaseTryAgain}`,
      );
      return;
    }

    try {
      const {
        success,
        signinApple: { profile, error: serverErrorRes },
      } = await Auth.connectApple({
        ...response,
        /** Use raw nonce - notice that response has tha sha256 nonce */
        nonce,
      });
      setServerError?.(serverErrorRes?.message || serverErrorRes);
      setAppleLoading(false);
      if (success) {
        onSuccess?.(profile, { noVerificationRequired: true });
        navigate(`/${profile.username}`);
      }
    } catch (err: any) {
      setServerError?.(
        err?.message || 'Unknown error encountered. Please try again.',
      );
      setAppleLoading(false);
    }
  };
  return (
    <>
      <div className={classNames([styles.buttons, className])}>
        <ButtonConnect
          size="lg"
          onlyIcon
          data-testid="sign_in__buttons__LoginWithFacebook"
          disabled={fbLoading}
          platform="facebook"
          onClick={() => {
            mixpanelHelpers.trackEvent('Login_Button_LoginWithFacebook');
            _fbLogin();
          }}
        />
        <ButtonConnect
          data-testid="sign_in__buttons__LoginWithApple"
          platform="apple"
          onlyIcon
          disabled={appleLoading}
          size="lg"
          onClick={_appleLogin}
          onMouseDown={() => {
            mixpanelHelpers.trackEvent('SIGNUP_BUTTON_LOGINWITHAPPLE');
          }}
        />
        <ButtonConnect
          onlyIcon
          isConnected
          platform="google"
          size="lg"
          disabled={false}
          onClick={() => setIsComingSoonModalOpen(true)}
        />
        <ButtonConnect
          onlyIcon
          isConnected
          platform="twitter"
          size="lg"
          disabled={false}
          onClick={() => setIsComingSoonModalOpen(true)}
        />
      </div>
      <ModalComingSoon
        isOpen={isComingSoonModalOpen}
        toggle={() => setIsComingSoonModalOpen(false)}
      />
    </>
  );
};

export default ExternalAuthButtons;
