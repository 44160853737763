/* @flow */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import FastClick from 'js-fastclick';
import { withRouter } from '../../hocs/withRouter/withRouter';

import ModalLegal from '../ModalLegal/ModalLegal';
import { Socket } from '../../services';
import {
  logger,
  environment,
  facebookHelpers,
  intercomHelpers,
} from '../../utils';
import { ExtendRemoteAccessTokenMutation } from '../../graphql/mutations';
import {
  UnsubscribePopUp,
  ModalVerifyCode,
  ModalAddPhone,
  BackData,
  CookiesConsent,
} from './components';
import {
  useScrollToTop,
  useConsoleWelcomeLogs,
  useTrackRouteChange,
} from './hooks';

import messagesSubscribe from '../../graphql/subscriptions/messagesSubscribe';
import messageReactionsSubscribe from '../../graphql/subscriptions/messageReactionsSubscribe';
import lastUnreadNotificationTimestampSubscribe from '../../graphql/subscriptions/lastUnreadNotificationTimestampSubscribe';
import lastUnreadThreadTimestampSubscribe from '../../graphql/subscriptions/lastUnreadThreadTimestampSubscribe';
import dealsSubscribe from '../../graphql/subscriptions/dealsSubscribe';
import paymentMethodsSubscribe from '../../graphql/subscriptions/paymentMethodsSubscribe';
import sellerAccountSubscribe from '../../graphql/subscriptions/sellerAccountSubscribe';
import settingsSubscribe from '../../graphql/subscriptions/settingsSubscribe';
import threadsSubscribe from '../../graphql/subscriptions/threadsSubscribe';
import lastNetworkSyncedTimestampSubscribe from '../../graphql/subscriptions/lastNetworkSyncedTimestampSubscribe';

import './SiteWrapper.scss';

type Props = {
  children: React$Node,
  location: Location,
  isAuthenticated: boolean,
  subscriptionCmdTimestamp: number | null,
};

/**
 * Creates the global app subscriptions
 */
const _subscriptionsEffect = ({
  isAuthenticated,
  subscriptionCmdTimestamp,
}: {
  isAuthenticated: boolean,
  isAuthenticated: number | null,
}): ?Function => {
  if (isAuthenticated && subscriptionCmdTimestamp) {
    const {
      dispose: disposeLastUnreadNotificationTimestampSubscribe,
    } = lastUnreadNotificationTimestampSubscribe();
    const {
      dispose: disposeLastUnreadThreadTimestampSubscribe,
    } = lastUnreadThreadTimestampSubscribe();
    const { dispose: disposeThreadsSubscribe } = threadsSubscribe();
    const { dispose: disposeMessagesSubscribe } = messagesSubscribe();
    const {
      dispose: disposeMessageReactionsSubscribe,
    } = messageReactionsSubscribe();
    const { dispose: disposeDealsSubscribe } = dealsSubscribe();
    const {
      dispose: disposePaymentMethodsSubscribe,
    } = paymentMethodsSubscribe();
    const { dispose: disposeSellerAccountSubscribe } = sellerAccountSubscribe();
    const { dispose: disposeSettingsSubscribe } = settingsSubscribe();
    const {
      dispose: disposeLastNetworkSyncedTimestampSubscribe,
    } = lastNetworkSyncedTimestampSubscribe();
    return () => {
      disposeLastUnreadNotificationTimestampSubscribe();
      disposeLastUnreadThreadTimestampSubscribe();
      disposeThreadsSubscribe();
      disposeMessagesSubscribe();
      disposeDealsSubscribe();
      disposePaymentMethodsSubscribe();
      disposeSellerAccountSubscribe();
      disposeSettingsSubscribe();
      disposeMessageReactionsSubscribe();
      disposeLastNetworkSyncedTimestampSubscribe();
    };
  }
  return undefined;
};

/**
 * The SiteWrapper component is responsible for
 * a lot of root events.
 *
 * - Console welcome logs upon mounting
 * - Google Analytics Page tracking
 * - Legal Modals
 * - Scroll smoothing to top upon children change (route change)
 * - Sentry error tracking and logging upon catching an error
 */
const SiteWrapper = ({
  children,
  location,
  isAuthenticated,
  subscriptionCmdTimestamp,
}: Props) => {
  /** initial mount effect */
  useEffect(() => {
    /** Log startup data */
    logger.info('REACT_APP info: ', {
      version: environment.getAppVersion(),
      host_env: environment.getHostEnv(),
    });
    /** Init socket */
    Socket.connectToServer();
    /** Extend fbAccessTokn */
    facebookHelpers.getAndExtendFbAccessToken({
      mutation: ExtendRemoteAccessTokenMutation,
    });
    /** Attach Fastclick */
    FastClick.attach(document.body);
  }, []);

  /** Graphql Global Subscriptions */
  useEffect(
    () => _subscriptionsEffect({ isAuthenticated, subscriptionCmdTimestamp }),
    [isAuthenticated, subscriptionCmdTimestamp],
  );

  /** Scroll to top on route change */
  useScrollToTop({ location });

  /** Track route change */
  useTrackRouteChange({ location });

  /** Console welcome logs */
  useConsoleWelcomeLogs();

  /** Intercom */
  useEffect(() => {
    if (isAuthenticated) {
      intercomHelpers.init();
    }
  }, [isAuthenticated]);

  /**
   * We render react-helmet again to override previous renders from previous screens
   */
  return (
    <div className="site-wrapper" id="site-wrapper">
      <Helmet
      // titleTemplate="%s - Stay Home"
      >
        <title>Plural</title>
      </Helmet>
      <CookiesConsent />
      {children}
      <ModalLegal />
      <ModalVerifyCode />
      <ModalAddPhone />
      <UnsubscribePopUp />
      <BackData />
    </div>
  );
};

const mapStateToProps = ({
  auth: { isAuthenticated },
  appSettings: { subscriptionCmdTimestamp },
}) => ({
  isAuthenticated,
  subscriptionCmdTimestamp,
});

export { SiteWrapper as PureSiteWrapper };

export default connect(mapStateToProps)(withRouter(SiteWrapper));
