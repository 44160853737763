/* @flow */
import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  userToInitials,
  placeholderColorsHelpers,
} from '@pluralcom/plural-js-utils';

import styles from './UserAvatar.module.scss';

type AvatarKeyType = 'smallThumbnail' | 'thumbnail' | 'medium' | 'original';

type AvatarType = {
  id?: string,
  [AvatarKeyType]: ?string,
};

type UserType = {
  id?: string,
  first_name?: ?string,
  last_name?: ?string,
  name?: ?string,
};

type TextSizeType = 'xs' | 'sm' | 'md' | 'lg';

type Props = {
  avatar?: ?AvatarType,
  user?: ?UserType,
  type?: ?AvatarKeyType,
  textSize?: ?TextSizeType,
  className?: ?string,
  imageProps?: ?Object,
  src?: ?string,
  placeholderElementProps?: ?Object,
  uiType?: 'rounded-square' | 'circle',
  width?: string | number,
  height?: string | number,
};

const avatarTypeToTextSizeMap: { [AvatarKeyType]: TextSizeType } = {
  smallThumbnail: 'sm',
  thumbnail: 'md',
  medium: 'lg',
  original: 'lg',
};

/**
 * Plural User Avatar
 *
 * Fallsback to display placeholder images and initials
 */
const UserAvatar = ({
  avatar,
  user,
  type = 'thumbnail',
  textSize, // defaults to avatarTypeToTextSizeMap[type]
  className,
  src,
  imageProps,
  placeholderElementProps,
  uiType = 'circle',
  ...rest
}: Props) => {
  const synSrc =
    src || avatar ? get(avatar, type) : get(user, `avatar.${type}`);
  const commonProps = {
    className: classNames([styles.el, styles[uiType], className]),
    'aria-label': 'user avatar',
    ...rest,
  };

  if (synSrc) {
    return (
      <img src={synSrc} alt="user avatar" {...commonProps} {...imageProps} />
    );
  }

  const profile_color =
    user?.profile_color ||
    placeholderColorsHelpers.stringToColorObject(
      user?.user?.id || user?.pluralUser?.id || user?.id || '',
    ).color;
  const background_color = placeholderColorsHelpers.mapColorCode(
    placeholderColorsHelpers.AVATAR_BACKGROUND,
    profile_color,
  ).color;

  /**
   * Note the usage of object since the HTML element behaves like an image accepting width and height
   * divs etc would be squished
   */
  return (
    <object
      {...commonProps}
      style={{
        ...commonProps.style,
        backgroundColor: background_color,
        color: profile_color,
        borderColor: profile_color,
      }}
      {...placeholderElementProps}
    >
      <span
        className={`${styles.initials} ${
          styles[`text--${textSize || avatarTypeToTextSizeMap[type]}`]
        }`}
      >
        {userToInitials(user)}
      </span>
    </object>
  );
};

export default UserAvatar;
