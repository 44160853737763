/* @flow */
import React from 'react';
import classNames from 'classnames';
import { TouchableOpacity } from '@pluralcom/blueprint';

import { mixpanelHelpers } from '../../utils';
import imgAppStore from './Download_on_the_App_Store_Badge_US-UK_135x40.svg';
import styles from './ButtonAppStore.module.scss';

const ButtonAppStore = ({
  className,
  onClick,
  to,
  mp_location,
  ...rest
}: {
  className?: ?string,
  mp_location?: ?string,
  onClick?: Function,
  to?: string | Object,
}) => (
  <TouchableOpacity
    {...(onClick || to
      ? {
          onClick,
          to,
        }
      : {
          href: `https://apps.apple.com/us/app/plural-com/id${process.env.REACT_APP_APP_STORE_ID}`,
          target: '_blank',
        })}
    className={classNames([styles.button, className])}
    // rest props override default behavior
    {...rest}
    onClick={mixpanelHelpers.trackEventAndPass(
      {
        name: `${mp_location ? `${mp_location}` : ''}Button_AppStore`,
      },
      { forceTrack: true },
    )(onClick)}
  >
    <img src={imgAppStore} alt="Download Plural.com from the App Store" />
  </TouchableOpacity>
);

export default ButtonAppStore;
