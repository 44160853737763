/**
 * @generated SignedSource<<e8ef3f882234530c92e16a2a79878193>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveEmailOrPhoneInput = {
  clientMutationId?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type RemoveEmailOrPhoneMutation$variables = {
  input: RemoveEmailOrPhoneInput;
};
export type RemoveEmailOrPhoneMutation$data = {
  readonly removeEmailOrPhone: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly profile: {
      readonly emails: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
        readonly verified: boolean | null;
      } | null> | null;
      readonly phones: ReadonlyArray<{
        readonly id: string;
        readonly value: string | null;
        readonly verified: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};
export type RemoveEmailOrPhoneMutation = {
  response: RemoveEmailOrPhoneMutation$data;
  variables: RemoveEmailOrPhoneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "verified",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Email",
  "kind": "LinkedField",
  "name": "emails",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Phone",
  "kind": "LinkedField",
  "name": "phones",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveEmailOrPhoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEmailOrPhonePayload",
        "kind": "LinkedField",
        "name": "removeEmailOrPhone",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveEmailOrPhoneMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveEmailOrPhonePayload",
        "kind": "LinkedField",
        "name": "removeEmailOrPhone",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4aee73786611d2186ecdbb9a7af774db",
    "id": null,
    "metadata": {},
    "name": "RemoveEmailOrPhoneMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveEmailOrPhoneMutation(\n  $input: RemoveEmailOrPhoneInput!\n) {\n  removeEmailOrPhone(input: $input) {\n    profile {\n      emails {\n        id\n        value\n        verified\n      }\n      phones {\n        id\n        value\n        verified\n      }\n      id\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc103d4292ddcb98f9412d05719ee175";

export default node;
