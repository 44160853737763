import React from 'react';

import { Text } from '@pluralcom/blueprint';
import TimeAgo from '../../../TimeAgo/TimeAgo';

import styles from './ListItemUserInteractionTimeAgo.module.scss';

interface ListItemUserInteractionTimeAgoProps
  extends React.ComponentProps<typeof TimeAgo> {
  /** custom className */
  className?: string;
}

/**
 * - Figma v0.0.1
 * - ListItemUserInteractionTimeAgo component to show the time ago of the user interaction
 * - Developer component
 */
const ListItemUserInteractionTimeAgo = ({
  className,
  ...rest
}: ListItemUserInteractionTimeAgoProps) => (
  <Text fontSizeType="t7" className={styles.time}>
    <TimeAgo className={styles.time} {...rest} />
  </Text>
);

export default ListItemUserInteractionTimeAgo;
