/**
 * @generated SignedSource<<5ef6f00fff3f1f84d268ea59e5cb25ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecommendSkillInput = {
  clientMutationId?: string | null;
  skill_id?: string | null;
};
export type RecommendSkillMutation$variables = {
  input: RecommendSkillInput;
};
export type RecommendSkillMutation$data = {
  readonly recommendSkill: {
    readonly error: {
      readonly message: string | null;
    } | null;
  } | null;
};
export type RecommendSkillMutation = {
  response: RecommendSkillMutation$data;
  variables: RecommendSkillMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecommendSkillPayload",
        "kind": "LinkedField",
        "name": "recommendSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecommendSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecommendSkillPayload",
        "kind": "LinkedField",
        "name": "recommendSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8885712122880de663fb2f78900166ed",
    "id": null,
    "metadata": {},
    "name": "RecommendSkillMutation",
    "operationKind": "mutation",
    "text": "mutation RecommendSkillMutation(\n  $input: RecommendSkillInput!\n) {\n  recommendSkill(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e59e16769a4c538c7c5aefe6df32ea46";

export default node;
