import React, { SyntheticEvent, useReducer, useState } from 'react';

import { Text, InputText } from '@pluralcom/blueprint';
import { analyticsHelpers } from '@pluralcom/plural-js-utils';
import AddModal from '../AddModal/AddModal';
import { mixpanelHelpers, validation } from '../../../../../utils';
import { errorTexts } from '../../../../../assets/data';
import { AddEmailOrPhoneMutation } from '../../../../../graphql/mutations';

import styles from './AddModalEmail.module.scss';

interface AddModalEmailProps extends React.ComponentProps<typeof AddModal> {
  /** auth request ID */
  auth_reqid: string;
  /** callback when success */
  onSuccess?: Function;
}

/**
 * - Figma v0.0.1
 * - This is the AddModalEmail compoent for settings/AccountTab
 */
const AddModalEmail = ({
  auth_reqid: is_auth_reqid,
  toggle,
  onSuccess,
  ...rest
}: AddModalEmailProps) => {
  const [accountData, setAccountData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      value: '',
      error: '',
      type: 'email',
    },
  );

  const [isLoading, setIsLoading] = useState(false);

  /**
   * - Reset form state
   */
  const _resetFormState = () => {
    const currAccountData = { ...accountData };
    currAccountData.value = '';
    currAccountData.error = '';
    setAccountData(currAccountData);
    setIsLoading(false);
  };

  /**
   * - Check if email is valid
   */
  const _getFieldError = (value: string): boolean => {
    const currAccountData = { ...accountData };

    if (!validation.isValidEmail(value)) {
      currAccountData.error = errorTexts.invalidEmail;
    } else {
      currAccountData.error = null;
    }

    setAccountData(currAccountData);

    if (!currAccountData.error) {
      return false;
    }
    return true;
  };

  /**
   * - Handle input change
   */
  const _handleInputChange = ({ target: { value } }) => {
    if (!accountData.value) {
      mixpanelHelpers.trackEvent(
        analyticsHelpers.events.SETTINGS_ACCOUNT_POPUP_PHONE_CHANGE.name,
      );
    }

    /**
     * - Set account data
     */
    setAccountData({
      ...accountData,
      value,
      error: '',
    });
  };

  /**
   * - Handle form submit
   */
  const _handleSubmit = (e: SyntheticEvent) => {
    e?.preventDefault();

    /**
     * - Check if email is valid
     */
    const _hasError = _getFieldError(accountData.value);

    /**
     * - If there is an error, return
     */
    if (_hasError) {
      return false;
    }

    /**
     * - Set loading state
     */
    setIsLoading(true);

    /**
     * - Construct mutation payload
     */
    const input = {
      auth_reqid: is_auth_reqid,
      email: accountData.value,
    };

    const { value } = accountData;

    /**
     * - Call mutationseSat
     */
    AddEmailOrPhoneMutation(input)
      .then((res: any) => {
        /**
         * - If there is an error, set error state
         */
        if (res.addEmailOrPhone.error) {
          setAccountData({
            ...accountData,
            error: res.addEmailOrPhone.error.message,
          });

          /**
           * - If there is an onSuccess callback, call it
           */
        } else if (onSuccess) {
          /**
           * - Reset form state
           */
          setAccountData({
            ...accountData,
            error: '',
          });

          /**
           * - Call onSuccess callback
           */
          const { auth_reqid, profile } = res.addEmailOrPhone;
          onSuccess?.({ value, auth_reqid, profile, modal: 'AddEmail' });
        }

        /**
         * - Set loading state
         */
        setIsLoading(false);
      })
      .catch(() => {
        /**
         * - Set loading state
         * - Set error state
         * - Reset form state
         * - Return
         */
        const currAccountData = { ...accountData };
        currAccountData.error = 'Something went wrong';
        setAccountData(currAccountData);
        setIsLoading(false);
      });

    return true;
  };

  /**
   * - Toggle modal
   * - Reset form state
   */
  const _toggle = () => {
    // If loading, return
    if (isLoading) {
      return;
    }

    _resetFormState();
    toggle?.();
  };

  return (
    <AddModal
      {...rest}
      isMaxHeight={false}
      toggle={_toggle}
      navHeaderProps={{
        text: 'Add Email',
      }}
      bottomBarProps={{
        primaryButtonProps: {
          isLoading,
          onClick: _handleSubmit,
        },
        tertiaryButtonProps: {
          disabled: isLoading,
          onClick: _toggle,
        },
      }}
      modalBody={
        <>
          <Text fontSizeType="t7" className={styles.text}>
            Enter the Email you want to add to your account
          </Text>
          <InputText
            name="email"
            value={accountData.value}
            hasError={accountData?.error}
            helperText={accountData?.error || ''}
            placeholder="Enter Email"
            autoFocus
            onChange={_handleInputChange}
            onKeyUp={(e) => {
              e?.preventDefault?.();
              e?.stopPropagation?.();

              /**
               * - If ENTER key is pressed, submit form
               */
              if ([13].includes(e.keyCode)) {
                _handleSubmit?.(e);
              }

              /**
               * - If ESC key is pressed, toggle modal
               */
              if ([27].includes(e.keyCode)) {
                _toggle();
              }
            }}
          />
        </>
      }
    />
  );
};

export default AddModalEmail;
