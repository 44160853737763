/* @flow */
import React from 'react';

import styles from './ErrorView.module.scss';

type Props = {
  className?: ?string,
  error: Error | Array<Error>,
};

export default ({ className, error, ...rest }: Props) => {
  const errorText = Array.isArray(error)
    ? error[0]?.message || error[0]?.name
    : error?.message || error?.name;
  return (
    <section
      className={`${styles.container}${className ? ` ${className}` : ''}`}
      {...rest}
    >
      <span
        className={styles.emoji}
        role="img"
        aria-label="smiling_face_with_smiling_eyes_and_hand_covering_mouth"
      >
        🤭
      </span>
      <p className={styles.text}>Oops, Something went unexpectedly wrong.</p>
      {errorText ? <p className={styles.text}>{errorText}</p> : null}
      <button
        className={styles.button}
        type="button"
        onClick={() => window.location.reload()}
      >
        Reload
      </button>
      <a className={styles.button} href="/">
        Back to Plural
      </a>
    </section>
  );
};
