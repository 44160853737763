/**
 * @generated SignedSource<<a3f7ae2475111cf3f3f6dbb6de1b8160>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditSkillFAQuestionInput = {
  answer: string;
  clientMutationId?: string | null;
  skill_question_id: string;
};
export type EditAnswerMutation$variables = {
  input: EditSkillFAQuestionInput;
};
export type EditAnswerMutation$data = {
  readonly editSkillFAQuestion: {
    readonly error: {
      readonly code: number | null;
      readonly id: string;
      readonly message: string | null;
      readonly technical_message: string | null;
      readonly title: string | null;
    } | null;
    readonly skill_question: {
      readonly answer: string;
      readonly id: string;
    } | null;
  } | null;
};
export type EditAnswerMutation = {
  response: EditAnswerMutation$data;
  variables: EditAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditSkillFAQuestionPayload",
    "kind": "LinkedField",
    "name": "editSkillFAQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillFAQuestion",
        "kind": "LinkedField",
        "name": "skill_question",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answer",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "technical_message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditAnswerMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditAnswerMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1b4c8d525d5f7d5b4490d1e11b30c2fa",
    "id": null,
    "metadata": {},
    "name": "EditAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation EditAnswerMutation(\n  $input: EditSkillFAQuestionInput!\n) {\n  editSkillFAQuestion(input: $input) {\n    skill_question {\n      id\n      answer\n    }\n    error {\n      id\n      title\n      technical_message\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "219533f59e3345992a226bc63e758341";

export default node;
