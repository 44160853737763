/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';

import ErrorText from '../ErrorText/ErrorText';

import TileItemMenu from './components/TileItemMenu/TileItemMenu';

import styles from './TileItem.module.scss';

type Props = {
  children: React$Node,
  error?: string,
  noWrapper?: boolean,
  className?: string,
  containerClassName?: string,
  errorDataTestId?: string,
  showMenu?: boolean,
  onCloseMenu?: Function,
  menuProps?: object,
  uiType: ?'clay' | 'transparent' | 'clay-split',
};

export default class TileItem extends Component<*, Props, *> {
  _containerRef = null;

  _setRef = (el) => {
    this._containerRef = el;
  };

  _renderChildren = () => {
    const {
      children,
      className,
      noWrapper,
      menuProps,
      showMenu,
      onCloseMenu,
    } = this.props;
    const { className: menuClassName } = menuProps || {};
    const fragment = (
      <>
        {children}
        {menuProps ? (
          <TileItemMenu
            showMenu={showMenu}
            onClose={onCloseMenu}
            {...menuProps}
            className={classNames(styles.menu, menuClassName)}
            associatedElementRef={this._containerRef}
          />
        ) : null}
      </>
    );
    return noWrapper ? (
      fragment
    ) : (
      <div className={classNames([styles.item, className])}>{fragment}</div>
    );
  };

  render() {
    const {
      containerClassName,
      error,
      uiType = 'clay-split',
      errorDataTestId,
    } = this.props;
    return (
      <>
        <div
          className={classNames([
            styles['item-container'],
            styles[`item-container_${uiType}`],
            { [styles['item-container_error']]: !!error },
            containerClassName,
          ])}
          ref={this._setRef}
        >
          {this._renderChildren()}
        </div>
        <ErrorText
          dataTestId={errorDataTestId}
          className={styles.error}
          error={error}
        />
      </>
    );
  }
}
