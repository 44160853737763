/**
 * @generated SignedSource<<806843cf67239b64a24c0e3a1be71af4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePositionInput = {
  clientMutationId?: string | null;
  companyName: string;
  companyURL?: string | null;
  end?: string | null;
  skillId: string;
  start: string;
  title: string;
};
export type CreatePositionMutation$variables = {
  input: CreatePositionInput;
};
export type CreatePositionMutation$data = {
  readonly createPosition: {
    readonly error: {
      readonly message: string | null;
    } | null;
    readonly experience: {
      readonly id: string;
    } | null;
    readonly newPositionEdge: {
      readonly node: {
        readonly company: {
          readonly id: string;
          readonly name: string | null;
          readonly url: string | null;
        } | null;
        readonly end: string | null;
        readonly start: string | null;
        readonly title: string | null;
      } | null;
    } | null;
  } | null;
};
export type CreatePositionMutation = {
  response: CreatePositionMutation$data;
  variables: CreatePositionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Experience",
  "kind": "LinkedField",
  "name": "experience",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePositionPayload",
        "kind": "LinkedField",
        "name": "createPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PositionEdge",
            "kind": "LinkedField",
            "name": "newPositionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Position",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePositionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePositionPayload",
        "kind": "LinkedField",
        "name": "createPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PositionEdge",
            "kind": "LinkedField",
            "name": "newPositionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Position",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2699e939f74edc65120275eff0eb576",
    "id": null,
    "metadata": {},
    "name": "CreatePositionMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePositionMutation(\n  $input: CreatePositionInput!\n) {\n  createPosition(input: $input) {\n    error {\n      message\n      id\n    }\n    newPositionEdge {\n      node {\n        title\n        start\n        end\n        company {\n          id\n          name\n          url\n        }\n        id\n      }\n    }\n    experience {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eda1b18a714336d18b2638a16aebec2a";

export default node;
