/* @flow */
import React from 'react';
import classNames from 'classnames';
import { Marker } from 'react-map-gl';

import styles from './MapCurrentLocationPin.module.scss';

const MapCurrentLocationPin = ({
  lat,
  lng,
  className,
  style,
}: {
  lat: number,
  lng: number,
  className?: ?string,
  style: Object,
}) => (
  <Marker
    latitude={lat}
    longitude={lng}
    style={style}
    offset={[-19 / 2, -19 / 2]}
    className={classNames([styles['map-current-location-pin'], className])}
    anchor="top-left"
  >
    <div className={styles['map-current-location-pin__pin-container']}>
      <div className={styles['map-current-location-pin__pin']} />
    </div>
  </Marker>
);

export default MapCurrentLocationPin;
