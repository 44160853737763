/**
 * @generated SignedSource<<9660469e2ab99274dd81864745111237>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InputSkill_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InputSkillList_viewer">;
  readonly " $fragmentType": "InputSkill_viewer";
};
export type InputSkill_viewer$key = {
  readonly " $data"?: InputSkill_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InputSkill_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "other_user_id"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipOwnSkills"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "title_term"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InputSkill_viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "other_user_id",
          "variableName": "other_user_id"
        },
        {
          "kind": "Variable",
          "name": "skipOwnSkills",
          "variableName": "skipOwnSkills"
        },
        {
          "kind": "Variable",
          "name": "title_term",
          "variableName": "title_term"
        }
      ],
      "kind": "FragmentSpread",
      "name": "InputSkillList_viewer"
    }
  ],
  "type": "ViewerType",
  "abstractKey": null
};

(node as any).hash = "a893271ec7f7a14afdaf198088795d0a";

export default node;
